import { Box, Link, Typography } from '@mui/material';
import { get } from 'lodash';
import React, { FC } from 'react';
import SummaryLine from '../../../ui-component/form-component/abschluss-page/SummaryLine';

// import schemas
// import OrgaSchema from '../../../../lib/paths/organisation_FULL_Paths.json';
import PersonSchema from '../../../../lib/paths/person_FULL_Paths.json';
import UserSchema from '../../../../lib/paths/user_Paths.json';
import CreateForm from '../../../ui-component/form-component/create-form/CreateForm';
const PP = PersonSchema.attributes;
// const OP = OrgaSchema.attributes;
const UP = UserSchema.attributes;

const AbschlussRegistrierung: FC<{
	formValue: any;
	updateFunction: (_key: string, _value: any) => void;
	personDaten: any;
	// organisationDaten: any;
	LoginDaten: any;
}> = ({ formValue, updateFunction, personDaten, LoginDaten }) => {
	return (
		<>
			<Typography variant='h5' textAlign={'center'}>
				{' '}
				Abschluss
			</Typography>

			<Box sx={{ mt: '2rem' }}>
				<Typography variant='h6'>Persondaten</Typography>
				<SummaryLine label='Titel' value={get(personDaten, PP.Titel.path, '-')} showErrorMessagePlaceholder={false} />
				<SummaryLine label='Geschlecht' value={get(personDaten, PP.Geschlecht.path, '-')} showErrorMessagePlaceholder={false} />
				<SummaryLine label='Vorname' value={get(personDaten, PP.Vorname.path, '')} showErrorMessagePlaceholder={false} />
				<SummaryLine label='Nachname' value={get(personDaten, PP.Nachname.path, '')} showErrorMessagePlaceholder={false} />
				<SummaryLine label='Geburtsdatum' value={get(personDaten, PP.Geburtsdatum.path, '')} showErrorMessagePlaceholder={false} />
				<SummaryLine label='Staatsbuergerschaft' value={get(personDaten, PP.Staatsbuergerschaft.path, '')} showErrorMessagePlaceholder={false} />
				<SummaryLine
					label='E-Mail - Die Passwortvergabe wird an diese Adresse gesendet'
					value={get(personDaten, PP.E_Mail.path, '')}
					showErrorMessagePlaceholder={false}
				/>

				<SummaryLine label='Telefon' value={get(personDaten, PP.Telefon.path, '')} showErrorMessagePlaceholder={false} />
				<SummaryLine
					label='Strasse Und Hausnummer'
					value={get(personDaten, PP.Adressdaten.schema.attributes.Strasse_Und_Hausnummer.path, '')}
					showErrorMessagePlaceholder={false}
				/>
				<SummaryLine
					label='PLZ, Ort'
					value={
						get(personDaten, PP.Adressdaten.schema.attributes.PLZ.path, '') + ', ' + get(personDaten, PP.Adressdaten.schema.attributes.Ort.path, '')
					}
					showErrorMessagePlaceholder={false}
				/>
				<SummaryLine
					label='Bundesland'
					value={get(personDaten, PP.Adressdaten.schema.attributes.Bundesland.path, '')}
					showErrorMessagePlaceholder={false}
				/>
			</Box>

			{/* <Box sx={{ mt: '2rem' }}>
				<Typography variant='h6'>Organisationsdaten</Typography>

				<SummaryLine
					label='Rechtverbindlicher Name der Organisation'
					value={get(organisationDaten, OP.Name_Der_Organisation.path)}
					showErrorMessagePlaceholder={false}
				/>

				<SummaryLine
					label='Rechtform der Organisation'
					value={enumT(get(organisationDaten, OP.Rechtsform_Der_Organisation.path))}
					showErrorMessagePlaceholder={false}
				/>
				<SummaryLine
					label='Straße und Hausnummer'
					value={get(organisationDaten, OP.Strasse_Und_Hausnummer.path)}
					showErrorMessagePlaceholder={false}
				/>
				<SummaryLine
					label='Postleitzahl, Ort'
					value={get(organisationDaten, OP.PLZ.path) + ', ' + get(organisationDaten, OP.Ort.path)}
					showErrorMessagePlaceholder={false}
				/>
				<SummaryLine label='E-Mail-Adresse' value={get(organisationDaten, OP.E_Mail.path)} showErrorMessagePlaceholder={false} />
				<SummaryLine label='Telefon' value={get(organisationDaten, OP.Telefon.path)} showErrorMessagePlaceholder={false} />
			</Box> */}

			<Box sx={{ mt: '2rem', mb: '2rem' }}>
				<Typography variant='h6'>Logindaten</Typography>
				<SummaryLine label='Benutzername' value={get(LoginDaten, UP.username.path)} showErrorMessagePlaceholder={false} />
			</Box>

			<Typography variant='h6' sx={{ fontWeight: 600 }}>
				Zustimmung zu den Nutzungsbedingungen:
			</Typography>
			<Typography>
				Lesen Sie die{' '}
				<Link target='_blank' href={'/datenschutz/ANBest_P_2023.pdf'}>
					Nutzungsbedingungen
				</Link>{' '}
				und{' '}
				<Link target='_blank' href={'/datenschutz/Datenschutzhinweise_HA_Projektfoerderung.pdf'}>
					Datenschutzrichtlinien
				</Link>{' '}
				sorgfältig durch. Um fortzufahren, bestätigen Sie Ihre Zustimmung durch das Setzen eines Häkchens
			</Typography>

			<CreateForm formValues={formValue} formStruc={formStrucDatenschutz()} updateFormValuesFunc={updateFunction} />
		</>
	);
};

export default AbschlussRegistrierung;

const formStrucDatenschutz = () => {
	return [
		{
			fields: [
				{
					path: 'zustimmung',
					type: 'boolean',
					label: `Ich habe die Nutzungsbedingungen und die Datenschutzrichtlinien gelesen und akzeptiere diese.`,
				},
			],
			userProps: { columnToDivide: 1 },
		},
	];
};
