import React from 'react';
import { get } from 'lodash';

import { Alert, Snackbar } from '@mui/material';

interface MuiSnackbarProps {
	showSnackbar: boolean | null;
	closeSnackbar: () => void;
	alertMessage: string;
	error?: boolean;
	autoHideDuration?: number;
	[key: string]: any; // Allows additional props to be passed
}

const MuiSnackbar: React.FC<MuiSnackbarProps> = (props) => {
	const { showSnackbar, closeSnackbar, error, alertMessage, autoHideDuration, ...restProps } = props;
	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={Boolean(get(props, 'showSnackbar', false))}
			onClose={closeSnackbar}
			autoHideDuration={get(props, 'autoHideDuration', 2000)}
			{...restProps}
		>
			<Alert onClose={closeSnackbar} severity={error ? 'error' : 'success'}>
				{alertMessage}
			</Alert>
		</Snackbar>
	);
};

export default MuiSnackbar;
