import { Box, Container, Link, Typography } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGES_URLS } from '../../lib/constants/routes';

function Footersocket() {
	const navigate = useNavigate();
	const date = new Date();
	return (
		<Box component={'section'} sx={{ marginTop: '2rem', flexGrow: 1, backgroundColor: 'secondary.main', color: 'common.white', padding: '1rem' }}>
			<Container sx={{ marginTop: 0 }}>
				<Grid
					xs={12}
					container
					justifyContent='space-between'
					alignItems='center'
					flexDirection={{ xs: 'column', sm: 'row' }}
					sx={{ fontSize: '12px' }}
				>
					<Grid sx={{ order: { xs: 2, sm: 1 }, textAlign: 'left' }}>
						<Typography variant='subtitle2' component={'p'}>
							© {date.getFullYear()} Akademie der Künste
						</Typography>
					</Grid>
					<Grid container columnSpacing={2} sx={{ order: { xs: 1, sm: 2 } }}>
						<Grid>
							<Link
								variant='subtitle2'
								sx={{ textDecoration: 'none', color: '#fff', cursor: 'pointer' }}
								onClick={() => navigate(PAGES_URLS.Impressum)}
							>
								Impressum
							</Link>
						</Grid>
						<Grid>
							<Link
								variant='subtitle2'
								sx={{ textDecoration: 'none', color: '#fff', cursor: 'pointer' }}
								onClick={() => navigate(PAGES_URLS.Datenschutz)}
							>
								Datenschutz
							</Link>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}

export default Footersocket;
