export const METHOD_GET = 'GET';
export const METHOD_PUT = 'PUT';
export const METHOD_POST = 'POST';
export const METHOD_PATCH = 'PATCH';
export const METHOD_DELETE = 'DELETE';

export const APIS = {
	user: 'users',
	ChangePassword: 'auth/change-password',
	faq: 'faqs',
	FörderProgramme: 'foerderprogramms',
	TechnologieBereich: 'technologiebereichs',
	Person: 'persons',
	Organisation: 'organisations',
	Eingang: 'eingangs',
	Antrag: 'antrags',
	Foerderantrag: 'foerderantrags',
	Mittelabruf: 'mittelabrufs',
	Zwischenbericht: 'zwischenberichts',
	Verwendungsnachweis: 'verwendungsnachweiss',
	Abschlussbericht: 'abschlussberichts',
	Kostenkategorie: 'kostenkategories',
	Leistungsgruppe: 'leistungsgruppes',
	Kostenposition: 'kostenpositions',
	Nachweisposition: 'nachweispositions',
	StundenNachwies: 'stundennachweiss',
	Abschreibung: 'abschreibungs',
	'Portal-Nachricht': 'nachrichts',
	PushNotificaiton: 'push-notifications',
	AenderungsAnfrage: 'aenderungsanfrages',
	RegistrierungsAnfrage: 'registrierungsanfrages',
	ausschreibungs: 'ausschreibungs',
};
