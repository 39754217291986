import AddIcon from '@mui/icons-material/Add';
import { Button, Container, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import theme from '../../../assets/theme/theme';
import LoadingBackdrop from '../../../components/ui-component/loading/LoadingBackdrop';
import NoDetailsInfo from '../../../components/ui-component/no-details-info/NoDetailsInfo';
import GetRecord from '../../../lib/api/GetRecord';
import POP_LEVEL from '../../../lib/api/Populations.json';
import { APIS } from '../../../lib/constants/apis';
import { PAGES_URLS } from '../../../lib/constants/routes';
import { enumT, removeDataKeys, removeNullDeep } from '../../../utils/helper';

// import Schema
import BewerbungSchema from '../../../lib/paths/antrag_FULL_Paths.json';
const P = BewerbungSchema.attributes;

const FoerderungUebersicht = () => {
	const location = useLocation();
	const numericId = get(location, 'state.id');

	const [applicationData, setApplicationData] = useState({});
	const [loading, setLoading] = useState<boolean>(false);

	const RenderDashboard = () => {
		return <></>;
	};

	useEffect(() => {
		const fetch = async () => {
			setLoading(true);
			try {
				let res = await GetRecord(APIS.Antrag, numericId, POP_LEVEL.antrag.FULL);
				if (res) {
					const app = removeNullDeep(removeDataKeys(get(res, 'data', {})));
					setApplicationData(app);
				}
			} catch (err: any) {
				console.error('ERROR: Fetching Application ', err);
			} finally {
				setLoading(false);
			}
		};
		if (numericId) fetch();
	}, [numericId]);

	if (!numericId) return <NoDetailsInfo title='Antrag' goBackUrl={PAGES_URLS.MeineFoerderung} />;
	if (loading) return <LoadingBackdrop open={loading} />;

	return (
		<>
			<Container>
				<Stack spacing={3} className='buit-dashboard'>
					<span>
						<Typography variant='h3' component='h2'>
							Meine Förderung: {get(applicationData, P.Projekttitel.path, '')}
						</Typography>
						<Typography variant='body1'>
							{' '}
							Förderprogramm: {enumT(get(applicationData, P.Ausschreibung.schema.attributes.Programmname.path))}
						</Typography>
					</span>
					<Typography variant='body1'>Dies ist ein Beispieltext, der als Platzhalter dient.</Typography>
					{/* ** Render Dashboard abhängig von Antragsformular ** */}
					<RenderDashboard />
				</Stack>
			</Container>
		</>
	);
};

export default FoerderungUebersicht;

type AntragButtonProps = {
	name: string;
	description: string;
	onClick: () => void;
	variant?: 'text' | 'outlined' | 'contained';
	disabled?: boolean;
};
export const AntragButton: FC<AntragButtonProps> = ({ name, description, onClick, disabled, variant = 'outlined' }) => {
	return (
		<>
			<Button
				disabled={disabled}
				variant={'outlined'}
				onClick={() => onClick()}
				sx={{
					borderRadius: 0,
					borderColor: theme.palette.border.primary,
					backgroundColor: disabled ? theme.palette.action.disabled : '#fff',
					color: disabled ? theme.palette.text.disabled : theme.palette.primary.main,
					textAlign: 'left',
					width: '26.5rem',
					justifyContent: 'space-between',
					padding: '1rem 1rem',
					marginTop: '0.5rem !important',
					maxWidth: 'unset',
				}}
				endIcon={variant === 'contained' ? <AddIcon sx={{ fontSize: '2.5rem !important' }} /> : null}
			>
				<Stack>
					<Typography variant='h5' sx={{ fontWeight: 400 }}>
						{name}
					</Typography>
					<Typography variant='body2'>{description}</Typography>
				</Stack>
			</Button>
		</>
	);
};

type AntragsButtonWrapperType = {
	title: string;
	children: ReactNode;
};
export const AntragsButtonWrapper: FC<AntragsButtonWrapperType> = ({ title, children }) => {
	return (
		<Stack spacing={0} sx={{ mt: '1rem' }}>
			<Typography variant='h6'>{title}</Typography>
			<Stack direction={'column'} columnGap={5}>
				{children}
			</Stack>
		</Stack>
	);
};
