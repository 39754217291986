import { concat } from 'lodash';
import React, { FC } from 'react';
import CreateForm from '../../../../../../components/ui-component/form-component/create-form/CreateForm';
import { useApplication } from '../../../../../../context/ApplicationContext';
import { PLATZHALTER_TEXT } from '../../../../../../lib/constants/constant';
import { FormStrucItem } from '../../../../../../lib/types/types';
import ApplicationHeading from '../../../application-heading/ApplicationHeading';

// import schemas
import AntragSchema from '../../../../../../lib/paths/antrag_FULL_Paths.json';
const P = AntragSchema.attributes;

const Allgemein: FC<{}> = () => {
	const { application, setApplicationProperty, readOnly } = useApplication();
	return (
		<>
			<ApplicationHeading title='Bewerbungsdaten' description={PLATZHALTER_TEXT} />

			<CreateForm formStruc={formStrucBeschreibung()} formValues={application} updateFormValuesFunc={setApplicationProperty} readOnly={readOnly} />
		</>
	);
};
export default Allgemein;

const formStrucBeschreibung = () => {
	let struc: FormStrucItem[] = [
		{
			fields: [P.Projekttitel, P.Bewerbung_geplantes_Projekt],
			userProps: { columnToDivide: 1 },
		},
		{
			fields: [P.Bewerbung_Wunschzeitraum_Fuer_Stipendium_Von, P.Bewerbung_Wunschzeitraum_Fuer_Stipendium_Bis],

			userProps: { columnToDivide: 1 },
		},
	];
	return struc;
};

export const getFormStrucAllgemein = () => {
	return concat(formStrucBeschreibung());
};
