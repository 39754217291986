import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { get } from 'lodash';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import NotFoundImage from '../../../../../assets/images/404_image.png';
import MarkdownRenderer from '../../../../../components/ui-component/markdown-renderer/MarkdownRenderer';
import { PAGES_URLS } from '../../../../../lib/constants/routes';
import { STRAPI_URL } from '../../../../../lib/constants/url';
import { FoerderProgrammProps } from '../../../../../lib/types/types';

// import Schemas
import { isArray } from 'lodash';
import FOERDERPROGRAMM_PATHS from '../../../../../lib/paths/ausschreibung_FULL_Paths.json';
const P = FOERDERPROGRAMM_PATHS.attributes;

const FoerderProgrammGrid: FC<FoerderProgrammProps> = ({ data }) => {
	const navigate = useNavigate();

	const getImageUrl = (index: number) => {
		if (get(data[index], P.itaros_id.path + '.attributes.url')) {
			return `${STRAPI_URL}${get(data[index], P.itaros_id.path + '.attributes.url')}`;
		} else {
			return NotFoundImage;
		}
	};

	if (isArray(data) && (data || []).length > 0) {
		return (
			<>
				<Grid container spacing={5} columns={3}>
					{(data || []).map((ele, i) => (
						<Grid xs={3} sm={1.5} md={1} key={i}>
							<Card
								raised
								sx={{ maxWidth: '25rem' }}
								onClick={() => navigate(`${PAGES_URLS.FoerderProgrammDetails}`, { state: { id: get(ele, 'id', -1) } })}
							>
								<CardActionArea component='div'>
									<CardMedia
										component={'img'}
										height={265}
										image={getImageUrl(i)}
										alt={`Ausschreibungs-logo-${get(ele, P.itaros_id.path + '.attributes.name')}`}
									/>
									<CardContent>
										<Typography gutterBottom variant='h6' component={'div'} sx={{ mb: '1rem', fontWeight: 600 }}>
											{get(ele, 'attributes.Allgemein_Titel_Deutsch')}
										</Typography>
										<Typography
											variant='body1'
											color='text.secondary'
											component={'div'}
											sx={{
												height: '6.5rem', // Adjust the height to match 5 lines of text
												lineHeight: '1.1rem', // Line height for body2
												overflow: 'hidden',
												display: '-webkit-box',
												WebkitBoxOrient: 'vertical',
												WebkitLineClamp: 5,
											}}
										>
											<MarkdownRenderer markdown={get(ele, P.Programmname.path, '')} />
										</Typography>
									</CardContent>
									<CardActions>
										<Button endIcon={<KeyboardArrowRightIcon />}>Mehr erfahren</Button>
									</CardActions>
								</CardActionArea>
							</Card>
						</Grid>
					))}
				</Grid>
			</>
		);
	} else {
		return (
			<>
				<Typography>Keine Ergebnisse</Typography>
			</>
		);
	}
};

export default FoerderProgrammGrid;
