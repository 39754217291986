import { AppBar, Box, Toolbar, Typography, styled } from '@mui/material';

import { NavLink, useLocation } from 'react-router-dom';

import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useAuthentication } from '../../context/AuthContext';
import { getLocalstorageToken } from '../../context/AuthHelper';
import GetRecords from '../../lib/api/GetRecords';
import { APIS } from '../../lib/constants/apis';
import DesktopHeader from './header-components/DesktopHeader';
import MobileHeader from './header-components/MobileHeader';

export const StyledToolbar = styled(Toolbar)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
	height: ' 75px',
});
export const NavLinksContainer = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
}));
export const NavLinkStyled = styled(NavLink)(({ theme }) => ({
	padding: theme.spacing(3.1, 2),
	color: theme.palette.navbar.primary.main,
	textDecoration: 'none',
	borderBottom: `2px solid transparent`,
	fontWeight: 500,
	fontSize: '0.938rem',
	'&.active': {
		color: theme.palette.navbar.primary.active,
		borderBottom: `2px solid ${theme.palette.navbar.primary.active}`,
	},
}));

function Header() {
	const location = useLocation();
	const { isAuthenticated } = useAuthentication();
	const [unreadMessageCount, setUnreadMessageCount] = useState(0);

	useEffect(() => {
		const fetchNachricht = async () => {
			try {
				let fields = ['id'];
				let filterOption = {
					Organisation: { id: { $eq: get(getLocalstorageToken(), 'organisationId') } },
					Ordner: 'Posteingang',
					gelesen: 'false',
				};
				let pagination = {
					start: 0,
					limit: 1,
					withCount: true,
				};
				let res = await GetRecords(APIS['Portal-Nachricht'], [], filterOption, [], pagination, fields);
				setUnreadMessageCount(get(res, 'meta.pagination.total', 0));
			} catch (err: any) {
				const errorData = JSON.parse(err.message);
				if (get(errorData, 'error.status') === 403 || get(errorData, 'error.status') === 401) {
					localStorage.removeItem('adk-token');
					window.location.reload();
				}
				console.error('ERROR: Fetching Nachrichten ', err);
			}
		};

		if (isAuthenticated) fetchNachricht();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	if (isAuthenticated) {
		return (
			<>
				<AppBar component={'nav'} position='sticky' sx={{ borderBottom: '1px solid #000' }}>
					<Box sx={{ display: { xs: 'none', md: 'block' } }}>
						<DesktopHeader unreadMessageCount={unreadMessageCount} />
					</Box>
					<Box sx={{ display: { xs: 'block', md: 'none' } }}>
						<MobileHeader />
					</Box>
					<Typography sx={{ filter: 'contrast(100%)', display: 'none' }} className='visually-hidden' component={'h1'}>
						Hidden Heading
					</Typography>
				</AppBar>
			</>
		);
	}
	return <></>;
}

export default Header;
