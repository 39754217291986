import { cloneDeep, get, isArray } from 'lodash';
import { GetUpdateDependencies } from '../../pages/private/online-antrag/OnlineAntrag';
import { APIS } from '../constants/apis';
import { PostRecord } from './PostRecord';

export async function PushNotificationApplication(application: any) {
	let entries: any[] = [];

	let relations = cloneDeep(get(GetUpdateDependencies(application), 'relations', []));

	for (let ele of relations) {
		if (get(ele, 'api') === APIS.Organisation) {
			entries.push({ api: get(ele, 'api'), id: get(application, `${get(ele, 'field')}.id`) });
		}
	}
	entries.push({ api: APIS.Antrag, id: get(application, 'id') });

	for (let relation of relations) {
		if (get(relation, 'api') !== APIS.Organisation) {
			let values = get(application, get(relation, 'field', []));
			values = isArray(values) ? values : [values];

			for (let value of values) {
				entries.push({ api: get(relation, 'api'), id: get(value, 'id') });
			}
		}
	}

	await PostRecord(APIS.PushNotificaiton, { data: { entities: entries } });
}
