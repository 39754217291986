declare module '@mui/material/styles' {
	interface Palette {
		navbar: {
			primary: {
				main: string;
				active: string;
			};
			secondary: {
				main: string;
				active: string;
			};
		};
		border: {
			primary: string;
		};
		formField: {
			/** background-color for normal form field. #F8F4F4 */
			background: string;
			/** background-color for disabled form field. #0000001F */
			disabled: string;
			error: string;
		};
	}
	interface PaletteOptions {
		navbar?: {
			primary?: {
				main?: string;
				active?: string;
			};
			secondary?: {
				main?: string;
				active?: string;
			};
		};
	}
}

export const palette = {
	background: {
		default: '#FFF',
		paper: '#EEE',
	},
	primary: {
		main: '#0076BD',
	},
	secondary: {
		main: '#2B2728',
		light: '#BEC4DB',
	},
	navbar: {
		primary: {
			main: '#000000',
			active: '#0075BD',
		},
		secondary: {
			main: '#fff',
			active: '#DC2C35',
		},
	},
	text: {
		primary: '#000000',
		secondary: '#161616',
		disabled: '#84858a',
		dark: '#00265A',
	},
	action: {
		disabled: '#f1f1f3',
	},
	border: {
		primary: '#EAF0F1',
	},
	formField: {
		background: '#F8F4F4',
		disabled: '#0000001F',
		error: '#D32F2F',
	},
};
