import { Help } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { get, set } from 'lodash';
import React, { FC, useMemo } from 'react';
import { FORM_FIELDS_TYPES } from '../../../../../lib/constants/constant';
import { MUIFieldProps } from '../../../../../lib/types/types';
import { DisplayDate, DisplayDecimal, DisplayEuro } from '../../../../../utils/formats';
import { mapTablePath } from '../../../../../utils/formStruc';
import { enumT, fieldNameTranslation, isTrue } from '../../../../../utils/helper';
import MUIToolTip from '../../../mui-tooltip/MUIToolTip';
import FormFields from '../FormFields';
import SingleUpload from './SingleUpload';

const EditableTable: FC<MUIFieldProps> = (props) => {
	const { fieldValue, updateFieldValue, isDisabled, error } = props;

	const component = get(props, 'component');
	const componentPart = component.split('.');
	const apiName = useMemo(() => {
		if (get(props, 'api')) {
			return get(props, 'api');
		} else {
			return componentPart[0] + 's';
		}
	}, [componentPart, props]);

	const columns = get(props, 'schema.attributes');
	const isFieldDisabled = (columnKey: string) => {
		if (isDisabled) return true;
		if (get(props, 'disabledColumns', []).includes(columnKey)) {
			return true;
		} else return false;
	};
	const tablePath = get(props, 'path', '');
	const width = get(props, 'width', '100%');

	const handleAddRow = () => {
		let newValue = Object.keys(columns).map((key) => {
			return { [key]: null };
		});
		let newElement = [...fieldValue, newValue[0]];
		set(fieldValue, `${fieldValue.length}`, ...newValue);

		let e = { target: { value: newElement } };
		updateFieldValue(e as unknown as React.ChangeEvent<HTMLInputElement>);
	};
	const handleChange = (path: any, value: any, rowIndex: number) => {
		set(fieldValue, `${rowIndex}.${path}`, value);

		let e = { target: { value: fieldValue } };
		updateFieldValue(e as React.ChangeEvent<HTMLInputElement>);
	};
	const handleDelete = (rowIndex: number) => {
		const newRow = fieldValue.filter((_: any, index: number) => index !== rowIndex);

		let e = { target: { value: newRow } };
		updateFieldValue(e as React.ChangeEvent<HTMLInputElement>);
	};

	const visibleColumns = Object.keys(columns).filter((key: string) => !get(props, 'hideColumns', []).includes(key));

	return (
		<>
			<Box sx={{ mt: '1rem' }}>
				{get(props, 'tableLabel', '') && (
					<Typography sx={{ mb: 1, fontWeight: 600 }}>
						{get(props, 'tableLabel', '')}{' '}
						{get(props, 'tableLabelTooltip', '') ?
							<MUIToolTip title={get(props, 'tableLabelTooltip', '')}>
								<Help fontSize='small' color='primary' sx={{ ml: '5px' }} />{' '}
							</MUIToolTip>
						:	<></>}
					</Typography>
				)}
				<TableContainer component={'div'} sx={{ width: width, border: fieldValue.length === 0 && error ? '2px dashed #f00' : '' }}>
					<Table className='editable-table'>
						<TableHead>
							<TableRow>
								{visibleColumns.map((key) => {
									const colDef = columns[key];
									return (
										<TableCell
											key={key}
											align={isFieldDisabled(key) && ['integer', 'decimal', 'date'].includes(get(colDef, 'type')) ? 'right' : 'left'}
											sx={{ padding: '0.5rem 1rem', minWidth: get(props, `fieldProps.${key}.width`, '11rem') }}
										>
											<>
												{getColumnLabel(get(colDef, 'path'), key, columns, apiName)}
												{getToolTip(get(props, 'fieldProps', {}), key) && (
													<Tooltip title={getToolTip(get(props, 'fieldProps', {}), key)}>
														<Help fontSize='small' color='primary' sx={{ ml: '5px' }} />
													</Tooltip>
												)}
											</>
										</TableCell>
									);
								})}
								{!get(props, 'hideDeleteButton') && !isDisabled && (
									<TableCell sx={{ padding: '0.5rem 1rem', backgroundColor: 'background.paper' }} className='pinned-right-column'>
										{!get(props, 'hideAddButton') && (
											<IconButton onClick={() => handleAddRow()} disabled={isDisabled || fieldValue.length >= get(props, 'maxLevel', 999)}>
												<AddCircleOutlineIcon />
											</IconButton>
										)}
									</TableCell>
								)}
							</TableRow>
						</TableHead>

						<TableBody>
							{(fieldValue || []).map((eachRow: any, rowIndex: number) => {
								return (
									<TableRow key={rowIndex}>
										{visibleColumns.map((columnKey) => {
											const colDef = columns[columnKey];
											const attributePathWithIndex = mapTablePath(colDef.path, rowIndex, tablePath);
											return (
												<TableCell
													key={columnKey}
													sx={{ padding: '0.5rem 1rem' }}
													align={isFieldDisabled(columnKey) && ['integer', 'decimal', 'date'].includes(get(colDef, 'type')) ? 'right' : 'left'}
												>
													{isFieldDisabled(columnKey) ?
														<Typography>
															{getDisplayText(
																colDef.type,
																eachRow[columnKey],
																get(props, `fieldProps.${columnKey}.format`, ''),
																get(props, `fieldProps.${columnKey}.label`, '')
															)}
														</Typography>
													:	<FormFields
															{...colDef}
															label=''
															type={colDef.type}
															formValues={eachRow}
															formErrors={get(props, 'formErrors')}
															format={get(props, `fieldProps.${columnKey}.format`)}
															path={columnKey}
															onChange={(path, value) => handleChange(path, value, rowIndex)}
															disabled={isDisabled || get(props, 'disabledColumns', []).includes(columnKey)}
															editableTablePathWIthIndex={attributePathWithIndex}
															allowOnlyImage={get(props, `fieldProps.${columnKey}.allowOnlyImage`)}
															size='small'
														/>
													}
												</TableCell>
											);
										})}

										{!get(props, 'hideDeleteButton') && !isDisabled && (
											<TableCell className='pinned-right-column' sx={{ padding: '0.5rem 1rem', backgroundColor: 'background.paper' }}>
												<IconButton onClick={() => handleDelete(rowIndex)} disabled={isDisabled}>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										)}
									</TableRow>
								);
							})}
							{get(props, 'sumRow', []).map((lastRow: any) => {
								return (
									<TableRow sx={{ backgroundColor: 'background.paper' }}>
										{visibleColumns.map((columnKey) => {
											const colDef = columns[columnKey];
											return (
												<TableCell
													key={columnKey}
													sx={{ padding: '0.5rem 1rem' }}
													align={['integer', 'decimal', 'date'].includes(get(colDef, 'type')) ? 'right' : 'left'}
												>
													<Typography>{getDisplayText(colDef.type, lastRow[columnKey], get(props, `fieldProps.${columnKey}.format`))}</Typography>
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				{fieldValue.length === 0 && error ?
					<p
						style={{
							color: '#d32f2f',
							fontFamily: 'Manrope, sans-serif',
							fontWeight: 400,
							fontSize: '0.75rem',
							lineHeight: 1.66,
							textAlign: 'left',
							marginTop: '3px',
							marginRight: 0,
							marginBottom: 0,
							marginLeft: '1rem',
						}}
					>
						Dies ist ein Pflichtfeld
					</p>
				:	null}
			</Box>
		</>
	);
};
export default EditableTable;

function getColumnLabel(path: string, key: string, fieldProps: any, apiName: string): string {
	if (fieldProps && fieldProps[key] && fieldProps[key].label) {
		return fieldProps[key].label;
	}

	const parts: string[] = path.split('.');
	const lastPart: string = parts.pop()!;

	if (lastPart.startsWith('Zelle')) {
		// Extract the number from the last part
		const zelleNumber: string = lastPart.match(/\d+/)![0];
		// Modify the second to last part, which contains Block_X
		let blockPart: string = parts.pop()!;
		blockPart = blockPart.includes('Block_') ? blockPart : blockPart;

		// const modifiedBlockPart: string = blockPart.replace(/Block_\d+/, `Spalte_${zelleNumber}`);
		const modifiedBlockPart: string = blockPart + `_Spalte_${zelleNumber}`;
		return fieldNameTranslation(modifiedBlockPart, apiName);
	} else {
		return fieldNameTranslation(lastPart, apiName);
	}
}

function getToolTip(fieldProps: any, key: string) {
	return get(fieldProps, `${key}.toolTip`, '');
}

function getDisplayText(type: string, value: any, format?: string, label?: string) {
	if (value === undefined) return '';

	if (label) return label;
	switch (type) {
		case 'decimal':
			if (format === 'currency') {
				return DisplayEuro(value);
			}
			return DisplayDecimal(value);
		case 'date':
			return DisplayDate(value);
		case 'enumeration':
			return enumT(value);
		case 'boolean':
			return isTrue(value) ? 'Ja' : 'Nein';
		case FORM_FIELDS_TYPES.media:
			return <SingleUpload label={''} fieldValue={value} updateFieldValue={(_e) => {}} isDisabled={true} error={false} errorMessage={''} />;
		default:
			return value;
	}
}
