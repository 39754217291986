import { get } from 'lodash';
import { useEffect, useState } from 'react';
import GetRecords from '../lib/api/GetRecords';
import { APIS } from '../lib/constants/apis';
import { UseFetchStatesType } from '../lib/types/types';
import { getFieldNamefromDataPath, removeDataKeys, removeNullDeep } from '../utils/helper';

import GetRecord from '../lib/api/GetRecord';
import PersonSchema from '../lib/paths/person_FULL_Paths.json';
const P = PersonSchema.attributes;

function useFetchPerson_Nutzerverwaltung(organisationId: number, activeShowcase: string): UseFetchStatesType {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<any[]>([]);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		// Fetch data
		const fetchData = async () => {
			try {
				const filter = {
					[getFieldNamefromDataPath(P.Organisation.path)]: { id: { $eq: organisationId } },
				};
				const res = await GetRecords(APIS.Person, ['User', 'Organisation'], filter);
				const result: any[] = removeNullDeep(removeDataKeys(get(res, 'data', [])));
				setData(result);
			} catch (err: any) {
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [organisationId, activeShowcase]);

	return { loading, data, error };
}

function useFetchPerson(personId: number): UseFetchStatesType {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<any[]>([]);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		// Fetch data
		const fetchData = async () => {
			try {
				const res = await GetRecord(APIS.Person, personId, ['User', 'Organisation', 'Adressdaten']);
				const result: any[] = removeNullDeep(removeDataKeys(get(res, 'data', [])));
				setData(result);
			} catch (err: any) {
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};

		if (personId) fetchData();
	}, [personId]);

	return { loading, data, error };
}

export { useFetchPerson, useFetchPerson_Nutzerverwaltung };
