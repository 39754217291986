import { Box, Button, Typography } from '@mui/material';
import { cloneDeep, get, set } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import CreateForm from '../../../../../components/ui-component/form-component/create-form/CreateForm';
import LoadingIconOnly from '../../../../../components/ui-component/loading/LoadingIconOnly';
import MuiSnackbar from '../../../../../components/ui-component/mui-snackbar/MuiSnackbar';
import { useAuthentication } from '../../../../../context/AuthContext';
import GetRecord from '../../../../../lib/api/GetRecord';
import PutRecord from '../../../../../lib/api/PutRecord';
import { APIS } from '../../../../../lib/constants/apis';
import { NOT_FOUND_ERROR_MESSAGE } from '../../../../../lib/constants/constant';
import Schema from '../../../../../lib/paths/person_FULL_Paths.json';
import { removeDataKeys, removeNullDeep } from '../../../../../utils/helper';
import PersonalDataValidator from './PersonalDataValidator';
let P = Schema.attributes;

// const formStruc = [{ fields: [P.Titel] }, { fields: [P.Vorname, P.Nachname] }, { fields: [P.E_Mail, P.Fax] }, { fields: [P.Telefon] }];

const PersonalData: FC<{}> = () => {
	const { authenticatedData } = useAuthentication();
	const [loading, setLoading] = useState<boolean>(true);
	const [updateSuccess, setUpdateSuccess] = useState<boolean | null>(null);
	const [wsError, setWsError] = useState<boolean>(false);
	const [personData, setPersonData] = useState<Record<string, any>>({});
	const [formErrors, setFormErrors] = useState<Record<string, any>>({});

	const handleInputChange = (path: string, value: any) => {
		setUpdateSuccess(null);
		let personDataCopy = cloneDeep(personData);
		set(personDataCopy, path, value);
		setPersonData(personDataCopy);
	};
	const validatePersonData = () => {
		let errorObject = {};
		let errs = PersonalDataValidator.validate(personData);
		for (let error of errs) {
			set(errorObject, error.path, get(error, 'message'));
		}
		setFormErrors(errorObject);
		return Object.keys(errorObject).length === 0;
	};
	const handleUpdatePersonData = async () => {
		if (validatePersonData()) {
			let res = await PutRecord(APIS.Person, get(authenticatedData, 'personId'), { data: get(personData, 'attributes') });
			if (get(res, 'data.id')) setUpdateSuccess(true);
		}
	};

	useEffect(() => {
		const fetchPerson = async () => {
			setLoading(true);
			try {
				let res = await GetRecord(APIS.Person, get(authenticatedData, 'personId'), ['Adressdaten']);
				console.log('PERSON DATA', res);
				setPersonData(removeNullDeep(removeDataKeys(get(res, 'data', {}))));
			} catch (err) {
				console.error('ERROR Fetching Person ', err);
				setWsError(true);
			} finally {
				setLoading(false);
			}
		};
		fetchPerson();
	}, [authenticatedData]);

	if (loading) return <LoadingIconOnly />;

	return (
		<>
			<MuiSnackbar showSnackbar={wsError} closeSnackbar={() => setWsError(false)} alertMessage={NOT_FOUND_ERROR_MESSAGE} error={wsError} />
			<MuiSnackbar showSnackbar={updateSuccess} closeSnackbar={() => setUpdateSuccess(null)} alertMessage='Daten wurde aktualisiert' />
			<Typography variant='h3' component='h2'>
				Persönliche Daten
			</Typography>
			<CreateForm
				formStruc={formStruc()}
				formValues={personData}
				formErrors={formErrors}
				updateFormValuesFunc={handleInputChange}
				api={APIS.Person}
			/>

			<Box sx={{ textAlign: 'right', mt: '2rem' }}>
				<Button onClick={handleUpdatePersonData}>Aktualisieren</Button>
			</Box>
		</>
	);
};
export default PersonalData;

const formStruc = () => {
	return [
		{
			fields: [P.Titel, P.Geschlecht],
			userProps: { columnToDivide: 1 },
		},
		{
			fields: [P.Vorname, P.Nachname],
			userProps: { columnToDivide: 1 },
		},
		{
			fields: [P.Geburtsdatum, P.Staatsbuergerschaft],

			userProps: { columnToDivide: 1 },
		},
		{
			fields: [P.E_Mail, P.Telefon],
			userProps: { columnToDivide: 1 },
		},
		{
			fields: [P.Adressdaten.schema.attributes.Strasse_Und_Hausnummer, P.Adressdaten.schema.attributes.PLZ],
			userProps: { columnToDivide: 1 },
		},
		{
			fields: [P.Adressdaten.schema.attributes.Ort, P.Adressdaten.schema.attributes.Bundesland],
			userProps: { columnToDivide: 1 },
		},
	];
};
