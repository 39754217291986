import React, { FC, useMemo } from 'react';
import { MUIFieldProps } from '../../../../../lib/types/types';
import { Button, Stack, Typography } from '@mui/material';
import CreateForm from '../../create-form/CreateForm';

const MUIComponentGroups: FC<MUIFieldProps> = (props) => {
	const { label, fieldValue, updateFieldValue, isDisabled, error, errorMessage, ...restProps } = props;

	console.log(' ---------------------------------------------component Groups props', props);
	console.log(' ---------------------------------------------component Groups restprops', restProps);

	console.log(' props attributes ', props.schema.attributes);
	const formStrc = useMemo(() => {
		let allFields: any[] = Object.keys(props.schema.attributes).map((key) => {
			console.log(' what is each keys ', key);
			console.log(' waht is value ', props.schema.attributes[key]);

			return props.schema.attributes[key];
		});

		return [{ fields: allFields, userProps: { direction: 'column', columnToDivide: 1 } }];
	}, []);

	console.log(' form striun ', formStrc);
	return (
		<>
			<Stack direction={'row'} justifyContent={'space-between'}>
				<Typography variant="h6">{label}</Typography>
				<Button variant="contained">Hinzufügen</Button>
			</Stack>

			<CreateForm formStruc={formStrc} formValues={[]} updateFormValuesFunc={() => console.log(' update')} />
		</>
	);
};
export default MUIComponentGroups;
