import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import React, { FC } from 'react';
import { MUIFieldProps } from '../../../../../lib/types/types';

import HtmlEditor, { Item, Toolbar } from 'devextreme-react/html-editor';
import 'devextreme/dist/css/dx.light.css';
import { loadMessages, locale } from 'devextreme/localization';
import deMessages from 'devextreme/localization/messages/de.json';

loadMessages(deMessages);
locale(navigator.language);

const TextEditorField: FC<MUIFieldProps> = (props) => {
	const { label, fieldValue, updateFieldValue, isDisabled, error, errorMessage, hideCharacterLengthInfo, ...restProps } = props;

	const handleContentChange = (value: any) => {
		let event = { target: { value: value } };
		updateFieldValue(event as React.ChangeEvent<HTMLInputElement>);
	};
	console.log(' hideCharacterLengthInfo ', hideCharacterLengthInfo);

	return (
		<>
			<Stack>
				<Typography>{label}</Typography>
				<Box
					className='widget-container'
					style={{
						backgroundColor: '#fff',
						borderRadius: '4px',
						borderWidth: '2px !important',
						border: error ? '2px solid #d32f2f' : '2px solid transparent !important',
					}}
				>
					<HtmlEditor
						height={300}
						defaultValue={fieldValue}
						onValueChanged={(e) => handleContentChange(e.value)}
						placeholder={get(restProps, 'placeholder')}
					>
						<Toolbar>
							<Item name='undo' />
							<Item name='redo' />
							<Item name='separator' />
							<Item name='size' acceptedValues={['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']} />
							<Item name='separator' />
							<Item name='bold' />
							<Item name='italic' />
							<Item name='strike' />
							<Item name='underline' />
							<Item name='separator' />
							<Item name='alignLeft' />
							<Item name='alignCenter' />
							<Item name='alignRight' />
							<Item name='alignJustify' />
							<Item name='separator' />
						</Toolbar>
					</HtmlEditor>
					{error && <FormHelperText error>{errorMessage}</FormHelperText>}
				</Box>
			</Stack>
		</>
	);
};

export default TextEditorField;
