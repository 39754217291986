import React from 'react';

import { Help } from '@mui/icons-material';
import { TextField, Typography } from '@mui/material';
import { get } from 'lodash';
import { MAX_TEXTBOX_LENGTH } from '../../../../../lib/constants/constant';
import { MUIFieldProps } from '../../../../../lib/types/types';
import MUIToolTip from '../../../mui-tooltip/MUIToolTip';

const MUITextAreaField: React.FC<MUIFieldProps> = (props) => {
	const { label, tooltip, fieldValue, updateFieldValue, isDisabled, error, errorMessage, hideCharacterLengthInfo, ...restProps } = props;

	const maxAllowCharacterLength = get(props, 'maxCharLength', MAX_TEXTBOX_LENGTH);

	const handleOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let event = { target: { value: get(e, 'target.value', '') } };
		updateFieldValue(event as React.ChangeEvent<HTMLInputElement>);
	};
	return (
		<>
			<TextField
				multiline
				id='demo-helper-text-aligned'
				label={
					tooltip ?
						<>
							<>{label}</>
							<MUIToolTip title={tooltip}>
								{' '}
								<Help fontSize='small' color='primary' sx={{ ml: '5px' }} />{' '}
							</MUIToolTip>
						</>
					:	<>{label}</>
				}
				value={fieldValue}
				onChange={handleOnchange}
				disabled={isDisabled}
				error={error}
				helperText={errorMessage}
				minRows={get(props, 'minRows', 4)}
				maxRows={get(props, 'row', 6)}
				fullWidth
				{...restProps}
			/>
			{!get(props, 'hideCharacterLengthInfo', false) ?
				<Typography variant='caption'>
					{fieldValue.length + ' / ' + maxAllowCharacterLength + ' Zeichen (inkl. Leerzeichen) verwendet'}
				</Typography>
			:	<></>}
		</>
	);
};

export default MUITextAreaField;
