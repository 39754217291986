import React, { FC } from "react";
import MUIAlert from "../../../ui-component/mui-alert/MUIAlert";
import { Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PAGES_URLS } from "../../../../lib/constants/routes";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const NachRegistrierung: FC<{ isRegistrationSuccess: boolean | null }> = ({
  isRegistrationSuccess,
}) => {
  const navigate = useNavigate();

  if (isRegistrationSuccess) {
    return (
      <>
        <Box
          sx={{
            maxWidth: "39rem",
            textAlign: "left",
            "& + .MuiBox-root.css-18lcbnd": {
              mt: ".1rem",
            },
          }}
        >
          <Typography variant="h4" marginBottom={"2.5rem"}>
            Vielen Dank für Ihre Registrierung!
          </Typography>

          <Typography variant="body1" marginBottom={"1.5rem"}>
            Um Ihre Registrierung abzuschließen, haben wir Ihnen eine E-Mail mit
            einem Bestätigungslink zugesendet.
          </Typography>

          <Typography variant="body1" marginBottom={"1.5rem"}>
            Bitte überprüfen Sie Ihren Posteingang und klicken Sie auf den Link,
            um Ihre E-Mail-Adresse zu verifizieren.
          </Typography>

          <Typography variant="h6">Hinweis:</Typography>

          <Typography variant="body1">
            Der Bestätigungslink ist nur 24 Stunden gültig. Sollten Sie
            innerhalb dieses Zeitraums keine Bestätigung vornehmen, verfällt der
            Link und Sie müssen den Registrierungsprozess erneut durchlaufen.
            <br />
            Wenn Sie keine E-Mail erhalten haben, überprüfen Sie bitte auch
            Ihren Spam-Ordner oder kontaktieren Sie unseren Support.
          </Typography>

          <Button
            variant="loginButton"
            startIcon={
              <ArrowBackIosIcon
                sx={{
                  width: "1.1rem",
                  height: "1.1rem",
                }}
              />
            }
            sx={{
              borderRadius: 1,
              maxWidth: "100%",
              padding: "0.75rem  1.375rem ", // 12px 22px
              mt: "2rem",
            }}
            onClick={() => navigate(PAGES_URLS.Login)}
          >
            <Typography variant="subtitle1">Zurück zum Login</Typography>
          </Button>
        </Box>
      </>
    );
  } else if (!isRegistrationSuccess) {
    return (
      <MUIAlert
        infoType="error"
        message="Etwas ist schief gelaufen. Versuchen Sie es bitte ernuet."
      />
    );
  }

  return <></>;
};

export default NachRegistrierung;
