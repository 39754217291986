import React, { FC } from 'react';
import { MUIFieldProps } from '../../../../../lib/types/types';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { get } from 'lodash';
import { enumT } from '../../../../../utils/helper';
import { Help } from '@mui/icons-material';
import MUIToolTip from '../../../mui-tooltip/MUIToolTip';

const MUIRadioField: FC<MUIFieldProps> = (props) => {
	const { label, tooltip, fieldValue, updateFieldValue, isDisabled, error, errorMessage, ...restProps } = props;

	return (
		<>
			<FormControl fullWidth error={error} sx={{ mt: '1rem' }}>
				<FormLabel>
					{tooltip ? (
						<>
							<>{label}</>
							<MUIToolTip title={tooltip}>
								{' '}
								<Help fontSize="small" color="primary" sx={{ ml: '5px' }} />{' '}
							</MUIToolTip>
						</>
					) : (
						<>{label}</>
					)}
				</FormLabel>
				<RadioGroup
					aria-label="demo-controlled-radio-buttons-group"
					value={fieldValue}
					onChange={updateFieldValue}
					{...restProps}
				>
					{get(props, 'options') ? (
						<>
							<Stack direction={get(props, 'direction', 'row')}>
								{get(props, 'options', []).map((ele: Record<string, any>, i: number) => (
									<FormControlLabel
										disabled={isDisabled}
										key={i}
										value={get(ele, 'value')}
										control={<Radio />}
										label={get(ele, 'label', '')}
									/>
								))}
							</Stack>
						</>
					) : (
						<>
							{get(props, 'enum') ? (
								<>
									<Stack direction={get(props, 'direction', 'row')}>
										{get(props, 'enum', []).map((ele: string, i: number) => (
											<FormControlLabel
												disabled={isDisabled}
												key={i}
												value={ele}
												control={<Radio />}
												label={enumT(`${ele}`)}
											/>
										))}
									</Stack>
								</>
							) : (
								<>
									<Stack direction={get(props, 'direction', 'row')}>
										<FormControlLabel disabled={isDisabled} value={true} control={<Radio />} label={'Ja'} />
										<FormControlLabel disabled={isDisabled} value={false} control={<Radio />} label={'Nein'} />
									</Stack>
								</>
							)}
						</>
					)}
				</RadioGroup>
			</FormControl>
			{error && <FormHelperText error>{errorMessage}</FormHelperText>}
		</>
	);
};
export default MUIRadioField;
