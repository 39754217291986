import { useMediaQuery } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { FC } from 'react';
import { APIS } from '../../../../lib/constants/apis';
import Schema from '../../../../lib/paths/organisation_FULL_Paths.json';
import ApplicationHeading from '../../../../pages/private/online-antrag/application-heading/ApplicationHeading';
import CreateForm from '../../../ui-component/form-component/create-form/CreateForm';
import MUIAlert from '../../../ui-component/mui-alert/MUIAlert';
const P = Schema.attributes;

const OrganisationDaten: FC<{
	formValue: any;
	updateFunction: (_key: string, _value: any) => void;
	organisationDatenError: any;
	formErrorMessage: string;
}> = ({ formValue, updateFunction, organisationDatenError, formErrorMessage }) => {
	const isSmallScreen = useMediaQuery('(max-width:600px)');

	return (
		<>
			<ApplicationHeading title='Organisationsdaten' description='Bitte vervollständigen Sie die Angaben zu Ihrer Organisation.' />
			{formErrorMessage && (
				<MUIAlert
					infoType='error'
					message='Ihre Organisation ist bereit registriert. Fragen Sie Ihren Nutzerverwalter für die Freischaltung des Accounts'
				/>
			)}

			<Grid2>
				<Grid2 sm={12}>
					<CreateForm
						formValues={formValue}
						formStruc={formStrucSpalte1(isSmallScreen)}
						updateFormValuesFunc={updateFunction}
						formErrors={organisationDatenError}
						api={APIS.Organisation}
					/>
				</Grid2>
			</Grid2>
			<Grid2>
				<Grid2 sm={12}>
					<CreateForm
						formValues={formValue}
						formStruc={formStrucSpalte2()}
						updateFormValuesFunc={updateFunction}
						formErrors={organisationDatenError}
						api={APIS.Organisation}
					/>
				</Grid2>
			</Grid2>
		</>
	);
};

export default OrganisationDaten;

const formStrucSpalte1 = (isSmallScreen: boolean) => {
	return [
		{
			fields: [P.Name_Der_Organisation, P.Rechtsform_Der_Organisation, P.Strasse_Und_Hausnummer],
			userProps: { direction: 'column', columnToDivide: 1 },
		},
		{
			fields: [P.PLZ, P.Ort],
			userProps: {
				direction: isSmallScreen ? 'column' : 'row',
				columnToDivide: isSmallScreen ? 1 : 2,
				columnGap: '1rem',
			},
		},
	];
};

const formStrucSpalte2 = () => {
	return [
		{
			fields: [P.E_Mail, P.Telefon],
			userProps: { direction: 'column', columnToDivide: 1 },
		},
	];
};
