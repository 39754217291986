import { Box, Collapse, Typography, alpha, styled } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import {
	TreeItem2Content,
	TreeItem2Icon,
	TreeItem2IconContainer,
	TreeItem2Label,
	TreeItem2Root,
	UseTreeItem2Parameters,
	treeItemClasses,
} from '@mui/x-tree-view';
import { useTreeItem2 } from '@mui/x-tree-view/useTreeItem2/useTreeItem2';
import { animated, useSpring } from '@react-spring/web';
import clsx from 'clsx';
import React from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import MessageIcon from '@mui/icons-material/Message';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { TreeNodeType } from '../../../../lib/types/types';

export const StyledTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
	position: 'relative',
	[`& .${treeItemClasses.groupTransition}`]: {
		marginLeft: theme.spacing(3.5),
	},
}));

export const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
	flexDirection: 'row-reverse',
	borderRadius: theme.spacing(0.7),
	marginBottom: theme.spacing(0.5),
	marginTop: theme.spacing(0.5),
	padding: theme.spacing(0.5),
	paddingRight: theme.spacing(1),
	fontWeight: 500,
	[`&.Mui-expanded `]: {
		'&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon': {
			color: theme.palette.primary.main,
		},
		'.labelIcon, .labelIconError': {
			backgroundColor: '#d2d9e6',
		},
	},
	'&:hover': {
		backgroundColor: alpha(theme.palette.primary.main, 0.1),
		color: theme.palette.primary.main,
		'.labelIcon, .labelIconError': {
			backgroundColor: '#d2d9e6',
		},
	},
	[`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
		// backgroundColor: theme.palette.primary.main,
		// color: theme.palette.primary.contrastText,
		fontWeight: 700,
	},
	[`&.Mui-selected.Mui-focused`]: {
		'.labelIcon, .labelIconError': {
			backgroundColor: '#bbc8d9',
		},
	},
	[`& .labelIcon`]: {
		fill: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		width: '17.6px',
		height: '17.86px',
		top: '21px',
		left: '12px',
		gap: '0px',
		opacity: '0px',
	},
	[`& .labelIconError`]: {
		color: 'red',
		backgroundColor: theme.palette.background.paper,
		width: '17.6px',
		height: '17.86px',
		top: '21px',
		left: '12px',
		gap: '0px',
		opacity: '0px',
	},
}));

const AnimatedCollapse = animated(Collapse);

export function TransitionComponent(props: TransitionProps) {
	const style = useSpring({
		to: {
			opacity: props.in ? 1 : 0,
			transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
		},
	});

	return <AnimatedCollapse style={style} {...props} />;
}

interface CustomLabelProps {
	children: React.ReactNode;
	iconLeft?: React.ElementType;
	iconRight?: React.ElementType;
	expandable?: boolean;
}

export function CustomLabel({
	iconLeft: IconLeft,
	iconRight: IconRight,
	expandable,
	children,
	...other
}: CustomLabelProps) {
	const inlineColor = IconLeft === ErrorOutlineOutlinedIcon ? 'labelIconError' : 'labelIcon';
	return (
		<TreeItem2Label
			{...other}
			sx={{
				display: 'flex',
				alignItems: 'center',
			}}
		>
			{IconLeft && (
				<Box component={IconLeft} className={inlineColor} color='inherit' sx={{ mr: 1, fontSize: '1.2rem' }} />
			)}
			<Typography variant='body1'>{children}</Typography>
			{IconRight && <Box component={IconRight} className={'labelIcon'} sx={{ ml: 1, fontSize: '1.2rem' }} />}
		</TreeItem2Label>
	);
}

interface CustomTreeItemProps
	extends Omit<UseTreeItem2Parameters, 'rootRef'>,
	Omit<React.HTMLAttributes<HTMLLIElement>, 'onFocus'> { }

interface PublicAPI {
	getItem: (id: string) => TreeNodeType;
}

export const CustomTreeItem = React.forwardRef(function CustomTreeItem(
	props: CustomTreeItemProps,
	ref: React.Ref<HTMLLIElement>,
) {
	const { id, itemId, label, disabled, children, ...other } = props;

	const {
		getRootProps,
		getContentProps,
		getIconContainerProps,
		getLabelProps,
		getGroupTransitionProps,
		status,
		publicAPI,
	} = useTreeItem2({
		id,
		itemId,
		children,
		label,
		disabled,
		rootRef: ref,
	});
	let iconLeft = CircleOutlinedIcon;
	let iconRight;
	let { showError, hasBeenVisited, hasComment } = (publicAPI as PublicAPI).getItem(itemId);
	showError = showError ? true : false;
	hasBeenVisited = hasBeenVisited ? true : false;
	if (showError) {
		iconLeft = ErrorOutlineOutlinedIcon;
	} else if (showError === false && hasBeenVisited === true) {
		iconLeft = CheckCircleIcon;
	} else {
		iconLeft = CircleOutlinedIcon;
	}
	if (hasComment) {
		iconRight = MessageIcon;
	}

	return (
		<>
			<StyledTreeItemRoot {...getRootProps(other)}>
				<CustomTreeItemContent
					{...getContentProps({
						className: clsx('content', {
							'Mui-expanded': status.expanded,
							'Mui-selected': status.selected,
							'Mui-focused': status.focused,
							'Mui-disabled': status.disabled,
						}),
					})}
				>
					<TreeItem2IconContainer {...getIconContainerProps()}>
						<TreeItem2Icon status={status} />
					</TreeItem2IconContainer>
					<CustomLabel {...getLabelProps({ iconLeft, iconRight })} />
				</CustomTreeItemContent>
				{children && <TransitionComponent {...getGroupTransitionProps()} />}
			</StyledTreeItemRoot>
		</>
	);
});
