import Grid2 from '@mui/material/Unstable_Grid2';
import React, { FC } from 'react';
import CreateForm from '../../../../../../components/ui-component/form-component/create-form/CreateForm';
import { useApplication } from '../../../../../../context/ApplicationContext';
import { FORM_FIELDS_TYPES, PLATZHALTER_TEXT } from '../../../../../../lib/constants/constant';
import ApplicationHeading from '../../../application-heading/ApplicationHeading';
// import { Box } from "devextreme-react";
// import { useMediaQuery } from '@mui/material';
import { Box, Divider, Typography } from '@mui/material';
import { concat } from 'lodash';
import AntragSchema from '../../../../../../lib/paths/antrag_FULL_Paths.json';
import { FormStrucItem } from '../../../../../../lib/types/types';
import { Required } from '../../../../../../lib/validation/ValidationTypes';
import { getFormStrucComponentTable } from '../../../../../../utils/formStruc';
const P = AntragSchema.attributes;

const Bewerbungsdaten: FC<{}> = () => {
	const { application, setApplicationProperty, readOnly } = useApplication();

	return (
		<>
			<ApplicationHeading title='Bewerbungsdaten' description={PLATZHALTER_TEXT} />

			<Grid2 container direction={'column'}>
				<Grid2 xs={12} md={6}>
					<CreateForm
						formStruc={formStrucAllgemeinBeschreibung()}
						formValues={application}
						updateFormValuesFunc={setApplicationProperty}
						readOnly={readOnly}
					/>
				</Grid2>
				<Grid2 xs={12} sx={{ mt: '4rem' }}>
					<CreateForm
						formStruc={formStrucVeroeffentlichungen()}
						formValues={application}
						updateFormValuesFunc={setApplicationProperty}
						readOnly={readOnly}
					/>
				</Grid2>
			</Grid2>

			<Box sx={{ mt: '4rem' }}>
				<Typography sx={{ mb: '.5rem' }} variant='h4'>
					Anlagen
				</Typography>
				<Divider />
				<CreateForm
					formStruc={formStrucPflichtAnlageArbeitsproben()}
					formValues={application}
					updateFormValuesFunc={setApplicationProperty}
					readOnly={readOnly}
				/>
			</Box>
			<Box sx={{ mt: '2rem', width: { xs: '100%', md: '50%' } }} gap={2}>
				{/* <Typography sx={{ mb: '.5rem' }} variant='h5'>
					Lebenslauf
				</Typography> */}
				{/* <Divider sx={{ mb: '1rem' }} /> */}
				<CreateForm
					formStruc={formStrucPflichtAnlageLebenslauf()}
					formValues={application}
					updateFormValuesFunc={setApplicationProperty}
					readOnly={readOnly}
				/>
			</Box>
			<Box sx={{ m: '2rem 0' }} gap={2}>
				{/* <Typography sx={{ mb: '.5rem' }} variant='h5'>
					Empfehlungsschreiben
				</Typography> */}
				{/* <Divider sx={{ mb: '1rem' }} /> */}
				<CreateForm
					formStruc={formStrucEmpfehlungsschreiben()}
					formValues={application}
					updateFormValuesFunc={setApplicationProperty}
					readOnly={readOnly}
				/>
			</Box>
		</>
	);
};
export default Bewerbungsdaten;

const formStrucAllgemeinBeschreibung = () => {
	let struc: FormStrucItem[] = [
		{
			fields: [P.Bewerbung_Beginn_Der_Kuenstlerischen_Taetigkeit],
			userProps: { columnToDivide: 1 },
		},
	];
	return struc;
};

const formStrucVeroeffentlichungen = () => {
	let struc: FormStrucItem[] = [
		{
			fields: [
				{
					...P.Bewerbung_Bisherige_Veroeffentlichungen,
					type: FORM_FIELDS_TYPES.editableTable,
					maxLevel: 10,
					validation: [{ validateFunction: Required }],
				},
			],
			userProps: { columnToDivide: 1 },
		},
	];
	return struc;
};

const formStrucPflichtAnlageArbeitsproben = () => {
	let struc: FormStrucItem[] = [
		{
			fields: [
				{
					...P.Bewerbung_Arbeitsproben,

					validation: [{ validateFunction: Required }],
				},
			],
			userProps: { direction: 'column', columnToDivide: 1 },
		},
	];
	return struc;
};
const formStrucPflichtAnlageLebenslauf = () => {
	let struc: FormStrucItem[] = [
		{
			fields: [{ ...P.Bewerbung_kuenstlerischer_Lebenslauf }],

			userProps: { direction: 'column', columnToDivide: 1 },
		},
	];
	return struc;
};
const formStrucEmpfehlungsschreiben = () => {
	let struc: FormStrucItem[] = [
		{
			fields: [{ ...P.Bewerbung_Empfehlungsschreiben, maxFiles: 5, validation: [{ validateFunction: Required }] }],
			userProps: { direction: 'column', columnToDivide: 1 },
		},
	];
	return struc;
};

export const getFormStrucBewerbungsdaten = (application: any) => {
	const veroeffentlichungenWithIndex = getFormStrucComponentTable(application, formStrucVeroeffentlichungen());

	return concat(
		veroeffentlichungenWithIndex,
		formStrucAllgemeinBeschreibung(),
		formStrucPflichtAnlageLebenslauf(),
		formStrucPflichtAnlageArbeitsproben(),
		formStrucVeroeffentlichungen(),
		formStrucEmpfehlungsschreiben()
	);
};
