import { Button, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmUserEmail } from '../../../lib/api/AuthUser';
import { PAGES_URLS } from '../../../lib/constants/routes';
import LoadingBackdrop from '../../ui-component/loading/LoadingBackdrop';
import MUIAlert from '../../ui-component/mui-alert/MUIAlert';

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ConfirmEMail() {
	const queryParams = useQuery();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [isMailConfirmed, setIsMailConfirmed] = useState<boolean | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			let confirmed = await ConfirmUserEmail(queryParams.get('registration') as string);
			console.log(' confirmed ', confirmed);
			setLoading(false);
			setIsMailConfirmed(true);
		};
		if (queryParams) fetchData();
	}, [queryParams]);

	if (loading) return <LoadingBackdrop open={loading} />;

	if (isMailConfirmed) {
		return (
			<Container>
				<MUIAlert
					infoType='success'
					title='Bestätigung Ihrer E-Mail-Adresse'
					message={`Vielen Dank, dass Sie sich bei Adk registriert haben. Wir möchten Ihnen bestätigen, dass Ihr Konto erfolgreich erstellt und aktiviert wurde. Sie können sich jetzt gern anmelden`}
				/>
				<Button variant={'outlined'} onClick={() => navigate(PAGES_URLS.Login)} sx={{ mt: '2rem' }}>
					Zurück zur Login Seite
				</Button>
			</Container>
		);
	}

	return <></>;
}

export default ConfirmEMail;
