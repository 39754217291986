import { getUserJwt } from '../../context/AuthHelper';
import { METHOD_GET } from '../constants/apis';
import { STRAPI_URL } from '../constants/url';
import { getQueryString } from './Population';

export default async function GetRecords(
	api: string,
	poplevel: any[] = [],
	filter: any[] | Record<string, any> = [],
	sort: any[] = [],
	pagination: object = {},
	fields: any = [],
) {
	let response = await fetch(`${STRAPI_URL}/api/${api}${getQueryString(poplevel, filter, sort, pagination, fields)}`, {
		method: METHOD_GET,
		headers: {
			Authorization: `Bearer ${getUserJwt()}`,
		},
	});
	if (!response.ok) throw new Error(await response.text());
	const responseData = await response.json();
	return responseData;
}
