import { Help } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { get, set } from 'lodash';
import React, { FC, useMemo } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { MUIFieldProps } from '../../../../../lib/types/types';
import MUIToolTip from '../../../mui-tooltip/MUIToolTip';

const MUINumberField: FC<MUIFieldProps> = (props) => {
	const { label, tooltip, fieldValue, updateFieldValue, isDisabled, error, errorMessage, ...restProps } = props;

	const initialValue = useMemo(() => {
		return fieldValue || fieldValue === 0 ? fieldValue : '';
	}, [fieldValue]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let inputValue = e.target.value ? e.target.value : '';
		let strapiValue = inputValue ? inputValue.replace(/[^0-9.-]/g, '') : '';
		if (get(props, 'maxDigit')) {
			strapiValue = strapiValue.slice(0, get(props, 'maxDigit'));
		}

		if (parseFloat(initialValue) === parseFloat(strapiValue)) {
			// DO Noting
		} else {
			set(e, 'target.value', strapiValue);
			updateFieldValue(e);
		}
	};

	return (
		<>
			<TextField
				label={
					tooltip ? (
						<>
							<>{label}</>
							<MUIToolTip title={tooltip}>
								{' '}
								<Help fontSize="small" color="primary" sx={{ ml: '5px' }} />{' '}
							</MUIToolTip>
						</>
					) : (
						<>{label}</>
					)
				}
				value={initialValue}
				onChange={handleInputChange}
				disabled={isDisabled}
				error={error}
				helperText={errorMessage}
				fullWidth
				InputProps={{
					startAdornment: get(props, 'format', '') === 'currency' ? <InputAdornment position="start">€</InputAdornment> : '',
					inputComponent: get(props, 'format', '') === 'currency' || get(props, 'type', '') === 'decimal' ? (NumericFormatCustom as any) : '',
				}}
				inputProps={{
					sx: {
						textAlign: 'right',
						...get(props, 'inputProps.sx', {}),
					},
					...get(props, 'inputProps', {}),
				}}
				{...restProps}
			/>
		</>
	);
};
export default MUINumberField;

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
	const { onChange, ...other } = props;

	const handleValueChange = (values: any) => {
		onChange({
			target: {
				name: props.name,
				value: values.value,
			},
		});
	};

	return (
		<NumericFormat
			{...other}
			getInputRef={ref}
			onValueChange={handleValueChange}
			thousandSeparator="."
			decimalSeparator=","
			decimalScale={2}
			fixedDecimalScale
			valueIsNumericString
		/>
	);
});
