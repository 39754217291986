import { Help, Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';
import { MUIFieldProps } from '../../../../../lib/types/types';
import MUIToolTip from '../../../mui-tooltip/MUIToolTip';

const MUITextField: React.FC<MUIFieldProps> = (props) => {
	const { type, label, tooltip, fieldValue, updateFieldValue, isDisabled, error, errorMessage, ...restProps } = props;

	const [showPassword, setShowPassword] = useState(false);

	return (
		<TextField
			fullWidth
			id={`${label}-input`}
			type={showPassword ? 'text' : type}
			aria-labelledby={`${label}-label`}
			aria-describedby={
				error ? `${label}-error-text`
				: tooltip ?
					`${label}-tooltip`
				:	undefined
			}
			label={
				tooltip ?
					<span id={`${label}-label`}>
						{label}
						<MUIToolTip title={tooltip} id={`${label}-tooltip`}>
							{' '}
							<Help fontSize='small' color='primary' sx={{ ml: '5px' }} />{' '}
						</MUIToolTip>
					</span>
				:	<span id={`${label}-label`}>{label}</span>
			}
			value={fieldValue}
			onChange={updateFieldValue}
			disabled={isDisabled}
			error={error}
			helperText={error ? <span id={`${label}-error-text`}>{errorMessage}</span> : undefined}
			InputProps={{
				endAdornment: type === 'password' && (
					<IconButton
						aria-label={showPassword ? 'Passwort verbergen' : 'Passwort anzeigen'}
						aria-pressed={showPassword}
						onClick={() => setShowPassword(!showPassword)}
					>
						{showPassword ?
							<Visibility />
						:	<VisibilityOff />}
					</IconButton>
				),
			}}
			{...restProps}
		/>
	);
};

export default MUITextField;
