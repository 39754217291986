import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
	Button,
	// Box,
	Container,
	// Link,
	Stack,
	Typography,
} from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import ScrollToTop from '../../../components/layout/ScrollToTop';
import { PAGES_URLS } from '../../../lib/constants/routes';

const Impressum: FC<{}> = () => {
	const navigate = useNavigate();
	return (
		<>
			<ScrollToTop />
			<Container>
				<Button
					variant='outlined'
					startIcon={<ArrowBackIcon />}
					sx={{ margin: '0 auto 3rem auto', boxShadow: 'none' }}
					onClick={() => navigate(PAGES_URLS.Startseite)}
				>
					Zurück Zur Startseite
				</Button>
				<Stack spacing={2}>
					<Typography variant='h2' component='h2'>
						Impressum
					</Typography>
					<Typography variant='h3' component='h3'>
						Anbieter:
					</Typography>
					<Typography variant='h4' component='h4'>
						Akademie der Künste
					</Typography>
					{/* <Box>
						<Typography>Mainzer Str. 118</Typography>
						<Typography>65189 Wiesbaden</Typography>
						<Typography>Tel. +49 611 9501780</Typography>
						<Typography>Fax +49 611 950178466</Typography>
						<Typography>
							E-Mail: <Link href={'mailto:info@hessen-agentur.de'}>info@hessen-agentur.de</Link>
						</Typography>
					</Box> */}
				</Stack>
			</Container>
		</>
	);
};
export default Impressum;
