import { Box } from '@mui/material';
import React, { FC } from 'react';

interface ContentsProps {
	sidebars: { icon?: React.ReactElement; label: string; element: React.ReactNode }[];
	activeSideBarButton: number;
}
const Contents: FC<ContentsProps> = ({ sidebars, activeSideBarButton }) => {
	return (
		<>
			<Box sx={{ width: '100%', maxWidth: '71.25rem', padding: '0 0 1.875rem' }}>
				{sidebars.map((sidebar, index) =>
					index === activeSideBarButton ? <React.Fragment key={index}>{sidebar.element}</React.Fragment> : null,
				)}
			</Box>
		</>
	);
};
export default Contents;
