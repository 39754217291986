import MessageIcon from '@mui/icons-material/Message';
import { Box, Container, Stack, Tab, Tabs } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { cloneDeep, get, isArray, set } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TreeNavigationButtons from '../../../components/ui-component/form-component/tree-view-navigation/TreeNavigationButtons';
import TreeViewNavigationMobile from '../../../components/ui-component/form-component/tree-view-navigation/TreeNavigationMobileView';
import TreeNavigationView from '../../../components/ui-component/form-component/tree-view-navigation/TreeNavigationView';
import { constructHierarchyArray, extractIds } from '../../../components/ui-component/form-component/tree-view-navigation/TreeNavigatrionHelper';
import LoadingBackdrop from '../../../components/ui-component/loading/LoadingBackdrop';
import MuiDialog from '../../../components/ui-component/mui-dialog/MuiDialog';
import MuiSnackbar from '../../../components/ui-component/mui-snackbar/MuiSnackbar';
import NoDetailsInfo from '../../../components/ui-component/no-details-info/NoDetailsInfo';
import ApplicationContextProvider from '../../../context/ApplicationContext';
import GetRecord from '../../../lib/api/GetRecord';
import Population from '../../../lib/api/Populations.json';
import { PostRecord } from '../../../lib/api/PostRecord';
import { PushNotificationApplication } from '../../../lib/api/PushNotification';
import PutRecord from '../../../lib/api/PutRecord';
import { APIS } from '../../../lib/constants/apis';
import { ANTRAGS_STATUS, APP_STATUS_EDITABLE } from '../../../lib/constants/constant';
import { PAGES_URLS } from '../../../lib/constants/routes';
import { ErrorValidation, TreeNodeType } from '../../../lib/types/types';
import { GetFieldErrors } from '../../../lib/validation/ApplicationValidator';
import { checkErrorInCurrentNode, setHasBeenVisited, setShowError, validateAll } from '../../../lib/validation/validationHelperFunction';
import { isObjectEmpty, removeDataKeys, removeNullDeep } from '../../../utils/helper';
import AntragRightSideBar from './AntragRightSideBar';
import ApplicationBreadcrumbs from './application-breadcrumbs/ApplicationBreadCrumbs';
import { FoerderAntragDependencies } from './structure/foerderantrag/FoerderAntragDependencies';
import { TreeStructureFoerderAntrag } from './structure/foerderantrag/TreeStructureFoerderAntrag';

// import schemas
import AntragSchema from '../../../lib/paths/antrag_FULL_Paths.json';
let P = AntragSchema.attributes;

export default function OnlineAntrag() {
	const navigate = useNavigate();
	const [hasUnsavedAttributes, setHasUnsavedAttributes] = useState(false);
	const [treeStructure, setTreeStructure] = useState<TreeNodeType[]>([]);
	const [currentNode, setCurrentNode] = useState<string[]>(['1']);
	const [wsActive, setWsActive] = useState<boolean>(false);
	const [wsError, setWsError] = useState<boolean>(false);
	const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState<boolean>(false);
	const [appSubmitSuccess, setAppSubmitSuccess] = useState<boolean | null>(false);
	const [errors, setErrors] = useState<ErrorValidation[]>([]);
	const [storageHasBeenVisited, setStorageHasBeenVisited] = useState<boolean>(false);

	const location = useLocation();

	const numericId = get(location, 'state.id', 0);

	const [application, setApplication] = useState({}); //Antrag, wie er original in strapi liegt
	const [updates, setUpdates] = useState({}); //hier werden alle Usereingaben reingeschrieben. Wird für PUT verwendet
	const readOnly = useMemo(() => {
		return GetReadOnly(application);
	}, [application]);
	const IconRight = MessageIcon;

	const handleNavigation = (nodeIds: string[], buttonClick?: string) => {
		let hasErrorInCurrentNode = hasCurrentContentError();
		if (buttonClick === 'next' || buttonClick === 'previous') {
			const allNodesIdsArr = extractIds(treeStructure);
			const currentNodeStr = currentNode[currentNode.length - 1];
			const currentNodeIndex = allNodesIdsArr.findIndex((ele: any) => ele === currentNodeStr);

			if (currentNodeIndex !== -1) {
				const prevNodeStr = allNodesIdsArr[currentNodeIndex - 1];
				const nextNodeStr = allNodesIdsArr[currentNodeIndex + 1];

				if (buttonClick === 'next') {
					if (hasErrorInCurrentNode) return;
					window.scrollTo(0, 0);
					saveApplication();
					setCurrentNode(constructHierarchyArray(nextNodeStr));
				}
				if (buttonClick === 'previous') {
					if (hasErrorInCurrentNode) return;
					window.scrollTo(0, 0);
					saveApplication();
					setCurrentNode(constructHierarchyArray(prevNodeStr));
				}
			}
		}
		if (Array.isArray(nodeIds) && nodeIds.length) {
			saveApplication();
			setCurrentNode(constructHierarchyArray(nodeIds[0], treeStructure));
		}
		const treeStruc = validateAll(updates, treeStructure, setErrors, readOnly, true);
		setTreeStructure(treeStruc);
	};

	const setApplicationProperty = (key: string, value: any) => {
		console.log('##################### SETTING APPLICATION PROPERTIY\nPATH: ', key, '\nVALUE: ', value);
		let appCopy = cloneDeep(updates);
		set(appCopy, key, value);
		const formulaFields = executeFormulas();
		if (isArray(formulaFields) && formulaFields.length > 0) {
			for (let ele of formulaFields) {
				set(appCopy, get(ele, 'path'), get(ele, 'value'));
			}
		}
		console.log(' app copy ', appCopy);
		setHasUnsavedAttributes(true);
		setUpdates(appCopy);
	};
	const saveApplication = async (submit: boolean = false, newApplication: boolean | any = false, changeLastEdited: boolean = true) => {
		if (!hasUnsavedAttributes && newApplication === false && !submit) return;
		let updateRecord = newApplication || cloneDeep(updates);

		if (submit) {
			set(updateRecord, P.Status.path, ANTRAGS_STATUS.eingegangen);
			set(updateRecord, P.Allgemein_Eingereicht_Am.path, new Date());
		}

		if (changeLastEdited) {
			// DO NOthing for Now
			// 		set(updateRecord, P.Allgemein_Geaendert_Durch_Antragsteller.path, new Date());
		}
		console.log(' update record BEFORE PUT AUFRUF ', updateRecord);
		try {
			setWsActive(true);
			let updateRecordCopy = cloneDeep(updateRecord);
			const formulaFields = executeFormulas();
			if (isArray(formulaFields) && formulaFields.length > 0) {
				for (let ele of formulaFields) {
					set(updateRecordCopy, get(ele, 'path'), get(ele, 'value'));
				}
			}
			let updatedRecord = await updateRelations(updateRecordCopy);
			let res = await PutRecord(
				get(location, 'state.api'),
				get(updatedRecord, 'id'),
				{ data: get(updatedRecord, 'attributes') },
				get(Population, `${get(location, 'state.api').replace(/.$/, '')}.${get(location, 'state.antragsformular', 'FULL')}`)
			);
			if (res) {
				setApplication(removeNullDeep(removeDataKeys(get(res, 'data', []))));
				setUpdates(removeNullDeep(removeDataKeys(get(res, 'data', []))));
				if (submit) {
					await PushNotificationApplication(updatedRecord);
					setAppSubmitSuccess(true);
				} else setOpenSuccessSnackbar(true);
			}
		} catch (err: any) {
			const errorData = JSON.parse(get(err, 'message', {}));
			if (get(errorData, 'error.status') === 403 || get(errorData, 'error.status') === 401) {
				localStorage.removeItem('adk-token');
				window.location.reload();
			}
			console.error('ERROR: Updating Application ', err);
			setWsError(true);
		} finally {
			setHasUnsavedAttributes(false);
			setWsActive(false);
		}
	};

	const findNodeContent = (nodes: TreeNodeType[], nodeId: any[]): JSX.Element | null => {
		for (const node of nodes) {
			if (node.children) {
				const foundContent = findNodeContent(node.children, nodeId);

				if (foundContent) {
					return (
						<>
							{node.content}
							<Box
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<Tabs
									value={nodeId[1]}
									variant='scrollable'
									scrollButtons
									allowScrollButtonsMobile
									aria-label='scrollable force tabs example'
									onChange={(_e, newId) => {
										handleNavigation([newId]);
									}}
									TabIndicatorProps={{ style: { display: 'none' } }}
									sx={{
										margin: '1rem -40px',
										'& .MuiTabs-indicatorSpan': {
											display: 'none',
										},
										'& .MuiButtonBase-root.MuiTab-root': {
											borderTopLeftRadius: '0px',
											borderBottomLeftRadius: '0px',
										},
										'& .MuiTouchRipple-root': {
											borderLeft: '4px solid #fdfdfdcf',
										},
										'& .MuiTab-root.Mui-selected + .MuiButtonBase-root span, & .MuiTab-root.Mui-selected.MuiButtonBase-root span, & .MuiTabs-flexContainer > button:first-of-type span, & .MuiTabScrollButton-root  > span ':
											{
												borderLeft: '0px !important', // Add desired styling
											},

										'& .MuiTab-root': {
											textTransform: 'none',
											fontWeight: 600,
											fontSize: '14px',
											color: '#666',
											padding: '10px 20px',
											transition: 'all 0.3s ease',
											backgroundColor: 'background.paper',
											borderBottom: '2px solid',
											borderColor: 'primary.main',
											'&:hover': {
												backgroundColor: 'transparent',
											},
										},
										'& .MuiTab-root.Mui-selected': {
											color: 'primary.main',
											backgroundColor: 'transparent',
											borderTop: '2px solid',
											borderLeft: '2px solid',
											borderRight: '2px solid',
											borderBottom: 'none',
											borderColor: 'primary.main',
											borderRadius: '5px 5px 0 0',
										},

										'& .MuiTab-root:hover': {
											backgroundColor: 'transparent',
										},
									}}
								>
									{node.children.map((ele, i) => {
										return (
											<Tab
												key={i}
												value={ele.id}
												label={ele.label}
												icon={ele.hasComment ? <IconRight sx={{ color: 'primary.main' }} /> : <></>}
												iconPosition='end'
												sx={{ color: ele.showError ? `#d32f2f !important` : null }}
											/>
										);
									})}
								</Tabs>
								<div
									style={{
										borderBottom: '2px solid #023671',
										height: 48,
										flexGrow: 1,
									}}
								></div>
							</Box>
							{foundContent}
						</>
					);
				}
			}
			if (node.id === nodeId[nodeId.length - 1]) {
				return node.content;
			}
		}
		return null;
	};

	function hasCurrentContentError(): boolean {
		if (treeStructure.length <= 0) return false;

		const antragId = get(application, 'id');
		// get current content and compare path with errorList
		const errorList = GetFieldErrors(treeStructure, updates, readOnly);
		setErrors(errorList);
		const curNode = currentNode[currentNode.length - 1];
		const parentNode = currentNode[0];
		let hasErrorParent = checkErrorInCurrentNode(treeStructure, errorList, parentNode);
		let structure = [...treeStructure];
		let hasErrorChild;
		if (Number(curNode) > 0) {
			// check if current node is child node
			if (parentNode !== curNode) {
				hasErrorChild = checkErrorInCurrentNode(treeStructure, errorList, curNode);
				setShowError(treeStructure, currentNode, hasErrorParent, hasErrorChild);
			} else {
				setShowError(treeStructure, currentNode, hasErrorParent);
			}
			setHasBeenVisited(structure, currentNode);
			setTreeStructure(structure);
		}
		if (!storageHasBeenVisited) {
			localStorage.setItem(antragId, JSON.stringify(true));
			setStorageHasBeenVisited(true);
		}
		console.log({ errorList });
		return hasErrorChild === undefined ? hasErrorParent : hasErrorChild;
	}

	const validateCompleteForm = () => {
		let structure = validateAll(application, treeStructure, setErrors, readOnly);
		setTreeStructure(structure);
	};

	const renderContent = () => {
		if (!currentNode) {
			return null;
		}
		return findNodeContent(treeStructure, currentNode);
	};

	// Setting Tree Structure
	useEffect(() => {
		const updatedTreeStructure = GetTreeStructure(updates, treeStructure, readOnly, storageHasBeenVisited, setStorageHasBeenVisited, setErrors);

		console.log(' updatedTreeStructure ', updatedTreeStructure);

		// Setting current node to the first tab in the first node of tree if the first node has children.
		if (currentNode.includes('1') && updatedTreeStructure[0]?.children) {
			setCurrentNode(['1', '1.1']);
		}
		setTreeStructure(updatedTreeStructure);
		// hasCurrentContentError();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [application, location, updates]);
	// Fetching application
	useEffect(() => {
		const fetchApp = async () => {
			setWsActive(true);
			try {
				let appRes = await GetRecord(
					get(location, 'state.api'),
					numericId,
					get(Population, `${get(location, 'state.api').replace(/.$/, '')}.${get(location, 'state.antragsformular', 'FULL')}`)
				);
				const app = removeNullDeep(removeDataKeys(get(appRes, 'data', [])));
				setApplication(app);
				setUpdates(app);
			} catch (err: any) {
				console.error('ERROR: Fetch App', err);
			} finally {
				setWsActive(false);
			}
		};
		if (numericId) fetchApp();
	}, [location, numericId]);

	const DialogContent = useMemo(() => {
		switch (get(location, 'state.api')) {
			case APIS.Antrag:
				return 'Ihr Antrag wurde erfolgreich eingereicht';
			case APIS.Mittelabruf:
				return 'Ihr Mittelabruf wurde erfolgreich eingereicht';
			case APIS.Verwendungsnachweis:
				return 'Ihr Verwendungsnachweis wurde erfolgreich eingereicht';
			case APIS.Zwischenbericht:
				return 'Ihr Zwischenbericht wurde erfolgreich eingereicht';
			case APIS.Abschlussbericht:
				return 'Ihr Abschlussbericht wurde erfolgreich eingereicht';
			default:
				return 'Ihr Antrag wurde erfolgreich eingereicht';
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	console.log(' update ', updates);

	if (!numericId) return <NoDetailsInfo title='Antrag' goBackUrl={PAGES_URLS.MeineFoerderung} />;

	return (
		<>
			<LoadingBackdrop open={wsActive} />
			<Box sx={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
				<ApplicationContextProvider
					application={updates}
					setApplicationProperty={setApplicationProperty}
					saveApplication={saveApplication}
					tree={treeStructure}
					errors={errors}
					readOnly={readOnly}
				>
					<MuiDialog
						open={appSubmitSuccess}
						onClose={() => {
							setAppSubmitSuccess(false);
							navigate(PAGES_URLS.MeineFoerderung);
						}}
						onCloseButtonName='Zurück zu meinen Anträgen'
						title={'Erfolg'}
						content={DialogContent}
					/>
					<MuiSnackbar showSnackbar={openSuccessSnackbar} closeSnackbar={() => setOpenSuccessSnackbar(false)} alertMessage='Antrag gesichert' />
					<MuiSnackbar showSnackbar={wsError} error={true} closeSnackbar={() => setWsError(false)} alertMessage='Etwas ist schief gelaufen' />
					<Container>
						<Grid container columns={12} spacing={4} sx={{ mt: '.5rem', padding: '0 .5rem', minHeight: '100vh' }}>
							<Grid xs={0} sm={0} md={3} lg={2.5} sx={{ display: { xs: 'none', md: 'block' }, paddingLeft: { xs: 0, md: 'inherit' } }}>
								<TreeNavigationView treeStructure={treeStructure} currentNode={currentNode} handleNavigation={handleNavigation} />
							</Grid>
							<Grid xs={12} sm={12} md={9} lg={7} sx={{ display: 'flex', flexDirection: 'column' }}>
								<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
									<ApplicationBreadcrumbs />
									<TreeViewNavigationMobile treeStructure={treeStructure} currentNode={currentNode} handleNavigation={handleNavigation} />
								</Stack>
								{renderContent()}
								<TreeNavigationButtons
									treeStructure={treeStructure}
									currentNode={currentNode}
									handleNavigation={handleNavigation}
									hasCurrentContentError={hasCurrentContentError}
									validateCompleteForm={validateCompleteForm}
								/>
							</Grid>
							<Grid xs={0} sm={0} lg={2.5} sx={{ display: { xs: 'none', lg: 'block' } }}>
								<AntragRightSideBar />
							</Grid>
						</Grid>
					</Container>
				</ApplicationContextProvider>
			</Box>
		</>
	);
}
const GetReadOnly = (application: any) => {
	return !APP_STATUS_EDITABLE.includes(get(application, P.Status.path));
};

const GetTreeStructure = (
	application: any,
	treeStructure: TreeNodeType[],
	readOnly: boolean,
	storageHasBeenVisited: boolean,
	setStorageHasBeenVisited: any,
	setErrors: any
): TreeNodeType[] => {
	return getMappedTreeStructure(
		application,
		TreeStructureFoerderAntrag(application, readOnly),
		treeStructure,
		storageHasBeenVisited,
		setStorageHasBeenVisited,
		setErrors,
		readOnly
	);
};

const updateRelations = async (application: any) => {
	let appCopy = cloneDeep(application);
	let updateDependencies = GetUpdateDependencies(appCopy);

	// POST or PUT for the relation
	for (let relation of get(updateDependencies, 'relations', [])) {
		let path = get(relation, 'field');
		let value = get(appCopy, path, []);
		let api = get(relation, 'api');
		if (isArray(value)) {
			for (let index in value) {
				await upsertRelation(appCopy, path + '.' + index, api);
			}
		} else {
			await upsertRelation(appCopy, path, api);
		}
		// unset(application, path);
	}
	return appCopy;
};
const upsertRelation = async (application: any, path: string, api: string) => {
	let data = { data: get(application, path + '.attributes', {}) };
	if (isObjectEmpty(get(data, 'data', ''))) return;

	let id = get(application, path + '.id');
	if (id) {
		await PutRecord(api, id, data);
	} else {
		let res = await PostRecord(api, data);
		set(application, path, get(res, 'data.id', null));
	}
};

export const GetUpdateDependencies = (application: any) => {
	return FoerderAntragDependencies(application);
};

const executeFormulas = (): any => {
	return [];
};

const mapAndGetTreeErrorNavigation = (initialTree: TreeNodeType[], treeStructure: TreeNodeType[]) => {
	let currentTree = [...treeStructure];
	const updatedTree = replaceFormStruc(currentTree, initialTree);
	return updatedTree;
};

const getMappedTreeStructure = (
	application: any,
	initialTree: TreeNodeType[],
	treeStructure: TreeNodeType[],
	storageHasBeenVisited: boolean,
	setStorageHasBeenVisited: any,
	setErrors: any,
	readOnly: boolean
): TreeNodeType[] => {
	const initial = treeStructure.length <= 0 && get(application, 'attributes');

	console.log(' initial ', initial);
	console.log(' treeStructure ', treeStructure);

	if (initial) {
		const antragId = get(application, 'id');
		const formHasBeenVisited = localStorage.getItem(antragId) ? localStorage.getItem(antragId) : false;
		if (formHasBeenVisited && !storageHasBeenVisited) {
			setStorageHasBeenVisited(true);
			return validateAll(application, initialTree, setErrors, readOnly);
		}
		return initialTree;
	} else if (treeStructure.length > 0 && get(application, 'attributes')) {
		const antragId = get(application, 'id');
		const formHasBeenVisited = localStorage.getItem(antragId) ? localStorage.getItem(antragId) : false;
		if (formHasBeenVisited && !storageHasBeenVisited) {
			setStorageHasBeenVisited(true);
			return validateAll(application, treeStructure, setErrors, readOnly);
		} else {
			return mapAndGetTreeErrorNavigation(initialTree, treeStructure);
		}
	}
	return [];
};

const replaceFormStruc = (oldTree: TreeNodeType[], initialTree: TreeNodeType[]) => {
	return initialTree.map((item: TreeNodeType) => {
		const result = findNodeByLabel(oldTree, item.label);
		const node = result ? result : item;
		let formStruc;
		let children;
		if (item.formStruc) {
			formStruc = {
				formStruc: item.formStruc,
			};
		}
		if (item.children) {
			children = {
				children: item.children.map((child) => {
					const resultChild = findNodeByLabel(oldTree, item.label, child.label);
					const nodeChild = resultChild ? resultChild : child;

					return {
						...child,
						showError: nodeChild.showError,
						hasBeenVisited: nodeChild.hasBeenVisited,
						formStruc: child.formStruc,
					};
				}),
			};
		}
		return {
			...item,
			showError: node.showError,
			hasBeenVisited: node.hasBeenVisited,
			...formStruc,
			...children,
		};
	});
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const findNodeById = (tree: TreeNodeType[], id: string): TreeNodeType | null => {
	for (const node of tree) {
		if (node.id === id) {
			return node;
		}

		if (node.children) {
			const foundInChildren = findNodeById(node.children, id);
			if (foundInChildren) {
				return foundInChildren;
			}
		}
	}
	return null;
};
const findNodeByLabel = (tree: TreeNodeType[], parentLabel: string, childLabel?: string): TreeNodeType | null => {
	for (const node of tree) {
		if (!childLabel && node.label === parentLabel) return node;

		if (childLabel === node.label) return node;

		if (childLabel && node.children && node.label === parentLabel) {
			const foundInChildren = findNodeByLabel(node.children, parentLabel, childLabel);
			if (foundInChildren) {
				return foundInChildren;
			}
		}
	}
	return null;
};
