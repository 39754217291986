type Dependencies = {
	files: any[];
	relations: any[];
};
function FoerderAntragDependencies(_application: any) {
	let dependencies: Dependencies = {
		files: [],
		relations: [],
	};

	return dependencies;
}
export { FoerderAntragDependencies };
