import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { getLocalstorageToken } from '../context/AuthHelper';
import GetRecords from '../lib/api/GetRecords';
import { APIS } from '../lib/constants/apis';
import { ANTRAGS_STATUS } from '../lib/constants/constant';
import { UseFetchStatesType } from '../lib/types/types';
import { getFieldNamefromDataPath, removeDataKeys, removeNullDeep } from '../utils/helper';

// import Schema
import AntragSchema from '../lib/paths/antrag_FULL_Paths.json';
const P = AntragSchema.attributes;
function useFetchApplicationsForNachrichten(): UseFetchStatesType {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<any[]>([]);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		// Fetch data
		const fetchData = async () => {
			try {
				const filter = {
					[getFieldNamefromDataPath(P.Antragsteller_Bewerber.path)]: get(getLocalstorageToken(), 'organisationId'),
					[getFieldNamefromDataPath(P.Status.path)]: { $ne: ANTRAGS_STATUS.inBearbeitung },
					// Skizze: { id: { $null: true } },
				};
				const res = await GetRecords(APIS.Antrag, [], filter, [{ updatedAt: 'desc' }]);
				console.log('res', res);
				const result: any[] = removeNullDeep(removeDataKeys(get(res, 'data', [])));
				setData(result);
			} catch (err: any) {
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	return { loading, data, error };
}
export { useFetchApplicationsForNachrichten };
