import { compact, get, has, isArray } from 'lodash';
import mapping from '../lib/mapping/mapping.json';

function removeDataKeys(jsonObj: any): any {
	if (typeof jsonObj !== 'object' || jsonObj === null) {
		return jsonObj;
	}
	if (Array.isArray(jsonObj)) {
		return jsonObj.map(removeDataKeys);
	}
	const transformedObj: any = {};
	for (const key in jsonObj) {
		if (key === 'data') {
			return removeDataKeys(jsonObj[key]);
		} else {
			transformedObj[key] = removeDataKeys(jsonObj[key]);
		}
	}
	return transformedObj;
}

function removeNullDeep(obj: any): any {
	if (Array.isArray(obj)) {
		return obj.map(removeNullDeep);
	} else if (typeof obj === 'object' && obj !== null) {
		return Object.fromEntries(
			Object.entries(obj)
				.filter(([_, v]) => v !== null)
				.map(([k, v]) => [k, removeNullDeep(v)])
		);
	} else {
		return obj;
	}
}

function getInitials(input: string): string {
	// Splitte den String in Worte anhand der Leerzeichen
	const words = input.split(' ');

	// Extrahiere den ersten Buchstaben jedes Wortes, mache ihn groß und füge ihn zusammen
	const initials = words.map((word) => word.charAt(0).toUpperCase()).join('');

	return initials;
}

function mapValuesCustom<T extends Record<string, any>, U>(obj: T, fn: (value: T[keyof T]) => U): { [K in keyof T]: U } {
	const result: Partial<{ [K in keyof T]: U }> = {};
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			result[key] = fn(obj[key]);
		}
	}
	return result as { [K in keyof T]: U };
}

function T(key: string) {
	return get(mapping, key, key);
}

function fieldNameTranslation(key: string, api?: string) {
	const fieldMapping = (window as any).fieldMapping || {};
	if (api) {
		return get(fieldMapping, `${api}_${key}`, key);
	}
	return get(fieldMapping, key, key);
}

function enumT(key: string) {
	const enumMapping = (window as any).enum || {};
	const enumMixinMapping = (window as any).enumMixin || {};

	return has(enumMixinMapping, key) ? get(enumMixinMapping, key, key) : get(enumMapping, key, key);
}

export function getDBKeysForEnum(value: string): string | undefined {
	const enumMapping = (window as any).enum || {};
	// Find the key by value
	const foundEntry = Object.entries(enumMapping).find(([_key, val]) => val === value);

	// Return the key if found, or '' if not
	return foundEntry ? foundEntry[0] : '';
}

function tooltipTranslate(key: string, api?: string) {
	const tooltipMapping = (window as any).tooltips || {};
	if (api) {
		return get(tooltipMapping, `${api}_${key}`, '');
	}
	return get(tooltipMapping, key, '');
}

function transformValues(obj: any, transformFunc: (value: any) => any): any {
	// console.log(' transformvalues  ', obj);
	if (Array.isArray(obj)) {
		return obj.map((item: any) => transformValues(item, transformFunc));
	} else if (typeof obj === 'object' && obj !== null) {
		return Object.keys(obj).reduce((acc: { [key: string]: any }, key: string) => {
			if (key === 'Allgemein_Kostenposition' || key === 'Allgemein_Kostenart' || key === 'Allgemein_Antragsformular') {
				acc[key] = obj[key];
			} else {
				acc[key] = transformValues(obj[key], transformFunc);
			}
			return acc;
		}, {});
	} else {
		return transformFunc(obj);
	}
}

function getLabelFromJSON(searchValue: string, jsonObject: any): string {
	let label = null;
	for (let key in jsonObject) {
		if (typeof jsonObject[key] === 'object') {
			label = getLabelFromJSON(searchValue, jsonObject[key]);
			if (label !== null) {
				break;
			}
		} else if (key === 'value' && jsonObject[key] === searchValue) {
			label = jsonObject['label'];
			break;
		}
	}
	return label;
}

function getValueFromJSON(searchlabel: string, jsonObject: any): string {
	let value = null;
	for (let key in jsonObject) {
		if (typeof jsonObject[key] === 'object') {
			value = getValueFromJSON(searchlabel, jsonObject[key]);
			if (value !== null) {
				break;
			}
		} else if (key === 'label' && jsonObject[key] === searchlabel) {
			value = jsonObject['value'];
			break;
		}
	}
	return value;
}

function getLabelFromDataPath(dataPath: string) {
	const dataPathParts = dataPath.split('.');
	return T(dataPathParts.length > 0 ? dataPathParts[dataPathParts.length - 1] : dataPath);
}

function getFieldNamefromDataPath(dataPath: string) {
	const dataPathParts = dataPath.split('.');
	return dataPathParts.length > 0 ? dataPathParts[dataPathParts.length - 1] : dataPath;
}

function addIndexToPath(path: string, index: number, specificPath: string) {
	const updatedPath = path.replace(specificPath, specificPath + '.' + index);
	return updatedPath;
}

function addIndexesToPath(path: string, indexes: string[], pathKeys: string[]) {
	let result = path;
	pathKeys.forEach((key, index) => {
		const regex = new RegExp(`\\b${key}\\b`);
		result = result.replace(regex, `${key}.${indexes[index]}`);
	});
	return result;
}

function isObjectEmpty(obj: any) {
	return Object.keys(obj).length === 0;
}

type ResDataItem = {
	id: number;
	attributes: {
		[key: string]: any;
	};
};
function getOptionsFromRes(resData: ResDataItem[], keyForLabel: any) {
	let result: any[];
	if (isArray(keyForLabel)) {
		result = resData.map((ele) => ({
			label: keyForLabel.map((key: string) => enumT(get(ele, `attributes.${key}`))).join(' - '),
			value: ele.id,
		}));
	} else {
		// eslint-disable-next-line array-callback-return
		result = resData.map((ele) => {
			if (ele.attributes[keyForLabel] && ele.id) {
				return { label: ele.attributes[keyForLabel], value: ele.id };
			}
		});
	}

	// Remove undefined values and compact the result
	result = compact(result);

	// Sort the result alphabetically by the `label` field
	result.sort((a, b) => a.label.localeCompare(b.label));
	return result;
}

/**
 * Select-Field can also have options for another Collection-Type (e.g. Leistungsgruppe)
 * When the value comes from data base, then the object is saved in the path. So return value.id
 * But when user has selected a new value from the options then only the id is saved in that path
 * So this function is used to get the id from the option.
 * @param value | It can be an object or ID also.
 * @returns | ID
 */
function getIDFromResponseOption(value: any) {
	if (typeof value === 'object') {
		return get(value, 'id');
	}
	return value;
}

function assignIdsToStruc(struc: any[], prefix = ''): any[] {
	return struc.map((ele, i) => {
		const id = prefix ? `${prefix}.${i + 1}` : `${i + 1}`;
		const newEle = { ...ele, id };
		if (ele.children) {
			newEle.children = assignIdsToStruc(ele.children, id);
		}
		return newEle;
	});
}

function groupKostenByPosition(kostenKategories: any[]): Record<string, string[]> {
	return kostenKategories.reduce(
		(acc, kategory) => {
			const position = kategory.attributes.Allgemein_Kostenposition;
			const finanzierung = kategory.attributes.Allgemein_Kostenart_Finanzierung;

			if (!acc[position]) {
				acc[position] = [];
			}

			if (!acc[position].includes(finanzierung)) {
				acc[position].push(finanzierung);
			}

			return acc;
		},
		{} as Record<string, string[]>
	);
}

function isTrue(value: any) {
	return value === 'true' || value === true;
}

const isValidBase64 = (str: string): boolean => {
	try {
		// Decode and encode again to check if the string is valid base64
		return btoa(atob(str)) === str;
	} catch (err) {
		return false;
	}
};

const isValidJSON = (str: string): boolean => {
	try {
		JSON.parse(str);
		return true;
	} catch (err) {
		return false;
	}
};

/**
 * The array should contains a JSON-Objects as element.
 * Is searches in each element where the key:searchKey === value:searchValue
 * If it find the element then the value of the key:returnKey will be returned
 * @param arrayOfObjects | Array with JSON Objects as element
 * @param searchKey | keyName in the JSON-Object from each element
 * @param searchValue | value in the JSON-Object
 * @param returnKey | keyName from where the value should be returned
 * @returns | STRING: Value of the given key
 */
const findValueByKeyInArray = (arrayOfObjects: any[], searchKey: string, searchValue: string, returnKey: string): string | null => {
	// Find the object in the array that matches the searchKey and searchValue
	const foundObject = arrayOfObjects.find((item) => get(item, searchKey) === searchValue);

	// If the object is found, return the value associated with the returnKey
	if (foundObject) {
		return get(foundObject, returnKey) as string;
	}
	// If not found, return undefined
	return null;
};

/**
 * It takes an array or Object as an input.
 * Then it search for the key "id" and then it removes it.
 * Mainly used to duplication some collection-types.
 * @param obj | JSON-Object from the response in componente or media or multi-relations
 * @returns | same array but with removed Ids
 */
function removeIdsFromArrayObjects(obj: any): any {
	if (Array.isArray(obj)) {
		// Only remove 'id' from objects within arrays
		return obj.map((item: any) => {
			if (item && typeof item === 'object') {
				const { id, ...rest } = item;
				return removeIdsFromArrayObjects(rest);
			}
			return item;
		});
	} else if (obj && typeof obj === 'object') {
		// Traverse deeper into the object to handle nested arrays
		for (const key in obj) {
			obj[key] = removeIdsFromArrayObjects(obj[key]);
		}
	}
	return obj;
}

const generateUniqueId = (): string => {
	const timestamp = Date.now().toString(36); // Zeitstempel in eine base36-Zeichenkette umwandeln
	const randomString = Math.random().toString(36).substring(2, 8); // Zufällige Zeichenkette generieren
	return `${timestamp}-${randomString}`;
};

export {
	addIndexesToPath,
	addIndexToPath,
	assignIdsToStruc,
	enumT,
	fieldNameTranslation,
	findValueByKeyInArray,
	generateUniqueId,
	getFieldNamefromDataPath,
	getIDFromResponseOption,
	getInitials,
	getLabelFromDataPath,
	getLabelFromJSON,
	getOptionsFromRes,
	getValueFromJSON,
	groupKostenByPosition,
	isObjectEmpty,
	isTrue,
	isValidBase64,
	isValidJSON,
	mapValuesCustom,
	removeDataKeys,
	removeIdsFromArrayObjects,
	removeNullDeep,
	T,
	tooltipTranslate,
	transformValues,
};
