import { Chip, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import { get, isArray } from 'lodash';
import React, { useState } from 'react';
import { MUIFieldProps } from '../../../../../lib/types/types';
import { enumT } from '../../../../../utils/helper';

import CancelIcon from '@mui/icons-material/Cancel';

const MUIMultiSelectField: React.FC<MUIFieldProps> = (props) => {
	const { label, fieldValue, updateFieldValue, updateFieldValueMulti, isDisabled, error, errorMessage, ...restProps } = props;
	const value = isArray(fieldValue) ? fieldValue : [];
	const [selectedNames, setSelectedNames] = useState<string[]>(value);
	return (
		<FormControl fullWidth error={error} {...restProps} sx={{ mt: '1rem' }}>
			<InputLabel sx={{ userSelect: 'text', cursor: 'text' }}>{label}</InputLabel>
			<Select
				disabled={isDisabled}
				error={error}
				fullWidth
				{...restProps}
				multiple
				displayEmpty
				value={selectedNames}
				onChange={(e) => {
					const temp = e.target.value as string[];
					setSelectedNames(temp);
					updateFieldValueMulti(get(props, 'path'), temp);
				}}
				input={<OutlinedInput label={label} />}
				renderValue={(selected) => {
					if (selected.length === 0) {
						return <em>{get(props, 'placeholder', 'Bitte eine oder mehrere Optionen aus der Liste wählen.')}</em>;
					}
					return (
						<Stack gap={1} direction='row' flexWrap='wrap'>
							{selected.map((value) => {
								// Find the corresponding option by value
								const selectedOption = get(props, 'options', []).find((ele: any) => get(ele, 'value') === value);

								// If there's a label, show it; otherwise, use enumT
								let displayValue = selectedOption ? get(selectedOption, 'label', enumT(value)) : enumT(value);

								return (
									<Chip
										key={value}
										label={displayValue}
										onDelete={() => {
											const filteredArray = selectedNames.filter((item) => item !== value);
											setSelectedNames(filteredArray);
											updateFieldValueMulti(get(props, 'path'), filteredArray);
										}}
										deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
									/>
								);
							})}
						</Stack>
					);
				}}
			>
				{get(props, 'options', []).map((ele: any, i: number) => {
					if (get(ele, 'label')) {
						return (
							<MenuItem key={i} value={get(ele, 'value')}>
								{get(ele, 'label')}
							</MenuItem>
						);
					} else {
						return (
							<MenuItem key={i} value={ele}>
								{enumT(ele)}
							</MenuItem>
						);
					}
				})}
			</Select>
			{error && <FormHelperText>{errorMessage}</FormHelperText>}
		</FormControl>
	);
};

export default MUIMultiSelectField;
