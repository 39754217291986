import React, { FC } from 'react';
import { APIS } from '../../../../lib/constants/apis';
import ApplicationHeading from '../../../../pages/private/online-antrag/application-heading/ApplicationHeading';
import CreateForm from '../../../ui-component/form-component/create-form/CreateForm';

// import schemas
import Schema from '../../../../lib/paths/person_FULL_Paths.json';
const P = Schema.attributes;

const PersonDaten: FC<{
	formValue: any;
	updateFunction: (_key: string, _value: any) => void;
	personDatenError: any;
}> = ({ formValue, updateFunction, personDatenError }) => {
	return (
		<>
			<ApplicationHeading
				title='Personendaten'
				description='Bitte vervollständigen Sie die Angaben zu Ihrer Person.
'
			/>

			<CreateForm
				formValues={formValue}
				formStruc={formStruc()}
				updateFormValuesFunc={updateFunction}
				formErrors={personDatenError}
				api={APIS.Person}
			/>
		</>
	);
};

export default PersonDaten;

const formStruc = () => {
	return [
		{
			fields: [P.Titel, P.Geschlecht],
			userProps: { columnToDivide: 1 },
		},
		{
			fields: [P.Vorname, P.Nachname],
			userProps: { columnToDivide: 1 },
		},
		{
			fields: [P.Geburtsdatum, P.Staatsbuergerschaft],

			userProps: { columnToDivide: 1 },
		},
		{
			fields: [P.E_Mail, P.Telefon],
			userProps: { columnToDivide: 1 },
		},
		{
			fields: [P.Adressdaten.schema.attributes.Strasse_Und_Hausnummer, P.Adressdaten.schema.attributes.PLZ],
			userProps: { columnToDivide: 1 },
		},
		{
			fields: [P.Adressdaten.schema.attributes.Ort, P.Adressdaten.schema.attributes.Bundesland],
			userProps: { columnToDivide: 1 },
		},
	];
};
