import { Delete, DensityMediumOutlined } from '@mui/icons-material';
import { Box, Container, Typography } from '@mui/material';
import {
	DataGridPremium,
	GridActionsCellItem,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarFilterButton,
	GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';
import { deDE } from '@mui/x-data-grid/locales';
import { LicenseInfo } from '@mui/x-license';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MuiDialog from '../../../components/ui-component/mui-dialog/MuiDialog';
import MuiSnackbar from '../../../components/ui-component/mui-snackbar/MuiSnackbar';
import GetRecords from '../../../lib/api/GetRecords';
import POP_LEVEL from '../../../lib/api/Populations.json';
import { APIS } from '../../../lib/constants/apis';
import { ANTRAGS_STATUS, MUIX_LICENSE } from '../../../lib/constants/constant';
import { PAGES_URLS } from '../../../lib/constants/routes';
import { DisplayDate } from '../../../utils/formats';
import { enumT, getFieldNamefromDataPath, getLabelFromDataPath, isTrue, removeDataKeys, removeNullDeep } from '../../../utils/helper';

// import Schemas
import { getLocalstorageToken } from '../../../context/AuthHelper';
import DeleteRecord from '../../../lib/api/DeleteRecord';
import Schema from '../../../lib/paths/antrag_FULL_Paths.json';
let P = Schema.attributes;

LicenseInfo.setLicenseKey(MUIX_LICENSE);

function MeineAntraege() {
	const navigate = useNavigate();
	const location = useLocation();
	const [wsActive, setWsActive] = useState(true);
	const [wsError, setWsError] = useState(false);
	const [data, setData] = useState<any>([]);

	const [appIdToDelete, setAppIdToDelete] = useState(0);
	const [appDeleteSuccess, setAppDeleteSuccess] = useState<boolean | null>(null);

	const columns = useMemo(() => {
		let column: any[] = [
			{
				field: 'id',
				headerName: 'ID',
				maxWidth: 50,
			},
			{
				field: getFieldNamefromDataPath(P.Projekttitel.path),
				headerName: 'Name der Bewerbung',
				flex: 1,
			},
			{
				field: getFieldNamefromDataPath(P.Ausschreibung.schema.attributes.Programmname.path),
				headerName: 'Förderprogramm',
				flex: 1,
			},
			{
				field: getFieldNamefromDataPath(P.Status.path),
				headerName: getLabelFromDataPath(P.Status.path),
				flex: 1,
			},
			{
				field: getFieldNamefromDataPath(P.Allgemein_Eingereicht_Am.path),
				headerName: getLabelFromDataPath(P.Allgemein_Eingereicht_Am.path),
				flex: 1,
				maxWidth: 160,
			},
			// {
			// 	field: getFieldNamefromDataPath('P.Eingangsnummer.path'),
			// 	headerName: getLabelFromDataPath('P.Eingangsnummer.path'),
			// 	flex: 1,
			// 	maxWidth: 150,
			// },
			{
				type: 'actions',
				field: 'actions',
				headerName: 'Aktion',
				width: 80,
				getActions: (params: any) => {
					let actions: any[] = [];
					if (get(params, `row.${getFieldNamefromDataPath(P.Status.path)}`) === enumT(ANTRAGS_STATUS.inBearbeitung)) {
						actions = [<GridActionsCellItem icon={<Delete />} label='Löschen' onClick={() => setAppIdToDelete(get(params, 'row.id', -1))} />];
					}
					return actions;
				},
			},
		];
		return column;
	}, []);

	function CustomToolbar() {
		return (
			<>
				<GridToolbarContainer>
					<GridToolbarFilterButton />
					<GridToolbarDensitySelector slotProps={{ button: { startIcon: <DensityMediumOutlined /> } }} />
					<GridToolbarQuickFilter sx={{ marginLeft: 'auto' }} />
				</GridToolbarContainer>
				<Box sx={{ height: '50px', width: '100%' }}></Box> {/* Empty spacer */}
			</>
		);
	}

	const handleDeleteApp = async () => {
		try {
			setWsActive(true);
			let res = await DeleteRecord(APIS.Antrag, appIdToDelete);
			if (res) {
				setAppDeleteSuccess(true);
			}
		} catch (err: any) {
			console.error('ERROR: Deleting Application ', err);
			setAppDeleteSuccess(false);
		} finally {
			setAppIdToDelete(0);
			setWsActive(false);
		}
	};

	useEffect(() => {
		const fetch = async () => {
			try {
				// TODO: Muss Überarbeitet werden, damit alle Anträge hier aufgelistet wird)
				let filter = {
					//Skizze: { id: { $null: true } },
					Bewerber: { id: get(getLocalstorageToken(), 'personId') },
				};
				let res = await GetRecords(APIS.Antrag, POP_LEVEL.antrag.FULL, filter, [{ updatedAt: 'desc' }]);

				const transformedData = removeNullDeep(removeDataKeys(get(res, 'data', []))).map((ele: Record<string, any>) => {
					return {
						id: get(ele, 'id'),
						[getFieldNamefromDataPath(P.Projekttitel.path)]: get(ele, P.Projekttitel.path),
						[getFieldNamefromDataPath(P.Ausschreibung.schema.attributes.Programmname.path)]: get(
							ele,
							P.Ausschreibung.schema.attributes.Programmname.path
						),
						[getFieldNamefromDataPath(P.Status.path)]: enumT(get(ele, P.Status.path)),
						[getFieldNamefromDataPath(P.Allgemein_Eingereicht_Am.path)]:
							get(ele, P.Allgemein_Eingereicht_Am.path) ? DisplayDate(get(ele, P.Allgemein_Eingereicht_Am.path)) : '-',
						// [getFieldNamefromDataPath('P.Eingangsnummer.path')]: T(get(ele, 'P.Eingangsnummer.path')),
					};
				});
				setData(transformedData);
				setWsActive(true);
			} catch (err: any) {
				console.error('ERROR: Fetching Applications ', err);
				setWsError(true);
			} finally {
				setWsActive(false);
			}
		};

		fetch();
	}, [appDeleteSuccess]);

	if (wsError) {
		return <Typography>Etwas is schief gelaufen</Typography>;
	}

	return (
		<Container>
			<MuiSnackbar
				showSnackbar={isTrue(appDeleteSuccess)}
				closeSnackbar={() => setAppDeleteSuccess(null)}
				alertMessage='Der Antrag wurde erfolgreich gelöscht'
			/>
			<MuiSnackbar
				showSnackbar={appDeleteSuccess === false}
				closeSnackbar={() => setAppDeleteSuccess(null)}
				alertMessage='Der Antrag konnte nicht gelöscht werden'
			/>
			<MuiDialog
				open={appIdToDelete > 0}
				title={'Antrag Löschen?'}
				content={'Wollen Sie den Antrag wirklich löschen? Diese Aktion ist unwiderruflich.'}
				onClose={() => setAppIdToDelete(0)}
				onConfirm={() => handleDeleteApp()}
			/>
			<Typography variant='h3'> Meine Anträge</Typography>
			<Box sx={{ mt: '2rem' }}>
				<DataGridPremium
					sx={{
						'& .MuiButtonBase-root': {
							backgroundColor: '#eee',
						},
					}}
					loading={wsActive}
					autoHeight
					pagination
					pageSizeOptions={[5, 10, 25, 50, 100]}
					rows={data}
					columns={columns}
					columnVisibilityModel={{ Skizze: false }}
					disableColumnSelector
					onRowClick={(param) => {
						navigate(`${PAGES_URLS.OnlineAntrag}`, {
							state: {
								id: param.id,
								api: APIS.Antrag,
							},
						});
					}}
					initialState={{
						filter: {
							filterModel: {
								items: [get(location, 'state.filter', {})],
							},
						},
						pagination: {
							paginationModel: { pageSize: 10, page: 0 },
						},
					}}
					localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
					slots={{ toolbar: CustomToolbar }}
				/>
			</Box>
		</Container>
	);
}

export default MeineAntraege;
