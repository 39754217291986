import { CssBaseline, ThemeProvider } from '@mui/material';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import theme from './assets/theme/theme';
import Header from './components/layout/Header';
import AuthenticationProvider from './context/AuthContext';
import NotFound from './pages/public/NotFound';
// import Footer from './components/layout/Footer';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { deDE } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/de';
import React from 'react';
import '../src/assets/styles/App.css';
import Footersocket from './components/layout/Footersocket';
import ScrollToTop from './components/layout/ScrollToTop';
import { AUTHENTICATION_PAGES, PRIVATE_PAGES, PROFILE_PAGES, PUBLIC_PAGES } from './lib/constants/routes';

function App() {
	const router = createBrowserRouter([
		{
			element: (
				<>
					<ScrollToTop />
					<Header />
					<div id='main'>
						<Outlet />
					</div>
					{/* <Footer /> */}
					<Footersocket />
				</>
			),
			errorElement: (
				<>
					<Header />
					<NotFound />
				</>
			),
			children: [
				// **************************************************************************************************************
				// ***************************************  Navigation for All Users Only ***************************************
				// **************************************************************************************************************
				...PUBLIC_PAGES.map((ele) => ({
					path: ele.url,
					element: ele.element,
				})),

				//  **************************************************************************************************************
				//  **************************************  Navigation for Public Users Only *************************************
				//  **************************************************************************************************************
				...AUTHENTICATION_PAGES.map((ele) => {
					return {
						path: ele.url,
						element: ele.element,
					};
				}),

				// **************************************************************************************************************
				// **********************************  Navigation for Authenticated Users Only **********************************
				// **************************************************************************************************************
				...PRIVATE_PAGES.map((ele) => {
					return {
						path: ele.url,
						element: ele.element,
					};
				}),
				...PROFILE_PAGES.map((ele) => {
					return {
						path: ele.url,
						element: ele.element,
					};
				}),
			],
		},
	]);

	return (
		<>
			<LocalizationProvider
				dateAdapter={AdapterDayjs}
				adapterLocale='de'
				localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
			>
				<AuthenticationProvider>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<RouterProvider router={router}></RouterProvider>
					</ThemeProvider>
				</AuthenticationProvider>
			</LocalizationProvider>
		</>
	);
}

export default App;
