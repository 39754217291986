import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useAuthentication } from '../../../../../context/AuthContext';
import { get } from 'lodash';
import PasswordForm from './PasswordForm';
import MuiSnackbar from '../../../../../components/ui-component/mui-snackbar/MuiSnackbar';

const LoginData: FC<{}> = () => {
	const { authenticatedData } = useAuthentication();
	const [showPasswordForm, setShowPasswordForm] = useState<boolean>(false);
	const [passwordUpdateSuccess, setPasswordUpdateSuccess] = useState<boolean | null>(null);
	return (
		<>
			<MuiSnackbar
				showSnackbar={passwordUpdateSuccess}
				closeSnackbar={() => setPasswordUpdateSuccess(null)}
				alertMessage={'Ihr Passwort wurde aktualisiert'}
			/>
			<Stack spacing={3}>
				<Typography variant="h3" component="h2">
					Ihre Anmelde Daten
				</Typography>
				<Typography> Sie können hier Ihr Passwort ändern</Typography>
				<Divider />
				<Box>
					<Typography sx={{ fontWeight: '700' }}> E-Mail</Typography>
					<Typography> {get(authenticatedData, 'user.email')}</Typography>
				</Box>
				<Box>
					<Typography sx={{ fontWeight: '700' }}>Benutzername</Typography>
					<Typography>{get(authenticatedData, 'user.username')}</Typography>
				</Box>
				<Divider />
				<Typography>Passwort</Typography>
				{!showPasswordForm ? (
					<>
						<Stack direction={'row'} justifyContent={'space-between'}>
							<Typography>************</Typography>
							<Button variant="outlined" onClick={() => setShowPasswordForm((prevValue: boolean) => !prevValue)}>
								Ändern
							</Button>
						</Stack>
					</>
				) : (
					<>
						<Box sx={{ border: '1px solid #707070', padding: '2rem' }}>
							<Typography>Password ändern</Typography>
							<PasswordForm
								setShowPasswordForm={setShowPasswordForm}
								setPasswordUpdateSuccess={setPasswordUpdateSuccess}
							/>
						</Box>
					</>
				)}
			</Stack>
		</>
	);
};
export default LoginData;
