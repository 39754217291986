export const IS_PRIVATE_PLATFORM = true;

export const MUIX_LICENSE: string = 'a5563a2bfbfe6d6f2d1c0a389d421128Tz05MTQxNCxFPTE3NDg0NTI1NDMwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y';

export const ERROR_MESSAGE = {
	// Login
	'Invalid identifier or password': 'Benutzername oder E-Mail Adresse oder Passwort ist falsch.',
	'Your account email is not confirmed': 'Ihr Konto ist noch nicht aktiv.',
	'Your account has been blocked by an administrator': 'Ihr Benutzerkonto wurde gesperrt.',
	// Send E-Mail Confirmation
	'already.confirmed': 'Ihre E-Mail-Adresse wurde schon einmal bestätigt. Sie brauchen nicht nochmal zu bestätigen.',
	'token.invalid': 'Ungültiger Schlüssel.',
	// Forgot-Password
	'This email does not exist': 'Die von Ihnen eingegebene E-Mail-Adresse existiert nicht.',
	'This user is disabled': 'Ihr Benutzerkonto ist deaktiviert.',
	'Incorrect code provided': 'Ungültiger Schlüssel.',
	'Passwords do not match': 'Beide Passwörter stimmen nicht überein.',
	'Incorrect params provided': 'Beide Passwörter stimmen nicht überein.',
	'The provided current password is invalid': 'Ihr altes Password ist falsch.',
	'Your new password must be different than your current password': 'Ihr neues Passwort muss sich von Ihrem aktuellen Passwort unterscheiden.',
	'User not found': 'Der Benutzername existiert nicht. Bitte überprüfen Sie Ihre Eingabe.',
};

export const ANTRAG_BUTTON_NAME = 'Projekttitel: hier eintragen';
export const HINWEIS_AN_HA_PLACEHOLDER_TEXT = 'Fassen Sie in wenigen Worten zusammen, welche Änderungen Sie an der Skizze vorgenommen haben.';
export const PLATZHALTER_TEXT = '';
export const ALS_ERLEDIGT_MARKIEREN_LABEL = 'Als erledigt markieren';
export const UEBERARBEITUNGS_HINWEIS_TITLE = 'Überarbeitungshinweis';
export const ERLAUTERUNG_IN_KOSTEN_TITLE = '';
export const NOCH_NICHT_MOEGLICH_TEXT = 'Noch nicht möglich';
export const MITTEL_ABRUF_ZUERST_ERSTELLEN_TEXT = 'Sie müssen ein Mittelabruf zuerst erstellen';
export const MITTEL_ABRUF_IST_ABGELEHNT_TEXT = 'Der Mittelabruf wurde abgelehnt. Daher kann kein Verwendungsnachweis erbracht werden.';
export const LETZTE_VN_ERST_FREI_TEXT =
	'Sie können neuen Verwendungsnachweis für das Jahr erstellen, sobald die vorherige Verwendungsnachweis freigegeben ist';
export const ABSCHLUSS_DESCRIPTION =
	'Überprüfen Sie noch einmal die wichtigsten Daten und folgen dann Schritten weiter unten um den Antrag einzureichen';
export const ANGABEN_IN_NETTO = 'Alle Angaben sind in Netto';
export const ANGABEN_IN_BRUTTO = 'Alle Angaben sind in Brutto';
export const PARTNER_DATEN_NICHT_ERFASST = 'Für diesen Partner wurden noch keine Daten eingegeben. Bitte tragen Sie zuerst die Partnerdaten ein.';
export const UNTERNEHMENSDATEN_NICHT_ERFASST =
	'Achtung: Für den ausgewählten Institutionstyp unter "Antragsteller/in" wurden noch nicht alle Felder im Abschnitt "IV. Unternehmensdaten" ausgefüllt. Bitte stellen Sie sicher, dass im Reiter "Unternehmensdaten" alle erforderlichen Angaben gemacht wurden.';

export const EMOB_INVEST_IMAGE_UPLOAD_FIELD_PROPS = {
	tableLabel: 'Upload-Bereich für Bilder',
	tableLabelTooltip: '(Bitte verweisen Sie oben in Ihrer Beschreibung auf die jeweiligen hochgeladenen Objekte.)',
	fieldProps: { Bild: { allowOnlyImage: true } },
};

export const FORM_FIELDS_TYPES = {
	label: 'label',
	string: 'string',
	text: 'text',
	textEditor: 'textEditor',
	richtext: 'richtext',
	markdownEditor: 'markdownEditor',
	integer: 'integer',
	decimal: 'decimal',
	enumeration: 'enumeration',
	singleSelect: 'singleSelect',
	autocomplete: 'autocomplete',
	singleUpload: 'singleUpload',
	media: 'media',
	componentMedia: 'componentMedia',
	datetime: 'datetime',
	date: 'date',
	checkbox: 'checkbox',
	boolean: 'boolean',
	radio: 'radio',
	// component: 'component',
	componentTable: 'componentTable',
	editableTable: 'editableTable',
	multiSelect: 'multiSelect',
	divider: 'divider',
	infoBox: 'infoBox',
	nothing: 'nothing',
} as const;

export const MONAT_LISTE = [
	{ Monat: 'Januar' },
	{ Monat: 'Februar' },
	{ Monat: 'Maerz' },
	{ Monat: 'April' },
	{ Monat: 'Mai' },
	{ Monat: 'Juni' },
	{ Monat: 'Juli' },
	{ Monat: 'August' },
	{ Monat: 'September' },
	{ Monat: 'Oktober' },
	{ Monat: 'November' },
	{ Monat: 'Dezember' },
];

export const ERLAUTERUNG_DEFAULT_COLUMNS_LABEL = ['Liegt bei', 'Entfällt', 'Erläuterung zur Anlage'];

export const ERLAUTERUNG_DEFAULT_TWO_COLUMNS_LABEL = ['Liegt bei', 'Erläuterung zur Anlage'];

export const APP_STATUS_EDITABLE = ['inBearbeitung', 'inUeberarbeitungDurchAntragssteller', 'FinalisierungDurchAntragssteller'];

export const FILE_UPLOAD_ALLOWED_SIZE = 10000000; // 10MB
export const FILE_UPLOAD_ALLOWED_NUMBER = 10; // Maximum number of files allowed to upload
export const FILE_UPLOAD_ALLOWED_FORMATS = [
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/pdf',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'image/jpeg',
	'image/bmp',
	'image/png',
];
export const FILE_UPLOAD_ALLOWED_FORMATS_ONLY_IMAGES = ['image/jpeg', 'image/bmp', 'image/png'];
export const INVALID_FILE_UPLOAD_TEXT_MESSAGE =
	'Zulässige Dateiformate sind: .docx, .pdf, .xlsx, .png, .bmp oder .jpeg. Ältere Formate sind wie .doc oder .xls werden nicht akzeptiert.';
export const INVALID_ONLY_IMAGES_UPLOAD_TEXT_MESSAGE = 'Zulässige Dateiformate sind: .png, .bmp oder .jpeg. Andere Formate  werden nicht akzeptiert.';
export const MAX_TEXTBOX_LENGTH = 10000;
export const MAX_TEXTFIELD_LENGTH = 255;

export const NOT_FOUND_ERROR_MESSAGE = 'Etwas ist schief gelaufen.';

export const PERSON_ROLLE = {
	AnsprechpartnerFoerdermittelgeber: 'AnsprechpartnerFoerdermittelgeber',
	ItarosUser: 'I45taros45User',
	AnsprechpartnerAntragsteller: 'AnsprechpartnerAntragsteller',
	AnsprechpartnerProjektpartner41: 'Ansprechpartner40Projektpartner41',
	AnsprechpartnerPotentiellerAntragsteller41: 'Ansprechpartner40PotentiellerAntragsteller41',
	AnsprechpartnerAsoziiertePartner41: 'Ansprechpartner40AsoziiertePartner41',
	PortalNutzer: 'Portal45Nutzer',
	Bewerber: 'Bewerber',
};

export const MEDIAN_INTERVAL = {
	jaehrlich: 1,
	halbjaehrlich: 2,
	quartalisch: 3,
};

export const INSTITUTIONSTYP_MAPPING = {
	Privatpersonen: 'Privatpersonen',
	KleineUndMittlereUnternehmen: 'KleineUndMittlereUnternehmen',
	Einzelunternehmen: 'Einzelunternehmen',
	KleineUnternehmen: 'KleineUnternehmen',
	MittlereUnternehmen: 'MittlereUnternehmen',
	Grossunternehmen: 'Grossunternehmen',
	UnternehmenMitAuslaendischemMehrheitsbesitz: 'UnternehmenMitAuslaendischemMehrheitsbesitz',
	Forschungseinrichtungen: 'Forschungseinrichtungen',
	Freiberufler: 'Freiberufler',
	Behoerden: 'Behoerden',
	Kommunen: 'Kommunen',
	Vereine: 'Vereine',
	Verbaende: 'Verbaende',
	Stiftungen: 'Stiftungen',
};

export const ANTRAGS_FORMULAREN = {
	EmobInvest: 'Elektromobilitaet40Investitionsvorhaben41',
	EmobFundE: 'Elektromobilitaet40Forschungs45UndEntwicklungsvorhaben41',
	Loewe: 'LOEWE45Foerderlinie3',
	Elektrobusse: 'Elektrobusse',
	LogistikUndMobilitaet: 'LogistikUndMobilitaet',
	Mobilitaetsplaene: 'Mobilitaetsplaene',
	StartUpStipendium: 'Start45up45Stipendium',
	StartUpProjekte: 'Start45up45Projekte',
	Kreativwirtschaft: 'Kreativwirtschaft',
	Messefoerderung: 'Messefoerderung',
	Entwicklungszusammenarbeit: 'Entwicklungszusammenarbeit',
};
export const ART_DES_ANTRAGS = {
	ErstAntrag: 'Erstantrag',
	AenderungsAntrag: 'Aenderungsantrag',
};
export const ANTRAGS_STATUS = {
	inBearbeitung: 'inBearbeitung',
	eingegangen: 'inFormalerPruefung',
	inPruefung: 'inPruefung',
	vollstaendigKorrekt: 'vollstaendig47Korrekt',
	unvollstaendigInkorrekt: 'unvollstaendig47Inkorrekt',
	inUeberarbeitungDurchAntragssteller: 'inUeberarbeitungDurchAntragssteller',
	fuerGremiumFreigegeben: 'fuerGremiumFreigegeben',
	EmpfehlungZurUeberarbeitung: 'EmpfehlungZurUeberarbeitung',
	EmpfehlungZurAblehnung: 'EmpfehlungZurAblehnung',
	EmpfehlungZurAntragstellung: 'EmpfehlungZurAntragstellung',
	EmpfehlungZurFoerderung: 'EmpfehlungZurFoerderung',
	abgelehnt: 'abgelehnt',
	GutachtenAngefordert: 'GutachtenAngefordert',
	GutachtenErfolgt: 'GutachtenErfolgt',
	FinalisierungDurchAntragssteller: 'FinalisierungDurchAntragssteller',
	eingegangenNachFinalisierung: 'eingegangenNachFinalisierung',
	imUnterschriftenlauf: 'imUnterschriftenlauf',
	FoerderbescheidErstellt: 'FoerderbescheidErstellt',
	FoerderbescheidUebermittelt: 'FoerderbescheidUebermittelt',
	FoerderbescheidHatBestandskraft: 'FoerderbescheidHatBestandskraft',
	inFoerderung: 'inFoerderung',
	demProgrammleiterVorgelegt: 'demProgrammleiterVorgelegt',
	demControllingVorgelegt: 'demControllingVorgelegt',
	demMinisteriumVorgelegt: 'demMinisteriumVorgelegt',
	ProjektBeendet: 'ProjektBeendet',
	ProjektBeendet45ZweckbindungsfristLaeuft: 'ProjektBeendet45ZweckbindungsfristLaeuft',
	AntragDurchAenderungsantragAbgeloest: 'AntragDurchAenderungsantragAbgeloest',
	EntscheidungsvorlageErstellt: 'EntscheidungsvorlageErstellt',
	ControllingErfolgt: 'ControllingErfolgt',
	FoerderbescheidVorbereitet: 'FoerderbescheidVorbereitet',
	FoerderbescheidImUnterschriftenlauf: 'FoerderbescheidImUnterschriftenlauf',
	AntragstellungErlauben: 'AntragstellungErlauben',
	AufStellungnahme47GutachtenVerzichtet: 'AufStellungnahme47GutachtenVerzichtet',
	freigegeben: 'akzeptiert',
	antragFreigeben: 'freigegeben',
};

export const NACHRICHT_ORDNER = {
	Posteingang: 'Posteingang',
	Postausgang: 'Postausgang',
	Entwuerfe: 'Entwuerfe',
};
export const ZAHLUNGSWEISE = {
	Vorschuessig: 'vorschuessig',
	Nachschus: 'nachschuessig',
};

export const ANTRAG_TYP = {
	Antrag: 'Antrag',
	Skizze: 'Skizze',
};
export const KOSTENTRAEGER = {
	Antragsteller: 'Antragsteller',
	Verbundpartner: 'Verbundpartner',
};
export const VORHABENS_ART = {
	Einzelvorhaben: 'Einzelvorhaben',
	Verbundvorhaben: 'Verbundvorhaben',
};

export const KOSTEN_POSITION = {
	Ausgabe: 'Ausgabe',
	Einnahme: 'Einnahme',
};
export const KOSTEN_ARTEN = {
	Personalkosten: 'Personalkosten',
	Sachkosten: 'Sachkosten',
	Reisekosten: 'Reisekosten',
	Investitionskosten: 'Investitionskosten',
	Eigenmittel: 'Eigen45UndDrittmittel',
	Verwaltungskostenpauschale: 'Verwaltungskostenpauschale',
};
export const AUSGABE_KATEGORIE = {
	Elektrofahrzeug: 'Elektrofahrzeuge',
	LadeTankInfrastruktur: 'Lade4547Tankinfrastruktur',
	NetzanschlussUndErdarbeiten: 'NetzanschlussUndErdarbeiten',
	H2: 'H245Infrastruktur',
};

export const PRUEF_ASSISTENZ_STATUS = {
	ungeprüft: 'ungeprueft',
	uberarbeitung: 'zurUeberarbeitung',
	freigegeben: 'freigegeben',
};

export const TEMPLATES = {
	[ANTRAGS_FORMULAREN.EmobInvest]: 3,
	[ANTRAGS_FORMULAREN.EmobFundE]: 1,
	[ANTRAGS_FORMULAREN.Messefoerderung]: 5,
	[ANTRAGS_FORMULAREN.Kreativwirtschaft]: 8,
	[ANTRAGS_FORMULAREN.StartUpProjekte]: 9,
	[ANTRAGS_FORMULAREN.Entwicklungszusammenarbeit]: 11,
	[ANTRAGS_FORMULAREN.Mobilitaetsplaene]: 23,
	[ANTRAGS_FORMULAREN.Loewe]: 24,
	[ANTRAGS_FORMULAREN.Elektrobusse]: 28,
	[ANTRAGS_FORMULAREN.LogistikUndMobilitaet]: 30,
};
export const TEMPLATES_MA = {
	default: 7,
};
export const TEMPLATES_VN = {
	default: 32,
};
export const TEMPLATES_ZN = {
	[ANTRAGS_FORMULAREN.EmobInvest]: 99,
	[ANTRAGS_FORMULAREN.EmobFundE]: 21,
	[ANTRAGS_FORMULAREN.Messefoerderung]: 99,
	[ANTRAGS_FORMULAREN.Kreativwirtschaft]: 99,
	[ANTRAGS_FORMULAREN.StartUpProjekte]: 99,
	[ANTRAGS_FORMULAREN.Entwicklungszusammenarbeit]: 99,
	[ANTRAGS_FORMULAREN.Loewe]: 99,
	[ANTRAGS_FORMULAREN.Elektrobusse]: 99,
	[ANTRAGS_FORMULAREN.LogistikUndMobilitaet]: 99,
};
export const TEMPLATES_AB = {
	[ANTRAGS_FORMULAREN.EmobInvest]: 18,
	[ANTRAGS_FORMULAREN.EmobFundE]: 10,
	[ANTRAGS_FORMULAREN.Messefoerderung]: 33,
	[ANTRAGS_FORMULAREN.Kreativwirtschaft]: 19,
	[ANTRAGS_FORMULAREN.StartUpProjekte]: 20,
	[ANTRAGS_FORMULAREN.Entwicklungszusammenarbeit]: 99,
	[ANTRAGS_FORMULAREN.Loewe]: 99,
	[ANTRAGS_FORMULAREN.Elektrobusse]: 99,
	[ANTRAGS_FORMULAREN.LogistikUndMobilitaet]: 99,
};
export const TEMPLATES_VORZEITIGER_MASSNAHME = {
	default: 12,
	[ANTRAGS_FORMULAREN.EmobFundE]: 10,
};
export const TEMPLATES_FUNKTION_BESCHREIBUNG = {
	default: 13,
};
export const TEMPLATES_STAMMDATENBLATT = {
	default: 29,
};
export const TEMPLATES_DEMINIMIS = {
	default: 31,
};
export const TEMPLATES_SELBST_ERKLAERUNG = {
	default: 33,
};

export const IN_BEANTRAGTE_VORHABEN = {
	ausschliesslich: 'ausschliesslich',
	nurTeilweise: 'nurTeilweise',
};

export const PROJEKTDURCHFUERUNG = {
	inland: 'Inland',
	ausland: 'Ausland',
};

export const AENDERUNG_ANFRAGEN = {
	Mitteluebertragung: 'Mitteluebertragung',
	Laufzeitverlaengerung: 'Laufzeitverlaengerung',
	Adressaenderung: 'Adressaenderung',
	AenderungDerGeplantenProjektausgaben: 'AenderungDerGeplantenProjektausgaben',
	AntragZurueckziehen: 'AntragZurueckziehen',
	Projektabbruch: 'Projektabbruch',
	Sonstiges: 'Sonstiges',
};

export const GEGENSTAND_DER_FOERDERUNG = {
	Erstellung: 'ErstellungEinesNachhaltigenIntegriertenMobilitaetsplans',
	Umsetzung: 'UmsetzungEinesNachhaltigenIntegriertenMobilitaetsplans',
	Beschaffung: 'dieBeschaffungVonElektro_fcd2e4896fd4f8_esetz44TeilBNr46IZiffer5',
	Nachruestung: 'dieNotwendigeNachruestun_327bb440f90141_esetz44TeilBNr46IZiffer7',
};

export const ART_DER_EIGENLEISTUNG = {
	SozialversicherungspflichtigesPersonal: 'SozialversicherungspflichtigesPersonal',
	UnbareEigenleistung: 'UnbareEigenleistung',
	BareEigenleistung: 'BareEigenleistung',
};

export const BENUTZERDATEN_PORTAL_ROLLE = {
	Admin: 'Orga45Admin',
	Standard: 'Standard',
	HA_USER: 'HA45User',
};

export const CUSTOM_PATH = {
	isAdmin: 'isAdmin',
};

export const KMU_SELECTIONS = {
	Institutionstyp: {
		KleineUnternehmen: {
			value: 'KleineUnternehmen',
			AnzahlMitarbeiter: {
				value: 'bis49',
				min: 0,
				max: 49,
			},
			JahresBilanzSumme: {
				value: 'bis10Mio468364',
				min: 0,
				max: 10000000,
			},
			JahresUmsatz: {
				value: 'bis10Mio468364',
				min: 0,
				max: 10000000,
			},
		},
		MittlereUnternehmen: {
			value: 'MittlereUnternehmen',
			AnzahlMitarbeiter: {
				value: 'F5045249',
				min: 50,
				max: 249,
			},
			JahresBilanzSumme: {
				value: 'ueber10Mio468364Bis43Mio468364',
				min: 10000001,
				max: 43000000,
			},
			JahresUmsatz: {
				value: 'ueber10Mio468364Bis508364',
				min: 10000001,
				max: 50000000,
			},
		},
		Grossunternehmen: {
			value: 'Grossunternehmen',
			AnzahlMitarbeiter: {
				value: 'C250OderMehr',
				min: 250,
			},
			JahresBilanzSumme: {
				value: 'ueber43Mio468364',
				min: 43000001,
			},
			JahresUmsatz: {
				value: 'ueber50Mio468364',
				min: 50000001,
			},
		},
	},
};
