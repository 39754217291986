import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const MarkdownRenderer: FC<{ markdown: string }> = ({ markdown }) => {
	return (
		<ReactMarkdown
			className='test-react-markdown'
			components={{
				a: ({ node, ...props }) => (
					<a {...props} target='_blank' rel='noopener noreferrer' style={{ color: '#023671' }}>
						{props.children}
					</a>
				),
			}}
			rehypePlugins={[rehypeRaw]} // Erlaubt das Parsen von HTML-Tags wie <u>
		>
			{markdown}
		</ReactMarkdown>
	);
};

export default MarkdownRenderer;
