import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MeinBereichButton, MeinBereichPaper } from '../../../../components/ui-component/mein-bereich/MeinBereichComponents';
import { PAGES_URLS } from '../../../../lib/constants/routes';

function VorDerForderung() {
	const navigate = useNavigate();
	return (
		<>
			<MeinBereichPaper titleIcon={<EditIcon />} title={'Vor der Förderung'}>
				<MeinBereichButton
					buttonName='Neuen Antrag einreichen'
					onClick={() => {
						navigate(PAGES_URLS.FoerderProgramm);
					}}
				/>
				<MeinBereichButton
					buttonName='Einen Antrag bearbeiten'
					onClick={() => {
						navigate(PAGES_URLS.MeineFoerderung, {
							state: {
								filter: {
									field: 'Status',
									operator: 'contains',
									value: 'in Bearbeitung',
								},
							},
						});
					}}
				/>
				<MeinBereichButton
					buttonName='Eingereichte Anträge ansehen'
					onClick={() => {
						navigate(PAGES_URLS.MeineFoerderung, {
							state: {
								filter: {
									field: 'Status',
									operator: 'contains',
									value: 'In formaler Prüfung',
								},
							},
						});
					}}
				/>
			</MeinBereichPaper>
		</>
	);
}

export default VorDerForderung;
