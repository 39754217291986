import Schema from 'validate';
import isEmail from 'validator/lib/isEmail';
import PersonSchema from '../../../../../lib/paths/person_FULL_Paths.json';
import { ValidateBirthdateRange } from '../../../../../lib/validation/ValidationTypes';
let P = PersonSchema.attributes;

const mailValidator = (val: string) => isEmail(val);

const PersonalDataValidator = new Schema({
	[P.Titel.path]: {
		type: String,
	},
	[P.Geschlecht.path]: {
		type: String,
		required: true,
	},
	[P.Vorname.path]: {
		type: String,
		required: true,
	},
	[P.Nachname.path]: {
		type: String,
		required: true,
	},
	[P.Geburtsdatum.path]: {
		required: true,
		...ValidateBirthdateRange(),
	},
	[P.Staatsbuergerschaft.path]: {
		type: String,
		required: true,
	},
	[P.E_Mail.path]: {
		type: String,
		required: true,
		use: { mailValidator },
		message: {
			mailValidator: 'Ungültige E-Mail-Adresse.',
		},
	},
	[P.Telefon.path]: {
		type: String,
		required: true,
		use: {
			phone: (val: string) => /^[0-9\s]*$/.test(val),
		},
		message: {
			phone: 'Diese Telefonnummer ist ungültig. Es sind nur Zahlen und Leerzeichen erlaubt.',
		},
	},
	[P.Adressdaten.schema.attributes.Strasse_Und_Hausnummer.path]: {
		type: String,
		required: true,
	},
	[P.Adressdaten.schema.attributes.PLZ.path]: {
		type: String,
		required: true,
	},
	[P.Adressdaten.schema.attributes.Ort.path]: {
		type: String,
		required: true,
	},
	[P.Adressdaten.schema.attributes.Bundesland.path]: {
		type: String,
		required: true,
	},
});

PersonalDataValidator.message({
	required: (_path: string) => 'Dies ist ein Pflichtfeld.',
	type: (path: string) => `Das Formular ist fehlerhaft, bitte melden Sie diesen Fehler. Geben Sie als Grund "falscher Datentyp, ${path}" an.`,
});

export default PersonalDataValidator;
