import { KeyboardArrowDown } from '@mui/icons-material';
import { Badge, Box, Button, Container, Menu, MenuItem, Typography } from '@mui/material';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../../context/AuthContext';
import { isUserLoggedIn } from '../../../context/AuthHelper';
import { IS_PRIVATE_PLATFORM } from '../../../lib/constants/constant';
import { PAGES_URLS, PRIVATE_PAGES, PROFILE_PAGES, PUBLIC_PAGES } from '../../../lib/constants/routes';
import { isTrue } from '../../../utils/helper';
import { NavLinkStyled, NavLinksContainer, StyledToolbar } from '../Header';
import logo from './../../../assets/images/logo/ADK-Logo.svg';
import PrivateHeader from './PrivateHeader';

function DesktopHeader({ unreadMessageCount }: any) {
	const { logout, authenticatedData } = useAuthentication();
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = useState(null);

	const handleOpenMenu = (e: any) => {
		setAnchorEl(e.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	if (IS_PRIVATE_PLATFORM) {
		return (
			<>
				<StyledToolbar>
					<Container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 'unset' }}>
						<Typography variant='h6' component='div' noWrap>
							<img src={logo} alt='logo- Akademie der Künste' style={{ maxWidth: '17rem', maxHeight: '2.5rem' }} />
						</Typography>
						<NavLinksContainer>
							{PRIVATE_PAGES.filter((ele: any) => isTrue(ele.display)).map((ele, i) => (
								<React.Fragment key={i}>
									{get(ele, 'showBadge') ?
										<>
											<Badge
												badgeContent={unreadMessageCount}
												color='primary'
												sx={{
													'& .MuiBadge-badge': {
														transform: `scale(0.9) translate(0%, 50%)`,
													},
												}}
											>
												<NavLinkStyled key={i} to={ele.url}>
													{ele.name}
												</NavLinkStyled>
											</Badge>
										</>
									:	<NavLinkStyled key={i} to={ele.url}>
											{ele.name}
										</NavLinkStyled>
									}
								</React.Fragment>
							))}
							<Box sx={{ display: 'flex' }}>
								<Button
									variant='text'
									endIcon={<KeyboardArrowDown />}
									onClick={handleOpenMenu}
									disableRipple
									sx={{ color: 'navbar.primary.main', fontWeight: '500', fontSize: '0.938rem', '&:hover': { backgroundColor: 'unset' } }}
									aria-label='Benutzerprofil öffnen'
									aria-controls='profile-menu'
									aria-haspopup='true'
								>
									{get(authenticatedData, 'user.username')}
								</Button>
								<Menu
									id='menu-appbar'
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									open={Boolean(anchorEl)}
									onClose={handleCloseMenu}
									MenuListProps={{
										'aria-labelledby': 'profile-menu-button',
									}}
								>
									{PROFILE_PAGES.map((ele, i) => (
										<MenuItem
											key={i}
											onClick={() => {
												handleCloseMenu();
												navigate(get(ele, 'url', ''));
											}}
										>
											{ele.name}
										</MenuItem>
									))}
									<MenuItem onClick={logout}>Abmelden</MenuItem>
								</Menu>
							</Box>
						</NavLinksContainer>
					</Container>
				</StyledToolbar>
			</>
		);
	}

	return (
		<>
			<StyledToolbar>
				{/* Logo */}
				<Typography variant='h6' noWrap>
					<img src={logo} alt='logo' />
				</Typography>

				{/* Nav Links */}
				<NavLinksContainer>
					{PUBLIC_PAGES.filter((ele) => ele.display === 'true').map((ele, i) => (
						<NavLinkStyled key={i} to={ele.url} tabIndex={0}>
							{ele.name}
						</NavLinkStyled>
					))}
				</NavLinksContainer>

				{!isUserLoggedIn() && (
					<Button variant='contained' onClick={() => navigate(PAGES_URLS.Login)}>
						Login
					</Button>
				)}
			</StyledToolbar>
			{isUserLoggedIn() && <PrivateHeader {...{ logout, authenticatedData }} />}
		</>
	);
}

export default DesktopHeader;
