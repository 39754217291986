import { get } from 'lodash';
import { useEffect, useState } from 'react';
import GetRecords from '../lib/api/GetRecords';
import { APIS } from '../lib/constants/apis';
import { UseFetchStatesType } from '../lib/types/types';

function useFetchFaq(): UseFetchStatesType {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<any[]>([]);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		// Fetch data
		const fetchData = async () => {
			try {
				const res = await GetRecords(APIS.faq);
				const result: any[] = get(res, 'data', []);
				setData(result);
			} catch (err: any) {
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	return { loading, data, error };
}

export default useFetchFaq;
