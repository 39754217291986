import { Box, Stack } from '@mui/material';
import { get } from 'lodash';
import React, { ReactNode } from 'react';

interface UserProps {
	fullWidth?: string;
	columnToDivide?: string;
	[key: string]: any; // Allows additional props to be passed
}

interface FieldGroupProps {
	userProps?: UserProps;
	eachFieldProps?: any;
	children: ReactNode[];
}

const FieldGroup: React.FC<FieldGroupProps> = (props) => {
	let columnToDivide = 2;

	if (get(props, 'userProps.columnToDivide', '') !== '') {
		columnToDivide = parseInt(get(props, 'userProps.columnToDivide', ''), 10);
	} else if (get(props, 'eachFieldProps.fields', []).length === 1) {
		if (get(props, 'eachFieldProps.fields.0.type') === 'textArea' || get(props, 'eachFieldProps.fields.0.type') === 'divider') {
			columnToDivide = 1;
		}
	} else if (get(props, 'userProps.fullWidth', '') !== '') {
		columnToDivide = 1;
	} else if (props.children.length >= 2) {
		columnToDivide = props.children.length;
	}

	return (
		<Stack
			sx={{
				alignItems: get(props, 'userProps.direction') === 'column' ? 'flex-start' : 'flex-start',
				columnGap: get(props, 'userProps.columnGap', '1.5rem'),
				/*,columnGap: '3rem'*/
			}}
			// spacing={{ xs: 1, sm: 1 }}
			direction={{ sx: 'column', sm: get(props, 'userProps.direction', 'row') }}
			useFlexGap
		>
			{React.Children.map(props.children, (child, index) => (
				<Box
					key={index} // Add a key to the mapped children
					sx={{
						width: `calc(100% / ${columnToDivide})`,
					}}
				>
					{child}
				</Box>
			))}
		</Stack>
	);
};

export default FieldGroup;
