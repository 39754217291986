import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { getLocalstorageToken } from '../context/AuthHelper';
import GetRecord from '../lib/api/GetRecord';
import GetRecords from '../lib/api/GetRecords';
import POP_LEVEL from '../lib/api/Populations.json';
import PutRecord from '../lib/api/PutRecord';
import { APIS } from '../lib/constants/apis';
import { UseFetchStatesType } from '../lib/types/types';
import { getFieldNamefromDataPath, removeDataKeys, removeNullDeep } from '../utils/helper';

// import schemas
import Schema from '../lib/paths/nachricht_FULL_Paths.json';
let P = Schema.attributes;

function useFetchNachrichten(showCreateNachricht?: boolean, ordner?: string): UseFetchStatesType {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<any[] | null>(null);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const filter = {
				Ordner: ordner,
				Organisation: { id: { $eq: get(getLocalstorageToken(), 'organisationId') } },
			};
			try {
				const res = await GetRecords(APIS['Portal-Nachricht'], POP_LEVEL.nachricht.TABLE, filter, [{ createdAt: 'desc' }]);
				const result: any[] = removeNullDeep(removeDataKeys(get(res, 'data', [])));
				setData(result);
			} catch (err: any) {
				console.error('ERROR Fetching Portal-Nachrichten', err);
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, [showCreateNachricht, ordner]);
	return { loading, data, error };
}
function useFetchnachrichtenStartSeite(): UseFetchStatesType {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<any[] | null>(null);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				let filterOptions = {
					Organisation: { id: { $eq: get(getLocalstorageToken(), 'organisationId') } },
					Ordner: 'Posteingang',
					gelesen: 'false',
				};
				let sort = ['createdAt:desc'];
				let pagination = {
					start: 0,
					limit: 3,
					withCount: true,
				};
				let fields = [
					'id',
					getFieldNamefromDataPath(P.Betreff.path),
					getFieldNamefromDataPath(P.Inhalt.path),
					getFieldNamefromDataPath(P.Erstellt_Amum.path),
				];
				const res = await GetRecords(APIS['Portal-Nachricht'], POP_LEVEL.nachricht.START_SEITE, filterOptions, sort, pagination, fields);
				const result: any[] = removeNullDeep(removeDataKeys(get(res, 'data', [])));
				setData(result);
			} catch (err: any) {
				console.error('ERROR Fetching Portal-Nachrichten', err);
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, []);
	return { loading, data, error };
}

function useFetchNachrichtenDetails(id: number): UseFetchStatesType {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<any[]>([]);
	const [error, setError] = useState<string | null>(null);
	useEffect(() => {
		// Fetch data
		const fetchData = async () => {
			try {
				const res = await GetRecord(APIS['Portal-Nachricht'], id, POP_LEVEL.nachricht.DETAILS_SEITE);
				if (res) {
					if (!get(res, P.gelesen.path, false)) {
						await PutRecord(APIS['Portal-Nachricht'], id, { data: { gelesen: true } });
					}
				}
				const result: any[] = removeNullDeep(removeDataKeys(get(res, 'data', [])));
				setData(result);
			} catch (err: any) {
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};

		if (id) fetchData();
	}, [id]);

	return { loading, data, error };
}
export { useFetchNachrichten, useFetchNachrichtenDetails, useFetchnachrichtenStartSeite };
