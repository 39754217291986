import StarIcon from '@mui/icons-material/Star';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MeinBereichButton, MeinBereichPaper } from '../../../../components/ui-component/mein-bereich/MeinBereichComponents';
import { PAGES_URLS } from '../../../../lib/constants/routes';

function AntraegeInForderung() {
	const navigate = useNavigate();
	return (
		<>
			<MeinBereichPaper titleIcon={<StarIcon />} title={'Anträge in Förderung'}>
				<MeinBereichButton
					buttonName='Anträge in Förderung ansehen'
					onClick={() => {
						navigate(`${PAGES_URLS.MeineFoerderung}`, {
							state: {
								filter: {
									field: 'Status',
									operator: 'contains',
									value: 'In künstlerischer Prüfung',
								},
							},
						});
					}}
				/>
				<MeinBereichButton buttonName='Mittelabruf durchführen' disabled={true} />
				<MeinBereichButton buttonName='Verwendungsnachweis einreichen' disabled={true} />
			</MeinBereichPaper>
		</>
	);
}

export default AntraegeInForderung;
