import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { FoerderProgrammProps } from '../../../../../lib/types/types';

const FoerderProgrammList: FC<FoerderProgrammProps> = ({ data }) => {
	console.log(' data ', data);
	return (
		<>
			<Typography variant="h4"> Förderprogramm list</Typography>
		</>
	);
};

export default FoerderProgrammList;
