import { Breadcrumbs, Typography } from '@mui/material';
import { get } from 'lodash';
import React, { FC } from 'react';
import { useApplication } from '../../../../context/ApplicationContext';

// import schemas
import Schema from '../../../../lib/paths/antrag_FULL_Paths.json';
const P = Schema.attributes;

const BreadCrumbAntrag: FC<{}> = () => {
	const { application } = useApplication();

	return (
		<>
			<Breadcrumbs separator='›' aria-label='breadcrumb' sx={{ maxWidth: '81.875rem' }}>
				<Typography color='secondary'>Mein Bereich</Typography>
				<Typography color='secondary'>Förderungen</Typography>
				<Typography color='secondary'>{'Antrag'}</Typography>
				<Typography color='primary'>{get(application, P.Projekttitel.path, '*')}</Typography>
			</Breadcrumbs>
		</>
	);
};

export default BreadCrumbAntrag;
