import { get } from 'lodash';
import { getLocalstorageToken, getUserJwt } from '../../context/AuthHelper';
import { METHOD_DELETE, METHOD_POST } from '../constants/apis';
import { STRAPI_URL } from '../constants/url';

export async function DeleteFile(id: number) {
	let response = await fetch(`${STRAPI_URL}/api/upload/files/${id}`, {
		method: METHOD_DELETE,
		headers: {
			Authorization: `Bearer ${getUserJwt()}`,
		},
	});
	if (!response.ok) {
		throw new Error('NETWORK_NOT_OK');
	}
	let responseData = await response.json();
	return responseData;
}

export async function PostFile(file: any) {
	let formData = new FormData();
	formData.append('files', file, file.name);
	formData.append('type', 'private');
	formData.append('ownerId', get(getLocalstorageToken(), 'organisationId'));
	let response = await fetch(`${STRAPI_URL}/api/upload`, {
		method: METHOD_POST,
		headers: {
			Authorization: `Bearer ${getUserJwt()}`,
		},
		body: formData,
	});
	if (!response.ok) {
		throw new Error('NETWORK_NOT_OK');
	}
	let responseData = await response.json();
	return responseData;
}
