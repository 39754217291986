import { getUserJwt } from '../../context/AuthHelper';
import { METHOD_DELETE } from '../constants/apis';
import { STRAPI_URL } from '../constants/url';

export default async function DeleteRecord(api: string, id: number) {
	let response = await fetch(`${STRAPI_URL}/api/${api}/${id}`, {
		method: METHOD_DELETE,
		headers: {
			Authorization: `Bearer ${getUserJwt()}`,
		},
	});

	if (!response.ok) {
		throw new Error(await response.text());
	}
	const responseData = await response.json();
	return responseData;
}
