import Schema from 'validate';

const NachrichtFieldsValidator = new Schema({
	Bewerbung: {
		type: Number,
		required: true,
	},
	Betreff: {
		type: String,
		required: true,
	},
	Inhalt: {
		type: String,
		required: true,
	},
});

NachrichtFieldsValidator.message({
	required: (_path) => 'Dies ist ein Pflichtfeld.',
	type: (path) => `Das Formular ist fehlerhaft, bitte melden Sie diesen Fehler. Geben Sie als Grund "falscher Datentyp, ${path}" an.`,
});

export default NachrichtFieldsValidator;
