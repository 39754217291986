import Schema from 'validate';
import { isPostalCode } from 'validator';
import isEmail from 'validator/lib/isEmail';

import OrgaSchema from '../../../../lib/paths/organisation_FULL_Paths.json';
const P = OrgaSchema.attributes;

const mailValidator = (val: string) => isEmail(val);
const postalCodeValidator = (val: string) => isPostalCode(val, 'DE');

const OrganisationDatenValidator = new Schema({
	[P.Name_Der_Organisation.path]: {
		type: String,
		required: true,
	},
	[P.Rechtsform_Der_Organisation.path]: {
		type: String,
		required: true,
	},
	[P.Strasse_Und_Hausnummer.path]: {
		type: String,
		required: true,
	},
	[P.PLZ.path]: {
		type: String,
		required: true,
		use: { postalCodeValidator },
		message: {
			postalCodeValidator: 'Ungültige Postleitzahl.',
		},
	},
	[P.Ort.path]: {
		type: String,
		required: true,
	},
	[P.E_Mail.path]: {
		type: String,
		required: true,
		use: { mailValidator },
		message: {
			mailValidator: 'Ungültige E-Mail-Adresse.',
		},
	},
	[P.Telefon.path]: {
		type: String,
		required: true,
		use: {
			phone: (val: string) => /^[0-9\s]*$/.test(val),
		},
		message: {
			phone: 'Diese Telefonnummer ist ungültig. Es sind nur Zahlen und Leerzeichen erlaubt.',
		},
	},
});

OrganisationDatenValidator.message({
	required: (_path: string) => 'Dies ist ein Pflichfeld',
	type: (path: string) => `Das Formular ist fehlerhaft, bitte melden Sie diesen Felder. Geben Sie als Grund "falscher Datantyp, ${path}" an`,
});

export default OrganisationDatenValidator;
