import { Divider } from '@mui/material';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { useApplication } from '../../../../context/ApplicationContext';
import { FormStrucItem, TreeNodeType } from '../../../../lib/types/types';
import { findErrorByKey } from '../../../../lib/validation/validationHelperFunction';
import { fieldNameTranslation, isTrue, tooltipTranslate } from '../../../../utils/helper';
import MUIAutocomplete from './fields/MUIAutocomplete';
import MUICheckboxField from './fields/MUICheckboxField';
import MUIComponentGroups from './fields/MUIComponentGroups';
// import MUIComponentTable from './fields/MUIComponentTable';
import { useLocation } from 'react-router-dom';
import EditableTable from './fields/EditableTable';
import MarkdownEditor from './fields/MarkdownEditor';
import MUIDateField from './fields/MUIDateField';
import MUIMultiSelectField from './fields/MUIMultiSelectField';
import MUINumberField from './fields/MUINumberField';
import MUIRadioField from './fields/MUIRadioField';
import MUISelectField from './fields/MUISelectField';
import MUITextAreaField from './fields/MUITextAreaField';
import MUITextField from './fields/MUITextField';
import MultiUploadField from './fields/MultiUploadField';
import SingleUpload from './fields/SingleUpload';
import TextEditorField from './fields/TextEditor';

interface FormFieldsProps {
	type: string;
	fieldType?: string;
	formValues: Record<string, any>;
	onChange: (path: string, value: any) => void;
	formErrors?: Record<string, any>;
	editableTablePathWIthIndex?: string;
	readOnly?: boolean;
	disabled?: boolean;
	hideLabel?: boolean;
	hideCharacterLengthInfo?: boolean;
	[key: string]: any; // Allows additional props to be passed
}

const FormFields: React.FC<FormFieldsProps> = (props) => {
	// const COMPONENT_TABLE = 'componentTable';
	const { errors, tree } = useApplication();
	const {
		type,
		fieldType,
		formValues,
		onChange,
		formErrors,
		editableTablePathWIthIndex,
		readOnly,
		disabled,
		inversedBy,
		maxCharLength,
		maxDigit,
		hideLabel,
		hideCharacterLengthInfo,
		...restProps
	} = props;
	const dataPathParts = get(props, 'path', '').split('.');
	const location = useLocation();

	const strapiFieldName = dataPathParts.length > 0 ? dataPathParts[dataPathParts.length - 1] : get(props, 'path', '');
	const apiName = get(props, 'api') ? get(props, 'api', '') : get(location, 'state.api');
	const labelTranlated = fieldNameTranslation(strapiFieldName, apiName);

	const fieldLabel = isTrue(get(props, 'hideLabel')) ? '' : get(props, 'label', labelTranlated);
	const toolTip = tooltipTranslate(strapiFieldName, apiName);

	const isDisabled = get(props, 'disabled', '') === false ? false : disabled || readOnly;

	const errorPath = editableTablePathWIthIndex ? editableTablePathWIthIndex : get(props, 'path', '');
	const showError = useMemo(() => {
		return findErrorByKey(tree, errorPath, type) ? true : false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tree, props, errorPath]);

	let errorInField = false;
	let errorMessage = '';

	if (Array.isArray(errors) && errors.length > 0) {
		// First logic
		errorInField = Boolean(errors.find((e) => get(e, 'path', '') === errorPath));

		// Combine with showError
		errorInField = errorInField && showError;

		if (errorInField) {
			errorMessage = get(
				errors.find((e) => get(e, 'path', '') === errorPath),
				'message',
				''
			);
		}
	} else if (formErrors && typeof formErrors === 'object' && Object.keys(formErrors).length > 0) {
		// Second logic
		errorInField = Boolean(get(formErrors, errorPath, ''));

		if (errorInField) {
			errorMessage = get(formErrors, `${errorPath}`, '');
		}
	}

	const initialValue = useMemo(() => {
		const value = get(formValues, `${get(props, 'path')}`, '');
		return value || value === false || value === 0 ? value : '';
	}, [formValues, props]);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let value: any = null;
		if (e.target.value !== null) {
			if (typeof e.target.value === 'object' || Array.isArray(e.target.value)) {
				value = e.target.value;
			} else {
				value = e.target.value ? e.target.value : null;
			}
		}
		onChange(get(props, 'path'), value);
	};

	switch (type) {
		case 'string':
			return (
				<MUITextField
					type={fieldType ? fieldType : 'text'}
					label={fieldLabel}
					fieldValue={initialValue}
					updateFieldValue={handleOnChange}
					isDisabled={isDisabled}
					error={errorInField}
					errorMessage={errorMessage}
					tooltip={toolTip}
					{...restProps}
				/>
			);
		case 'text':
			return (
				<MUITextAreaField
					label={fieldLabel}
					hideCharacterLengthInfo={hideCharacterLengthInfo}
					fieldValue={initialValue}
					updateFieldValue={handleOnChange}
					isDisabled={isDisabled}
					error={errorInField}
					errorMessage={errorMessage}
					tooltip={toolTip}
					{...restProps}
				/>
			);
		case 'textEditor':
			return (
				<TextEditorField
					label={fieldLabel}
					hideCharacterLengthInfo={hideCharacterLengthInfo}
					fieldValue={initialValue}
					updateFieldValue={handleOnChange}
					isDisabled={isDisabled}
					error={errorInField}
					errorMessage={errorMessage}
					tooltip={toolTip}
					{...restProps}
				/>
			);
		case 'richtext':
		case 'markdownEditor':
			return (
				<MarkdownEditor
					label={fieldLabel}
					hideCharacterLengthInfo={hideCharacterLengthInfo}
					fieldValue={initialValue}
					updateFieldValue={handleOnChange}
					isDisabled={isDisabled}
					error={errorInField}
					errorMessage={errorMessage}
					tooltip={toolTip}
					{...restProps}
				/>
			);
		case 'integer':
		case 'decimal':
			return (
				<MUINumberField
					label={fieldLabel}
					type={type}
					fieldValue={initialValue}
					updateFieldValue={handleOnChange}
					isDisabled={isDisabled}
					error={errorInField}
					errorMessage={errorMessage}
					tooltip={toolTip}
					{...restProps}
				/>
			);
		case 'enumeration':
		case 'singleSelect':
			return (
				<MUISelectField
					label={fieldLabel}
					fieldValue={initialValue}
					updateFieldValue={handleOnChange}
					isDisabled={isDisabled}
					error={errorInField}
					errorMessage={errorMessage}
					tooltip={toolTip}
					{...restProps}
				/>
			);
		case 'autocomplete':
			return (
				<MUIAutocomplete
					label={fieldLabel}
					fieldValue={initialValue}
					updateFieldValue={handleOnChange}
					isDisabled={isDisabled}
					error={errorInField}
					errorMessage={errorMessage}
					tooltip={toolTip}
					{...restProps}
				/>
			);
		case 'singleUpload':
			return (
				<SingleUpload
					label={fieldLabel}
					fieldValue={initialValue}
					updateFieldValue={handleOnChange}
					isDisabled={isDisabled}
					error={errorInField}
					errorMessage={errorMessage}
					tooltip={toolTip}
					{...restProps}
				/>
			);
		case 'media':
			if (get(props, 'multiple', false)) {
				return (
					<MultiUploadField
						label={fieldLabel}
						fieldValue={initialValue}
						updateFieldValue={handleOnChange}
						isDisabled={isDisabled}
						error={errorInField || Boolean(errorMessage)}
						errorMessage={errorMessage}
						tooltip={toolTip}
						{...restProps}
					/>
				);
			} else {
				return (
					<SingleUpload
						label={fieldLabel}
						fieldValue={initialValue}
						updateFieldValue={handleOnChange}
						isDisabled={isDisabled}
						error={errorInField || Boolean(errorMessage)}
						errorMessage={errorMessage}
						tooltip={toolTip}
						{...restProps}
					/>
				);
			}
		case 'componentMedia':
			return (
				<MultiUploadField
					label={fieldLabel}
					fieldValue={initialValue}
					updateFieldValue={handleOnChange}
					isDisabled={isDisabled}
					error={errorInField || Boolean(errorMessage)}
					errorMessage={errorMessage}
					tooltip={toolTip}
					{...restProps}
				/>
			);
		case 'datetime':
		case 'date':
			return (
				<MUIDateField
					label={fieldLabel}
					fieldValue={initialValue}
					updateFieldValue={handleOnChange}
					isDisabled={isDisabled}
					error={errorInField}
					errorMessage={errorMessage}
					tooltip={toolTip}
					{...restProps}
				/>
			);
		case 'checkbox':
		case 'boolean':
			return (
				<MUICheckboxField
					label={fieldLabel}
					fieldValue={initialValue}
					updateFieldValue={handleOnChange}
					isDisabled={isDisabled}
					error={errorInField}
					errorMessage={errorMessage}
					tooltip={toolTip}
					{...restProps}
				/>
			);
		case 'radio':
			return (
				<MUIRadioField
					label={fieldLabel}
					fieldValue={initialValue}
					updateFieldValue={handleOnChange}
					isDisabled={isDisabled}
					error={errorInField || Boolean(errorMessage)}
					errorMessage={errorMessage}
					tooltip={toolTip}
					{...restProps}
				/>
			);
		case 'component':
			return (
				<MUIComponentGroups
					label={fieldLabel}
					fieldValue={initialValue}
					updateFieldValue={handleOnChange}
					isDisabled={isDisabled}
					error={errorInField}
					errorMessage={errorMessage}
					{...restProps}
				/>
			);
		case 'componentTable':
		case 'editableTable':
			return (
				<EditableTable
					label={fieldLabel}
					fieldValue={initialValue}
					updateFieldValue={handleOnChange}
					isDisabled={isDisabled}
					formErrors={formErrors}
					error={errorInField}
					errorMessage={errorMessage}
					tooltip={toolTip}
					api={apiName}
					{...restProps}
				/>
			);
		case 'multiSelect':
			return (
				<MUIMultiSelectField
					label={fieldLabel}
					fieldValue={initialValue}
					updateFieldValue={handleOnChange}
					updateFieldValueMulti={onChange}
					isDisabled={isDisabled}
					error={errorInField}
					errorMessage={errorMessage}
					tooltip={toolTip}
					{...restProps}
				/>
			);

		// case 'infoBox':
		// 	return <MUITextInfo {...restProps} />;
		case 'divider':
			return <Divider sx={{ borderBottomWidth: '0.2rem' }} />;
		case 'nothing':
			return <></>;
		default:
			return (
				<MUITextField
					type={fieldType ? fieldType : 'text'}
					label={fieldLabel}
					fieldValue={initialValue}
					updateFieldValue={handleOnChange}
					isDisabled={isDisabled}
					error={errorInField}
					errorMessage={errorMessage}
					tooltip={toolTip}
					{...restProps}
				/>
			);
	}
};

const findFormStrucbyPath = (pathString: string, treeStructure: TreeNodeType[]): FormStrucItem[] => {
	let result: FormStrucItem[] = [];
	treeStructure?.forEach((item) => {
		if (item.formStruc) {
			item.formStruc.forEach((form) => {
				form.fields?.forEach((field) => {
					if (field.path && field.path?.includes(pathString)) {
						result = item.formStruc ? item.formStruc : [];
					}
				});
			});
		}
		if (item.children) {
			const formStrucInChildren = findFormStrucbyPath(pathString, item.children);
			if (formStrucInChildren.length > 0) result = formStrucInChildren;
		}
	});

	return result;
};

export default FormFields;
