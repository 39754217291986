import { get, merge, set } from 'lodash';
import { getUserJwt } from '../../context/AuthHelper';
import { APIS, METHOD_GET, METHOD_POST } from '../constants/apis';
import { STRAPI_URL } from '../constants/url';
import { getQueryString } from './Population';

// import schemas
import { getFieldNamefromDataPath } from '../../utils/helper';
import { PERSON_ROLLE } from '../constants/constant';
import PersonSchema from '../paths/person_FULL_Paths.json';
const PP = PersonSchema.attributes;

/**
 * Authenticates the user
 * @param {string} userName | username entered by user
 * @param {string} password | password entered by user
 * @returns | returns a responseBody from the API
 */
export default async function AuthUser(userName: string, password: string) {
	let response = await fetch(`${STRAPI_URL}/api/auth/local`, {
		method: METHOD_POST,
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ identifier: userName, password: password }),
	});

	if (response.ok) {
		let responseData = await response.json();
		return responseData;
	}

	const err = await response.text();
	throw new Error(err);
}

export async function GetUserMe(jwt = '') {
	let poplevel = [{ Person: 'id', 'Person.Organisation': 'Geschaeftsbezogene_Daten_Name_Der_Organisation' }];
	let filter: any[] = [];
	let sort: any[] = [];
	let pagination: object = {};
	let fields: any = [];
	let response = await fetch(`${STRAPI_URL}/api/users/me${getQueryString(poplevel, filter, sort, pagination, fields)}`, {
		method: METHOD_GET,
		headers: {
			Authorization: `Bearer ${jwt ? jwt : getUserJwt()}`,
		},
	});

	if (!response.ok) {
		throw new Error(await response.text());
	}
	const responseData = await response.json();
	return responseData;
}

export async function ChangeUserPassword(data: Record<string, any>) {
	// let data = {
	// 	currentPassword: oldPassword,
	// 	password: newPassword,
	// 	passwordConfirmation: newPasswordRepeat,
	// };

	let response = await fetch(`${STRAPI_URL}/api/auth/change-password`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getUserJwt()}`,
		},
		body: JSON.stringify(data),
	});

	if (response.ok) {
		let responseData = await response.json();
		return responseData;
	}

	const err = await response.text();
	throw new Error(err);
}

export async function CheckIfUserAndOrganisationExist(organisationData: any, loginDaten?: any, personDaten?: any) {
	let bodyData: any = {
		data: {
			organisation: get(organisationData, 'attributes', {}),
			performRegister: false,
		},
	};
	console.log(' body data ', bodyData);
	if (loginDaten) {
		console.log('logindaten is there ', loginDaten);
		merge(bodyData.data, loginDaten);
		// set(bodyData, 'data', loginDaten);
	}
	if (personDaten) {
		console.log(' person daten ist therer ', personDaten);
		set(bodyData, 'data.person', get(personDaten, 'attributes'));
	}

	console.log(' body data before fetch  ', bodyData);

	let response: any = await fetch(`${STRAPI_URL}/api/auth/local/register`, {
		method: METHOD_POST,
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(bodyData.data),
	});
	if (!response.ok) {
		throw new Error(response);
	}
	let responseData = await response.json();
	return responseData;
}

export async function CheckIfPersonExist(personEmail: string) {
	const filter = {
		$and: [
			{ [getFieldNamefromDataPath(PP.E_Mail.path)]: { $eq: personEmail } },
			{ [getFieldNamefromDataPath(PP.Personenbezogene_Daten_Rolle.path)]: { $eq: PERSON_ROLLE.Bewerber } },
		],
	};

	let response = await fetch(`${STRAPI_URL}/api/${APIS.Person}${getQueryString([], filter)}`, {
		method: METHOD_GET,
		headers: {
			Authorization: `Bearer ${getUserJwt()}`,
		},
	});

	if (!response.ok) throw new Error(await response.text());
	const responseData = await response.json();
	return responseData;
}

export async function RegisterUserAndOrganisation(
	organisationData: any,
	loginDetails: any = {},
	personData: any,
	registerUserOnly: boolean = false,
	personId: number = 0,
	organisationId: number = 0
) {
	let data = {
		...loginDetails,
		blocked: false,
		confirmed: false,
		person: get(personData, 'attributes', {}),
		organisation: get(organisationData, 'attributes', {}),
		performRegister: true,
		registerUserOnly: registerUserOnly,
	};

	if (personId) {
		set(data, 'person', { id: personId });
	}
	if (organisationId) {
		set(data, 'ZuwendungsempfaengerId', organisationId);
	}
	console.log('🚀 Payload Before Sending:', JSON.stringify(data, null, 2));
	let response = await fetch(`${STRAPI_URL}/api/auth/local/register`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	});

	if (response.ok) {
		let responseData = await response.json();
		return responseData;
	}

	const err = await response.text();
	throw new Error(err);
}

export async function ConfirmUserEmail(confirmationToken: string) {
	let response = await fetch(`${STRAPI_URL}/api/auth/email-confirmation?confirmation=${confirmationToken}`, {
		method: METHOD_GET,
	});
	return response;
}
/**
 * Initiates the password reset process for a user.
 * @param {string} username - The username of the user.
 * @returns {Promise<any>} - The response from the API.
 */
export async function ForgotUserPassword(username: string) {
	let response = await fetch(`${STRAPI_URL}/api/auth/forgot-password`, {
		method: METHOD_POST,
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			username: username,
		}),
	});

	if (response.ok) {
		let responseData = await response.json();
		return responseData;
	}

	const err = await response.text();
	throw new Error(err);
}

// email: user's email
export async function SendForgotPasswordMail(data: any) {
	let response = await fetch(`${STRAPI_URL}/api/auth/forgot-password`, {
		method: METHOD_POST,
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	});

	if (response.ok) {
		let responseData = await response.json();
		return responseData;
	}

	const err = await response.text();
	console.log('Error sending password SendForgotPasswordMail:', err);
	throw new Error(err);
}
