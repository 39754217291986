import React, { FC, useState } from 'react';
import MuiSnackbar from '../mui-snackbar/MuiSnackbar';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import NachrichtFields from './NachrichtFields';

interface CreateNachrichtProps {
	showNachrichtDialog: boolean;
	handleCloseDialog: () => void;
	applications: any[];
	nachrichtId?: number;
	isReply?: boolean;
	originalTitle?: string;
	originalContent?: string;
}
const CreateNachricht: FC<CreateNachrichtProps> = ({
	showNachrichtDialog,
	handleCloseDialog,
	applications,
	nachrichtId = 0,
	isReply = false,
	originalTitle = '',
	originalContent = '',
}) => {
	const [sendNachrichtSuccess, setSendNachrichtSuccess] = useState<boolean | null>(null);

	return (
		<>
			<MuiSnackbar
				showSnackbar={sendNachrichtSuccess}
				closeSnackbar={() => setSendNachrichtSuccess(null)}
				alertMessage="Ihre Nachricht wurde erfolgreich versendet"
			/>
			<Dialog open={showNachrichtDialog} fullWidth maxWidth={'md'}>
				<DialogTitle> Schreiben Sie hier Ihre Nachricht</DialogTitle>
				<DialogContent>
					<NachrichtFields
						{...{
							handleCloseDialog,
							applications,
							setSendNachrichtSuccess,
							nachrichtId,
							isReply,
							originalTitle,
							originalContent,
						}}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};
export default CreateNachricht;
