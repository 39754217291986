import { Box, Container, Stack, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
// import titleImage from '../../../../assets/images/programm/ProgrammTitle.png';
// import HeroHeaderSection from '../../../../components/ui-component/hero-header/HeroHeader';
import LoadingBackdrop from '../../../../components/ui-component/loading/LoadingBackdrop';
import { useFetchFoerderProgramm } from '../../../../hooks/FetchFoerderProgramm';
import FoerderProgrammList from './structure/FoerderProgrammList';
import FoerderProgrammGrid from './structure/FoerdProgrammGrid';

const FoerderProgrammeView: FC = () => {
	const [programmView, setProgrammView] = useState<string>('grid');

	const { loading, data, error } = useFetchFoerderProgramm();

	const updateProgramView = () => {
		setProgrammView((prevValue) => (prevValue === 'list' ? 'grid' : 'list'));
	};

	if (error) console.error('ERROR: Fetching Förderprogramme ', error);
	if (loading) return <LoadingBackdrop />;
	return (
		<>
			<Container>
				<Typography variant='h3' component={'h2'}>
					Förderprogramme Übersicht
				</Typography>
				<Typography variant='body1' sx={{ mt: '1rem' }}>
					'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
					sed diam voluptua. At
				</Typography>
				<ProgramTitleSection {...{ dataLength: data?.length, programmView, updateProgramView }} />

				<Box sx={{ mt: '2rem' }}>
					{programmView === 'list' ?
						<FoerderProgrammList data={data || []} />
					:	<FoerderProgrammGrid data={data || []} />}
				</Box>
			</Container>
		</>
	);
};

const ProgramTitleSection: FC<{ dataLength: number | undefined; programmView: string; updateProgramView: () => void }> = ({ dataLength }) => {
	console.log(' data Length  ', dataLength);
	return (
		<>
			<Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'}>
				{/* <Typography variant='h5'> {dataLength} Förderangebote zu Ihren Suchkriterien gefunden</Typography> */}
			</Stack>
		</>
	);
};

export default FoerderProgrammeView;
