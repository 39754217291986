import React, { FC } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { get } from 'lodash';

interface MuiDialogProps {
	open: boolean | null;
	title: string;
	content: string;
	onClose?: () => void;
	onCloseButtonName?: string;
	onConfirm?: () => void;
	onConfirmButtonName?: string;
	hasError?: boolean;
}
const MuiDialog: FC<MuiDialogProps> = (props) => {
	return (
		<>
			<Dialog
				open={get(props, 'open', false)}
				onClose={get(props, 'onClose')}
				sx={{
					textAlign: 'left',
					'& .MuiDialog-paper': {
						backgroundColor: '#eeeeee',
						maxWidth: '55rem',
					},
				}}>
				<DialogTitle>{get(props, 'title', '')}</DialogTitle>
				<DialogContent>
					<Typography variant='body1'>{get(props, 'content', '')}</Typography>
				</DialogContent>
				<DialogActions>
					{typeof get(props, 'onClose') === 'function' ? (
						<Button variant='outlined' onClick={get(props, 'onClose')}>
							{get(props, 'onCloseButtonName', 'Abbrechen')}
						</Button>
					) : null}
					{typeof get(props, 'onConfirm') === 'function'
						? !get(props, 'hasError', false) && (
								<Button variant='contained' onClick={get(props, 'onConfirm')}>
									{get(props, 'onConfirmButtonName', 'Bestätigen')}
								</Button>
							)
						: null}
				</DialogActions>
			</Dialog>
		</>
	);
};

export default MuiDialog;
