import React from 'react';
import { TreeNodeType } from '../../../../../lib/types/types';
import { assignIdsToStruc } from '../../../../../utils/helper';
import Abschluss from './content/Abschluss';
import Allgemein, { getFormStrucAllgemein } from './content/Allgemein';
import Antragsteller, { getFormStrucAntragsteller } from './content/Antragsteller';
import Bewerbungsdaten, { getFormStrucBewerbungsdaten } from './content/Bewerbungsdaten';

// import schemas
// import Schema from '../../../../../lib/paths/antrag_FULL_Paths.json';
// const P = Schema.attributes;

export const TreeStructureFoerderAntrag = (_application: any, _readOnly: boolean) => {
	let treeNodes: TreeNodeType[] = [
		{
			label: 'Allgemein',
			content: <Allgemein />,
			formStruc: getFormStrucAllgemein(),
		},
		{
			label: 'Antragsteller/in',
			content: <Antragsteller />,
			formStruc: getFormStrucAntragsteller(),
		},
		{
			label: 'Bewerbungsdaten',
			content: <Bewerbungsdaten />,
			formStruc: getFormStrucBewerbungsdaten(_application),
		},
		{
			label: 'Abschluss',
			content: <Abschluss />,
		},
	];

	return assignIdsToStruc(treeNodes);
};
