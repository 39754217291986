import { BoldItalicUnderlineToggles, ListsToggle, MDXEditor, Separator, UndoRedo, listsPlugin, toolbarPlugin } from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { Help } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { get } from 'lodash';
import React, { FC } from 'react';
import { MAX_TEXTBOX_LENGTH } from '../../../../../lib/constants/constant';
import { MUIFieldProps } from '../../../../../lib/types/types';
import MUIToolTip from '../../../mui-tooltip/MUIToolTip';

const MarkdownEditor: FC<MUIFieldProps> = (props) => {
	const { label, tooltip, fieldValue, updateFieldValue, isDisabled, error, errorMessage, hideCharacterLengthInfo, ...restProps } = props;

	const maxAllowCharacterLength = get(props, 'maxCharLength', MAX_TEXTBOX_LENGTH);

	const handleChange = (value: any) => {
		let event = { target: { value: value } };
		updateFieldValue(event as React.ChangeEvent<HTMLInputElement>);
	};

	return (
		<>
			<Box sx={{ marginTop: '1rem' }}>
				<Typography variant='body1' sx={{ color: error ? 'error.main' : 'text.secondary', fontWeight: 600 }}>
					{tooltip ?
						<>
							<>{label}</>
							<MUIToolTip title={tooltip}>
								{' '}
								<Help fontSize='small' color='primary' sx={{ ml: '5px' }} />{' '}
							</MUIToolTip>
						</>
					:	<>{label}</>}
				</Typography>
				<Typography variant='body2' sx={{ color: error ? 'error.main' : 'text.secondary' }}>
					<em>{get(props, 'placeholder', '')}</em>
				</Typography>
				<MDXEditor
					className={`buit-markdown-editor${error ? '-error' : ''}`}
					markdown={fieldValue}
					onChange={handleChange}
					plugins={[
						listsPlugin(),
						toolbarPlugin({
							toolbarContents: () => (
								<>
									<UndoRedo />
									<Separator />
									<BoldItalicUnderlineToggles />
									<Separator />
									<ListsToggle options={['bullet', 'number']} />
									<Separator />
								</>
							),
						}),
					]}
					readOnly={isDisabled}
					{...restProps}
				/>
				{error && (
					<Typography variant='caption' color='error' sx={{ marginLeft: '1rem' }}>
						{errorMessage}
						<br></br>
					</Typography>
				)}
				{get(props, 'hideCharacterLengthInfo', false) ?
					<></>
				:	<Typography variant='caption'>
						{fieldValue.length + ' / ' + maxAllowCharacterLength + ' Zeichen (inkl. Leerzeichen) verwendet'}
					</Typography>
				}
			</Box>
		</>
	);
};
export default MarkdownEditor;
