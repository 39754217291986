import { get } from 'lodash';
import { TreeNodeType } from '../../../../lib/types/types';

export const constructHierarchyArray = (input: string, treeStructure?: TreeNodeType[]): string[] => {
	if (input && input.includes('.')) {
		const segments = input.split('.');
		const hierarchyArray: string[] = [];

		let currentSegment = '';

		for (const segment of segments) {
			currentSegment = currentSegment ? `${currentSegment}.${segment}` : segment;
			hierarchyArray.push(currentSegment);
		}
		return hierarchyArray;
	} else {
		const treeElement = treeStructure?.find((ele) => ele.id === input);
		if (treeElement?.children && treeElement.children.length > 0) {
			return [input, treeElement.children[0].id ?? ''];
		}
		return [input];
	}
};

export const extractIds = (data: TreeNodeType[]): string[] => {
	const ids: string[] = [];

	function traverse(nodes: TreeNodeType[]) {
		for (const node of nodes) {
			ids.push(get(node, 'id'));
			if (node.children) {
				ids.pop();
				traverse(node.children);
			}
		}
	}

	traverse(data);
	return ids;
};
