import React, { PropsWithChildren, FC } from 'react';
import { ErrorValidation, TreeNodeType } from '../lib/types/types';

interface StrapiForm {
	application: any;
	setApplicationProperty: (key: string, value: any) => void;
	saveApplication: (submit?: boolean, newApplication?: boolean | any, changeLastEdited?: boolean) => void;
	readOnly: boolean;
	tree: TreeNodeType[];
	errors: ErrorValidation[];
}

const ApplicationContext = React.createContext<PropsWithChildren<StrapiForm>>({
	application: {},
	setApplicationProperty: (_key: string, _value: any) => {},
	saveApplication: (_submit?: boolean, _newApplication?: boolean | any, _changeLastEdited?: boolean) => {},
	readOnly: false,
	tree: [],
	errors: [],
});

const ApplicationContextProvider: FC<PropsWithChildren<StrapiForm>> = ({
	children,
	application = {},
	setApplicationProperty = (_key: string, _value: any) => {},
	saveApplication = (_submit: boolean = false, _newApplication: boolean | any = false, _changeLastEdited: boolean = true) => {},
	readOnly = false,
	tree = [],
	errors = [],
}) => {
	return (
		<ApplicationContext.Provider value={{ application, setApplicationProperty, saveApplication, readOnly, tree, errors }}>
			{children}
		</ApplicationContext.Provider>
	);
};

export default ApplicationContextProvider;

function useApplication() {
	const context = React.useContext(ApplicationContext);
	if (context === undefined) {
		throw new Error('useApplication must be used within a ApplicationContextProvider');
	}
	return context;
}

export { useApplication };
