// src/pages/authentication/ForgotPassword.tsx
import { Alert, Box, Button, Snackbar, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ForgotUserPassword } from '../../../lib/api/AuthUser';
import { PAGES_URLS } from '../../../lib/constants/routes';

function ForgotPassword() {
	const [username, setUsername] = useState<string>('');
	const [errMsg, setErrMsg] = useState('');
	const [successMsg, setSuccessMsg] = useState('');
	const navigate = useNavigate();

	const handleForgotPassword = async (event: React.FormEvent) => {
		event.preventDefault();
		if (username.trim()) {
			try {
				await ForgotUserPassword(username.trim());
				setSuccessMsg('Wenn ein Konto mit diesem Benutzernamen existiert, wurde eine E-Mail zum Zurücksetzen des Passworts gesendet.');
			} catch (error) {
				console.error('Error sending password reset email:', error);
				setErrMsg('Fehler beim Senden der E-Mail zum Zurücksetzen des Passworts.');
			}
		} else {
			setErrMsg('Bitte geben Sie Ihren Benutzernamen ein.');
		}
	};

	return (
		<>
			{errMsg && (
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={Boolean(errMsg)}
					autoHideDuration={6000}
					onClose={() => setErrMsg('')}
				>
					<Alert severity='error' onClose={() => setErrMsg('')}>
						{errMsg}
					</Alert>
				</Snackbar>
			)}
			{successMsg && (
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={Boolean(successMsg)}
					autoHideDuration={6000}
					onClose={() => setSuccessMsg('')}
				>
					<Alert severity='success' onClose={() => setSuccessMsg('')}>
						{successMsg}
					</Alert>
				</Snackbar>
			)}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					minHeight: '100vh',
					width: '100%',
					backgroundColor: 'background.paper',
					mb: '-2rem',
				}}
			>
				<Box
					sx={{
						maxWidth: '39.123rem',
						width: '100%',
						padding: 'clamp(1.5rem, 2.5vw, 2.5rem)',
						margin: '0 1rem',
						backgroundColor: '#fff',
						borderRadius: '8px',
						boxShadow: 1,
					}}
				>
					<Typography variant='h1' fontSize={25} gutterBottom>
						Passwort vergessen
					</Typography>
					<Typography variant='body1' sx={{ mt: 'clamp(2rem, 2.5vw, 2.5rem)' }} gutterBottom>
						Bitte geben Sie Ihren Benutzernamen ein, um Ihr Passwort zurückzusetzen.
					</Typography>

					<form onSubmit={handleForgotPassword}>
						<TextField
							fullWidth
							label='Benutzername'
							variant='outlined'
							size='medium'
							value={username}
							onChange={(e) => setUsername(e.target.value)}
						/>
						<Stack
							direction={{ xs: 'column', sm: 'row' }}
							spacing={2}
							sx={{
								paddingTop: 'clamp(.1rem, 4vw, 3rem)',
								justifyContent: 'space-between',
								cursor: 'pointer',
							}}
						>
							<Box>
								<Button color='primary' size='large' onClick={() => navigate(PAGES_URLS.Login)}>
									Zurück zum Login
								</Button>
							</Box>
							<Button variant='contained' disabled={!username.trim()} color='primary' size='large' type='submit'>
								Passwort zurücksetzen
							</Button>
						</Stack>
					</form>
				</Box>
			</Box>
		</>
	);
}

export default ForgotPassword;
