import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Stack, Step, StepConnector, StepLabel, Stepper, styled, Typography } from '@mui/material';
import { cloneDeep, get, set } from 'lodash';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo_Link from '../../../assets/images/logo/ADK-Logo.svg';
// import Logo_Recht from '../../../assets/images/logo/Logo_Recht.svg';
import { CheckIfUserAndOrganisationExist, RegisterUserAndOrganisation } from '../../../lib/api/AuthUser';
import { PAGES_URLS } from '../../../lib/constants/routes';
import ScrollToTop from '../../layout/ScrollToTop';
import LoadingBackdrop from '../../ui-component/loading/LoadingBackdrop';
import NoDetailsInfo from '../../ui-component/no-details-info/NoDetailsInfo';
import AbschlussRegistrierung from './abschluss/AbschlussRegistrierung';
import NachRegistrierung from './abschluss/NachRegistrierung';
import LoginDaten from './login-daten/LoginDaten';
import LoginDatenValidator from './login-daten/LoginDatenValidator';
import OrganisationDatenValidator from './organisation-daten/OrganistaionDatenValidator';
import PersonDaten from './person-daten/PersonDatan';
import PersonDatenValidator from './person-daten/PersonDatenValidator';

import OrgaSchema from '../../../lib/paths/organisation_FULL_Paths.json';
import PersonSchema from '../../../lib/paths/person_FULL_Paths.json';
import UserSchema from '../../../lib/paths/user_Paths.json';
import { isTrue } from '../../../utils/helper';
import OrganisationDaten from './organisation-daten/OrganisationDaten';

const U_P = UserSchema.attributes;
const P_P = PersonSchema.attributes;
const OP_P = OrgaSchema.attributes;

const steps = [
	{
		label: 'Personendaten',
		innerSteps: [],
	},
	// {
	// 	label: 'Organisationsdaten',
	// 	innerSteps: [],
	// },
	{
		label: 'Ihre Zugangsdaten',
		innerSteps: [],
	},
	{
		label: 'Überprüfung',
		innerSteps: [],
	},
];

const innerStepsLabelStyle = {
	fontSize: '2rem',
};

const Register: FC<{}> = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const registrierungAls = get(location, 'state.registrierungAls');
	const [scrollToTop, setScrollToTop] = useState(false);
	const [activeStep, setActiveStep] = useState(0);

	const [loading, setLoading] = useState(false);
	const [isRegistrationSuccess, setIsRegistrationSuccess] = useState<boolean | null>(null);
	const [formErrorMessage, setFormErrorMessage] = useState('');

	const [personDaten, setPersonDaten] = useState({});
	const [personDatenError, setPersonDatenError] = useState({});
	const [organisationDaten, setOrganisationDaten] = useState({});
	const [datenschutzZustimmung, setDatenschutzZustimmung] = useState({
		zustimmung: false,
	});
	const [organisationDatenError, setOrganisationDatenError] = useState({});
	const [loginDaten, setLoginDaten] = useState({});
	const [loginDatenError, setLoginDatenError] = useState({});

	const handleUpdatePersonDaten = (key: string, value: any) => {
		// setHasFormEdited(true);
		//Add timestamps to Username
		// const updatedAt = new Date().toISOString().replace(/\D/g, '');
		let formValueCopy = cloneDeep(personDaten);
		set(formValueCopy, key, value);
		setPersonDaten(formValueCopy);
		let errorObject = cloneDeep(personDatenError);
		delete errorObject[key];
		setPersonDatenError(errorObject);
		if (get(formValueCopy, P_P.E_Mail.path, '')) {
			setLoginDaten((prevValue: any) => ({
				...prevValue,
				[U_P.email.path]: get(formValueCopy, P_P.E_Mail.path, ''),
			}));
		}
		if (registrierungAls === 'NatuerlichePerson') {
			setOrganisationDaten((prevValue: any) => {
				const stateCopy = cloneDeep(prevValue);
				set(stateCopy, OP_P.Dublettenpruefung.path, get(formValueCopy, P_P.E_Mail.path));
				set(stateCopy, OP_P.Name_Der_Organisation.path, get(formValueCopy, P_P.Vorname.path) + ' ' + get(formValueCopy, P_P.Nachname.path));
				return stateCopy;
			});
		}

		setFormErrorMessage('');
		setScrollToTop(false);
	};

	const handleUpdateOrganisationDaten = (key: string, value: any) => {
		// setHasFormEdited(true);
		let formValueCopy = cloneDeep(organisationDaten);
		if (key === OP_P.Name_Der_Organisation.path && typeof value === 'string') value = value.trim();
		set(formValueCopy, key, value);
		setOrganisationDaten(formValueCopy);
		let errorObject = cloneDeep(organisationDatenError);
		delete errorObject[key];
		setOrganisationDatenError(errorObject);
		setFormErrorMessage('');
		setScrollToTop(false);
	};
	const handleUpdateLoginDaten = (key: string, value: any) => {
		// setHasFormEdited(true);
		let formValueCopy = cloneDeep(loginDaten);
		set(formValueCopy, key, value);
		setLoginDaten(formValueCopy);
		let errorObject = cloneDeep(loginDatenError);
		delete errorObject[key];
		setLoginDatenError(errorObject);
		setFormErrorMessage('');
		setScrollToTop(false);
	};
	const handleUpdateDatenschutzZustimmung = (key: string, value: any) => {
		let formValueCopy = cloneDeep(datenschutzZustimmung);
		set(formValueCopy, key, value);
		setDatenschutzZustimmung(formValueCopy);
	};

	const validatePersonData = () => {
		let errorObject = {};
		let errs = PersonDatenValidator.validate(personDaten);

		for (let error of errs) {
			set(errorObject, error.path, get(error, 'message'));
		}
		setPersonDatenError(errorObject);
		return Object.keys(errorObject).length === 0;
	};
	const validateOrganisationData = () => {
		let errorObject = {};
		let errs = OrganisationDatenValidator.validate(organisationDaten);
		for (let error of errs) {
			set(errorObject, error.path, get(error, 'message'));
		}
		setOrganisationDatenError(errorObject);
		return Object.keys(errorObject).length === 0;
	};
	const validateLoginData = () => {
		let errorObject = {};
		let errs = LoginDatenValidator.validate(loginDaten);
		for (let error of errs) {
			set(errorObject, error.path, get(error, 'message'));
		}
		setLoginDatenError(errorObject);
		return Object.keys(errorObject).length === 0;
	};

	const handleBack = () => {
		setScrollToTop(true);
		if (registrierungAls === 'NatuerlichePerson' && activeStep === 2) {
			setActiveStep(1);
		} else {
			setActiveStep(activeStep - 1);
		}
	};

	const handleNext = async () => {
		console.log('personDaten', personDaten);
		console.log('organisationDaten', organisationDaten);
		setScrollToTop(true);
		if (registrierungAls === 'NatuerlichePerson') {
			switch (activeStep) {
				case 0:
					if (validatePersonData()) {
						setActiveStep(1);
					}
					break;
				case 1:
					if (validateLoginData()) {
						if (get(loginDaten, U_P.password.path) !== get(loginDaten, U_P.repeatPassword.path)) {
							setLoginDatenError((prevValue) => ({
								...prevValue,
								[U_P.repeatPassword.path]: 'Beide Passwörter stimmen nicht überein.',
							}));
						} else {
							try {
								await CheckIfUserAndOrganisationExist(organisationDaten, loginDaten);
								setActiveStep(2);
							} catch (err: any) {
								console.error('Error checking Perosn', err);
								setFormErrorMessage('Person existiert bereit');
							}
						}
					}
					break;
				default:
					break;
			}
		} else {
			switch (activeStep) {
				case 0:
					if (validateOrganisationData()) {
						try {
							await CheckIfUserAndOrganisationExist(organisationDaten);
							setActiveStep(1);
						} catch (err: any) {
							console.error('Error checking Organisation ', err);
							setFormErrorMessage('Organisation existiert bereit');
						}
					}
					break;

				case 1:
					if (validatePersonData()) {
						setActiveStep(2);
					}
					break;
				case 2:
					if (validateLoginData()) {
						if (get(loginDaten, U_P.password.path) !== get(loginDaten, U_P.repeatPassword.path)) {
							setLoginDatenError((prevValue) => ({
								...prevValue,
								[U_P.repeatPassword.path]: 'Beide Passwörter stimmen nicht überein.',
							}));
						} else {
							try {
								await CheckIfUserAndOrganisationExist(organisationDaten, loginDaten);
								setActiveStep(3);
							} catch (err: any) {
								console.error('Error checking Perosn', err);
								setFormErrorMessage('Person existiert bereit');
							}
						}
					}
					break;
				default:
					break;
			}
		}
	};

	const handleReigstration = async () => {
		try {
			setScrollToTop(true);
			setLoading(true);
			let res = await RegisterUserAndOrganisation(organisationDaten, loginDaten, personDaten);
			console.log(res);
			if (res) {
				setIsRegistrationSuccess(true);
			}
		} catch (err: any) {
			setIsRegistrationSuccess(false);
			console.error(' ERROR: Registration ', err);
		} finally {
			setLoading(false);
			setActiveStep(registrierungAls === 'NatuerlichePerson' ? 3 : 4);
		}
	};

	useEffect(() => {
		const unloadCallback = (event: any) => {
			event.preventDefault();
			event.returnValue = '';
			return '';
		};
		window.addEventListener('beforeunload', unloadCallback);
		return () => window.removeEventListener('beforeunload', unloadCallback);
	}, []);

	if (registrierungAls !== 'NatuerlichePerson') {
		return <NoDetailsInfo title='Registrierungsoption' goBackUrl={PAGES_URLS.Login} />;
	}
	const HeaderContainer = styled(Box)(({ theme }) => ({
		backgroundColor: theme.palette.navbar.secondary.main,
		padding: '.7rem 1rem',
		position: 'fixed',
		width: '100vw',
		top: 0,
		left: 0,
		right: 0,
		zIndex: 1000,

		// display: activeStep === 3 ? 'none' : 'block',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	}));
	const HeaderStepper = styled(Box)(({ theme }) => ({
		maxWidth: '43rem',
		flex: 1.4,
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	}));

	return (
		<>
			<LoadingBackdrop open={loading} />
			{scrollToTop && <ScrollToTop />}
			<HeaderContainer>
				<Stack direction='row' justifyContent='space-between' alignItems='center'>
					{/* Logo on the left */}

					<Box
						sx={{
							flex: 0.3,
						}}
					>
						<Typography variant='h6' component='div' noWrap sx={{ display: 'flex', alignContent: 'center' }}>
							<img src={Logo_Link} alt='logo- Akademie der Künste' style={{ maxWidth: '17rem', maxHeight: '2.5rem' }} />
						</Typography>
					</Box>
					<HeaderStepper>
						<FormStepper activeStep={activeStep} />
					</HeaderStepper>
					<Box
						sx={{
							flex: 0.3,
							textAlign: 'right',
						}}
					>
						{/* <Box
              sx={{
                height: { sm: '3rem', lg: 'auto' },
              }}
              component='img'
              src={Logo_Recht}
              alt='logo-Hessen-Agentur'
            /> */}
					</Box>
				</Stack>
			</HeaderContainer>

			<RegistrierungWrapper>
				<Box
					sx={{
						flex: '1 1 71.25rem',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Box
						component='form'
						sx={{
							width: '100%',
							maxWidth: '39.813rem',
							backgroundColor: '#fff',
							padding: '2rem',
						}}
					>
						{!isRegistrationSuccess && (
							<Box>
								<ArrowBack
									titleAccess='Zurück zum Login'
									onClick={() => navigate(PAGES_URLS.Login)}
									sx={{
										color: 'primary.main',
										cursor: 'pointer',
									}}
								/>
							</Box>
						)}

						{activeStep === (registrierungAls === 'NatuerlichePerson' ? 0 : 1) && (
							<PersonDaten
								formValue={personDaten}
								updateFunction={handleUpdatePersonDaten}
								personDatenError={personDatenError}
								// registrierungAls={registrierungAls}
							/>
						)}

						{registrierungAls !== 'NatuerlichePerson' && activeStep === 0 && (
							<OrganisationDaten
								formValue={organisationDaten}
								updateFunction={handleUpdateOrganisationDaten}
								organisationDatenError={organisationDatenError}
								formErrorMessage={formErrorMessage}
							/>
						)}

						{activeStep === (registrierungAls === 'NatuerlichePerson' ? 1 : 2) && (
							<>
								<LoginDaten
									formValue={loginDaten}
									updateFunction={handleUpdateLoginDaten}
									loginDatenError={loginDatenError}
									formErrorMessage={formErrorMessage}
								/>
							</>
						)}

						{activeStep === (registrierungAls === 'NatuerlichePerson' ? 2 : 3) && (
							<>
								<AbschlussRegistrierung
									formValue={datenschutzZustimmung}
									updateFunction={handleUpdateDatenschutzZustimmung}
									personDaten={personDaten}
									// organisationDaten={organisationDaten}
									LoginDaten={loginDaten}
									// registrierungAls={registrierungAls}
								/>
							</>
						)}

						{activeStep === (registrierungAls === 'NatuerlichePerson' ? 3 : 4) && <NachRegistrierung isRegistrationSuccess={isRegistrationSuccess} />}

						{/**
                            Logics for Navigation Buttons
                            */}
						<Box sx={{ mt: '3rem' }}>
							{activeStep === (registrierungAls === 'NatuerlichePerson' ? 2 : 3) ?
								<Stack direction='row' justifyContent='space-between'>
									<Button variant='outlined' onClick={handleBack}>
										Zurück
									</Button>
									<Button variant='contained' onClick={handleReigstration} disabled={!isTrue(get(datenschutzZustimmung, 'zustimmung'))}>
										Jetzt registrieren
									</Button>
								</Stack>
							:	<React.Fragment>
									{activeStep < 3 && (
										<Stack direction={'row'} justifyContent='space-between'>
											<Button variant='outlined' disabled={activeStep === 0} onClick={handleBack}>
												Zurück
											</Button>
											<Button variant='contained' onClick={handleNext}>
												Weiter
											</Button>
										</Stack>
									)}
								</React.Fragment>
							}
						</Box>
					</Box>
				</Box>
			</RegistrierungWrapper>
		</>
	);
};

export default Register;

const RegistrierungWrapper: FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<Box>
			<Box
				sx={{
					width: '100%',
					minHeight: '100vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: '#eee',
					padding: '1rem',
					m: '5.188rem 0 -2rem 0',
				}}
			>
				{children}
			</Box>
		</Box>
	);
};

const FormStepper: FC<{ activeStep: number }> = ({ activeStep }) => {
	return (
		<Box>
			<Stepper
				alternativeLabel
				activeStep={activeStep}
				orientation='horizontal'
				sx={{
					'& .MuiStepConnector-line': {
						borderColor: 'primary.main',
						borderWidth: '1.5px',
					},
				}}
			>
				{steps.map((ele: any, i: number) => {
					return (
						<Step key={i}>
							<StepLabel
								StepIconProps={{
									sx: {
										color: '#fff',
										'& .MuiStepIcon-text': {
											display: 'none',
										},
										'&.MuiSvgIcon-root.Mui-active': {
											bgcolor: '#023671',
										},
										'&.MuiStepIcon-root.Mui-completed': {
											bgcolor: '#fff',
										},
										borderRadius: '50%',
										border: '1.5px solid #023671',
									},
								}}
							>
								<Typography
									variant='body2'
									sx={{
										fontFeatureSettings: "'liga' off, 'clig' off",

										fontStyle: 'normal',
										fontSize: { md: '.8rem', lg: '0.875rem' },
									}}
								>
									{ele.label}
								</Typography>
							</StepLabel>
							<Box>
								<Stepper activeStep={i} orientation='vertical'>
									{ele.innerSteps.map((label: string, index: number) => {
										const stepProps = {};
										const labelProps = {};

										return (
											<Step key={index} {...stepProps}>
												<StepLabel {...labelProps}>
													<Typography sx={innerStepsLabelStyle}>{label}</Typography>
												</StepLabel>
												<StepConnector
													sx={{
														display: 'none',
													}}
												></StepConnector>
											</Step>
										);
									})}
								</Stepper>
							</Box>
						</Step>
					);
				})}
			</Stepper>
		</Box>
	);
};
