import { Help } from '@mui/icons-material';
import { MenuItem, TextField } from '@mui/material';
import { get } from 'lodash';
import React from 'react';
import { MUIFieldProps } from '../../../../../lib/types/types';
import { enumT } from '../../../../../utils/helper';
import MUIToolTip from '../../../mui-tooltip/MUIToolTip';

const MUISelectField: React.FC<MUIFieldProps> = (props) => {
	const { label, tooltip, fieldValue, updateFieldValue, isDisabled, sortAlphabetically, error, errorMessage, ...restProps } = props;
	let options = get(props, 'options', []).length > 0 ? get(props, 'options', []) : get(props, 'enum', []);
	if (sortAlphabetically) options.sort();
	return (
		<TextField
			select
			label={
				tooltip ?
					<>
						<>{label}</>
						<MUIToolTip title={tooltip}>
							{' '}
							<Help fontSize='small' color='primary' sx={{ ml: '5px' }} />{' '}
						</MUIToolTip>
					</>
				:	<>{label}</>
			}
			value={
				typeof fieldValue === 'object' ?
					get(props, 'options', []).length > 0 ?
						fieldValue.id
					:	''
				:	fieldValue
			}
			onChange={updateFieldValue}
			disabled={isDisabled}
			error={error}
			helperText={errorMessage}
			fullWidth
			{...restProps}
		>
			{options.map((ele: any, i: number) => {
				if (get(ele, 'label')) {
					return (
						<MenuItem key={i} value={get(ele, 'value')}>
							{get(ele, 'label')}
						</MenuItem>
					);
				} else {
					return (
						<MenuItem key={i} value={ele}>
							{enumT(ele)}
						</MenuItem>
					);
				}
			})}
		</TextField>
	);
};

export default MUISelectField;
