import { FormatListBulleted } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Drawer, IconButton, Paper } from '@mui/material';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useApplication } from '../../../../context/ApplicationContext';
import { PAGES_URLS } from '../../../../lib/constants/routes';
import { TreeNodeType } from '../../../../lib/types/types';
import { isTrue } from '../../../../utils/helper';
import { CustomTreeItem } from './TreeNavigationItems';

type TreeViewNavigationProps = {
	treeStructure: TreeNodeType[];
	currentNode: string[];
	handleNavigation: (nodeIds: string[], buttonClick?: string) => void;
};
const TreeViewNavigationMobile: React.FC<TreeViewNavigationProps> = ({ treeStructure, currentNode, handleNavigation }) => {
	const { saveApplication, readOnly } = useApplication();
	const navigate = useNavigate();

	const [openDrawer, setOpenDrawer] = React.useState(false);

	const handleNodeChange = (_e: React.SyntheticEvent, itemIds: string[]) => {
		handleNavigation(itemIds);
	};

	// This removes the children so that the onClick on the Tree navigates only through the Nodes and not children
	const newTreeStructure = treeStructure.map(({ children, ...rest }) => rest);
	const duplicatedTree: TreeNodeType[] = [];
	newTreeStructure.forEach((item) => {
		duplicatedTree.push(item);
		duplicatedTree.push({
			id: item.id + '.1',
			label: '',
			content: <></>,
		});
	});

	return (
		<>
			<Box sx={{ display: { xs: 'block', md: 'none' } }}>
				<IconButton onClick={() => setOpenDrawer(true)}>
					<FormatListBulleted />
				</IconButton>
			</Box>
			<Drawer
				anchor='left'
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					'@media print': {
						display: 'none',
					},
				}}
			>
				<Button
					variant='contained'
					color='secondary'
					startIcon={<ArrowBackIcon />}
					sx={{ width: '100%', maxWidth: '100%', margin: '0 auto 1rem auto', color: '#fff', boxShadow: 'none' }}
					onClick={async () => {
						await saveApplication();
						navigate(PAGES_URLS.MeineFoerderung);
					}}
				>
					{isTrue(readOnly) ? 'Zurück' : 'Speichern und zurück'}
				</Button>
				<Paper sx={{ maxWidth: '18.75rem', padding: '1rem' }}>
					<RichTreeView
						multiSelect={true}
						items={newTreeStructure}
						selectedItems={currentNode}
						expandedItems={currentNode}
						onSelectedItemsChange={handleNodeChange}
						sx={{ height: 'fit-content', flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
						slots={{ item: CustomTreeItem }}
					/>
				</Paper>
			</Drawer>
		</>
	);
};
export default TreeViewNavigationMobile;
