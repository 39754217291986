import { isInteger } from 'lodash';
import { EnumType } from 'typescript';
import { isDate, isDecimal, isPostalCode } from 'validator';
import isEmail from 'validator/lib/isEmail';
import { DisplayDecimal } from '../../utils/formats';
import { MAX_TEXTBOX_LENGTH } from '../constants/constant';

export const REGEX_WEBSITE =
	/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|ftp:\/\/|https:\/\/)?[a-z0-9-ZäöüÄÖÜ]+([.]{1}[a-z0-9]+)*\.[a-z]{2,50}(:[0-9]{1,5})?(\/.*)?$/;
const phoneRegex = /^(\+\d+|\(\d+\)|\d+)([\s\d]*)$/;

export const Required = {
	use: {
		required: (val: any) => {
			try {
				if (Array.isArray(val)) {
					return val.length > 0;
				} else if (typeof val === 'number') {
					return true;
				} else {
					return val ? true : false;
				}
			} catch (err) {
				return false;
			}
		},
	},
	message: { required: 'Dies ist ein Pflichtfeld' },
};

export const NonRequired = {
	use: {
		nonRequired: () => {
			return true;
		},
	},
};

export const DropdownRelationValidation = {
	use: {
		select: (val: any) => {
			try {
				if (!isNaN(val) || val.id) return true;
				return false;
			} catch (err) {
				return false;
			}
		},
	},
	message: {
		select: 'Bitte wählen Sie eine Möglichkeit aus',
	},
};

export const NotEmptyArrayValidation = {
	use: {
		array: (val: any) => {
			try {
				if (val.length !== 0) return true;
				return false;
			} catch (err) {
				return false;
			}
		},
	},
	message: {
		array: 'Dies ist ein Pflichtfeld',
	},
};

export const FileValidation = {
	use: {
		file: (val: any) => {
			try {
				if (val.attributes) return true;
				return false;
			} catch (err) {
				return false;
			}
		},
	},
	message: {
		file: 'Sie müssen diese Datei hochladen',
	},
};

export const RadioButtonValidation = {
	use: {
		radioButton: (val: any) => {
			try {
				if (val.attributes) return true;
				return false;
			} catch (err) {
				return false;
			}
		},
	},
	message: {
		radioButton: 'Bitte wählen Sie eine Möglichkeit aus',
	},
};

export const DateGTE2024Validation = {
	use: {
		dateVal: (val: any) => {
			try {
				return new Date(val) >= new Date('01.01.2024');
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		dateVal: 'Der frühestmögliche Beginn ist der 01.01.2024.',
	},
};

export const ValidDate = {
	use: {
		dateType: (val: any) => {
			try {
				if (!isDate(val)) return false;
				return true;
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		dateType: 'Bitte geben Sie ein gültiges Datum ein.',
	},
};

export function GetDropdownContainsValidation(validStrings: string[], containsMessage?: string) {
	const defaultContainsMessage = 'Das ausgewählte Feld ist nicht erlaubt.';
	return {
		use: {
			dropdownContainsValidation: (val: any) => {
				try {
					return validStrings.includes(String(val));
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			dropdownContainsValidation: containsMessage ? containsMessage : defaultContainsMessage,
		},
	};
}

export function GetDateGTMaxYearValidation(compareValue: string, maxYear: number) {
	return {
		use: {
			compareVal: () => {
				try {
					if (compareValue === undefined || compareValue === null) return false;
					if (!isDate(compareValue)) return false;
					return true;
				} catch (error) {
					return false;
				}
			},
			dateGT: (val: any) => {
				try {
					if (!isDate(val)) return false;
					if (!compareValue) return false;
					return new Date(val) > new Date(compareValue);
				} catch (error) {
					return false;
				}
			},
			maxYear: (val: any) => {
				const compareDate = new Date(compareValue);
				const futureDate = getFutureDate(compareDate, maxYear);
				try {
					if (!isDate(val)) return false;
					if (!compareValue) return false;
					return new Date(val) <= futureDate;
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			compareVal: 'Bitte wählen Sie ein gültiges Anfangsdatum aus.',
			dateGT: 'Das frühestmögliche Datum ist ' + getTomorrow(new Date(compareValue)),
			maxYear: `Das Enddatum darf höchstens ${maxYear} Jahre nach dem Anfangsdatum liegen.`,
		},
	};
}

/**
 *
 * @param compareValue
 * @param maxYear adds the additional years as max value to compare
 * @returns
 */
export function GetDateGTEMaxYearValidation(compareValue: string, maxYear: number) {
	return {
		use: {
			compareVal: () => {
				try {
					if (compareValue === undefined || compareValue === null) return false;
					if (!isDate(compareValue)) return false;
					return true;
				} catch (error) {
					return false;
				}
			},
			dateGTE: (val: any) => {
				try {
					if (!isDate(val)) return false;
					if (!compareValue) return false;
					return new Date(val) >= new Date(compareValue);
				} catch (error) {
					return false;
				}
			},
			maxYear: (val: any) => {
				const compareDate = new Date(compareValue);
				const futureDate = getFutureDate(compareDate, maxYear);
				try {
					if (!isDate(val)) return false;
					if (!compareValue) return false;
					return new Date(val) <= futureDate;
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			compareVal: 'Bitte wählen Sie ein gültiges Anfangsdatum aus.',
			dateGTE: 'Das frühestmögliche Datum ist ' + DisplayDate(new Date(compareValue)),
			maxYear: `Das Enddatum darf höchstens ${maxYear} Jahre nach dem Anfangsdatum liegen.`,
		},
	};
}

export function GetCompareMinMaxDate(startDate: string, endDate: string) {
	return {
		use: {
			compareVal: () => {
				try {
					if (startDate === undefined || startDate === null) return false;
					if (!isDate(startDate)) return false;
					return true;
				} catch (error) {
					return false;
				}
			},
			dateGT: (val: any) => {
				try {
					if (!isDate(val)) return false;
					if (!startDate) return false;
					return new Date(val) > new Date(startDate);
				} catch (error) {
					return false;
				}
			},
			maxDate: (val: any) => {
				try {
					if (!isDate(val)) return false;
					return new Date(val) <= new Date(endDate);
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			compareVal: 'Bitte wählen Sie ein gültiges Anfangsdatum aus.',
			dateGT: 'Das frühestmögliche Datum ist ' + getTomorrow(new Date(startDate)),
			maxDate: `Das Datum darf nicht nach dem ${DisplayDate(new Date(endDate))} liegen.`,
		},
	};
}

export function GetCompareMinMaxEqualDate(startDate: string, endDate: string) {
	return {
		use: {
			compareValStart: () => {
				try {
					if (startDate === undefined || startDate === null) return false;
					if (!isDate(startDate)) return false;
					return true;
				} catch (error) {
					return false;
				}
			},
			compareValEnd: () => {
				try {
					if (endDate === undefined || endDate === null) return false;
					if (!isDate(endDate)) return false;
					return true;
				} catch (error) {
					return false;
				}
			},
			dateGTE: (val: any) => {
				try {
					if (!isDate(val)) return false;
					if (!startDate) return false;
					return new Date(val) >= new Date(startDate);
				} catch (error) {
					return false;
				}
			},
			maxDate: (val: any) => {
				try {
					if (!isDate(val)) return false;
					return new Date(val) <= new Date(endDate);
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			compareValStart: 'Bitte wählen Sie ein gültiges Anfangsdatum aus.',
			compareValEnd: 'Bitte wählen Sie ein gültiges Enddatum aus.',
			dateGTE: 'Das frühestmögliche Datum ist ' + DisplayDate(new Date(startDate)),
			maxDate: `Das Datum darf nicht nach dem ${DisplayDate(new Date(endDate))} liegen.`,
		},
	};
}

export const DateNGTCurrentYearValidation = {
	use: {
		dateVal: (val: any) => {
			try {
				if (!isDate(val)) return false;
				const lastDayOfCurrentYear = new Date(new Date().getFullYear(), 11, 31);
				return new Date(val) <= lastDayOfCurrentYear;
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		dateVal: 'Bitte wählen Sie ein gültiges Datum aus.',
	},
};

export const DateGTECurrentYearInYearValidation = {
	use: {
		dateVal: (val: any) => {
			try {
				if (Number(val) >= new Date().getFullYear()) return true;
				return false;
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		dateVal: 'Bitte tragen Sie ein gültiges Jahr ein.',
	},
};

function DisplayDate(dateString: Date) {
	if (!dateString) return '';
	return new Date(dateString).toLocaleDateString('de-DE', {
		day: '2-digit',
		year: 'numeric',
		month: '2-digit',
	});
}
function getTomorrow(date: any) {
	const today = new Date(date);
	const tomorrow = new Date(today);
	tomorrow.setDate(today.getDate() + 1);

	return DisplayDate(tomorrow);
}
function getFutureDate(date: any, addYears: number) {
	const currentDate = new Date(date);
	const futureDate = new Date(currentDate);
	futureDate.setFullYear(currentDate.getFullYear() + addYears);

	return futureDate;
}

export const FullYear = {
	use: {
		fullYearVal: (val: any) => {
			try {
				const year = Number(val);
				return Number.isInteger(year) && year >= 1000 && year <= 9999;
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		fullYearVal: 'Bitte geben Sie ein gültiges Jahr ein.',
	},
};

export const FutureDateValidation = {
	use: {
		dateVal: (val: any) => {
			try {
				if (!isDate(val)) return false;
				return new Date(val) > new Date(Date.now());
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		dateVal: 'Der frühestmögliche Beginn ist der ' + getTomorrow(Date.now()),
	},
};

export const NotFutureDateValidation = {
	use: {
		dateVal: (val: any) => {
			try {
				if (!isDate(val)) return false;
				return new Date(val) <= new Date(Date.now());
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		dateVal: 'Das Datum muss vor dem ' + getTomorrow(new Date(Date.now())) + ' liegen',
	},
};

export function GetFutureDateValidation(currentDate: any) {
	return {
		use: {
			dateVal: (val: any) => {
				try {
					if (!isDate(val)) return false;
					return new Date(val) > new Date(currentDate);
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			dateVal: 'Der frühestmögliche Beginn ist der ' + getTomorrow(new Date(currentDate)),
		},
	};
}

export const DateFormatValidation = {
	type: String,
	use: {
		dateVal: (val: any) => {
			try {
				return isDate(val);
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		dateVal: 'Ungültiges Datumsformat.',
	},
};

export const PostalCodeValidation = {
	type: String,
	use: {
		postalValidator: (val: any) => {
			try {
				return isPostalCode(val, 'DE');
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		postalValidator: 'Diese Postleitzahl ist ungültig.',
	},
};

export const EmailValidation = {
	type: String,
	use: {
		mailVal: (val: any) => {
			try {
				return isEmail(val);
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		mailVal: 'Ungültige E-Mail-Adresse.',
	},
};

export const DomainValidation = {
	type: String,
	use: {
		fqdn: (val: any) => {
			return REGEX_WEBSITE.test(val);
		},
	},
	message: {
		fqdn: 'Ungültige Website.',
	},
};

export const IdValidation = {
	use: {
		id: (val: any) => {
			try {
				return Number.isInteger(parseInt(val));
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		id: 'Id Feld falsch befüllt.',
	},
};

export const IBANValidation = {
	type: String,
	use: {
		iban: (val: any) => {
			try {
				let formatted = val.replaceAll(' ', '');
				let length = formatted.length;
				if (length === 0) {
					return false;
				} else {
					let regexp = /[A-Z]{2,2}[0-9]{2,2}[a-zA-Z0-9]{1,30}/;
					if (formatted.match(regexp)) {
						return true;
					} else {
						return false;
					}
				}
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		iban: 'Ungültige IBAN.',
	},
};

export const BICValidation = {
	type: String,
	use: {
		iban: (val: any) => {
			try {
				var formatted = val.replaceAll(' ', '');
				var length = formatted.length;
				if (length === 0) {
					return false;
				} else {
					var regexp = /[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}/;
					if (formatted.match(regexp)) {
						return true;
					} else {
						return false;
					}
				}
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		iban: 'Ungültige BIC.',
	},
};

export const NonNegativeDecimalValidation = {
	// type: Number,
	use: {
		nonNegative: (val: any) => {
			try {
				return val >= 0;
			} catch (error) {
				return false;
			}
		},
		empty: (val: any) => {
			try {
				return val !== '';
			} catch (error) {
				return false;
			}
		},
		decimal: (val: any) => {
			try {
				return isDecimal(val.toString(), { decimal_digits: '0,2' });
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		nonNegative: 'Negative Werte sind nicht erlaubt.',
		empty: 'Dies ist ein Pflichtfeld.',
		decimal: 'Es sind nur 2 Stellen hinter dem Komma erlaubt.',
	},
};

export const NonNegativeIntValidation = {
	// type: Number,
	use: {
		nonNegativeInt: (val: any) => {
			try {
				return val >= 0;
			} catch (error) {
				return false;
			}
		},
		empty: (val: any) => {
			try {
				return val !== '';
			} catch (error) {
				return false;
			}
		},
		intTypeCheck: (val: any) => {
			try {
				return isInteger(Number(val));
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		nonNegativeInt: 'Negative Werte sind nicht erlaubt.',
		empty: 'Dies ist ein Pflichtfeld.',
		intTypeCheck: 'Es sind nur ganze Zahlen erlaubt',
	},
};

export const NumberValidation = {
	type: Number,
	use: {
		empty: (val: any) => {
			try {
				return val ? true : false;
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		empty: 'Dies ist ein Pflichtfeld',
		type: 'Bitte geben Sie eine Zahl ein',
	},
};

export const StringValidation = {
	type: String,
};

export const BooleanValidation = {
	type: Boolean,
};

export const ArrayValidation = {
	type: Array,
};

export const ForceTrue = {
	use: { trueValidation: (val: any) => val === true },
	message: {
		trueValidation: 'Dies ist ein Pflichtfeld.',
	},
};

export const ForceFalse = {
	use: { falseValidation: (val: any) => val === false },
	message: {
		falseValidation: 'Das Feld darf nicht gesetzt werden.',
	},
};

export function GetMinMaxIntValidation(min: number, max: number, message?: string) {
	const defaultMessage = `Der Wert muss zwischen ${min} und ${max} sein`;
	return {
		// type: Number,
		use: {
			minMaxInputCheck: () => {
				try {
					return min !== null || max !== null;
				} catch (error) {
					return false;
				}
			},
			minMaxValidation: (val: any) => {
				try {
					return val >= min && val <= max;
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			minMaxValidation: message ? message : defaultMessage,
			minMaxInputCheck: 'Bitte prüfen Sie den Min/Max Vergleichswert',
		},
	};
}

export function GetMinMaxDecimalValidation(min: number, max: number, message?: string) {
	const defaultMessage = `Der Wert muss zwischen ${DisplayDecimal(min)} und ${DisplayDecimal(max)} sein`;
	return {
		use: {
			minMaxValidation: (val: any) => {
				try {
					return Number(val) >= min && Number(val) <= max;
				} catch (error) {
					return false;
				}
			},
			decimal: (val: any) => {
				try {
					return isDecimal(val.toString(), { decimal_digits: '0,2' });
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			minMaxValidation: message ? message : defaultMessage,
			decimal: 'Es sind nur 2 Stellen hinter dem Komma erlaubt.',
		},
	};
}

export function GetMinDecimalValidation(min: number, message?: string, minParamMessage?: string) {
	const defaultMessage = `Der Mindestwert Wert beträgt ${DisplayDecimal(min)}`;
	const defaultMinParamMessage = 'Es wurde kein minimaler Vergleichswert gefunden';
	return {
		use: {
			minParamDecimalTypeCheck: () => {
				try {
					const num = Number(min);
					return Number.isInteger(num);
				} catch (error) {
					return false;
				}
			},
			minDecimalValidation: (val: any) => {
				try {
					return Number(val) >= Number(min);
				} catch (error) {
					return false;
				}
			},
			decimal: (val: any) => {
				try {
					return isDecimal(val.toString(), { decimal_digits: '0,2' });
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			minParamDecimalTypeCheck: minParamMessage ? minParamMessage : defaultMinParamMessage,
			minDecimalValidation: message ? message : defaultMessage,
			decimal: 'Es sind nur 2 Stellen hinter dem Komma erlaubt.',
		},
	};
}

export function GetMaxDecimalValidation(max: number, message?: string, maxParamMessage?: string) {
	const defaultMessage = `Der Maximalwert Wert beträgt ${DisplayDecimal(max)}`;
	const defaultMaxParamMessage = 'Es wurde kein maximaler Vergleichswert gefunden';

	return {
		use: {
			maxDecimalValidation: (val: any) => {
				try {
					return Number(val) <= max;
				} catch (error) {
					return false;
				}
			},
			maxParamDecimalTypeCheck: () => {
				try {
					const num = Number(max);
					return Number.isInteger(num);
				} catch (error) {
					return false;
				}
			},
			decimal: (val: any) => {
				try {
					return isDecimal(val.toString(), { decimal_digits: '0,2' });
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			maxParamDecimalTypeCheck: maxParamMessage ? maxParamMessage : defaultMaxParamMessage,
			maxDecimalValidation: message ? message : defaultMessage,
			decimal: 'Es sind nur 2 Stellen hinter dem Komma erlaubt.',
		},
	};
}

/**
 * only use if minimum has to be greater than 0
 */
export function GetMaxDecimalWithStaticMinValidation(max: number, message?: string, maxInputMessage?: string, maxValidationMessage?: string) {
	const defaultMessage = `Der Maximalwert ist ${DisplayDecimal(max)}.`;
	const nonNegativeInputDefaultMessage = `Der Vergleichswert darf nicht negativ sein.`;
	const maxValidationDefaultMessage = 'Es wurde kein Maximalwert gesetzt.';
	return {
		use: {
			maxValidation: () => {
				try {
					return max ? true : false;
				} catch (error) {
					return false;
				}
			},
			nonNegativeInput: () => {
				try {
					return max >= 0;
				} catch (error) {
					return false;
				}
			},
			minValidation: (val: any) => {
				try {
					return Number(val) > 0;
				} catch (error) {
					return false;
				}
			},
			compare: (val: any) => {
				try {
					return Number(val) >= 0 && Number(val) <= max;
				} catch (error) {
					return false;
				}
			},
			decimal: (val: any) => {
				try {
					return isDecimal(val.toString(), { decimal_digits: '0,2' });
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			nonNegativeInput: maxInputMessage ? maxInputMessage : nonNegativeInputDefaultMessage,
			minValidation: 'Der Wert muss größer als 0 sein.',
			maxValidation: maxValidationMessage ? maxValidationMessage : maxValidationDefaultMessage,
			compare: message ? message : defaultMessage,
			decimal: 'Es sind nur Dezimalzahlen mit 2 Stellen hinter dem Komma erlaubt.',
		},
	};
}

export function GetGesamtAusgabeGEGesamtEinnahme(gesamtEinnahme: number, gesamtAusgabe: number) {
	return {
		use: {
			// isDefined: (val: any) => {
			// 	try {
			// 		return val ? true : false;
			// 	} catch (error) {
			// 		return false;
			// 	}
			// },
			maxValidation: (_val: any) => {
				try {
					return Number(gesamtAusgabe) >= Number(gesamtEinnahme);
				} catch (error) {
					return false;
				}
			},
			decimal: (val: any) => {
				try {
					return isDecimal(val.toString(), { decimal_digits: '0,2' });
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			maxValidation: 'Die Eigen- und Drittmittel dürfen zusammen nicht die Gesamtausgaben überschreiten',
			decimal: 'Es sind nur Dezimalzahlen mit 2 Stellen hinter dem Komma erlaubt.',
		},
	};
}

export function GetMinMaxArrayLengthValidation(min: number, max: number, errorMessage: string) {
	return {
		type: Array,
		use: {
			arrLen: (val: any) => {
				try {
					return val.length >= min && val.length <= max;
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			arrLen: errorMessage,
		},
	};
}

export function GetMinIntValidation(min: any, inputIntMessage?: string, valMessage?: string) {
	const defaultInputMessage = 'Es wurde kein gültiger Mindestwert gesetzt.';
	const defaultMinIntValMessage = `Der Mindestwert beträgt ${Number(min)}`;
	return {
		use: {
			minIntTypeCheck: (val: any) => {
				try {
					const num = Number(val);
					return Number.isInteger(num);
				} catch (error) {
					return false;
				}
			},
			minInputIntTypeCheck: () => {
				try {
					const num = Number(min);
					return Number.isInteger(num);
				} catch (error) {
					return false;
				}
			},
			minIntValidation: (val: any) => {
				try {
					return Number(val) >= Number(min);
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			minIntTypeCheck: 'Nur ganze Zahlen sind erlaubt.',
			minInputIntTypeCheck: inputIntMessage ? inputIntMessage : defaultInputMessage,
			minIntValidation: valMessage ? valMessage : defaultMinIntValMessage,
		},
	};
}

export function GetNotEqualIntValidation(compareVal: any, inputIntMessage?: string, valMessage?: string) {
	const defaultInputMessage = 'Es wurde kein gültiger Vergleichswert gesetzt.';
	const defaultNotEqualIntValMessage = `Der Wert ${Number(compareVal)} ist nicht erlaubt.`;
	return {
		use: {
			notEqualIntTypeCheck: (val: any) => {
				try {
					const num = Number(val);
					return Number.isInteger(num);
				} catch (error) {
					return false;
				}
			},
			notEqualInputIntTypeCheck: () => {
				try {
					const num = Number(compareVal);
					return Number.isInteger(num);
				} catch (error) {
					return false;
				}
			},
			notEqualIntValidation: (val: any) => {
				try {
					return Number(val) !== Number(compareVal);
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			notEqualIntTypeCheck: 'Nur ganze Zahlen sind erlaubt.',
			notEqualInputIntTypeCheck: inputIntMessage ? inputIntMessage : defaultInputMessage,
			notEqualIntValidation: valMessage ? valMessage : defaultNotEqualIntValMessage,
		},
	};
}

export function GetMaxIntValidation(max: any, maxInputIntMessage?: string) {
	const defaultInputMessage = 'Es wurde kein gültiger Maximalwert gesetzt.';
	return {
		use: {
			maxIntTypeCheck: (val: any) => {
				try {
					const num = Number(val);
					return Number.isInteger(num);
				} catch (error) {
					return false;
				}
			},
			maxInputIntTypeCheck: () => {
				try {
					const num = Number(max);
					return Number.isInteger(num);
				} catch (error) {
					return false;
				}
			},
			maxIntValidation: (val: any) => {
				try {
					return 0 <= Number(val) && Number(val) <= Number(max);
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			maxIntTypeCheck: 'Nur ganze Zahlen sind erlaubt.',
			maxInputIntTypeCheck: maxInputIntMessage ? maxInputIntMessage : defaultInputMessage,
			maxIntValidation: `Der Maximalwert beträgt ${Number(max)}`,
		},
	};
}

export function GetTextboxValidation(maxChars = MAX_TEXTBOX_LENGTH) {
	return {
		use: {
			maxValidation: (val: any) => {
				try {
					if (val === undefined || val === null) return true;
					return val.trim().length <= maxChars;
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			maxValidation: `Maximal ${maxChars} Zeichen sind erlaubt.`,
		},
	};
}

export function GetEnumValidation(enumeration: EnumType) {
	return {
		enum: enumeration,
		message: {
			enum: 'Bitte wählen Sie einen Wert aus der Auswahlliste aus.',
		},
	};
}

export const PhoneNumberValidation = {
	use: {
		phoneValidation: (val: any) => {
			try {
				return phoneRegex.test(val);
			} catch (error) {
				return false;
			}
		},
	},
	message: {
		phoneValidation: 'Bitte geben Sie eine gültige Telefonnummer ein.',
	},
};
export function ValidateBirthdateRange() {
	return {
		use: {
			dateGT: (val: any) => {
				try {
					const dateTra = new Date(val) >= new Date('01.01.1990');
					return dateTra;
				} catch (error) {
					return false;
				}
			},
			maxDate: (val: any) => {
				try {
					if (!isDate(val)) return false;
					const lastDayOfCurrentYear = new Date(new Date().getFullYear(), 11, 31);
					return new Date(val) <= lastDayOfCurrentYear;
				} catch (error) {
					return false;
				}
			},
		},
		message: {
			dateGT: 'Das frühestmögliche Datum ist 1990 ',
			maxDate: `Das Datum darf nicht nach dem ${new Date().getFullYear()} liegen.`,
		},
	};
}
