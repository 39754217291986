import { Typography } from '@mui/material';
import { get } from 'lodash';
import React, { FC } from 'react';
import RightSidebarPaper from '../../../../components/ui-component/form-component/right-side-bar/RightSidebarPaper';
import { useApplication } from '../../../../context/ApplicationContext';
import { DisplayDateTime } from '../../../../utils/formats';
import { enumT } from '../../../../utils/helper';

// import schemas
import Schema from '../../../../lib/paths/antrag_FULL_Paths.json';
const P = Schema.attributes;

const FoerderAntragRightSidebar: FC<{}> = () => {
	const { application } = useApplication();

	return (
		<>
			<RightSidebarPaper>
				<span>
					<Typography variant='body1'>Online-Formular:</Typography>
					<Typography fontWeight={500} variant='body1'>
						{'Antrag'}
					</Typography>
				</span>

				<span>
					<Typography variant='body1'>Status:</Typography>
					<Typography fontWeight={500} variant='body1'>
						{enumT(get(application, P.Status.path, ''))}
					</Typography>
				</span>
				<span>
					<Typography variant='body1'>Förderprogramm:</Typography>
					<Typography fontWeight={500} variant='body1'>
						{enumT(get(application, P.Ausschreibung.schema.attributes.Programmname.path, ''))}
					</Typography>
				</span>
				<span>
					<Typography variant='body1'>Eingangsnummer:</Typography>
					<Typography fontWeight={500} variant='body1'>
						{get(application, ' P.Eingangsnummer.path,', 'ausstehend')}
					</Typography>
				</span>
				<span>
					<Typography variant='body1'>Erstellt am:</Typography>
					<Typography fontWeight={500} variant='body1'>
						{DisplayDateTime(get(application, 'attributes.createdAt'))}
					</Typography>
				</span>

				{/* <span>
					<Typography variant='body1'>Zuletzt geändert am:</Typography>
					<Typography fontWeight={500} variant='body1'>
						{DisplayDateTime(get(application, P.g.path, ''))}
					</Typography>
				</span> */}
			</RightSidebarPaper>
		</>
	);
};
export default FoerderAntragRightSidebar;
