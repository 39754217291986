import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Stack } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useApplication } from '../../../../context/ApplicationContext';
import { GetFieldErrors } from '../../../../lib/validation/ApplicationValidator';
import MuiDialog from '../../mui-dialog/MuiDialog';
import { constructHierarchyArray, extractIds } from './TreeNavigatrionHelper';

// import schemas
// import Schema from '../../../../lib/paths/antrag_FULL_Paths.json';
// const P = Schema.attributes;

type TreeViewNavigationProps = {
	treeStructure: any;
	currentNode: string[];
	handleNavigation: (nodeIds: string[], buttonClick?: string) => void;
	hasCurrentContentError: () => boolean;
	validateCompleteForm: () => void;
};
const TreeNavigationButtons: FC<TreeViewNavigationProps> = ({
	treeStructure,
	currentNode,
	handleNavigation,
	hasCurrentContentError,
	validateCompleteForm,
}) => {
	const { application, saveApplication, readOnly } = useApplication();
	const [previousNodes, setPreviousNodes] = useState<string[]>(['1']);
	const [nextNodes, setNextNodes] = useState<string[]>(['1']);
	const [hasError, setHasError] = useState(false);

	const [submitDialogOpen, setSubmitDialogOpen] = useState<boolean>(false);

	const handleSubmitApp = async () => {
		setSubmitDialogOpen(false);
		saveApplication(true);
	};

	const DialogTitle = useMemo(() => {
		return 'Antrag einreichen';
	}, []);
	const DialogContent = useMemo(() => {
		return 'Sind Sie sicher, dass Sie den Antrag einreichen möchten?';
	}, []);

	useEffect(() => {
		if (!currentNode || currentNode.length === 0) return;

		const allNodesIdsArr = extractIds(treeStructure);
		const currentNodeStr = currentNode[currentNode.length - 1];
		const currentNodeIndex = allNodesIdsArr.findIndex((ele: any) => ele === currentNodeStr);

		if (currentNodeIndex === -1) return;
		let prevNodeStr = allNodesIdsArr[currentNodeIndex - 1];
		let nextNodeStr = allNodesIdsArr[currentNodeIndex + 1];
		// Update state
		setPreviousNodes(constructHierarchyArray(prevNodeStr));
		setNextNodes(constructHierarchyArray(nextNodeStr));
	}, [treeStructure, currentNode]);

	const hasAnyErrorInForm = (): boolean => {
		const errorList = GetFieldErrors(treeStructure, application, readOnly);
		return errorList.length > 0;
	};

	const einreichen = () => {
		setSubmitDialogOpen(true);
		hasCurrentContentError();
		validateCompleteForm();
		setHasError(hasAnyErrorInForm());
	};

	return (
		<>
			<MuiDialog
				open={submitDialogOpen}
				onClose={() => setSubmitDialogOpen(false)}
				onConfirm={() => handleSubmitApp()}
				title={hasError ? 'Fehler im Formular' : DialogTitle}
				content={hasError ? 'Es befinden sich noch einige Fehler im Formular' : DialogContent}
				hasError={hasError}
			/>
			<Box className='detailNavigationButtons'>
				<Stack
					// sx={{ mt: '2rem' }}
					direction={'row'}
					justifyContent={'space-between'}
				>
					<Button
						variant='secondaryButton'
						disabled={previousNodes[0] === undefined}
						onClick={() => {
							handleNavigation([], 'previous');
						}}
						startIcon={<ArrowBackIcon />}
					>
						Zurück
					</Button>

					{nextNodes[0] !== undefined ?
						<Button variant='contained' onClick={() => handleNavigation([], 'next')} endIcon={<ArrowForwardIcon />}>
							Weiter
						</Button>
					:	<>
							{!readOnly && (
								<Button endIcon={<SendIcon />} onClick={() => einreichen()}>
									Einreichen
								</Button>
							)}
						</>
					}
				</Stack>
			</Box>
		</>
	);
};
export default TreeNavigationButtons;
