import React, { FC } from "react";
import { Button, Box, Paper, Stack, Typography } from "@mui/material";
import { ArrowBack, KeyboardArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PAGES_URLS } from "../../../lib/constants/routes";

const RegistrierungVorschritt: FC<{}> = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          width: "100%",
          backgroundColor: "background.paper",
          mb: "-2rem",
        }}
      >
        <Box
          sx={{
            m: "2rem",
          }}
        >
          <Paper
            sx={{
              backgroundColor: "#FFF",
              p: "2rem",
              maxWidth: "39.125rem",
            }}
          >
            <Stack gap={2}>
              <Box>
                <ArrowBack
                  titleAccess="Zurück zum Login"
                  onClick={() => navigate(PAGES_URLS.Login)}
                  sx={{
                    display: "flex",
                    color: "primary.main",
                    cursor: "pointer",
                  }}
                />
              </Box>
              <Typography
                variant="h4"
                component={"h4"}
                textAlign={"center"}
                color={"primary.main"}
                marginBottom={"1rem"}
              >
                Registrierung Ihrer Organisation
              </Typography>
              <Typography variant="h6" component={"h6"} textAlign={"left"}>
                Sie sind dabei, eine neue Organisation zu registrieren
              </Typography>
            </Stack>
            <Typography sx={{ mt: "1rem" }}>
              Für die Registrierung Ihrer Organisation stehen Ihnen zwei
              Optionen zur Verfügung:
            </Typography>
            <ol>
              <li>
                <Typography sx={{ fontWeight: 600 }}>
                  Registrierung als Organisation:
                </Typography>
                Wenn Ihre Organisation als kleines oder mittleres Unternehmen
                (KMU) eingestuft wird, klicken Sie bitte auf den Button „Als KMU
                registrieren“ und folgen Sie den weiteren Anweisungen.
              </li>

              <li>
                <Typography sx={{ fontWeight: 600, mt: ".3rem" }}>
                  Registrierung als Abteilung einer Organisation:
                </Typography>
                Möchten Sie eine Abteilung oder ein Tochterunternehmen
                registrieren, setzen Sie sich bitte direkt mit unserer
                Verwaltung in Verbindung. Unser Team wird Ihnen bei der weiteren
                Registrierung behilflich sein.
              </li>
            </ol>

            <Typography>
              Bitte wählen Sie eine der Optionen aus oder kontaktieren Sie uns,
              um die Registrierung Ihrer Abteilung zu besprechen.
            </Typography>

            <Stack
              gap={3}
              sx={{
                m: "2rem 0 0 0 ",
              }}
            >
              <Button
                variant="loginButton"
                sx={{
                  borderRadius: 1,
                  justifyContent: "space-between",
                  padding: "0.75rem .5rem 0.75rem 1.375rem ", // 12px 22px
                  maxWidth: "100%",
                }}
                endIcon={<KeyboardArrowRight />}
                onClick={() =>
                  navigate(PAGES_URLS.Registrierung, {
                    state: { registrierenAls: "KMU" },
                  })
                }
              >
                Organisation registrieren
              </Button>

              <Button
                variant="meinBereich"
                endIcon={<KeyboardArrowRight />}
                onClick={() =>
                  navigate(PAGES_URLS.AbteilungRegistrierung, {
                    state: { registrierenAls: "Abteilung" },
                  })
                }
                sx={{
                  justifyContent: "space-between",
                  paddingRight: ".5rem",
                  borderRadius: 1,
                  border:
                    "1px solid var(--primary-_states-outlinedBorder, #02367180)", // Uses CSS variable with fallback
                }}
              >
                Abteilung registrieren
              </Button>
            </Stack>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default RegistrierungVorschritt;
