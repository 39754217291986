import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-premium';
import { deDE } from '@mui/x-data-grid/locales';
import { LicenseInfo } from '@mui/x-license';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateForm from '../../../components/ui-component/form-component/create-form/CreateForm';
import CreateNachricht from '../../../components/ui-component/nachrichten/CreateNachricht';
import { useFetchApplicationsForNachrichten } from '../../../hooks/FetchApplications';
import { useFetchNachrichten } from '../../../hooks/FetchNachrichten';
import { MUIX_LICENSE, NACHRICHT_ORDNER } from '../../../lib/constants/constant';
import { PAGES_URLS } from '../../../lib/constants/routes';
import { DisplayDateTime } from '../../../utils/formats';
import { T, getFieldNamefromDataPath, getLabelFromDataPath } from '../../../utils/helper';

// import schemas
import Schema from '../../../lib/paths/nachricht_FULL_Paths.json';
let P = Schema.attributes;

LicenseInfo.setLicenseKey(MUIX_LICENSE);

function MeineNachrichten() {
	const navigate = useNavigate();

	const [ordnerValue, setOrdnerValue] = useState({ ordner: 'Posteingang' });
	const [showCreateNachricht, setShowCreatenachricht] = useState<boolean>(false);
	const { loading, data, error } = useFetchNachrichten(showCreateNachricht, get(ordnerValue, 'ordner', 'Posteingang'));
	const { data: applications } = useFetchApplicationsForNachrichten();
	console.log('data', data);
	console.log('applications', applications);
	const rows = useMemo(() => {
		return (
			data?.map((ele) => ({
				id: get(ele, 'id'),
				[getFieldNamefromDataPath(P.Bewerbung.schema.attributes.Projekttitel.path)]: T(get(ele, P.Bewerbung.schema.attributes.Projekttitel.path)),
				[getFieldNamefromDataPath(P.Betreff.path)]: T(get(ele, P.Betreff.path)),
				Erstellt_am: DisplayDateTime(get(ele, 'attributes.createdAt')),
				[getFieldNamefromDataPath(P.Ordner.path)]: get(ele, P.Ordner.path),
				[getFieldNamefromDataPath(P.gelesen.path)]: get(ele, P.gelesen.path),
			})) ?? []
		);
	}, [data]);

	const columns = useMemo(() => {
		let column: any[] = [
			{
				field: 'id',
				headerName: 'ID',
				maxWidth: 50,
			},
			{
				field: getFieldNamefromDataPath(P.Bewerbung.schema.attributes.Projekttitel.path),
				headerName: 'Antragstitel',
				flex: 1,
			},
			{
				field: getFieldNamefromDataPath(P.Betreff.path),
				headerName: getLabelFromDataPath(P.Betreff.path),
				flex: 1,
			},

			{
				field: 'Erstellt_am',
				headerName: 'Erstellt am',
				flex: 1,
				maxWidth: 170,
			},
		];

		if (get(ordnerValue, 'ordner') === NACHRICHT_ORDNER.Posteingang) {
			column.push({
				field: 'read',
				headerName: 'Gelesen',
				sortable: false,
				width: 90,
				disableColumnMenu: true,
				renderCell: (params: any) => {
					return params.row.Allgemein_gelesen ? <DraftsOutlinedIcon fontSize='small' /> : <MarkunreadIcon fontSize='small' />;
				},
			});
		}

		return column;
	}, [ordnerValue]);

	if (error) console.error(' error', error);
	const formSTRUC = [
		{
			fields: [
				{
					label: 'Ordner',
					path: 'ordner',
					type: 'singleSelect',
					options: [
						{ label: 'Posteingang', value: 'Posteingang' },
						{ label: 'Postausgang', value: 'Postausgang' },
					],
					sx: {
						width: '13.75rem',
						mt: 'unset',
						border: '0.063rem solid #fff',
						backgroundColor: '#eee',
						borderRadius: '0.25rem',
						'& .MuiOutlinedInput-root': {
							backgroundColor: '#eee !important',
						},
						'& .MuiOutlinedInput-root fieldset': {
							border: 'none',
						},
						'& .MuiOutlinedInput-input': {
							padding: '0.72rem 0.88rem',
						},
						'& .MuiFormLabel-root': { display: 'none' },
					},
				},
			],
		},
	];

	function CustomToolbar() {
		return (
			<>
				<GridToolbarContainer>
					<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ backgroundColor: '#eee', padding: '0 0.63rem' }}>
						<Button
							sx={{
								backgroundColor: '#0076BD !important',
								color: '#fff !important',
								padding: '0.44rem 1rem !important',
								borderRadius: '0.25rem',
							}}
							onClick={() => setShowCreatenachricht(true)}
							endIcon={
								<SendIcon
									sx={{
										color: '#fff !important',
									}}
								/>
							}
						>
							Neue Nachricht
						</Button>
						<CreateForm
							formStruc={formSTRUC}
							formValues={ordnerValue}
							updateFormValuesFunc={(_path: string, value: any) => setOrdnerValue({ ordner: value })}
						/>
					</Stack>
					<GridToolbarFilterButton
						slotProps={{
							button: {
								startIcon: (
									<FilterAltIcon
										sx={{
											fontSize: '2rem  !important',
										}}
									/>
								),
								sx: {
									padding: 0,
									minWidth: 0,
									'& .MuiButton-startIcon': { margin: ' 0' },
								},
							},
						}}
					/>
					{null}
				</GridToolbarContainer>
				<Box sx={{ height: '50px', width: '100%' }}></Box> {/* Empty spacer */}
			</>
		);
	}
	return (
		<>
			<CreateNachricht
				showNachrichtDialog={showCreateNachricht}
				handleCloseDialog={() => setShowCreatenachricht(false)}
				applications={applications as any[]}
			/>
			<Container>
				<Stack>
					<Typography variant='h3' component='h2'>
						Nachrichten
					</Typography>
					<Typography variant='body2' margin={'20px 0'}>
						Hier können Sie direkt mit der ADK zu Ihrem laufenden Projekt kommunizieren und Ihre bisherigen Nachrichten einsehen.
					</Typography>
				</Stack>
				<Box sx={{ mt: '2rem' }}>
					<DataGridPremium
						sx={{ '& .MuiDataGrid-footerContainer': { minHeight: '4.06rem' } }}
						autoHeight
						loading={loading}
						rows={rows}
						columns={columns}
						onRowClick={(param) => navigate(`${PAGES_URLS.NachrichtenDetails}`, { state: { id: param.id } })}
						pinnedColumns={{ right: ['read'] }}
						getRowClassName={(params) =>
							`${params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'} message-read-status--${
								params.row.gelesen
							}--message-folder-${params.row.Ordner}`
						}
						disableColumnMenu
						pagination
						pageSizeOptions={[5, 10, 25, 50, 100]}
						initialState={{
							pagination: {
								paginationModel: { pageSize: 10, page: 0 },
							},
						}}
						localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
						slots={{ toolbar: CustomToolbar }}
					/>
				</Box>
			</Container>
		</>
	);
}

export default MeineNachrichten;
