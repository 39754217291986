import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';

const ApplicationHeading: FC<{ title: string; description?: string }> = ({ title, description }) => {
	return (
		<Box sx={{ mt: '1rem', mb: '1rem' }}>
			<Typography variant="h3" component="h1">
				{title}
			</Typography>

			{description && (
				<Typography variant="body2" component="h2">
					{description}
				</Typography>
			)}
		</Box>
	);
};
export default ApplicationHeading;
