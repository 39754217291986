import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Button, FormHelperText, Link, Menu, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { cloneDeep, get, unset } from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { set } from 'lodash';
import { getLocalstorageToken } from '../../../context/AuthHelper';
import { PostRecord } from '../../../lib/api/PostRecord';
import { APIS } from '../../../lib/constants/apis';
import { NOT_FOUND_ERROR_MESSAGE } from '../../../lib/constants/constant';
import { enumT, isTrue } from '../../../utils/helper';
import CreateForm from '../form-component/create-form/CreateForm';
import LoadingBackdrop from '../loading/LoadingBackdrop';
import MuiSnackbar from '../mui-snackbar/MuiSnackbar';
import NachrichtFieldsValidator from './NachrichtFieldsValidator';

// import schemas
import Schema from '../../../lib/paths/antrag_FULL_Paths.json';
let AP = Schema.attributes;

interface NachrichtFieldsProps {
	handleCloseDialog: () => void;
	applications: any[];
	setSendNachrichtSuccess: (value: boolean) => void;
	nachrichtId?: number;
	isReply?: boolean;
	originalTitle?: string;
	originalContent?: string;
}
const NachrichtFields: FC<NachrichtFieldsProps> = ({
	handleCloseDialog,
	applications,
	setSendNachrichtSuccess,
	nachrichtId,
	isReply,
	originalTitle,
	originalContent,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedApplication, setSelectedApplication] = useState<any>({});
	const [formValues, setFormValues] = useState<Record<string, any>>({});
	const [formErros, setFormErros] = useState<Record<string, any>>({});
	const [wsErrorMsg, setWsErrorMsg] = useState<string>('');

	const applicationLists = useMemo(() => {
		if (applications.length > 0) return applications;
		else return [];
	}, [applications]);

	const handleInputChange = (path: string, value: any) => {
		setFormValues((prevValue) => ({
			...prevValue,
			[path]: value,
		}));
		let errorObject = cloneDeep(formErros);
		unset(errorObject, path);
		setFormErros(errorObject);
	};

	const validateFormValues = () => {
		console.log('formValues', formValues);
		let errorObj = {};
		let errs = NachrichtFieldsValidator.validate(formValues);
		console.log('errs', errs);
		for (let error of errs) {
			set(errorObj, error.path, get(error, 'message', ''));
		}
		setFormErros(errorObj);
		return Object.keys(errorObj).length === 0;
	};

	const handleSendNachricht = async () => {
		if (validateFormValues()) {
			const data = {
				data: {
					...formValues,
					Ordner: 'Postausgang',
					Organisation: get(getLocalstorageToken(), 'organisationId'),
					Erstellt_Amum: new Date(),
					Urspruengliche_Nachricht: isTrue(isReply) ? nachrichtId : null,
				},
			};
			console.log('data', data);
			console.log('data', formValues);
			if (isReply) set(data, 'data.UrspruenglicheNachricht', nachrichtId);
			setLoading(true);
			console.log('data', data);
			try {
				const res = await PostRecord(APIS['Portal-Nachricht'], data);
				let pushNotificationdata = { data: { entities: [{ api: 'nachrichts', id: get(res, 'data.id') }] } };
				await PostRecord(APIS.PushNotificaiton, pushNotificationdata);
				if (res) setSendNachrichtSuccess(true);
			} catch (err: any) {
				console.error('ERROR Saving Nachrichten ', err);
				setWsErrorMsg(err.message);
			} finally {
				handleCloseDialog();
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		if (applicationLists.length) {
			if (applicationLists.length === 1) {
				setSelectedApplication(applicationLists[0]);
			}
			// Setting Initial Values
			setFormValues(() => ({
				Bewerbung: applicationLists.length === 1 ? get(applicationLists[0], 'id') : null,
				Betreff: isReply ? `Re: ${originalTitle}` : '',
				Inhalt:
					originalContent ?
						`${'\n\n-------------------------------------- Ursprüngliche Nachricht --------------------------------------\n' + originalContent}`
					:	'',
			}));
		}
		setLoading(false);
	}, [applicationLists, isReply, originalContent, originalTitle]);

	if (loading) return <LoadingBackdrop />;
	return (
		<>
			<MuiSnackbar
				showSnackbar={Boolean(wsErrorMsg)}
				closeSnackbar={() => setWsErrorMsg('')}
				alertMessage={wsErrorMsg || NOT_FOUND_ERROR_MESSAGE}
				error={true}
			/>
			<Stack spacing={3}>
				<Box>
					<Link
						onClick={(e) => setAnchorEl(e.currentTarget)}
						sx={{
							cursor: 'pointer',
							display: 'inline-flex',
							alignContent: 'center',
							justifyContent: 'space-between',
							minWidth: '12.82rem',
							borderBottom: `${get(formErros, 'Bewerbung', false) ? '2px solid #d32f2f' : '2px solid'}`,
							color: `${get(formErros, 'Bewerbung', false) ? '2px solid #d32f2f' : null}`,
						}}
					>
						An: An: {get(selectedApplication, AP.Projekttitel.path, 'ausstehend')}
						{/* {get(selectedApplication, AP.Allgemein_Name_Der_Bewerbung.path) ?
							`${`[${get(selectedApplication, 'AP.Eingangsnummer.path', 'ausstehend')}] ${get(selectedApplication, AP.Allgemein_Name_Der_Bewerbung.path)}`}`
						:	''} */}
						{anchorEl ?
							<KeyboardArrowUp />
						:	<KeyboardArrowDown />}
					</Link>
					{get(formErros, 'Bewerbung', false) && <FormHelperText error>{get(formErros, 'Bewerbung')}</FormHelperText>}

					<DropdownListApplication {...{ applicationLists, setSelectedApplication, handleInputChange, anchorEl, setAnchorEl }} />
				</Box>

				<CreateForm
					formStruc={[
						{
							fields: [{ path: 'Betreff' }, { type: 'text', path: 'Inhalt', placeholder: 'Schreiben Sie hier Ihre Nachricht' }],
							userProps: { direction: 'column', columnToDivide: 1 },
						},
					]}
					formValues={formValues}
					updateFormValuesFunc={handleInputChange}
					formErrors={formErros}
					api={APIS['Portal-Nachricht']}
				/>
				<Stack direction={'row'} justifyContent={'space-between'}>
					<Button variant={'outlined'} onClick={handleCloseDialog}>
						{' '}
						Abbrechen
					</Button>
					<Button onClick={handleSendNachricht}>Abschicken</Button>
				</Stack>
			</Stack>
		</>
	);
};
export default NachrichtFields;

interface DropdownListApplicationProps {
	applicationLists: any[];
	setSelectedApplication: (app: Record<string, any>) => void;
	handleInputChange: (path: string, value: any) => void;
	anchorEl: HTMLElement | null;
	setAnchorEl: (anchorEl: HTMLElement | null) => void;
}
const DropdownListApplication: FC<DropdownListApplicationProps> = ({
	applicationLists,
	setSelectedApplication,
	handleInputChange,
	anchorEl,
	setAnchorEl,
}) => {
	const handleApplicationSelect = (app: Record<string, any>) => {
		setSelectedApplication(app);
		handleInputChange('Bewerbung', get(app, 'id'));
		setAnchorEl(null);
	};
	return (
		<>
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				MenuListProps={{
					'aria-labelledby': 'lock-button',
					role: 'listbox',
				}}
			>
				<TableContainer key={1}>
					<Table stickyHeader aria-label='sticky header table'>
						<TableHead>
							<TableRow>
								{/* <TableCell>Eingangsnummer</TableCell> */}
								<TableCell>Name der Bewerbung</TableCell>
								<TableCell>Status</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{applicationLists.map((ele, i) => (
								<TableRow key={i} hover onClick={() => handleApplicationSelect(ele)}>
									{/* <TableCell>{get(ele, ' AP.Eingangsnummer.path')}</TableCell> */}
									<TableCell>{get(ele, AP.Projekttitel.path)}</TableCell>
									<TableCell>{enumT(get(ele, AP.Status.path))}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Menu>
		</>
	);
};
