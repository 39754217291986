import { Box, Button, Container, Menu, MenuItem, Stack, Toolbar, styled } from '@mui/material';
import { useState } from 'react';
import { PRIVATE_PAGES, PROFILE_PAGES } from '../../../lib/constants/routes';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { get } from 'lodash';
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthenticatedDataType } from '../../../lib/types/types';
import React from 'react';
function PrivateHeader({ logout, authenticatedData }: PrivateHeaderProps) {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);

	const PrivateNavLinkStyled = styled(NavLink)(({ theme }) => ({
		padding: theme.spacing(2, 2),
		color: theme.palette.navbar.secondary.main,
		textDecoration: 'none',
		borderBottom: `2px solid transparent`,
		'&.active': {
			color: theme.palette.navbar.secondary.active,
			borderBottom: `2px solid ${theme.palette.navbar.secondary.active}`,
		},
	}));

	const handleOpenMenu = (e: any) => {
		setAnchorEl(e.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Box sx={{ width: '100%', bgcolor: 'primary.main' }}>
				<Container sx={{ mt: 0 }}>
					<Stack direction="row" justifyContent={{ xs: 'flex-end', md: 'space-between' }} alignItems={'center'}>
						<Toolbar
							sx={{
								display: { xs: 'none', md: 'flex' },
								minHeight: { xs: 'auto' },
								width: '100%',
								padding: { sx: '1.5rem', md: '0' },
							}}
						>
							{PRIVATE_PAGES.filter((ele) => ele.display === true).map((ele, i) => (
								<PrivateNavLinkStyled key={i} to={get(ele, 'url', '')}>
									{get(ele, 'name', '')}
								</PrivateNavLinkStyled>
							))}
						</Toolbar>
						<Box>
							<Button
								variant="text"
								sx={{ color: '#fff' }}
								onClick={handleOpenMenu}
								startIcon={<AccountCircleIcon />}
								endIcon={<KeyboardArrowDownIcon />}
							>
								{get(authenticatedData, 'user.username')}
							</Button>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								open={Boolean(anchorEl)}
								onClose={handleCloseMenu}
							>
								{PROFILE_PAGES.map((ele, i) => (
									<MenuItem
										key={i}
										onClick={() => {
											handleCloseMenu();
											navigate(get(ele, 'url', ''));
										}}
									>
										{ele.name}
									</MenuItem>
								))}
								<MenuItem onClick={logout}>Abmelden</MenuItem>
							</Menu>
						</Box>
					</Stack>
				</Container>
			</Box>
		</>
	);
}

interface PrivateHeaderProps {
	logout: () => void;
	authenticatedData: AuthenticatedDataType | null;
}

export default PrivateHeader;
