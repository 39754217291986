import React, { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';

const LoadingIconOnly: FC<{}> = () => {
	return (
		<>
			<Box sx={{ textAlign: 'center' }}>
				<CircularProgress />
			</Box>
		</>
	);
};
export default LoadingIconOnly;
