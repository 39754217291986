import React, { FC } from 'react';
import CreateForm from '../../../../../../components/ui-component/form-component/create-form/CreateForm';
import { useApplication } from '../../../../../../context/ApplicationContext';
import { PLATZHALTER_TEXT } from '../../../../../../lib/constants/constant';
import ApplicationHeading from '../../../application-heading/ApplicationHeading';
// import { Box } from "devextreme-react";
// import { useMediaQuery } from '@mui/material';
import { concat } from 'lodash';
import AntragSchema from '../../../../../../lib/paths/antrag_FULL_Paths.json';
import { FormStrucItem } from '../../../../../../lib/types/types';
import { NonRequired, Required, ValidateBirthdateRange } from '../../../../../../lib/validation/ValidationTypes';
const P = AntragSchema.attributes;

const Antragsteller: FC<{}> = () => {
	const { application, setApplicationProperty, readOnly } = useApplication();

	return (
		<>
			<ApplicationHeading title='Antragsteller/in' description={PLATZHALTER_TEXT} />

			<CreateForm formStruc={formStrucAllgemeinDaten()} formValues={application} updateFormValuesFunc={setApplicationProperty} readOnly={readOnly} />
		</>
	);
};
export default Antragsteller;

const formStrucAllgemeinDaten = () => {
	let struc: FormStrucItem[] = [
		{
			fields: [{ ...P.Antragsteller_Titel, validation: [{ validateFunction: NonRequired }] }, P.Antragsteller_Geschlecht],
			userProps: { columnToDivide: 1 },
		},
		{
			fields: [P.Antragsteller_Vorname, P.Antragsteller_Nachname],
			userProps: { columnToDivide: 1 },
		},

		{
			fields: [P.Antragsteller_E_Mail, P.Antragsteller_Telefon],
			userProps: { columnToDivide: 1 },
		},
		{
			fields: [
				{ ...P.Antragsteller_Geburtsdatum, validation: [{ validateFunction: Required }, { validateFunction: ValidateBirthdateRange() }] },

				P.Antragsteller_Staatsbuergerschaft,
			],

			userProps: { columnToDivide: 1 },
		},

		{
			fields: [P.Antragsteller_Strasse_Und_Hausnummer, P.Antragsteller_PLZ],
			userProps: { columnToDivide: 1 },
		},
		{
			fields: [P.Antragsteller_Ort, P.Antragsteller_Bundesland],
			userProps: { columnToDivide: 1 },
		},
	];
	return struc;
};

export const getFormStrucAntragsteller = () => {
	return concat(formStrucAllgemeinDaten());
};
