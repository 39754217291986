import { get } from 'lodash';
import { AuthenticatedDataType } from '../lib/types/types';
import { isValidBase64, isValidJSON } from '../utils/helper';

const isUserLoggedIn = (): boolean => {
	let tokenObj = getLocalstorageToken();
	return tokenObj !== null ? true : false;

	// return localStorage.getItem('adk-token') ? true : false;
};
const getLocalstorageToken = (): AuthenticatedDataType | null => {
	let tokenEncoded = localStorage.getItem('adk-token');

	try {
		if (tokenEncoded && isValidBase64(tokenEncoded)) {
			let decodedToken = atob(tokenEncoded);
			if (isValidJSON(decodedToken)) {
				let tokenObj = JSON.parse(decodedToken);
				return tokenObj;
			} else {
				console.error('ERROR: Decoded token is not valid JSON');
				return null;
			}
		} else {
			console.error('ERROR: Token is not valid Base64');
			return null;
		}
	} catch (err: any) {
		console.error('ERROR: Validating token', err);
		return null;
	}
};
const getUserJwt = (): string => {
	let tokenObj = getLocalstorageToken();
	return tokenObj !== null ? get(tokenObj, 'jwt', '') : '';
};

export { getLocalstorageToken, getUserJwt, isUserLoggedIn };
