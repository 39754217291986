import { concat, get } from 'lodash';
import { Field, FormStrucItem } from '../lib/types/types';
import { isAttributesPath } from '../lib/validation/ApplicationValidator';

export function getLengthOfNumberBeforeAttributes(input: string): number {
	// Regular expression to match the number before "attributes"
	const regex = /(\d+)\.attributes/;
	const match = input.match(regex);

	if (match && match[1]) {
		return match[1].length;
	} else {
		return 0;
	}
}

// path mapping for editableTable
export function mapTablePath(childWithoutIndex: string, index: number, parentPath: string): string {
	let childWithoutIndexArray = childWithoutIndex.split('.');
	const path = parentPath + `.${index}.` + childWithoutIndexArray[childWithoutIndexArray.length - 1];

	return path;
}

/**
 * mapping for component tables with custom formstruc to set specific validations
 * when to use:
 * 		- customFormStruc has no dynamic input
 * 		- validation does not have param-paths that need to be mapped dynamically
 * 				- e.g: validation: [{validateFunction:GetMaxIntValidation, params: [path_to_reference_value]}]
 * 				- where path is in a table and need to be mapped with index
 * else: create custom inputs with
 * 		- mapindexbyPath, mapIndexAndParamsBySameParentPath etc.
 * @param application 		contains application data
 * @param customFormStruc 	complete componentTable sub fields als FormStrucItem[]
 * @param parentObject 		componentTable parentObject for indexing
 * @returns 				FormStrucItem[]
 */
export function mapCustomFormStrucIndex(application: any, customFormStruc: FormStrucItem[], parentObject: any): FormStrucItem[] {
	let result: any = [];
	const componentTableLength = get(application, parentObject.path, []).length;
	//map index for table
	for (let i = 0; i < componentTableLength; i++) {
		mapindexbyPath(customFormStruc, i, parentObject).forEach((element: any) => {
			result.push(element);
		});
	}
	return result;
}

/**
 * used to index the fields in componentTable with a customFormStruc to create specific validations for the fields
 * when to use:
 * 		- customFormStruc has dynamic input (can already be pre computed)
 *		- validation does not have param-paths that need to be mapped dynamically
 * 				- e.g: validation: [{validateFunction:GetMaxIntValidation, params: [path_to_reference_value]}]
 * 				- where path is in a table and need to be mapped with index
 * 				- else use: mapIndexAndParamsBySameParentPath
 * @param formStruc 	customFormStruc with all child fields from parentobject as FormStrucItem[]
 * @param index 		loop table index
 * @param parentobject 	componentTable parentObject for indexing
 * @returns 			FormStrucItem[]
 */
export function mapindexbyPath(formStruc: any[], index: number, parentobject: any): FormStrucItem[] {
	let parentObjectPath = get(parentobject, 'path', '') as string;
	let indexLength = getLengthOfNumberBeforeAttributes(parentObjectPath);
	indexLength = indexLength === 0 ? 0 : indexLength + 1;

	let result: any = formStruc.map((row) => {
		return {
			...row,
			fields: (get(row, 'fields', []) as Array<any>).map((value) => {
				let childWithoutIndex = get(value, 'path', '') as string;

				return {
					...value,
					path: parentObjectPath.slice(0, parentobject.path.length) + `.${index}` + childWithoutIndex.slice(parentobject.path.length - indexLength),
				};
			}),
		};
	});

	return result;
}

export function mapIndexOnlybyPath(index: number, parentobject: any, childObject: any) {
	let parentObjectPath = get(parentobject, 'path', '') as string;
	let childWithoutIndexPath = get(childObject, 'path', '') as string;

	let indexLength = getLengthOfNumberBeforeAttributes(parentObjectPath);
	indexLength = indexLength === 0 ? 0 : indexLength + 1;

	return {
		...childObject,
		path: parentObjectPath.slice(0, parentobject.path.length) + `.${index}` + childWithoutIndexPath.slice(parentobject.path.length - indexLength),
	};
}

/**
 * should map index for each array entry and map params path of validation if validation has params with paths which should be evaluated
 * e.g.:
 * {..., params: [P.Zeitplanung_Zeitplanung_Block_1.schema.attributes.Zelle_4.path, 10]}
 */
export function mapIndexAndParamsBySameParentPath(formStruc: any[], index: number, parentobject: any): FormStrucItem[] {
	let parentObjectPath = get(parentobject, 'path', '') as string;
	let indexLength = getLengthOfNumberBeforeAttributes(parentObjectPath);
	indexLength = indexLength === 0 ? 0 : indexLength + 1;

	let result: any = formStruc.map((row) => {
		return {
			...row,
			fields: (get(row, 'fields', []) as Array<Field>).map((value) => {
				let childWithoutIndex = get(value, 'path', '') as string;

				return {
					...value,
					path: parentObjectPath.slice(0, parentobject.path.length) + `.${index}` + childWithoutIndex.slice(parentobject.path.length - indexLength),
					validation: value.validation?.map((validationItem) => {
						const { params } = validationItem;
						const modifiedParams = params?.map((param: any) => {
							// console.log('PArams param', param);
							// console.log('PArams isAttributesPath', isAttributesPath(param));
							if (isAttributesPath(param)) {
								const paramPath =
									parentObjectPath.slice(0, parentobject.path.length) + `.${index}` + param.slice(parentobject.path.length - indexLength);
								return paramPath;
							}
							return param;
						});
						return params ? { ...validationItem, params: modifiedParams } : { ...validationItem };
					}),
				};
			}),
		};
	});

	return result;
}

function getComponentTableFields(componentTable: any): FormStrucItem[] | {}[] {
	let result = [];
	let disabledColumns = componentTable.disabledColumns;
	let attributes = componentTable.schema?.attributes;
	if (attributes) {
		// console.log('getFormStrucStundenNachweis', componentTable);
		// console.log('getFormStrucStundenNachweis', attributes);
		let fieldsArray: Field[] = [];
		Object.entries(attributes).forEach(([key, attributeItem]) => {
			// console.log('getFormStrucStundenNachweis attributeItem', attributeItem);
			if (attributeItem && !disabledColumns?.includes(key)) fieldsArray.push(attributeItem as Field);
		});
		result.push({ fields: fieldsArray });
	}
	return result;
}

export function getFormStrucComponentTable(application: any, componentTableStruc: FormStrucItem[]): FormStrucItem[] {
	const componentTable = getComponentTableByFormStruc(componentTableStruc);

	let componentTableFormStrucResult: FormStrucItem[] = [];
	componentTable.forEach((cT) => {
		const componentTableLength = get(application, cT.path, []).length;
		let result: any = [];
		for (let i = 0; i < componentTableLength; i++) {
			const componentTableFormStruc = getComponentTableFields({
				...cT,
			});
			mapindexbyPath(componentTableFormStruc, i, cT).forEach((element: any) => {
				result.push(element);
			});
		}
		componentTableFormStrucResult = concat(componentTableFormStrucResult, result);
	});

	return componentTableFormStrucResult;
}

export function getComponentTableByFormStruc(componentTableStruc: FormStrucItem[]) {
	let result: any[] = [];
	componentTableStruc.forEach((formStrucItem) =>
		formStrucItem.fields.forEach((field) => {
			if (field.type === 'componentTable' || field.type === 'editableTable') {
				result.push(field);
			}
		}),
	);

	return result;
}
export function getFormStrucWithoutComponentTable(componentTableStruc: FormStrucItem[]) {
	let result: any[] = [{ fields: [] }];
	componentTableStruc.forEach((formStrucItem) =>
		formStrucItem.fields.forEach((field) => {
			if (field.type !== 'componentTable') {
				result[0].fields.push(field);
			}
		}),
	);

	return result;
}

// export type StorageTreeFlag = {
// 	id: string;
// 	hasBeenVisited: boolean;
// 	showError: boolean;
// };
// export const replaceTreeNavigationFlags = (treeStructure: TreeNodeType[], treeFlags: StorageTreeFlag[]): TreeNodeType[] => {
// 	return treeStructure.map((treeItem) => {
// 		const treeFlag = treeFlags.find((item) => item.id === treeItem.id);
// 		if (treeItem.children) {
// 			const childrenTree = replaceTreeNavigationFlags(treeItem.children, treeFlags);
// 			return {
// 				...treeItem,
// 				children: childrenTree,
// 				showError: treeFlag?.showError ? treeFlag.showError : treeItem.showError ? treeItem.showError : false,
// 				hasBeenVisited: treeFlag?.hasBeenVisited
// 					? treeFlag.hasBeenVisited
// 					: treeItem.hasBeenVisited
// 						? treeItem.hasBeenVisited
// 						: false,
// 			};
// 		} else {
// 			return {
// 				...treeItem,
// 				showError: treeFlag?.showError ? treeFlag.showError : treeItem.showError ? treeItem.showError : false,
// 				hasBeenVisited: treeFlag?.hasBeenVisited
// 					? treeFlag.hasBeenVisited
// 					: treeItem.hasBeenVisited
// 						? treeItem.hasBeenVisited
// 						: false,
// 			};
// 		}
// 	});
// };

// const getStorageValuesFromStructure = (treeStructure: TreeNodeType[]): {}[] => {
// 	let result: {}[] = [];
// 	treeStructure.forEach((item) => {
// 		result.push({
// 			id: item.id,
// 			hasBeenVisited: item.hasBeenVisited ? item.hasBeenVisited : false,
// 			showError: item.showError ? item.showError : false,
// 		});
// 		if (item.children) {
// 			const childResult = getStorageValuesFromStructure(item.children);
// 			result = concat(result, childResult);
// 		}
// 	});
// 	return result;
// };
