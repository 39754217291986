import Schema from 'validate';
import { isStrongPassword } from 'validator';
import isEmail from 'validator/lib/isEmail';

import UserSchema from '../../../../lib/paths/user_Paths.json';
const P = UserSchema.attributes;

const mailValidator = (val: string) => isEmail(val);
const passwordValidator = (val: string) => isStrongPassword(val);
const usernameValidator = (val: string) => /^[^\s]+$/.test(val);

const LoginDatenValidator = new Schema(
	{
		[P.username.path]: {
			type: String,
			required: true,
			use: { usernameValidator },
			message: {
				usernameValidator: 'Der Benutzername darf keine Leerzeichen enthalten.',
			},
		},
		[P.email.path]: {
			type: String,
			required: true,
			use: { mailValidator },
			message: {
				mailValidator: 'Ungültige E-Mail-Adresse.',
			},
		},
		[P.password.path]: {
			type: String,
			required: true,
			use: { passwordValidator },
			message: {
				passwordValidator:
					'Ihr Passwort sollte 8 Zeichen lang sein und mindestens ein Symbol, einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten.',
			},
		},
		[P.repeatPassword.path]: {
			type: String,
			required: true,
		},
	},
	{ strip: false }
);

LoginDatenValidator.message({
	required: (_path: string) => 'Dies ist ein Pflichfeld',
	type: (path: string) => `Das Formular ist fehlerhaft, bitte melden Sie diesen Felder. Geben Sie als Grund "falscher Datantyp, ${path}" an`,
});

export default LoginDatenValidator;
