import { ArrowBack } from '@mui/icons-material';
import { Button, Container, Paper, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

const NoDetailsInfo: FC<{ title: string; goBackUrl: string }> = ({ title, goBackUrl }) => {
	const navigate = useNavigate();
	return (
		<Container>
			<Paper sx={{ padding: '2rem', textAlign: 'center' }}>
				<Typography>Sie haben kein {title} ausgewählt. </Typography>
				<Button startIcon={<ArrowBack />} onClick={() => navigate(`/${goBackUrl}`)} sx={{ mt: '1rem' }}>
					{' '}
					Zurück zur Auswahl
				</Button>
			</Paper>
		</Container>
	);
};

export default NoDetailsInfo;
