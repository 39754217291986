import React, { FC, useState } from 'react';
import CreateForm from '../../../../../components/ui-component/form-component/create-form/CreateForm';
import { set, unset, get, cloneDeep } from 'lodash';
import validator from 'validator';
import { Button, Stack } from '@mui/material';
import LoadingBackdrop from '../../../../../components/ui-component/loading/LoadingBackdrop';
import MuiSnackbar from '../../../../../components/ui-component/mui-snackbar/MuiSnackbar';
import { ERROR_MESSAGE, NOT_FOUND_ERROR_MESSAGE } from '../../../../../lib/constants/constant';
import { ChangeUserPassword } from '../../../../../lib/api/AuthUser';

const formStruc = [
	{ fields: [{ fieldType: 'password', path: 'currentPassword', label: 'Altes Passwort' }] },
	{
		fields: [
			{ fieldType: 'password', path: 'password', label: 'Neues Passwort' },
			{ fieldType: 'password', path: 'passwordConfirmation', label: 'Neues Passwort wiederholen' },
		],
	},
];
interface PasswordFormProps {
	setShowPasswordForm: (show: boolean) => void;
	setPasswordUpdateSuccess: (show: boolean | null) => void;
}
const PasswordForm: FC<PasswordFormProps> = ({ setShowPasswordForm, setPasswordUpdateSuccess }) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [wsError, setWsError] = useState<boolean>(false);
	const [wsErrorMsg, setWsErrorMsg] = useState<string>('');
	const [formValues, setFormValues] = useState<Record<string, any>>({});
	const [formErros, setFormErrors] = useState<Record<string, any>>({});

	const handleInputChange = (path: string, value: any) => {
		let formValueCopy = cloneDeep(formValues);
		set(formValueCopy, path, value);
		let errorObject = cloneDeep(formErros);
		unset(errorObject, path);

		setFormValues(formValueCopy);
		setFormErrors(errorObject);
		setWsError(false);
	};
	const validatePasswords = () => {
		let success = false;
		if (get(formValues, 'currentPassword', '') === '') {
			setFormErrors({ currentPassword: 'Passwort darf nicht leer sein.' });
		} else if (get(formValues, 'password', '') === '' || get(formValues, 'passwordConfirmation') === '') {
			setFormErrors({ password: 'Passwort darf nicht leer sein', passwordConfirmation: 'Passwort darf nicht leer sein' });
		} else if (get(formValues, 'password', '') !== get(formValues, 'passwordConfirmation')) {
			setFormErrors({ passwordConfirmation: 'Beide Passwörter stimmen nicht überein.' });
		} else if (
			!validator.isStrongPassword(get(formValues, 'password', ''), {
				minLength: 8,
				minLowercase: 1,
				minUppercase: 1,
				minNumbers: 1,
				minSymbols: 1,
			})
		) {
			setFormErrors({
				password:
					'Ihr Passwort sollte 8 Zeichen lang sein und mindestens ein Symbol, einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten.',
			});
		} else {
			success = true;
		}
		return success;
	};

	const handleUpdatePassword = async () => {
		setFormErrors({});
		if (validatePasswords()) {
			setLoading(true);
			try {
				let res = await ChangeUserPassword(formValues);
				if (res) {
					setPasswordUpdateSuccess(true);
					setShowPasswordForm(false);
				}
			} catch (err) {
				console.error('ERRO Updating password ', err);
				let errorMsg = JSON.parse(get(err, 'message', '{}'));
				setWsError(true);
				setWsErrorMsg(get(ERROR_MESSAGE, get(errorMsg, 'error.message', ''), NOT_FOUND_ERROR_MESSAGE));
			} finally {
				setLoading(false);
			}
		}
	};

	if (loading) return <LoadingBackdrop />;
	return (
		<>
			<MuiSnackbar
				showSnackbar={wsError}
				closeSnackbar={() => {
					setWsError(false);
				}}
				alertMessage={wsError ? wsErrorMsg : NOT_FOUND_ERROR_MESSAGE}
				error={wsError}
			/>

			<CreateForm
				formStruc={formStruc}
				formValues={formValues}
				formErrors={formErros}
				updateFormValuesFunc={handleInputChange}
			/>

			<Stack direction={'row'} justifyContent={'space-between'} sx={{ mt: '2rem' }}>
				<Button variant="outlined" onClick={() => setShowPasswordForm(false)}>
					Abbrechen
				</Button>
				<Button onClick={handleUpdatePassword}>Aktualisieren</Button>
			</Stack>
		</>
	);
};
export default PasswordForm;
