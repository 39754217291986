import { Typography } from '@mui/material';
import { get } from 'lodash';
import React, { FC } from 'react';
import SummaryLine from '../../../../../../components/ui-component/form-component/abschluss-page/SummaryLine';
import { useApplication } from '../../../../../../context/ApplicationContext';
import { PLATZHALTER_TEXT } from '../../../../../../lib/constants/constant';
import { DisplayDate } from '../../../../../../utils/formats';
import ApplicationHeading from '../../../application-heading/ApplicationHeading';

// import schemas
import AntragSchema from '../../../../../../lib/paths/antrag_FULL_Paths.json';
const P = AntragSchema.attributes;

const Abschluss: FC<{}> = () => {
	const { application } = useApplication();
	return (
		<>
			<ApplicationHeading title='Abschluss' description={PLATZHALTER_TEXT} />

			<Typography> Projektdaten</Typography>
			<SummaryLine
				label='Datum der Einreichung'
				value={
					get(application, P.Allgemein_Eingereicht_Am.path) ?
						DisplayDate(get(application, P.Allgemein_Eingereicht_Am.path))
					:	'noch nicht eingereicht'
				}
			/>
			<SummaryLine label='Projekttitel:' value={get(application, P.Projekttitel.path)} />
			<SummaryLine label='geplantes Projekt:' value={get(application, P.Bewerbung_geplantes_Projekt.path)} />
			<SummaryLine
				label='Wunschzeitraum von:'
				value={
					get(application, P.Bewerbung_Wunschzeitraum_Fuer_Stipendium_Von.path) ?
						DisplayDate(get(application, P.Bewerbung_Wunschzeitraum_Fuer_Stipendium_Von.path))
					:	'-'
				}
			/>
			<SummaryLine
				label='Wunschzeitraum Bis:'
				value={
					get(application, P.Bewerbung_Wunschzeitraum_Fuer_Stipendium_Bis.path) ?
						DisplayDate(get(application, P.Bewerbung_Wunschzeitraum_Fuer_Stipendium_Bis.path))
					:	'-'
				}
			/>
			<SummaryLine label='Beginn Der Taetigkeit:' value={get(application, P.Bewerbung_Beginn_Der_Kuenstlerischen_Taetigkeit.path, '-')} />
		</>
	);
};

export default Abschluss;
