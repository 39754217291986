import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Container, Divider, Link, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingBackdrop from '../../../../components/ui-component/loading/LoadingBackdrop';
import CreateNachricht from '../../../../components/ui-component/nachrichten/CreateNachricht';
import NoDetailsInfo from '../../../../components/ui-component/no-details-info/NoDetailsInfo';
import { useFetchNachrichtenDetails } from '../../../../hooks/FetchNachrichten';
import { APIS } from '../../../../lib/constants/apis';
import { PAGES_URLS } from '../../../../lib/constants/routes';

// import schemas
import NachrichtSchema from '../../../../lib/paths/nachricht_FULL_Paths.json';
let P = NachrichtSchema.attributes;

const NachrichtenDetails: FC<{}> = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const numericId = get(location, 'state.id');

	const { loading, data, error } = useFetchNachrichtenDetails(numericId);
	const [openMessage, setOpenMessage] = useState(false);

	const RenderAppLink: FC<{}> = () => {
		return (
			<Typography>
				Förderkennzeichen:{' '}
				<Link
					sx={{ cursor: 'pointer', textDecoration: 'unterlinde' }}
					onClick={() => {
						navigate(`${PAGES_URLS.OnlineAntrag}`, {
							state: {
								id: get(data, `${P.Bewerbung.path}.id`),
								api: APIS.Antrag,
							},
						});
					}}
				>
					{/* {get(data, 'P.Bewerbung.schema.attributes.Eingangsnummer.path', '')} */}
					{' - '}
					{get(data, P.Bewerbung.schema.attributes.Projekttitel.path, '')}
				</Link>
			</Typography>
		);
	};

	if (!numericId) return <NoDetailsInfo title='Nachricht' goBackUrl={PAGES_URLS.Nachrichten} />;
	if (error) console.error(' error', error);
	if (loading) return <LoadingBackdrop />;
	console.log('data', get(data, P.Organisation.schema.attributes.Name_Der_Organisation.path));
	return (
		<>
			<Container>
				<CreateNachricht
					showNachrichtDialog={openMessage}
					handleCloseDialog={() => setOpenMessage(false)}
					applications={[get(data, P.Bewerbung.path)]}
					isReply={true}
					nachrichtId={get(data, 'id')}
					originalTitle={get(data, P.Betreff.path)}
					originalContent={get(data, P.Inhalt.path)}
				/>
				<Stack spacing={3}>
					<Stack direction={'row'} justifyContent={'space-between'}>
						<Button variant={'outlined'} startIcon={<ArrowBack />} onClick={() => navigate(PAGES_URLS.Nachrichten)}>
							{' '}
							Zurück zu meinen Nachrichten
						</Button>
						{get(data, P.Ordner.path) === 'Posteingang' && <Button onClick={() => setOpenMessage(true)}>Antworten</Button>}
					</Stack>
					<Stack spacing={3}>
						{' '}
						<Typography variant='h3' component='h2'>
							{'Betreff: ' + get(data, P.Betreff.path)}
						</Typography>
						<Box>
							<Typography>Von: Fördermittelgeber </Typography>

							{/* {get(data, P.Ordner.path) === 'Posteingang' ?
								<Typography>Von: Fördermittelgeber </Typography>
							:	<Typography>Von: {get(data, P.Organisation.schema.attributes.Name_Der_Organisation.path)} </Typography>} */}

							<RenderAppLink />
						</Box>
						<Divider />
						<Typography component={'div'}>
							<div dangerouslySetInnerHTML={{ __html: get(data, P.Inhalt.path) }}></div>
						</Typography>
					</Stack>
				</Stack>
			</Container>
		</>
	);
};
export default NachrichtenDetails;
