import { get } from 'lodash';
import { useEffect, useState } from 'react';
import GetRecord from '../lib/api/GetRecord';
import GetRecords from '../lib/api/GetRecords';
import POP_LEVEL from '../lib/api/Populations.json';
import { APIS } from '../lib/constants/apis';
import { UseFetchStatesType } from '../lib/types/types';
import { removeDataKeys, removeNullDeep } from '../utils/helper';

function useFetchFoerderProgramm(): UseFetchStatesType {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<any[] | []>([]);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		// Fetch data
		const fetchData = async () => {
			try {
				// const filter = { Allgemein_Aktiv: true };
				const res = await GetRecords(APIS.ausschreibungs, POP_LEVEL.ausschreibung.DETAILS_SEITE);

				const result: any[] = removeNullDeep(removeDataKeys(get(res, 'data', [])));
				setData(result);
			} catch (err: any) {
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	return { loading, data, error };
}

function useFetchFoerderProgrammDetail(id: number): UseFetchStatesType {
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<any[]>([]);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		// Fetch data
		const fetchData = async () => {
			try {
				const res = await GetRecord(APIS.ausschreibungs, id, POP_LEVEL.ausschreibung.FULL);

				const result: any[] = removeNullDeep(removeDataKeys(get(res, 'data', [])));
				setData(result);
			} catch (err: any) {
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};
		if (id) fetchData();
	}, [id]);

	return { loading, data, error };
}

export { useFetchFoerderProgramm, useFetchFoerderProgrammDetail };
