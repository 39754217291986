import { get } from 'lodash';
import React from 'react';
import { CreateFormProps } from '../../../../lib/types/types';
import FieldGroup from '../form-fields/FieldGroup';
import FormFields from '../form-fields/FormFields';

interface FieldProp {
	type: string;
	path: string;
	[key: string]: any; // Allows additional props to be passed
}

interface FieldGroupProp {
	userProps?: string;
	fields?: FieldProp[];
}

const CreateForm: React.FC<CreateFormProps> = (props) => {
	const { formStruc, formValues, formErrors, updateFormValuesFunc, readOnly } = props;

	const formComponents = (
		<>
			{(formStruc || []).map((eachFieldRow: FieldGroupProp, i: number) => (
				<FieldGroup key={i} userProps={get(eachFieldRow, 'userProps', '')} eachFieldProps={eachFieldRow}>
					{get(eachFieldRow, 'fields', []).map((fieldProp: FieldProp, index: number) => (
						<FormFields
							key={index}
							formValues={formValues}
							formErrors={formErrors}
							onChange={updateFormValuesFunc}
							readOnly={readOnly ? readOnly : false}
							api={get(props, 'api', '')}
							size={get(props, 'size', 'medium')}
							{...fieldProp}
						/>
					))}
				</FieldGroup>
			))}
		</>
	);
	return formComponents;
};

export default CreateForm;
