import React, { FC } from 'react';
import { SidebarProps } from '../../../lib/types/types';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const SideBars: FC<SidebarProps> = ({ sidebars, activeSideBarButton, toggleSideBarButton }) => {
	return (
		<>
			{/*
			 * Vertical Tabs Navigation for Tablets and Desktop
			 */}
			<Box sx={{ display: { xs: 'none', md: 'flex' }, margin: '1rem 2rem 1rem 0', minWidth: '16.4rem' }}>
				<Tabs
					orientation="vertical"
					value={activeSideBarButton}
					onChange={toggleSideBarButton}
					sx={{ borderRight: 1, borderColor: 'divider', width: '100%' }}
				>
					{sidebars.map((sidebar, index) => (
						<Tab key={index} icon={sidebar.icon} iconPosition="start" label={sidebar.label} {...a11yProps(index)} />
					))}
				</Tabs>
			</Box>
			{/*
			 * Horizontal Tabs Navigation for Mobiles
			 */}
			<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
				<Tabs
					orientation="horizontal"
					variant="scrollable"
					scrollButtons="auto"
					aria-label="scrollable auto tabs example"
					value={activeSideBarButton}
					onChange={toggleSideBarButton}
					sx={{ borderBottom: 1, borderColor: 'divider' }}
				>
					{sidebars.map((sidebar, index) => (
						<Tab key={index} label={sidebar.label} {...a11yProps(index)} />
					))}
				</Tabs>
			</Box>
		</>
	);
};
export default SideBars;

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}
