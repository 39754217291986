import { METHOD_PUT } from "../constants/apis";
import { STRAPI_URL } from "../constants/url";
import { getUserJwt } from "../../context/AuthHelper";
import { getQueryString } from "./Population";

export default async function PutRecord(
	api: string,
	id: number,
	body: any,
	poplevel: any[] = [],
	filter: any[] = [],
	sort: any[] = [],
	pagination: object = {},
	fields: any = [],
) {
	let response = await fetch(`${STRAPI_URL}/api/${api}/${id}${getQueryString(poplevel, filter, sort, pagination, fields)}`, {
		method: METHOD_PUT,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getUserJwt()}`,
		},
		body: JSON.stringify(body),
	});
	if (!response.ok) {
		throw new Error(await response.text());
	}
	const responseData = await response.json();
	return responseData;
}
