import { getUserJwt } from '../../context/AuthHelper';
import { METHOD_POST } from '../constants/apis';
import { STRAPI_URL } from '../constants/url';

export async function PostRecord(api: string, body: any) {
	let response = await fetch(`${STRAPI_URL}/api/${api}`, {
		method: METHOD_POST,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getUserJwt()}`,
		},
		body: JSON.stringify(body),
	});

	if (!response.ok) {
		throw new Error(await response.text());
	}
	const responseData = await response.json();
	return responseData;
}
