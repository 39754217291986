import { createTheme, ThemeOptions } from '@mui/material';
import createComponents from './componentStyle';
import { palette } from './palette';
import { typography } from './typography';

const themeOptions: ThemeOptions = {
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
		},
	},
	palette,
	typography,
};

const theme = createTheme(themeOptions);
theme.components = createComponents(theme);

export default theme;
