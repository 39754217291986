import React, { FC } from 'react';

const AbschlussAbteilungRegistrierung: FC<{}> = () => {
	return <></>;
};

export default AbschlussAbteilungRegistrierung;

// import { ArrowBack } from "@mui/icons-material";
// import {
//   Box,
//   Button,
//   Stack,
//   Typography,
//   Step,
//   StepContent,
//   StepLabel,
//   Stepper,
//   StepConnector,
//   styled,
// } from "@mui/material";
// import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
// import { cloneDeep, get, set } from "lodash";
// import ScrollToTop from "../../layout/ScrollToTop";
// import React, { FC, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { PostRecord } from "../../../lib/api/PostRecord";
// import { APIS } from "../../../lib/constants/apis";
// import { PAGES_URLS } from "../../../lib/constants/routes";
// import { FormStrucItem } from "../../../lib/types/types";
// import { isTrue } from "../../../utils/helper";
// import CreateForm from "../../ui-component/form-component/create-form/CreateForm";
// import LoadingBackdrop from "../../ui-component/loading/LoadingBackdrop";
// import {
//   PersonValidator,
//   AbteilungValidator,
// } from "./AbteilungRegistrierenValidator";
// import Logo_Link from "../../../assets/images/logo/Logo_Links.svg";
// import Logo_Recht from "../../../assets/images/logo/Logo_Recht.svg";
// import AbschlussRegistrierung from "./abschluss/AbschlussAbteilungRegistrierung";
// import NachRegistrierung from "../registration/abschluss/NachRegistrierung";

// import AnfrageSchema from "../../../lib/paths/registrierungsanfrage_FULL_Paths.json";
// const RA_P = AnfrageSchema.attributes;

// const steps = [
//   {
//     label: "Personendaten",
//     innerSteps: [],
//   },
//   {
//     label: "Abteilungsdaten",
//     innerSteps: [],
//   },
//   {
//     label: "Überprüfung",
//     innerSteps: [],
//   },
// ];

// const AbteilungRegistrieren: FC<{}> = () => {
//   const navigate = useNavigate();

//   const [loading, setLoading] = useState(false);
//   const [scrollToTop, setScrollToTop] = useState(false);

//   const [personDaten, setPersonDaten] = useState({});
//   const [abteilungDaten, setAbteilungDaten] = useState({});
//   const [datenschutzZustimmung, setDatenschutzZustimmung] = useState({
//     zustimmung: false,
//   });
//   const [personDatenError, setPersonDatenError] = useState({});
//   const [abteilungDatenError, setAbteilungDatenError] = useState({});
//   //const [formValuesErros, setFormValuesError] = useState({});
//   const [wsSuccess, setWsSuccess] = useState<boolean | null>(null);
//   const [activeStep, setActiveStep] = useState(0);

//   const handleUpdatePersonDaten = (key: string, value: any) => {
//     let formValueCopy = cloneDeep(personDaten);
//     set(formValueCopy, key, value);
//     setPersonDaten(formValueCopy);
//     let errorObject = cloneDeep(personDatenError);
//     delete errorObject[key];
//     setPersonDatenError(errorObject);
//     setScrollToTop(false);
//   };

//   const handleUpdateAbteilungDaten = (key: string, value: any) => {
//     let formValueCopy = cloneDeep(abteilungDaten);
//     set(formValueCopy, key, value);
//     set(formValueCopy, RA_P.Allgemein_Eingereicht_Am.path, new Date());
//     setAbteilungDaten(formValueCopy);
//     let errorObject = cloneDeep(abteilungDatenError);
//     delete errorObject[key];
//     setAbteilungDatenError(errorObject);
//     setScrollToTop(false);
//   };

//   const handleUpdateDatenschutzZustimmung = (key: string, value: any) => {
//     let formValueCopy = cloneDeep(datenschutzZustimmung);
//     set(formValueCopy, key, value);
//     setDatenschutzZustimmung(formValueCopy);
//   };

//   const validatePersonData = () => {
//     let errorObject = {};
//     let errs = PersonValidator.validate(personDaten);
//     for (let error of errs) {
//       set(errorObject, error.path, get(error, "message"));
//     }
//     setPersonDatenError(errorObject);
//     return Object.keys(errorObject).length === 0;
//   };

//   const validateAbteilungData = () => {
//     let errorObject = {};
//     let errs = AbteilungValidator.validate(abteilungDaten);
//     for (let error of errs) {
//       set(errorObject, error.path, get(error, "message"));
//     }
//     setAbteilungDatenError(errorObject);
//     return Object.keys(errorObject).length === 0;
//   };

//   const handleFormSubmit = async () => {
//     if (validatePersonData() && validateAbteilungData()) {
//       try {
//         setLoading(true);
//         setScrollToTop(true);
//         let res = await PostRecord(APIS.RegistrierungsAnfrage, {
//           data: { ...personDaten, ...abteilungDaten },
//         });
//         if (res) {
//           setWsSuccess(true);
//           setActiveStep(3); // Move to step 3 on success
//         }
//       } catch (err: any) {
//         console.error("ERROR: Creating Registrierung Anfrage ", err);
//         setWsSuccess(false);
//         setActiveStep(3);
//       } finally {
//         setLoading(false);
//       }
//     }
//   };

//   const handleBack = () => {
//     setScrollToTop(true);
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   const handleNext = () => {
//     setScrollToTop(true);
//     switch (activeStep) {
//       case 0:
//         if (validatePersonData()) {
//           setActiveStep((prev) => prev + 1);
//         }
//         break;
//       case 1:
//         if (validateAbteilungData()) {
//           setActiveStep((prev) => prev + 1);
//         }
//         break;
//       case 2:
//         handleFormSubmit();
//         break;
//       default:
//         break;
//     }
//   };

//   useEffect(() => {
//     const unloadCallback = (event: any) => {
//       event.preventDefault();
//       event.returnValue = "";
//       return "";
//     };

//     window.addEventListener("beforeunload", unloadCallback);
//     return () => window.removeEventListener("beforeunload", unloadCallback);
//   }, []);

//   const HeaderContainer = styled(Box)(({ theme }) => ({
//     backgroundColor: theme.palette.navbar.secondary.main,
//     padding: ".7rem 1rem",
//     position: "fixed",
//     width: "100vw",
//     top: 0,
//     left: 0,
//     right: 0,
//     zIndex: 1000,
//     display: activeStep === 3 ? "none" : "block",
//     [theme.breakpoints.down("sm")]: {
//       display: "none",
//     },
//   }));
//   const HeaderStepper = styled(Box)(({ theme }) => ({
//     maxWidth: "43rem",
//     flex: 1.3,
//     [theme.breakpoints.down("md")]: {
//       display: "none",
//     },
//   }));

//   return (
//     <>
//       <LoadingBackdrop open={loading} />
//       {scrollToTop && <ScrollToTop />}
//       <HeaderContainer>
//         <Stack
//           direction="row"
//           justifyContent="space-between"
//           alignItems="center"
//         >
//           {/* Logo on the left */}
//           <Box
//             sx={{
//               flex: 0.3,
//               textAlign: "left",
//             }}
//           >
//             <Box
//               sx={{
//                 width: { sm: "13.875rem", lg: "auto" },
//                 height: { sm: "3rem", lg: "auto" },
//               }}
//               component="img"
//               src={Logo_Link}
//               alt="-Hessen-Agentur"
//             />
//           </Box>
//           <HeaderStepper>
//             <FormStepper activeStep={activeStep} />
//           </HeaderStepper>
//           <Box
//             sx={{
//               flex: 0.3,
//               textAlign: "right",
//             }}
//           >
//             <Box
//               sx={{
//                 height: { sm: "3rem", lg: "auto" },
//               }}
//               component="img"
//               src={Logo_Recht}
//               alt="logo-Hessen-Agentur"
//             />
//           </Box>
//         </Stack>
//       </HeaderContainer>
//       <Box
//         sx={{
//           flex: "1 1 71.25rem",
//           display: "flex",
//           justifyContent: "center", // Horizontally center
//           alignItems: "center",
//           minHeight: "100vh",
//           mt: activeStep === 3 ? "0" : "6rem",
//         }}
//       >
//         <Box
//           sx={{
//             width: "100%",
//             maxWidth: "39.813rem",
//             backgroundColor: "navbar.secondary.main",
//             p: activeStep === 3 ? "2rem 2rem 0 2rem" : "2rem",
//           }}
//         >
//           {!wsSuccess && (
//             <Box>
//               <ArrowBack
//                 titleAccess="Zurück zum Login"
//                 onClick={() => navigate(PAGES_URLS.Login)}
//                 sx={{
//                   color: "primary.main",
//                   cursor: "pointer",
//                 }}
//               />
//             </Box>
//           )}
//           <Box>
//             {/* Step 1: Personendaten */}
//             {activeStep === 0 && (
//               <Grid2 container>
//                 <Grid2 sm={12}>
//                   <Box sx={{ mt: "1rem", mb: "1rem" }}>
//                     <Typography variant="h3" component="h1">
//                       Personendaten
//                     </Typography>
//                     <Typography variant="body2" component="h2">
//                     Bitte vervollständigen Sie die Angaben zu Ihrer Person.
//                     </Typography>
//                   </Box>
//                   <CreateForm
//                     formStruc={formStrucPerson()}
//                     formValues={personDaten}
//                     updateFormValuesFunc={handleUpdatePersonDaten}
//                     formErrors={personDatenError}
//                     api={APIS.RegistrierungsAnfrage}
//                     sx={{
//                       columnGap: "1rem", // Apply the gap here
//                     }}
//                   />
//                 </Grid2>
//               </Grid2>
//             )}

//             {/* Step 2: Abteilungsdaten */}
//             {activeStep === 1 && (
//               <Grid2 container gap={4}>
//                 <Grid2 sm={12}>
//                 <Box sx={{ mt: "1rem", mb: "1rem" }}>
//                     <Typography variant="h3" component="h1">
//                     Abteilungsdaten
//                     </Typography>
//                     <Typography variant="body2" component="h2">
//                     Bitte ergänzen Sie die Informationen zu Ihrer Organisationseinheit.
//                     </Typography>
//                   </Box>
//                   <CreateForm
//                     formStruc={formStrucOrga()}
//                     formValues={abteilungDaten}
//                     updateFormValuesFunc={handleUpdateAbteilungDaten}
//                     formErrors={abteilungDatenError}
//                     api={APIS.RegistrierungsAnfrage}
//                   />
//                 </Grid2>
//               </Grid2>
//             )}

//             {activeStep === 2 && (
//               <Typography variant="h4" component={"h2"}>
//                 <AbschlussRegistrierung
//                   formValue={datenschutzZustimmung}
//                   updateFunction={handleUpdateDatenschutzZustimmung}
//                   personDaten={personDaten}
//                   abteilungsDaten={abteilungDaten}
//                 />
//               </Typography>
//             )}
//             {activeStep === 3 && (
//               <NachRegistrierung isRegistrationSuccess={wsSuccess} />
//             )}
//           </Box>
//           <Box sx={{ mt: "3rem" }}>
//             {activeStep === 2 ? (
//               <Stack direction="row" justifyContent="space-between">
//                 <Button variant="outlined" onClick={handleBack}>
//                   Zurück
//                 </Button>
//                 <Button
//                   variant="contained"
//                   onClick={handleFormSubmit}
//                   disabled={!isTrue(get(datenschutzZustimmung, "zustimmung"))}
//                 >
//                   Jetzt registrieren
//                 </Button>
//               </Stack>
//             ) : (
//               <React.Fragment>
//                 {activeStep < 2 && (
//                   <Stack direction={"row"} justifyContent="space-between">
//                     <Button
//                       variant="outlined"
//                       disabled={activeStep === 0}
//                       onClick={handleBack}
//                     >
//                       Zurück
//                     </Button>
//                     <Button variant="contained" onClick={handleNext}>
//                       Weiter
//                     </Button>
//                   </Stack>
//                 )}
//               </React.Fragment>
//             )}
//           </Box>
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default AbteilungRegistrieren;

// const formStrucPerson = () => {
//   let struc: FormStrucItem[] = [
//     {
//       fields: [RA_P.Nutzerdaten_Anrede, RA_P.Nutzerdaten_Titel],
//       userProps: { columnToDivide: 2, columnGap: "1rem" },
//     },
//     {
//       fields: [
//         RA_P.Nutzerdaten_Vorname,
//         RA_P.Nutzerdaten_Nachname,
//         RA_P.Nutzerdaten_Benutzername,
//         RA_P.Nutzerdaten_E_Mail,
//         RA_P.Nutzerdaten_Telefon,
//       ],
//       userProps: { direction: "column", columnToDivide: 1 },
//     },
//   ];
//   return struc;
// };
// const formStrucOrga = () => {
//   let struc: FormStrucItem[] = [
//     {
//       fields: [
//         RA_P.Organisationsdaten_Name_Der_Abteilung,
//         RA_P.Organisationsdaten_Mutterorganisation_Hochschule,
//         RA_P.Organisationsdaten_Landkreis_Kreisfreie_Stadt,
//         RA_P.Organisationsdaten_Strasse_Und_Hausnummer,
//         RA_P.Organisationsdaten_Adresszusatz,
//       ],
//       userProps: { direction: "column", columnToDivide: 1 },
//     },
//     {
//       fields: [RA_P.Organisationsdaten_Ort, RA_P.Organisationsdaten_PLZ],
//       userProps: { columnToDivide: 2, columnGap: "1rem" },
//     },
//   ];
//   return struc;
// };

// const FormStepper: FC<{ activeStep: number }> = ({ activeStep }) => {
//   return (
//     <Box>
//       <Stepper
//         alternativeLabel
//         activeStep={activeStep}
//         orientation="horizontal"
//         sx={{
//           "& .MuiStepConnector-line": {
//             borderColor: "primary.main",
//             borderWidth: "1.5px",
//           },
//         }}
//       >
//         {steps.map((ele: any, i: number) => {
//           return (
//             <Step key={i}>
//               <StepLabel
//                 StepIconProps={{
//                   sx: {
//                     color: "#fff",
//                     "& .MuiStepIcon-text": {
//                       display: "none",
//                     },
//                     "&.MuiSvgIcon-root.Mui-active": {
//                       bgcolor: "#023671",
//                     },
//                     "&.MuiStepIcon-root.Mui-completed": {
//                       bgcolor: "#023671",
//                     },
//                     borderRadius: "50%",
//                     border: "1.5px solid #023671",
//                   },
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   sx={{
//                     fontFeatureSettings: "'liga' off, 'clig' off",
//                     fontFamily: '"Avenir Next LT Pro"',
//                     fontStyle: "normal",
//                     fontSize: { md: ".8rem", lg: "0.875rem" },
//                   }}
//                 >
//                   {ele.label}
//                 </Typography>
//               </StepLabel>
//               <StepContent>
//                 <Box>
//                   <Stepper activeStep={i} orientation="vertical">
//                     {ele.innerSteps.map((label: string, index: number) => {
//                       const stepProps = {};
//                       const labelProps = {};

//                       return (
//                         <Step key={index} {...stepProps}>
//                           <StepLabel {...labelProps}>
//                             <Typography fontSize={".8rem"}>{label}</Typography>
//                           </StepLabel>
//                           <StepConnector
//                             sx={{
//                               display: "none",
//                             }}
//                           ></StepConnector>
//                         </Step>
//                       );
//                     })}
//                   </Stepper>
//                 </Box>
//               </StepContent>
//             </Step>
//           );
//         })}
//       </Stepper>
//     </Box>
//   );
// };
