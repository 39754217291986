import { Tooltip } from '@mui/material';
import React, { FC, ReactNode } from 'react';

const MUIToolTip: FC<{ title: string; children: ReactNode; showTootip?: boolean; id?: string }> = ({
	title,
	children,
	showTootip = true,
	id = 'tooltip',
}) => {
	if (showTootip) {
		return (
			<Tooltip title={title} arrow placement='top' id={id}>
				<span style={{ maxWidth: 'fit-content' }}>{children}</span>
			</Tooltip>
		);
	} else {
		return <>{children}</>;
	}
};

export default MUIToolTip;
