import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, IconButton, Link, List, ListItem, Typography } from '@mui/material';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../../context/AuthContext';
import { isUserLoggedIn } from '../../../context/AuthHelper';
import { StyledToolbar } from '../Header';
import logo from './../../../assets/images/logo/ADK-Logo.svg';
import { PAGES_URLS, PRIVATE_PAGES, PUBLIC_PAGES } from './../../../lib/constants/routes';
import PrivateHeader from './PrivateHeader';
function MobileHeader() {
	const { logout, authenticatedData } = useAuthentication();
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);

	const toggleDrawer = (newOpen: any) => () => {
		setOpen(newOpen);
	};

	return (
		<>
			<StyledToolbar>
				<Box>
					<IconButton onClick={toggleDrawer(true)}>
						<MenuIcon />
					</IconButton>
					<Drawer open={open} onClose={toggleDrawer(false)}>
						<List sx={{ width: '20rem' }} onClick={toggleDrawer(false)}>
							{PUBLIC_PAGES.filter((ele) => ele.display === 'true').map((ele, i) => (
								<ListItem key={i}>
									<Link onClick={() => navigate(get(ele, 'url', ''))} underline='none'>
										{get(ele, 'name', '')}
									</Link>
								</ListItem>
							))}
							{isUserLoggedIn() ?
								<>
									{PRIVATE_PAGES.filter((ele) => ele.display === true).map((ele, i) => (
										<ListItem key={i}>
											<Link onClick={() => navigate(get(ele, 'url', ''))} underline='none'>
												{get(ele, 'name', '')}
											</Link>
										</ListItem>
									))}
								</>
							:	<ListItem>
									<Link onClick={() => navigate(PAGES_URLS.Login)} underline='none'>
										Anmelden
									</Link>
								</ListItem>
							}
						</List>
					</Drawer>
				</Box>

				{/* Logo */}
				<Typography variant='h6' noWrap sx={{ display: 'flex', alignContent: 'center' }}>
					<img src={logo} alt='logo' />
				</Typography>
			</StyledToolbar>

			{isUserLoggedIn() && <PrivateHeader {...{ logout, authenticatedData }} />}
		</>
	);
}

export default MobileHeader;
