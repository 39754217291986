import React, { FC, useMemo, useState } from 'react';
import { MUIFieldProps } from '../../../../../lib/types/types';
import {
	Autocomplete,
	Button,
	// createFilterOptions,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { get } from 'lodash';
import { enumT, getLabelFromJSON } from '../../../../../utils/helper';
import { Help } from '@mui/icons-material';
import MUIToolTip from '../../../mui-tooltip/MUIToolTip';

// interface test {
// 	inputValue?: string;
// 	label: string;
// 	value?: string;
// }
// const filter = createFilterOptions<test>();
const MUIAutocomplete: FC<MUIFieldProps> = (props) => {
	const {
		label,
		tooltip,
		fieldValue,
		updateFieldValue,
		isDisabled,
		error,
		errorMessage,
		onCreateOption,
		multiple = false,
		...restProps
	} = props;

	const [openDialog, setOpenDialog] = useState(false);

	const options = useMemo(() => {
		if (get(props, 'options')) {
			return get(props, 'options', []).map((ele: any) => ({
				label: ele.label ? ele.label : ele,
				value: ele.value ? `${ele.value}` : `${ele}`,
			}));
		} else {
			return get(props, 'enum', []).map((ele: any) => ({ label: enumT(ele), value: ele }));
		}
	}, [props]);

	const initialValue = useMemo(() => {
		if (typeof fieldValue === 'object') {
			return getLabelFromJSON(`${fieldValue.id}`, options);
		}
		return getLabelFromJSON(fieldValue, options);
	}, [fieldValue, options]);

	const handleChangeInput = (_e: any, newValue: any) => {
		if (newValue && newValue.value) {
			let e = { target: { value: newValue !== null ? newValue.value : null } };
			updateFieldValue(e as React.ChangeEvent<HTMLInputElement>);
		} else if (newValue && newValue.inputValue) {
			let e = { target: { value: 'create' } };
			updateFieldValue(e as React.ChangeEvent<HTMLInputElement>);
			setOpenDialog(true);
		}
	};

	const handleAddNewEntry = () => {
		console.log(' handle add new Entry');
	};

	return (
		<>
			<Autocomplete
				fullWidth
				freeSolo
				disabled={isDisabled}
				noOptionsText="Keine Erbenisse"
				multiple={multiple}
				onChange={handleChangeInput}
				value={initialValue || null}
				options={options}
				getOptionLabel={(option: any) => {
					if (typeof option === 'string') return option;
					if (option.inputValue) return option.inputValue;
					if (option.label) return get(option, 'label', '');
				}}
				// filterOptions={(options: any[], params: any) => {
				// 	const filtered = filter(options, params);
				// 	if (params.inputValue !== '') {
				// 		filtered.push({
				// 			inputValue: params.inputValue,
				// 			label: `erstelle ${params.inputValue}'`,
				// 		});
				// 	}
				// 	return filtered;
				// }}
				renderOption={(props: any, option: any) => {
					const { key, ...optionsProps } = props;
					return (
						<li key={key} {...optionsProps}>
							{option.label}
						</li>
					);
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label={
							tooltip ? (
								<>
									<>{label}</>
									<MUIToolTip title={tooltip}>
										{' '}
										<Help fontSize="small" color="primary" sx={{ ml: '5px' }} />{' '}
									</MUIToolTip>
								</>
							) : (
								<>{label}</>
							)
						}
						placeholder={get(props, 'placeholder')}
						{...restProps}
					/>
				)}
			/>

			<Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
				<DialogTitle>Erstelle neue Verbundpartner</DialogTitle>
				<DialogContent>Hier können Sie ein neues Verbundpartner hinzugüfen</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={() => setOpenDialog(false)}>
						Abbrechen
					</Button>
					<Button onClick={handleAddNewEntry}> Hinzufügen</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
export default MUIAutocomplete;
