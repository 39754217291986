import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { FunctionComponent } from 'react';

interface LoadingBackdropProps {
	open?: boolean;
}
const LoadingBackdrop: FunctionComponent<LoadingBackdropProps> = ({ open = true }) => {
	return (
		<Backdrop open={open} sx={{ zIndex: 99, flexDirection: 'column', gap: '1.5rem' }}>
			<Box>
				<Typography variant="h6" sx={{ color: '#fff', fontWeight: 600 }}>
					Bitte warten
				</Typography>
			</Box>
			<CircularProgress />
			<Box>
				<Typography variant="h6" sx={{ color: '#fff', fontWeight: 600 }}>
					Seite wird geladen
				</Typography>
			</Box>
		</Backdrop>
	);
};

export default LoadingBackdrop;
