import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
	Alert,
	Box,
	Button,
	Checkbox,
	Container,
	FormControlLabel,
	IconButton,
	// Link,
	Paper,
	Snackbar,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import background from '../../../assets/images/login/background-image.jpg';
import logo from '../../../assets/images/logo/ADK-Logo.svg';
import { useAuthentication } from '../../../context/AuthContext';
import { PAGES_URLS } from '../../../lib/constants/routes';
import LoadingBackdrop from '../../ui-component/loading/LoadingBackdrop';
import MuiDialog from '../../ui-component/mui-dialog/MuiDialog';

const BackgroundWrapper = styled('div')(() => ({
	backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url(${background})`,
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	minHeight: '100vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginBottom: '-2rem',
	backgroundColor: '#D9D9D9',
}));

const ContentWrapper = styled(Container)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'start',
	justifyContent: 'space-around',
	color: '#fff',
	minWidth: '100%',
	gap: 'clamp(1rem, 2.5vw, 3rem)',

	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '2rem',
	},
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
	maxWidth: '28.188rem',
	width: '100%',
	height: 'fit-content',
	padding: 'clamp(1.5rem, 2.5vw, 2.5rem)',
	textAlign: 'left',
	color: '#2B2728',
	background: '#FFF',
	backdropFilter: 'blur(17.5px)',
	gap: '0.625rem',
	[theme.breakpoints.down('sm')]: {
		width: '100%',
	},
}));

function Login() {
	const navigate = useNavigate();
	const { login } = useAuthentication();

	const [identifier, setIdentifier] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [rememberMe, setRememberMe] = useState<boolean>(false);
	const [errMsg, setErrMsg] = useState('');
	const [loading, setLoading] = useState(false);
	// const [openRegistrationDialog, setOpenRegistrationDialog] = useState(false);
	const [openForgotPassDialog, setOpenForgotPassDialog] = useState(false);

	const handleLogin = async (event: React.FormEvent) => {
		event.preventDefault();
		if (identifier.trim() && password) {
			let res = await login(identifier.trim(), password);
			if (!get(res, 'status.OK')) {
				setErrMsg(get(res, 'errorMsg'));
			}
		} else {
			setErrMsg('E-Mail-Adresse oder Passwort darf nicht leer sein.');
		}
	};
	const handleKeyPress = (event: React.KeyboardEvent<HTMLFormElement>) => {
		if (event.key === 'Enter') {
			handleLogin(event);
		}
	};

	return (
		<>
			<LoadingBackdrop open={loading} />
			<BackgroundWrapper>
				{errMsg && (
					<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={Boolean(errMsg)} autoHideDuration={2000}>
						<Alert severity='error' onClose={() => setErrMsg('')}>
							{errMsg}
						</Alert>
					</Snackbar>
				)}

				{/* <MuiDialog
					open={openRegistrationDialog}
					title='Neue Organisation registrieren'
					content='Hinweis: Sie sind dabei, eine neue Organisation zu registrieren. Wenn Ihre Organisation bereits registriert wurde, wenden Sie sich stattdessen an den Nutzerverwalter Ihrer Organisation. Dieser kann Sie in der Nutzerverwaltung zu dieser Organisation hinzufügen.'
					onClose={() => setOpenRegistrationDialog(false)}
					onConfirm={() => navigate(PAGES_URLS.RegistrierungVorschritt)}
					onConfirmButtonName='Neue Organisation registrieren'
				/> */}

				<MuiDialog
					open={openForgotPassDialog}
					title='Passwort vergessen?'
					content='Hinweis: Sie erhalten einen Link zum Zurücksetzen Ihres Passworts an Ihre registrierte E-Mail-Adresse.'
					onClose={() => setOpenForgotPassDialog(false)}
					onConfirm={() => navigate(PAGES_URLS.PasswortVergessen)}
					onConfirmButtonName='Weiter'
					aria-labelledby='forgot-password-dialog-title'
					aria-describedby='forgot-password-dialog-description'
				/>

				<ContentWrapper>
					<Box
						sx={{
							maxWidth: { xs: '100%', lg: '55%', xl: '60%' },
							width: '44.625rem',
							display: 'flex',
							padding: '1.25rem',
							flexDirection: 'column',
							allignItems: 'flex-start',
							background: '#fff',
							color: 'text.primary',
						}}
					>
						<Typography
							variant='h1'
							sx={{
								fontFeatureSettings: "'liga' off, 'clig' off",
								fontSize: 'clamp(1rem, 2vw, 2.5rem)',
								fontStyle: 'normal',
								fontWeight: 600,
								lineHeight: '112.5%',

								letterSpacing: '0.15px',
							}}
						>
							Herzlich willkommen im Stipindienportal der Akademie der Künste
						</Typography>
						<br />
						<Typography
							variant='body1'
							sx={{
								letterSpacing: '0.15px',
								mb: '.5rem',
							}}
						>
							Hier könnte noch ein Untertitel stehen. Und hier noch etwas mehr Text, sodass das Geschriebene sich auf zwei Zeilen verteilt.
						</Typography>
					</Box>

					<StyledPaper elevation={3}>
						<Typography component='div' noWrap sx={{ display: 'flex', justifyContent: 'center', margin: '1.2rem 0' }}>
							<img src={logo} alt='logo- Akademie der Künste' style={{ maxWidth: '17rem', textAlign: 'center' }} />
						</Typography>
						<Typography
							variant='body1'
							sx={{
								m: '1rem 0',
								textAlign: 'left',
								letterSpacing: '0.15px',
								fontWeight: '500',
							}}
						>
							Loggen Sie sich ein oder registrieren Sie sich, um Ihre Förderungen zu verwalten.
						</Typography>
						<form onSubmit={handleLogin} onKeyDown={handleKeyPress}>
							<Stack spacing={3}>
								<TextField
									fullWidth
									label='Benutzername'
									value={identifier}
									onChange={(e) => setIdentifier(e.target.value)}
									InputProps={{
										style: {
											backgroundColor: '#F8F4F4',
										},
									}}
								/>

								<TextField
									fullWidth
									label='Passwort'
									type={showPassword ? 'text' : 'password'}
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									InputProps={{
										endAdornment: (
											<IconButton aria-label='toggle password visibility' onClick={() => setShowPassword(!showPassword)}>
												{showPassword ?
													<Visibility />
												:	<VisibilityOff />}
											</IconButton>
										),
										style: {
											backgroundColor: '#F8F4F4',
										},
									}}
								/>
								<FormControlLabel
									sx={{ pb: 'clamp(.1rem, 2.5vw, 1rem)' }}
									control={<Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} color='secondary' />}
									label='Angemeldet bleiben'
								/>
								<Button
									variant='loginButton'
									type='submit'
									sx={{
										maxWidth: '100%',
										borderRadius: '4px',
										cursor: 'pointer',
										padding: '0.5rem 1.4rem',
									}}
								>
									Anmelden
								</Button>
								<Stack
									direction='row'
									sx={{
										paddingTop: 'clamp(.1rem, 2.5vw, 1rem)',
										justifyContent: 'space-between',
										cursor: 'pointer',
									}}
								>
									<Button
										variant='text'
										color='secondary'
										tabIndex={0}
										onClick={() => {
											setLoading(true);
											setTimeout(() => {
												navigate(PAGES_URLS.Registrierung, {
													state: { registrierungAls: 'NatuerlichePerson' },
												});
											}, 1000); // Delay navigation by 500ms
										}}
									>
										Registrieren
									</Button>

									<Button
										variant='text'
										color='secondary'
										role='button'
										tabIndex={0}
										onClick={() => setOpenForgotPassDialog(true)}
										onKeyDown={(e) => {
											e.stopPropagation(); // Prevent event from bubbling up to the form
											e.key === 'Enter' && setOpenForgotPassDialog(true);
										}}
									>
										Passwort vergessen?
									</Button>
								</Stack>
							</Stack>
						</form>
					</StyledPaper>
				</ContentWrapper>
			</BackgroundWrapper>
		</>
	);
}

export default Login;
