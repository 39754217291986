import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Button, Container, Link, Paper, Stack, styled, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { get } from 'lodash';
import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingBackdrop from '../../../../components/ui-component/loading/LoadingBackdrop';
import MarkdownRenderer from '../../../../components/ui-component/markdown-renderer/MarkdownRenderer';
import MuiSnackbar from '../../../../components/ui-component/mui-snackbar/MuiSnackbar';
import NoDetailsInfo from '../../../../components/ui-component/no-details-info/NoDetailsInfo';
import { useFetchFoerderProgrammDetail } from '../../../../hooks/FetchFoerderProgramm';
import { PostRecord } from '../../../../lib/api/PostRecord';
import { APIS } from '../../../../lib/constants/apis';
import { PAGES_URLS } from '../../../../lib/constants/routes';
import { getFieldNamefromDataPath } from '../../../../utils/helper';

// import schemas
import { useAuthentication } from '../../../../context/AuthContext';
import { useFetchPerson } from '../../../../hooks/FetchPerson';
import { ANTRAGS_STATUS } from '../../../../lib/constants/constant';
import AntragSchema from '../../../../lib/paths/antrag_FULL_Paths.json';
import FOERDERPROGRAMM_PATHS from '../../../../lib/paths/ausschreibung_FULL_Paths.json';
import PersonSchema from '../../../../lib/paths/person_FULL_Paths.json';
const A_P = AntragSchema.attributes;
const P = FOERDERPROGRAMM_PATHS.attributes;
const P_P = PersonSchema.attributes;

const StyledPaper = styled(Paper)({
	padding: '2rem',
});

const FoerderProgrammDetails: FC<{}> = () => {
	const { authenticatedData } = useAuthentication();
	const location = useLocation();
	const navigate = useNavigate();
	const numericId = get(location, 'state.id');

	const [wsActive, setWsActive] = useState(false);
	const [wsError, setWsError] = useState(false);

	const { loading, data, error } = useFetchFoerderProgrammDetail(numericId);
	const { data: PersonData } = useFetchPerson(get(authenticatedData, 'personId'));
	console.log('PersonData', PersonData);

	const handleCreateApplication = async () => {
		setWsActive(true);
		try {
			setWsActive(true);
			let bodyData = {
				[getFieldNamefromDataPath(A_P.Ausschreibung.path)]: numericId,
				[getFieldNamefromDataPath(A_P.Status.path)]: ANTRAGS_STATUS.inBearbeitung,
				[getFieldNamefromDataPath(A_P.Bewerber.path)]: get(authenticatedData, 'personId'),
				[getFieldNamefromDataPath(A_P.Antragsteller_Bewerber.path)]: get(authenticatedData, 'organisationId'),
				[getFieldNamefromDataPath(A_P.Bewerbung_geplantes_Projekt.path)]: '',
			};
			const antragStellerData = handleCreateAntragsteller();
			bodyData = { ...bodyData, ...antragStellerData };

			let res = await PostRecord(APIS.Antrag, { data: bodyData });
			if (res) {
				navigate(PAGES_URLS.OnlineAntrag, { state: { id: get(res, 'data.id'), api: APIS.Antrag } });
			}
		} catch (err: any) {
			console.error('ERROR: name ', err);
			setWsError(true);
		} finally {
			setWsActive(false);
		}
	};
	const handleCreateAntragsteller = () => {
		let antragsstellerDaten = {
			[getFieldNamefromDataPath(A_P.Antragsteller_Titel.path)]: get(PersonData, P_P.Titel.path),
			[getFieldNamefromDataPath(A_P.Antragsteller_Vorname.path)]: get(PersonData, P_P.Vorname.path),
			[getFieldNamefromDataPath(A_P.Antragsteller_Nachname.path)]: get(PersonData, P_P.Nachname.path),
			[getFieldNamefromDataPath(A_P.Antragsteller_E_Mail.path)]: get(PersonData, P_P.E_Mail.path),
			[getFieldNamefromDataPath(A_P.Antragsteller_Telefon.path)]: get(PersonData, P_P.Telefon.path),
			[getFieldNamefromDataPath(A_P.Antragsteller_Geburtsdatum.path)]: get(PersonData, P_P.Geburtsdatum.path),
			[getFieldNamefromDataPath(A_P.Antragsteller_Geschlecht.path)]: get(PersonData, P_P.Geschlecht.path),
			[getFieldNamefromDataPath(A_P.Antragsteller_Staatsbuergerschaft.path)]: get(PersonData, P_P.Staatsbuergerschaft.path),
			[getFieldNamefromDataPath(A_P.Antragsteller_Strasse_Und_Hausnummer.path)]: get(
				PersonData,
				P_P.Adressdaten.schema.attributes.Strasse_Und_Hausnummer.path
			),
			[getFieldNamefromDataPath(A_P.Antragsteller_PLZ.path)]: get(PersonData, P_P.Adressdaten.schema.attributes.PLZ.path),
			[getFieldNamefromDataPath(A_P.Antragsteller_Ort.path)]: get(PersonData, P_P.Adressdaten.schema.attributes.Ort.path),
			[getFieldNamefromDataPath(A_P.Antragsteller_Bundesland.path)]: get(PersonData, P_P.Adressdaten.schema.attributes.Bundesland.path),
		};
		return antragsstellerDaten;
	};

	const RenderCreateButton: FC<{}> = () => {
		/**
		 * ***********************************************************************************
		 * ******************************** DEFAULT ******************************************
		 * ***********************************************************************************
		 */
		return (
			<>
				<Button
					sx={{ maxWidth: 'fit-content' }}
					onClick={() => {
						handleCreateApplication();
					}}
				>
					Bewerbung erstellen
				</Button>
			</>
		);
	};

	if (error) console.error(' error ', error);
	if (!numericId) {
		return <NoDetailsInfo title='Förderprogramm' goBackUrl={PAGES_URLS.FoerderProgramm} />;
	}

	return (
		<>
			<LoadingBackdrop open={loading || wsActive} />
			{/* <Container>
				<MuiSnackbar showSnackbar={wsError} error={wsError} closeSnackbar={() => setWsError(false)} alertMessage='Etwas is schief gelaufen' />
				<Stack spacing={3}>
					<Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent={'space-between'}>
						<Button variant={'outlined'} startIcon={<KeyboardArrowLeftIcon />} onClick={() => navigate(PAGES_URLS.FoerderProgramm)}>
							Zurück
						</Button>
						<Box>
							<Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems={'flex-end'}>
								<RenderCreateButton />
							</Stack>
						</Box>
					</Stack>

					<Typography variant='h1' sx={{ fontSize: '1.375rem' }}>
						{' '}
						{get(data, P.Programmname.path, '')}
					</Typography>

					<Grid container columns={6} spacing={3}>
						<Grid xs={6} md={4} sx={{ paddingLeft: 0 }}>
							<StyledPaper>
								<Typography component={'div'} sx={{ mt: -2 }}>
									<MarkdownRenderer markdown={get(data, P.Kurzbeschreibung.path, '')} />
								</Typography>
							</StyledPaper>
						</Grid>
						<Grid xs={6} md={2}>
							<Box sx={{ mt: '1rem' }}>
								<Typography sx={{ fontWeight: 600 }}>{get(data, P.Kontaktdaten_Name.path)}</Typography>
								<Typography>{get(data, P.Kontaktdaten_Telefon.path)}</Typography>
								<Typography>
									<Link href={'mailto:' + get(data, P.Kontaktdaten_E_Mail.path)}>{get(data, P.Kontaktdaten_E_Mail.path)}</Link>
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</Stack>
			</Container> */}
			<Container>
				<MuiSnackbar showSnackbar={wsError} error={wsError} closeSnackbar={() => setWsError(false)} alertMessage='Etwas is schief gelaufen' />

				{/* Back Button */}
				<Stack direction='row' justifyContent='space-between'>
					<Button variant='outlined' startIcon={<KeyboardArrowLeftIcon />} onClick={() => navigate(PAGES_URLS.FoerderProgramm)}>
						Zurück
					</Button>
				</Stack>

				{/* Banner Image */}
				<Box sx={{ mt: 1 }}>
					<img width={'100%'} src='/images/ProgrammDefaultBanner.png' alt='Programm-Banner-Bild' />
				</Box>

				{/* Main Content Grid */}
				<Grid2 container columns={6} spacing={2} sx={{ mt: '2rem' }}>
					{/* Left Side - Title & Description */}
					<Grid2 xs={6} md={4}>
						<Typography variant='h1' sx={{ fontSize: '1.375rem', mb: '1.7rem' }}>
							{get(data, P.Programmname.path, '')}
						</Typography>
						<Box>
							<StyledPaper>
								<Typography component='div'>
									<MarkdownRenderer markdown={get(data, P.Kurzbeschreibung.path, '')} />
								</Typography>
							</StyledPaper>
						</Box>
					</Grid2>

					{/* Right Side - Action Button & Contact Info */}
					<Grid2 xs={6} md={2}>
						{/* Action Button */}
						<Stack direction='row' justifyContent='flex-end' sx={{ mb: 2 }}>
							<RenderCreateButton />
						</Stack>

						{/* Contact Section */}
						<Paper sx={{ padding: '1rem 0.5rem', mb: 2 }}>
							<Typography sx={{ fontWeight: 600 }}>Kontakt:</Typography>
							<Typography>
								<Link sx={{ filter: 'contrast(100%)' }} href='mailto:foerderung@adk.de'>
									foerderung@adk.de
								</Link>
							</Typography>
						</Paper>

						{/* Funding Information */}
						{/* <Paper sx={{ padding: '1rem 0.5rem' }}>
							<Typography sx={{ fontWeight: 600 }}>Fristen:</Typography>
							<Typography>Ab {DisplayEuro(60000)} Euro</Typography>
						</Paper> */}
					</Grid2>
				</Grid2>
			</Container>
		</>
	);
};
export default FoerderProgrammDetails;
