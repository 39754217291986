import { Button, Container, Stack, Typography } from '@mui/material';

import NotFoundImage from '../../assets/images/404_image.png';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { PAGES_URLS } from '../../lib/constants/routes';

function NotFound() {
	const navigate = useNavigate();
	return (
		<Container>
			<Stack spacing={2}>
				<Typography>
					<img src={NotFoundImage} alt="Seite nicht gefunden" />
				</Typography>
				<Typography variant="h1"> Seite nicht gefunden </Typography>
				<Typography variant="h3" component="h2">
					Bitte entschuldigen Sie, die Seite konnte nicht gefunden werden.
				</Typography>
				<Button variant="outlined" onClick={() => navigate(PAGES_URLS.Startseite)}>
					Zurück zur Startseite
				</Button>
			</Stack>
		</Container>
	);
}

export default NotFound;
