import { AccountBalance } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoginIcon from '@mui/icons-material/Login';
import { Box, Button, Container, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Contents from '../../../components/ui-component/mein-bereich/Contents';
import SideBars from '../../../components/ui-component/mein-bereich/Sidebars';
import { PAGES_URLS } from '../../../lib/constants/routes';
import { SidebarType } from '../../../lib/types/types';
import LoginData from './content/login-data/LoginData';
import PersonalData from './content/personal-data/PersonalData';

function MeinProfil() {
	const navigate = useNavigate();
	const [activeSideBarButton, setActiveSideBarButton] = useState<number>(0);
	const sidebars: SidebarType = [
		{ label: 'Persönliche Daten', icon: <AccountBalance />, element: <PersonalData /> },
		{ label: 'Passwort ändern', icon: <LoginIcon />, element: <LoginData /> },
	];
	const toggleSideBarButton = (_event: React.SyntheticEvent, newSideBarButton: number) => {
		setActiveSideBarButton(newSideBarButton);
	};
	return (
		<>
			<Container>
				<Stack direction={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'center', md: 'flex-start' }}>
					<Box>
						<Button
							variant='outlined'
							startIcon={<ArrowBackIcon />}
							onClick={() => {
								navigate(PAGES_URLS.MeinBereich);
							}}
							sx={{ m: '1rem 2rem 1rem 0', minWidth: '16.4rem' }}
						>
							Zurück zu Mein Bereich
						</Button>
						<SideBars sidebars={sidebars} activeSideBarButton={activeSideBarButton} toggleSideBarButton={toggleSideBarButton} />
					</Box>
					<Contents sidebars={sidebars} activeSideBarButton={activeSideBarButton} />
				</Stack>
			</Container>
		</>
	);
}

export default MeinProfil;
