import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MeinBereichButton, MeinBereichPaper } from '../../../../components/ui-component/mein-bereich/MeinBereichComponents';
import { PAGES_URLS } from '../../../../lib/constants/routes';

function EInstellung() {
	const navigate = useNavigate();
	return (
		<>
			<MeinBereichPaper titleIcon={<SettingsIcon />} title={'Einstellungen'}>
				<MeinBereichButton buttonName='Meine Daten verwalten' onClick={() => navigate(PAGES_URLS.MeinProfil)} />
				{/* <MeinBereichButton buttonName='Angaben zur Organisation verwalten' onClick={() => navigate(PAGES_URLS.MeineOrganisation)} /> */}
			</MeinBereichPaper>
		</>
	);
}

export default EInstellung;
