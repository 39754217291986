import { Box, Divider, FormControl, FormHelperText, Typography } from '@mui/material';

import React, { FC } from 'react';

const SummaryLine: FC<{
	label: string;
	value: any;
	error?: boolean;
	errorMessage?: string;
	showErrorMessagePlaceholder?: boolean;
}> = ({ label, value, error, errorMessage, showErrorMessagePlaceholder = true }) => {
	return (
		<FormControl sx={{ width: '100%' }} error={error}>
			<Box sx={{ display: 'flex', width: '100%', color: error ? 'error.main' : '' }}>
				<Typography sx={{ width: '50%', padding: '0.75rem 0' }} variant="body2">
					{label}
				</Typography>
				<Typography sx={{ fontWeight: 600, width: '50%', padding: '0.75rem 0' }} variant="body2">
					{value}
				</Typography>
			</Box>
			{showErrorMessagePlaceholder && <FormHelperText sx={{ margin: 0, padding: '0 0 0 1rem' }}>{errorMessage}</FormHelperText>}
			<Divider sx={{ width: '100%' }} />
		</FormControl>
	);
};
export default SummaryLine;
