import { cloneDeep, get, set } from 'lodash';
import React, { useEffect, useState } from 'react';
import AuthUser, { GetUserMe } from '../lib/api/AuthUser';
import { ERROR_MESSAGE, NOT_FOUND_ERROR_MESSAGE } from '../lib/constants/constant';
import { AuthenticatedDataType, AuthenticationContextType, AuthResponseType } from '../lib/types/types';
import { getLocalstorageToken, getUserJwt, isUserLoggedIn } from './AuthHelper';

// Create the Auth Context
const AuthenticationContext = React.createContext<AuthenticationContextType>({
	isAuthenticated: false,
	authenticatedData: null,
	login: async () => ({ status: false }), // Default login implementation
	logout: () => {},
});

// AuthProvider component to wrap your app
function AuthenticationProvider({ children }: { children?: React.ReactNode }) {
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(isUserLoggedIn());
	const [authenticatedData, setAuthenticatedData] = useState<AuthenticatedDataType | null>(getLocalstorageToken());

	// Function to log in a user
	async function login(identifier: string, password: string): Promise<AuthResponseType> {
		try {
			let res = await AuthUser(identifier, password);
			if (res) {
				let userDataRes = await GetUserMe(get(res, 'jwt'));
				let tokenData = cloneDeep(res);
				set(tokenData, 'personId', get(userDataRes, 'Person.id'));
				set(tokenData, 'organisationId', get(userDataRes, 'Person.Organisation.id'));
				set(tokenData, 'organisationName', get(userDataRes, 'Person.Organisation.Name_Der_Organisation'));
				localStorage.setItem('adk-token', btoa(JSON.stringify(tokenData))); // Set token in localStorage
				setAuthenticatedData(tokenData);
				setIsAuthenticated(true);
			}
			return {
				status: true,
			};
		} catch (err) {
			console.error('ERROR: Authentication user ', err);
			let errMsg: string | object;
			try {
				const message: string = get(err, 'message', '{}');
				errMsg = JSON.parse(message);
			} catch (err: any) {
				errMsg = err.message;
			}

			if (get(errMsg, 'error.message', '')) {
				return {
					status: false,
					errorMsg: get(ERROR_MESSAGE, get(errMsg, 'error.message', ''), NOT_FOUND_ERROR_MESSAGE),
				};
			} else {
				return {
					status: false,
					errorMsg: NOT_FOUND_ERROR_MESSAGE,
				};
			}
		}
	}

	// Function to log out a user
	function logout() {
		window.location.reload();
		localStorage.removeItem('adk-token');
		setAuthenticatedData(null);
		setIsAuthenticated(false);
	}

	useEffect(() => {
		const fetchUserdata = async () => {
			if (isUserLoggedIn()) {
				let userDataRes = await GetUserMe();
				let tokenData = {};
				set(tokenData, 'jwt', getUserJwt());
				set(tokenData, 'user', cloneDeep(userDataRes));
				set(tokenData, 'personId', get(userDataRes, 'Person.id'));
				set(tokenData, 'organisationId', get(userDataRes, 'Person.Organisation.id'));
				set(tokenData, 'organisationName', get(userDataRes, 'Person.Organisation.Geschaeftsbezogene_Daten_Name_Der_Organisation'));
				localStorage.setItem('adk-token', btoa(JSON.stringify(tokenData))); // Set token in localStorage
				setAuthenticatedData((prevValue: any) => {
					return { ...prevValue, tokenData };
				});
				setIsAuthenticated(true);
			} else {
				setAuthenticatedData(null);
				setIsAuthenticated(false);
			}
		};
		fetchUserdata();
	}, []);

	return <AuthenticationContext.Provider value={{ isAuthenticated, authenticatedData, login, logout }}>{children}</AuthenticationContext.Provider>;
}

// Custom hook to use the AuthContext
const useAuthentication = () => {
	const context = React.useContext(AuthenticationContext);
	if (context === undefined) {
		throw new Error('userAuth must be used within a AuthenticationProvider');
	}
	return context;
};

export default AuthenticationProvider;

export { AuthenticationContext, useAuthentication };
