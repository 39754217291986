import React, { FC, ReactElement } from 'react';
import { useAuthentication } from '../../context/AuthContext';
import { Navigate } from 'react-router-dom';

interface RouteProps {
	element: ReactElement;
}

export const AuthenticationRoute: FC<RouteProps> = ({ element }) => {
	const { isAuthenticated } = useAuthentication();
	return isAuthenticated ? <Navigate to="/mein-bereich" /> : element;
};
export const PrivateRoute: FC<RouteProps> = ({ element }) => {
	const { isAuthenticated } = useAuthentication();
	return isAuthenticated ? element : <Navigate to="/login" />;
};
