export function DisplayDayAndFullDate(dateString: string) {
	if (!dateString) return '';
	return new Date(dateString).toLocaleString('de-DE', {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: '2-digit',
	});
}

export function DisplayEuro(euro: number) {
	return Intl.NumberFormat('de-DE', {
		style: 'currency',
		currency: 'EUR',
	}).format(euro);
}

export function DisplayEuroMUI(euro: number) {
	const currencyFormatter = new Intl.NumberFormat('de-DE', {
		style: 'currency',
		currency: 'EUR',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	return currencyFormatter.format(euro);
}

export function DisplayDecimal(decimal: number, displayAsPercentage: boolean = false) {
	const decimalFormatter = new Intl.NumberFormat('de-DE', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});

	let formattedNumber = decimalFormatter.format(decimal);

	if (displayAsPercentage) {
		formattedNumber += ' %';
	}

	return formattedNumber;
}

export function DisplayDate(dateString: string) {
	if (!dateString) return '';
	return new Date(dateString).toLocaleDateString('de-DE', {
		day: '2-digit',
		year: 'numeric',
		month: '2-digit',
	});
}

export function DisplayDateTime(dateString: string) {
	if (!dateString) return '';
	return new Date(dateString).toLocaleString('de-DE', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	});
}

export function GetFileSize(sizeInKB: number) {
	let size = sizeInKB;
	let suffix = 'KB';
	for (let symbol of ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']) {
		if (size < 1000) break;
		suffix = symbol;
		size /= 1000;
	}
	return size.toFixed(2).replace('.', ',') + ' ' + suffix;
}

export function DisplayFileSize(sizeInBytes: number) {
	let size = sizeInBytes;
	let suffix = 'B';

	// SI-Einheiten: 1000 als Basis
	const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	for (const unit of units) {
		if (size < 1000) break;
		suffix = unit;
		size /= 1000;
	}

	return size.toFixed(2).replace('.', ',') + ' ' + suffix;
}
