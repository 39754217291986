import React from 'react';
import AbteilungRegistrieren from '../../components/auth/abteilung-registrieren/AbteilungRegistrieren';
import ConfirmEMail from '../../components/auth/confirm-email/ConfirmEMail';
import ForgotPassword from '../../components/auth/forgot-password/ForgotPassword';
import Login from '../../components/auth/login/Login';
import Register from '../../components/auth/registration/Register';
import RegistrierungVorschritt from '../../components/auth/registration/RegistrierungVorschritt';
import SaveNewPassword from '../../components/auth/save-new-password/SaveNewPassword';
import { AuthenticationRoute, PrivateRoute } from '../../components/routes/Routes';
import FoerderungUebersicht from '../../pages/private/foerderung-uebersicht/FoerderungUebersicht';
import MeinBereich from '../../pages/private/mein-bereich/MeinBereich';
import MeinProfil from '../../pages/private/mein-profil/MeinProfil';
import MeineAntraege from '../../pages/private/meine-antraege/MeineAntraege';
import MeineNachrichten from '../../pages/private/meine-nachrichten/MeineNachrichten';
import NachrichtenDetails from '../../pages/private/meine-nachrichten/nachrichten-details/NachrichtenDetails';
import OnlineAntrag from '../../pages/private/online-antrag/OnlineAntrag';
import Datenschutz from '../../pages/public/datenschutz/Datenschutz';
import Faq from '../../pages/public/faq/Faq';
import Impressum from '../../pages/public/impressum/Impressum';
import FoerderProgrammDetails from '../../pages/public/programms/Details/FoerderProgrammDetail';
import FoerderProgrammeView from '../../pages/public/programms/View/FoerderProgrammeView';

export const PAGES_URLS = {
	Startseite: '/',
	Impressum: '/impressum',
	Datenschutz: '/datenschutz',
	Kontakt: '/kontakt',
	FAQ: '/faq',
	Login: '/login',
	Registrierung: '/registrierung',
	RegistrierungVorschritt: '/registrierung-vorschritt',
	EmailBestaetigun: '/e-mail-bestaetigung',
	PasswortVergessen: '/passwort-vergessen',
	PasswortSpeichern: '/passwort-speichern',
	MeinBereich: '/mein-bereich',
	MeineFoerderung: '/meine-antraege',
	FoerderProgramm: '/foerderprogramm',
	FoerderProgrammDetails: '/foerderprogramm/details',
	FoerderDashboard: '/meine-antraege/foerderdashboard',
	OnlineAntrag: '/meine-antraege/online-antrag',
	Nachrichten: '/meine-nachrichten',
	NachrichtenDetails: '/meine-nachrichten/nachricht-details',
	MeinProfil: '/mein-bereich/mein-profil',
	MeineOrganisation: '/mein-bereich/meine-organisation',
	AbteilungRegistrierung: '/registrierung-abteilung',
};

export const PUBLIC_PAGES = [
	{ display: 'false', url: PAGES_URLS.Startseite, name: 'Startseite', element: <PrivateRoute element={<MeinBereich />} /> },
	{ display: 'false', url: PAGES_URLS.Impressum, name: 'Impressum', element: <Impressum /> },
	{ display: 'false', url: PAGES_URLS.Datenschutz, name: 'Datenschutz', element: <Datenschutz /> },
	// { display: 'false', url: PAGES_URLS.Kontakt, name: 'Kontakt', element: <Kontakt /> },
];
export const AUTHENTICATION_PAGES = [
	{ url: PAGES_URLS.Login, name: 'Login', element: <AuthenticationRoute element={<Login />} /> },
	{ url: PAGES_URLS.Registrierung, name: 'Registrierung', element: <AuthenticationRoute element={<Register />} /> },
	{
		url: PAGES_URLS.RegistrierungVorschritt,
		name: 'Registrierung Vorschritt',
		element: <AuthenticationRoute element={<RegistrierungVorschritt />} />,
	},
	{
		url: PAGES_URLS.AbteilungRegistrierung,
		name: 'Abteilung Registrierung',
		element: <AuthenticationRoute element={<AbteilungRegistrieren />} />,
	},
	{
		url: PAGES_URLS.EmailBestaetigun,
		name: 'E-Mail-Adresse bestätigen',
		element: <AuthenticationRoute element={<ConfirmEMail />} />,
	},
	{
		url: PAGES_URLS.PasswortVergessen,
		name: 'Passwort vergessen',
		element: <AuthenticationRoute element={<ForgotPassword />} />,
	},
	{
		url: PAGES_URLS.PasswortSpeichern,
		name: 'Passwort speichern',
		element: <AuthenticationRoute element={<SaveNewPassword />} />,
	},
];
export const PRIVATE_PAGES = [
	{ display: true, url: PAGES_URLS.MeinBereich, name: 'Mein Bereich', element: <PrivateRoute element={<MeinBereich />} /> },
	{ display: true, url: PAGES_URLS.MeineFoerderung, name: 'Meine Anträge', element: <PrivateRoute element={<MeineAntraege />} /> },
	{
		display: true,
		url: PAGES_URLS.FoerderProgramm,
		name: 'Förderprogramme',
		element: <PrivateRoute element={<FoerderProgrammeView />} />,
	},
	{
		display: 'false',
		url: PAGES_URLS.FoerderProgrammDetails,
		name: 'Förderprogramme Details',
		element: <PrivateRoute element={<FoerderProgrammDetails />} />,
	},
	{
		display: false,
		url: PAGES_URLS.FoerderDashboard,
		name: 'Meine Förderungen',
		element: <PrivateRoute element={<FoerderungUebersicht />} />,
	},
	{
		display: false,
		url: PAGES_URLS.OnlineAntrag,
		name: 'Online Antrag',
		element: <PrivateRoute element={<OnlineAntrag />} />,
	},
	{
		showBadge: true,
		display: true,
		url: PAGES_URLS.Nachrichten,
		name: 'Nachrichten',
		element: <PrivateRoute element={<MeineNachrichten />} />,
	},
	{
		display: false,
		url: PAGES_URLS.NachrichtenDetails,
		name: 'Nachrichten Details',
		element: <PrivateRoute element={<NachrichtenDetails />} />,
	},
	{ display: false, url: '/faq', name: 'FAQ', element: <PrivateRoute element={<Faq />} /> },
];
export const PROFILE_PAGES = [
	{ url: PAGES_URLS.MeinProfil, name: 'Mein Profil', element: <PrivateRoute element={<MeinProfil />} /> },
	// {
	// 	url: PAGES_URLS.MeineOrganisation,
	// 	name: 'Meine Organisation',
	// 	element: <PrivateRoute element={<MeineOrganisation />} />,
	// },
];
