import { get } from 'lodash';
import { ErrorValidation, Field, FormStrucItem, TreeNodeType } from '../types/types';
import { GetFieldErrors } from './ApplicationValidator';

const COMPONENT_TABLE = 'componentTable';

export function findErrorByKey(treeItems: TreeNodeType[] | undefined, key: string, type: string): boolean | undefined {
	const item = treeItems?.find((treeItem: TreeNodeType) => {
		let resultChildren;
		let resultFormStruc;
		if (treeItem.children) {
			resultChildren = findErrorByKey(treeItem.children, key, type);
		}
		if (treeItem.formStruc) {
			resultFormStruc = treeItem.formStruc?.some((fieldGroup: FormStrucItem) => {
				return fieldGroup.fields?.some((field: Field) => {
					if (type === COMPONENT_TABLE) return field.path?.includes(key);
					return field.path === key;
				});
			});
		}

		return (
			resultChildren === undefined ?
				resultFormStruc ? true
				:	false
			:	resultChildren
		);
	});

	return item ? item.showError : undefined;
}

export function checkErrorInCurrentNode(tree: TreeNodeType[], error: any, currentNode: string): boolean {
	let errorItem = tree.find((item) => {
		if (item.formStruc && item.id === currentNode) {
			return item.formStruc.some((fieldGroup: any) => {
				return fieldGroup.fields?.some((field: any) => {
					return error?.some((validationError: ErrorValidation) => validationError.path.includes(field.path));
				});
			});
		} else if (item.children) return checkErrorInCurrentNode(item.children, error, currentNode);
		else return false;
	});

	return errorItem ? true : false;
}

export function validateAll(
	application: any,
	structure: TreeNodeType[],
	setErrors: any,
	readOnly: boolean,
	onlyVisitedNodes?: boolean
): TreeNodeType[] {
	const errorList = GetFieldErrors(structure, application, readOnly);
	setErrors(errorList);
	const errorPaths = new Set(errorList.map((error: ErrorValidation) => error.path));

	const newStruc = validateStructure(structure, errorPaths, onlyVisitedNodes ? true : false);

	return newStruc;
}

function validateStructure(treeStructure: TreeNodeType[], errorPaths: Set<string>, onlyVisitedNodes: boolean): TreeNodeType[] {
	treeStructure.forEach((item: TreeNodeType) => {
		if (onlyVisitedNodes && !(item.hasBeenVisited || item.showError)) return item;
		if (!onlyVisitedNodes) item.hasBeenVisited = true;

		let itemHasError = false;
		let childHasError = false;

		if (item.children) {
			const child = validateStructure(item.children, errorPaths, onlyVisitedNodes);
			childHasError = child.some((childItem) => childItem.showError);
		}
		if (item.formStruc) {
			// validateCurrentStruc(item.formStruc, errorPaths);
			item.formStruc.forEach((formStrucItem) => {
				formStrucItem.fields?.forEach((field: Field) => {
					// Check if the data-path is in the errorPaths set
					if (errorPaths?.has(field.path)) {
						// Set the error property to true
						itemHasError = true;
					}
				});
			});
		}

		item.showError = childHasError ? true : itemHasError;
		return item as TreeNodeType;
	});
	return treeStructure;
}

export function setShowError(treeStructure: TreeNodeType[], curNode: string[], hasErrorInParent: boolean, hasErrorInChild?: boolean) {
	console.log('set show error curNode', curNode);
	if (curNode.length === 1) {
		const currentNode = treeStructure?.find((ele: TreeNodeType) => ele.id === curNode[0]);
		if (currentNode && currentNode.showError !== hasErrorInParent) currentNode.showError = hasErrorInParent;
	}
	if (curNode.length > 1) {
		const parentNode = treeStructure?.find((ele: TreeNodeType) => ele.id === curNode[0]);
		const childNode = parentNode?.children?.find((ele: TreeNodeType) => ele.id === curNode[1]);
		setShowErrorInNodes(parentNode, childNode, hasErrorInParent, hasErrorInChild ? true : false);
	}
}

function setShowErrorInNodes(
	parentNode: TreeNodeType | undefined,
	childNode: TreeNodeType | undefined,
	hasErrorInParent: boolean,
	hasErrorInChild: boolean
) {
	if (parentNode && hasErrorInParent === true) {
		parentNode.showError = true;
		if (childNode && childNode.showError !== hasErrorInChild) childNode.showError = hasErrorInChild;
	} else {
		if (childNode && childNode.showError !== hasErrorInChild) {
			childNode.showError = hasErrorInChild;
		}
		//check if all child are false and set parent to false, else parent still has error
		const hasNoError = parentNode?.children?.every((child) => !child.showError);
		if (parentNode) {
			parentNode.showError = hasNoError ? false : true;
		}
	}
}

export function setHasBeenVisited(treeStructure: TreeNodeType[], curNode: string[]) {
	if (curNode.length === 1) {
		const currentNode = treeStructure?.find((ele: TreeNodeType) => ele.id === curNode[0]);
		if (currentNode) currentNode.hasBeenVisited = true;
	}
	if (curNode.length > 1) {
		const parentNode = treeStructure?.find((ele: TreeNodeType) => ele.id === curNode[0]);
		if (parentNode) parentNode.hasBeenVisited = true;
		const childNode = parentNode?.children?.find((ele: TreeNodeType) => ele.id === curNode[1]);
		if (childNode) childNode.hasBeenVisited = true;
	}
}

function hasErrorInField(errors: any[], path: string) {
	let errorInField = Boolean(
		(Array.isArray(errors) ? errors : []).find((e) => {
			return get(e, 'path', '') === path;
		})
	);

	return errorInField;
}

export function getHasError(errors: any[], tree: TreeNodeType[], obj: any) {
	const { path, type } = obj;
	const errorInField = hasErrorInField(errors, path);
	const showError = findErrorByKey(tree, path, type);

	return errorInField && showError;
}

export function getErrorMessage(errors: any[], path: string) {
	const errorMessage = get(
		errors.find((e) => e.path === path),
		'message',
		''
	);

	return errorMessage;
}
