import React, { FC } from 'react';
import Schema from '../../../../lib/paths/user_Paths.json';
import ApplicationHeading from '../../../../pages/private/online-antrag/application-heading/ApplicationHeading';
import CreateForm from '../../../ui-component/form-component/create-form/CreateForm';
//import { PLATZHALTER_TEXT } from '../../../../lib/constants/constant';
import MUIAlert from '../../../ui-component/mui-alert/MUIAlert';
const P = Schema.attributes;

const LoginDaten: FC<{
	formValue: any;
	updateFunction: (_key: string, _value: any) => void;
	loginDatenError: any;
	formErrorMessage: string;
}> = ({ formValue, updateFunction, loginDatenError, formErrorMessage }) => {
	return (
		<>
			<ApplicationHeading title='Ihre Zugangsdaten' description='Legen Sie Ihren Benutzernamen und Ihr Passwort fest.' />
			{formErrorMessage && (
				<MUIAlert
					infoType='error'
					message='Eine Registrierung mit diesem Benutzernamen oder dieser E-Mail-Adresse besteht bereits. Bitte wenden Sie sich an Ihren Nutzerverwalter, um Ihren Account freizuschalten.'
				/>
			)}
			<CreateForm
				formValues={formValue}
				formStruc={formStrucEmail()}
				updateFormValuesFunc={updateFunction}
				formErrors={loginDatenError}
				readOnly={true}
			/>
			<CreateForm
				formValues={formValue}
				formStruc={formStruc()}
				updateFormValuesFunc={updateFunction}
				formErrors={loginDatenError}
				readOnly={false}
			/>
		</>
	);
};

export default LoginDaten;

const formStrucEmail = () => {
	return [
		{
			fields: [P.email],
			userProps: { columnToDivide: 1 },
		},
	];
};

const formStruc = () => {
	return [
		{
			fields: [P.username],
			userProps: { columnToDivide: 1 },
		},
		{
			fields: [P.password, P.repeatPassword],
			userProps: { columnToDivide: 1 },
		},
	];
};
