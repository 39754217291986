import { Help } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC, useMemo } from 'react';
import { MUIFieldProps } from '../../../../../lib/types/types';
import MUIToolTip from '../../../mui-tooltip/MUIToolTip';

const MUIDateField: FC<MUIFieldProps> = (props) => {
	const { label, tooltip, fieldValue, updateFieldValue, isDisabled, error, errorMessage, ...restProps } = props;

	const initialValue = useMemo(() => {
		return dayjs(fieldValue, 'YYYY-MM-DD');
	}, [fieldValue]);

	const handleOnChange = (value: Dayjs | null) => {
		const strapiValue = value ? value.format('YYYY-MM-DD') : null;
		const e = { target: { value: strapiValue } };
		updateFieldValue(e as React.ChangeEvent<HTMLInputElement>);
	};
	return (
		<DatePicker
			label={
				tooltip ?
					<>
						<>{label}</>
						<MUIToolTip title={tooltip}>
							{' '}
							<Help fontSize='small' color='primary' sx={{ ml: '5px' }} />{' '}
						</MUIToolTip>
					</>
				:	<>{label}</>
			}
			value={initialValue}
			onChange={handleOnChange}
			disabled={isDisabled}
			slotProps={{
				textField: {
					error: error,
					helperText: errorMessage,
					size: restProps.size ? restProps.size : 'medium',
				},
			}}
			sx={{ width: '100%' }}
			{...restProps}
			format={'DD.MM.YYYY'}
		/>
	);
};
export default MUIDateField;
