import { Alert, Box, Button, Container, Snackbar, Stack, TextField, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAGES_URLS } from '../../../lib/constants/routes';
import { STRAPI_URL } from '../../../lib/constants/url';

const SaveNewPassword: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const code = queryParams.get('code');

	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const [successMsg, setSuccessMsg] = useState('');

	if (!code) {
		return (
			<Container maxWidth='sm' sx={{ mt: 4 }}>
				<Typography variant='h5'>Ungültiger Passwort-Reset-Link.</Typography>
			</Container>
		);
	}

	const handleResetPassword = async (event: React.FormEvent) => {
		event.preventDefault();
		if (password !== passwordConfirmation) {
			setErrMsg('Passwörter stimmen nicht überein.');
			return;
		}
		try {
			const response = await fetch(`${STRAPI_URL}/api/auth/reset-password`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					code: code,
					password: password,
					passwordConfirmation: passwordConfirmation,
				}),
			});
			if (!response.ok) {
				const errorData = await response.json();
				setErrMsg(errorData.error?.message || 'Fehler beim Zurücksetzen des Passworts.');
			} else {
				setSuccessMsg('Ihr Passwort wurde erfolgreich zurückgesetzt.');
				setTimeout(() => {
					navigate(PAGES_URLS.Login);
				}, 300);
			}
		} catch (error) {
			console.error('Error resetting password:', error);
			setErrMsg('Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.');
		}
	};

	return (
		<>
			{errMsg && (
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={Boolean(errMsg)}
					autoHideDuration={6000}
					onClose={() => setErrMsg('')}
				>
					<Alert severity='error' onClose={() => setErrMsg('')}>
						{errMsg}
					</Alert>
				</Snackbar>
			)}
			{successMsg && (
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={Boolean(successMsg)}
					autoHideDuration={6000}
					onClose={() => setSuccessMsg('')}
				>
					<Alert severity='success' onClose={() => setSuccessMsg('')}>
						{successMsg}
					</Alert>
				</Snackbar>
			)}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					minHeight: '100vh',
					width: '100%',
					backgroundColor: 'background.paper',
					mb: '-2rem',
				}}
			>
				<Box
					sx={{
						maxWidth: '39.123rem',
						width: '100%',
						padding: 'clamp(1.5rem, 2.5vw, 2.5rem)',
						margin: '0 1rem',
						backgroundColor: '#fff',
						borderRadius: '8px',
						boxShadow: 1,
					}}
				>
					<Typography variant='h4' sx={{ mb: 'clamp(2rem, 2.5vw, 2.5rem)' }} gutterBottom>
						Passwort zurücksetzen
					</Typography>

					<form onSubmit={handleResetPassword}>
						<Stack spacing={2}>
							<TextField
								fullWidth
								label='Neues Passwort'
								type='password'
								variant='outlined'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<TextField
								fullWidth
								label='Passwort bestätigen'
								type='password'
								variant='outlined'
								value={passwordConfirmation}
								onChange={(e) => setPasswordConfirmation(e.target.value)}
							/>
						</Stack>
						<Button
							sx={{
								mt: 'clamp(.1rem, 4vw, 3rem)',
							}}
							variant='contained'
							color='primary'
							size='large'
							type='submit'
						>
							Passwort zurücksetzen
						</Button>
					</form>
				</Box>
			</Box>
		</>
	);
};

export default SaveNewPassword;
