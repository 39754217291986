import { Container, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { getLocalstorageToken } from '../../../context/AuthHelper';
import GetRecord from '../../../lib/api/GetRecord';
import { APIS } from '../../../lib/constants/apis';
import { DisplayDayAndFullDate } from '../../../utils/formats';
import { removeDataKeys, removeNullDeep } from '../../../utils/helper';
import AntraegeInForderung from './structure/AntraegeInForderung';
import BeendeteForderung from './structure/BeendeteForderung';
import EInstellung from './structure/EInstellung';
import Nachrichten from './structure/Nachrichten';
import VorDerForderung from './structure/VorDerForderung';

import Schema from '../../../lib/paths/organisation_FULL_Paths.json';
const P = Schema.attributes;

function MeinBereich() {
	const [orgaDetails, setOrgaDetails] = useState({});
	useEffect(() => {
		const fetch = async () => {
			let res = await GetRecord(APIS.Organisation, get(getLocalstorageToken(), 'organisationId'));
			if (res) {
				setOrgaDetails(removeNullDeep(removeDataKeys(get(res, 'data'))));
			}
		};
		fetch();
	}, []);

	return (
		<>
			<Container>
				<Stack direction={'row'} justifyContent={'space-between'}>
					<Typography variant='h3' component='h3'>
						{' '}
						Willkommen, {get(orgaDetails, P.Name_Der_Organisation.path, '')}
					</Typography>
					<Typography variant='h3' component='h3'>
						{' '}
						{DisplayDayAndFullDate(new Date().toISOString())}
					</Typography>
				</Stack>
				<Grid container columns={{ xs: 1, sm: 2, md: 3 }} spacing={4}>
					<Grid xs={3} sm={1} md={1}>
						<VorDerForderung />
						<BeendeteForderung />
					</Grid>
					<Grid xs={3} sm={1} md={1}>
						<AntraegeInForderung />
						<EInstellung />
					</Grid>
					<Grid xs={3} sm={2} md={1}>
						<Nachrichten />
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

export default MeinBereich;
