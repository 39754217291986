import { Alert, AlertTitle } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import Markdown from 'react-markdown';

const MUIAlert: FC<{ infoType?: 'success' | 'info' | 'warning' | 'error'; title?: string; message: string | ReactElement }> = ({
	infoType = 'info',
	title,
	message,
}) => {
	const RenderMessage = () => {
		if (typeof message === 'string') {
			return <Markdown components={{ p: 'div' }}>{message}</Markdown>;
		} else {
			return <>{message}</>;
		}
	};
	return (
		<Alert severity={infoType}>
			{title && <AlertTitle>{title}</AlertTitle>}
			<RenderMessage />
		</Alert>
	);
};
export default MUIAlert;
