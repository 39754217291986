import _, { cloneDeep, get, merge, set } from 'lodash';
import Schema from 'validate';
import { MAX_TEXTBOX_LENGTH, MAX_TEXTFIELD_LENGTH } from '../constants/constant';
import { ErrorValidation, Field, Validation } from '../types/types';
import { GetTextboxValidation, Required } from './ValidationTypes';

export function GetFieldErrors(structure: any, application: any, readOnly: boolean): ErrorValidation[] {
	console.log('APPLICATIOPNVALIDATOR 3', structure);
	const appCopy = _.cloneDeep(application);
	let applicationErrors = GetApplicationValidator(structure, appCopy, readOnly).validate(appCopy) as ErrorValidation[];
	// console.log('APPLICATIOPNVALIDATOR errors', [...applicationErrors]);
	return applicationErrors;
}

function GetApplicationValidator(structure: any, application: any, readOnly: boolean) {
	let object = {};
	// console.log("APPLICATIOPNVALIDATOR STRUCTURE", structure);
	let enableValidation = readOnly ? false : true;
	if (enableValidation) createSchema(structure, object, application);
	// console.log('APPLICATIOPNVALIDATOR object', object);

	return new Schema(object, { strip: false });
}

function createSchema(structure: any, object: any, application: any) {
	structure?.forEach((structItem: any) => {
		if (structItem?.formStruc) {
			structItem?.formStruc?.forEach((item: any) => {
				item?.fields?.forEach((field: Field) => {
					// console.log("APPLICATIOPNVALIDATORfield", field);
					if (field.path) {
						setValidationSchema(application, field, object);
					}
				});
			});
		}
		if (structItem?.children) {
			createSchema(structItem.children, object, application);
		}
	});
}

function setValidationSchema(application: any, field: Field, object: any) {
	const { validation, path } = field;
	const isFieldDisabled = field.disabled === true;
	if (validation) {
		//console.log('APPLICATIOPNVALIDATOR validation', validation);

		validation.forEach((validate: Validation) => {
			//console.log('APPLICATIOPNVALIDATOR validate', validate);

			let validationNotDisabled = !isFieldDisabled || validate.disabledValidation === false;
			if (validate.onlyValidateOnNonEmptyValue) {
				const currentValue = get(application, path);
				validationNotDisabled = validationNotDisabled && currentValue ? true : false;
			}
			if (validationNotDisabled) setValidateFunction(validate, application, path, object);
		});
	} else if (!isFieldDisabled) {
		setDefaultSchema(field, object);
	}
}

/**
 * SET DEFAULT VALIDATION
 * @param field 	form value object
 * @param object 	setting the default validation in validation object, can be overwritten in corresponding formStruc,
 */
function setDefaultSchema(field: Field, object: any) {
	const { path } = field;
	// set Required as default, set NonRequired in formStruc if not needed
	switch (field?.type) {
		case 'checkbox':
		case 'boolean':
		case 'label':
			return;
		case 'richtext':
		case 'markdownEditor':
		case 'text':
			const maxCharBoxLength = get(field, 'maxCharLength', MAX_TEXTBOX_LENGTH);
			set(object, path, merge({}, Required, GetTextboxValidation(maxCharBoxLength)));
			return;
		case 'string':
			const maxCharLength = get(field, 'maxCharLength', MAX_TEXTFIELD_LENGTH);
			set(object, path, merge({}, Required, GetTextboxValidation(maxCharLength)));
			return;
		default:
			set(object, path, { ...Required });
			return;
	}
}

/**
 *
 * @param validate object of type Validation to set function (and parameters) to @param object, and translate path in params to value in @param application
 * @param application key value object containing data for forms
 * @param fieldPath path to value in @param application
 * @param object schema for validation library
 */
function setValidateFunction(validate: Validation, application: any, fieldPath: string, object: any) {
	const { params } = validate;
	let objectCopy = cloneDeep(object);

	//console.log('APPLICATIOPNVALIDATOR fieldPath', fieldPath);
	//console.log('APPLICATIOPNVALIDATOR objectCopy', objectCopy);

	if (params && params.length > 0) {
		const currentValidateFunction = get(objectCopy, fieldPath);

		// console.log(
		// 	'APPLICATIOPNVALIDATOR get objectCopy',
		// 	get(objectCopy, 'attributes.Antragstellende_Organisa_0036308444_chtigung_prozentualer_Anteil'),
		// );
		// console.log('APPLICATIOPNVALIDATOR currentValidateFunction', currentValidateFunction);

		const input = params.map((param) => {
			//get value from Application if param is path
			if (isAttributesPath(param)) {
				return get(application, param);
			}
			return param;
		});
		if (input.length > 0) {
			const validateFunction = validate.validateFunction as Function;
			if (currentValidateFunction) {
				set(object, fieldPath, merge(currentValidateFunction, validateFunction(...input)));
				//console.log('APPLICATIOPNVALIDATOR object1', object);
			} else {
				set(object, fieldPath, validateFunction(...input));
				//console.log('APPLICATIOPNVALIDATOR object2', object);
			}
		}
	} else {
		const currentValidateFunction = get(objectCopy, fieldPath);
		//console.log('APPLICATIOPNVALIDATOR currentValidateFunction2', currentValidateFunction);

		const { validateFunction } = validate;
		if (typeof validateFunction === 'function') {
			//console.log('APPLICATIOPNVALIDATOR object', object);

			if (currentValidateFunction) {
				set(object, fieldPath, merge(currentValidateFunction, validateFunction()));
				//console.log('APPLICATIOPNVALIDATOR object3', object);
			} else {
				set(object, fieldPath, validateFunction());
				//console.log('APPLICATIOPNVALIDATOR object4', object);
			}
		} else {
			if (currentValidateFunction) {
				set(object, fieldPath, merge(currentValidateFunction, validateFunction));
				//console.log('APPLICATIOPNVALIDATOR object5', object);
			} else {
				set(object, fieldPath, validateFunction);
				//console.log('APPLICATIOPNVALIDATOR object6', object);
			}
		}
		// console.log('APPLICATIOPNVALIDATOR object end', object);
	}
}

export function isAttributesPath(str: any): boolean {
	if (typeof str === 'string') {
		const regex = /^attributes(\.[a-zA-Z0-9_]+)+$/;
		return regex.test(str);
	}
	return false;
}
