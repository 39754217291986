import { AttachFile, Delete, Download } from '@mui/icons-material';
import {
	Backdrop,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormHelperText,
	IconButton,
	List,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { get, isArray } from 'lodash';
import React, { FC, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { getUserJwt } from '../../../../../context/AuthHelper';
import { DeleteFile, PostFile } from '../../../../../lib/api/files';
import {
	FILE_UPLOAD_ALLOWED_FORMATS,
	FILE_UPLOAD_ALLOWED_FORMATS_ONLY_IMAGES,
	FILE_UPLOAD_ALLOWED_NUMBER,
	FILE_UPLOAD_ALLOWED_SIZE,
	INVALID_FILE_UPLOAD_TEXT_MESSAGE,
	INVALID_ONLY_IMAGES_UPLOAD_TEXT_MESSAGE,
} from '../../../../../lib/constants/constant';
import { STRAPI_URL } from '../../../../../lib/constants/url';
import { MUIFieldProps } from '../../../../../lib/types/types';
import { DisplayDateTime, DisplayFileSize, GetFileSize } from '../../../../../utils/formats';
import LoadingBackdrop from '../../../loading/LoadingBackdrop';

const MultiUploadField: FC<MUIFieldProps> = (props) => {
	const { label, fieldValue, updateFieldValue, isDisabled, errorMessage, allowOnlyImage } = props;

	const maxAllowedFileSize = get(props, 'maxFileSize', FILE_UPLOAD_ALLOWED_SIZE);
	const maxAllowedFilesNumbers = get(props, 'maxFiles', FILE_UPLOAD_ALLOWED_NUMBER);

	const mediaFieldKey = Object.entries(get(props, 'schema.attributes', {}))
		.filter(([_key, value]) => get(value, 'type') === 'media')
		.map(([key]) => key);

	const idPath = get(props, 'component', '') === '' ? 'id' : `${mediaFieldKey[0]}.id`;
	const urlPath = get(props, 'component', '') === '' ? 'attributes.url' : `${mediaFieldKey[0]}.attributes.url`;
	const mimePath = get(props, 'component', '') === '' ? 'attributes.mime' : `${mediaFieldKey[0]}.attributes.mime`;
	const namePath = get(props, 'component', '') === '' ? 'attributes.name' : `${mediaFieldKey[0]}.attributes.name`;
	const sizePath = get(props, 'component', '') === '' ? 'attributes.size' : `${mediaFieldKey[0]}.attributes.size`;
	const createdAtPath = get(props, 'component', '') === '' ? 'attributes.createdAt' : `${mediaFieldKey[0]}.attributes.createdAt`;

	const [imageSrc, setImageSrc] = useState<any>(null);
	const [showImage, setShowImage] = useState(false);

	const [deleteOpen, setDeleteOpen] = useState(-1);

	const [tooBigFiles, setTooBigFiles] = useState<any[]>([]);
	const [invalidFileFormat, setInvalidFileFormat] = useState<any[]>([]);
	const [tooManyFilesUploaded, setTooManyFilesUPloaded] = useState<boolean>(false);
	const [webserviceActive, setWebserviceActive] = useState<boolean>(false);

	const ALLOWED_FORMATS = allowOnlyImage ? FILE_UPLOAD_ALLOWED_FORMATS_ONLY_IMAGES : FILE_UPLOAD_ALLOWED_FORMATS;

	const handleShowImagePreview = async (url: string, fileType: string) => {
		if (fileType.includes('image')) fetchFile(url);
		else; // Do Nothing
	};
	const handleDownloadFile = async (url: string, fileName: string) => {
		await fetchFile(url, true, fileName);
	};
	const fetchFile = async (url: string, download: boolean = false, fileName: string = '') => {
		try {
			setWebserviceActive(true);
			let res = await fetch(`${STRAPI_URL}${url}`, {
				headers: {
					Authorization: `Bearer ${getUserJwt()}`,
				},
			});

			const blob = await res.blob();
			const objectUrl = URL.createObjectURL(blob);
			if (download) {
				const link = document.createElement('a');
				link.href = objectUrl;
				link.download = fileName;
				document.body.appendChild(link);
				link.click();
				link.remove();
				URL.revokeObjectURL(objectUrl);
			} else {
				setImageSrc(objectUrl);
			}
		} catch (err: any) {
			console.error(' ERROR ');
		} finally {
			if (!download) setShowImage(true);
			setWebserviceActive(false);
		}
	};

	const handleUploadFiles = async (files: any) => {
		let fileArr = Array.from(files);
		let invalidFile = fileArr.filter((f: any) => !ALLOWED_FORMATS.includes(f.type));
		if (invalidFile) setInvalidFileFormat(invalidFile);
		if (invalidFile.length === 0) {
			let tooBig = fileArr.filter((f: any) => f.size > FILE_UPLOAD_ALLOWED_SIZE);
			setTooBigFiles(tooBig);
		}

		let okay = fileArr.filter((f: any) => f.size <= FILE_UPLOAD_ALLOWED_SIZE && ALLOWED_FORMATS.includes(f.type));
		let newFiles: any = [
			...(isArray(fieldValue) ? fieldValue : []),
			...(await Promise.all(
				okay.map(async (file) => {
					try {
						setWebserviceActive(true);
						let res = await PostFile(file);
						if (get(props, 'component', '') === '') {
							return { id: get(res[0], 'id'), attributes: res[0] };
						} else {
							return { [mediaFieldKey[0]]: { id: get(res[0], 'id'), attributes: res[0] } };
						}
					} catch (err: any) {
						console.error('ERROR: Uploading Files ', err);
					} finally {
						setWebserviceActive(false);
					}
				})
			)),
		];

		if (newFiles.length > maxAllowedFilesNumbers) {
			setTooManyFilesUPloaded(true);
		}

		let e = { target: { value: newFiles.slice(0, maxAllowedFilesNumbers) } };
		updateFieldValue(e as React.ChangeEvent<HTMLInputElement>);
	};

	const handleDeleteFile = async (index: number) => {
		let fileId = get(fieldValue, `${index}.${idPath}`, '');
		if (fileId) {
			setWebserviceActive(true);
			DeleteFile(fileId).finally(() => setWebserviceActive(false));
		}
		let files = fieldValue;
		let e = { target: { value: files.filter((_f: any, i: number) => i !== index) } };
		updateFieldValue(e as React.ChangeEvent<HTMLInputElement>);
	};

	return (
		<>
			<Typography sx={{ marginTop: '20px' }}>{label}</Typography>
			<Box sx={{ mt: '0', border: '1px solid #EAF0F1', borderRadius: '4px', padding: '1rem', backgroundColor: '#fff' }}>
				<Backdrop open={showImage} onClick={() => setShowImage(false)} sx={{ zIndex: 999 }}>
					<img src={`${imageSrc}`} alt='Test' width='600' />
				</Backdrop>

				<LoadingBackdrop open={webserviceActive} />

				<Dialog open={deleteOpen >= 0} onClose={() => setDeleteOpen(-1)} aria-labelledby={'delete-file-dialog'}>
					<DialogTitle id={'delete-file-dialog'}>Datei löschen</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Möchten Sie die Datei <b>{get(fieldValue, `${deleteOpen}.${namePath}`)}</b> löschen?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant='outlined' onClick={() => setDeleteOpen(-1)} color='primary'>
							Abbrechen
						</Button>
						<Button
							variant='contained'
							onClick={() => {
								handleDeleteFile(deleteOpen);
								setDeleteOpen(-1);
							}}
							color='error'
						>
							Löschen
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog open={tooBigFiles.length > 0} onClose={() => setTooBigFiles([])} aria-labelledby={'too-big-file-dialog'}>
					<DialogTitle id={'too-big-file-dialog'}>{tooBigFiles.length > 1 ? 'Dateien' : 'Datei'} zu groß</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Die hochgeladene {tooBigFiles.length > 1 ? 'Dateien' : 'Datei'}
							{tooBigFiles.map((f) => (
								<p>{f.name}</p>
							))}
							{tooBigFiles.length > 1 ? 'sind' : 'ist'} überschreitet die zulässige Größenbeschränkung ({DisplayFileSize(maxAllowedFileSize)}) und{' '}
							{tooBigFiles.length > 1 ? 'wurden' : 'wurde'} daher verworfen. <br /> Bitte laden Sie eine kleinere Datei hoch oder reduzieren Sie die
							Dateigröße und versuchen Sie es erneut.
						</DialogContentText>
					</DialogContent>
					<DialogActions sx={{ justifyContent: 'center' }}>
						<Button variant='outlined' onClick={() => setTooBigFiles([])}>
							Schließen
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog open={invalidFileFormat.length > 0} onClose={() => setInvalidFileFormat([])} aria-labelledby={'too-big-file-dialog'}>
					<DialogTitle id={'too-big-file-dialog'}>{invalidFileFormat.length > 1 ? 'Dateien' : 'Datei'} Ungültiges Dateiformat!</DialogTitle>
					<DialogContent sx={{ textAlign: 'left' }}>
						<DialogContentText>
							Die {invalidFileFormat.length > 1 ? 'Dateien' : 'Datei'}
							{invalidFileFormat.map((f, i) => (
								<List key={i}>{f.name}</List>
							))}
							{invalidFileFormat.length > 1 ? 'sind' : 'ist'} nicht erlaubt und {invalidFileFormat.length > 1 ? 'wurden' : 'wurde'} daher verworfen.
							<br /> <br />
							{allowOnlyImage ? INVALID_ONLY_IMAGES_UPLOAD_TEXT_MESSAGE : INVALID_FILE_UPLOAD_TEXT_MESSAGE}
						</DialogContentText>
					</DialogContent>
					<DialogActions sx={{ justifyContent: 'center' }}>
						<Button variant='outlined' onClick={() => setInvalidFileFormat([])}>
							Schließen
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog open={tooManyFilesUploaded} onClose={() => setTooManyFilesUPloaded(false)} aria-labelledby={'too-big-file-dialog'}>
					<DialogTitle id={'too-big-file-dialog'}>Zuviele Dateien</DialogTitle>
					<DialogContent>
						<DialogContentText>Sie können {maxAllowedFilesNumbers} Dateien hochladen. Daher wurden einige Dateien verworfen</DialogContentText>
					</DialogContent>
					<DialogActions sx={{ justifyContent: 'center' }}>
						<Button variant='outlined' onClick={() => setTooManyFilesUPloaded(false)}>
							Schließen
						</Button>
					</DialogActions>
				</Dialog>

				{!isDisabled && (
					<>
						<FileUploader multiple={true} handleChange={handleUploadFiles} disabled={isDisabled}>
							<Box
								sx={{
									border: `${errorMessage ? '2px dashed #f00' : '2px dashed #023671'}`,
									borderRadius: '9px',
									padding: '2rem',
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<AttachFile sx={{ width: '3em', height: '2em' }} />
								<Typography variant='body2' component={'div'}>
									Datei auswählen oder per Drag and Drop hier ablegen.
									<br />
									Maximal {maxAllowedFilesNumbers} Dateien. Jede Datei darf maximal 10 MB groß sein
								</Typography>
							</Box>
						</FileUploader>
						{errorMessage ?
							<FormHelperText error sx={{ marginLeft: '1rem' }}>
								{errorMessage}
							</FormHelperText>
						:	null}
					</>
				)}
				<TableContainer sx={{ overflow: 'initial', mt: 1 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell sx={{ padding: '0.5rem 1rem' }}>Dateiname</TableCell>
								<TableCell align='right' sx={{ padding: '0.5rem 1rem' }}>
									Dateigröße
								</TableCell>
								{!get(props, 'hideUploadDate') && (
									<TableCell align='right' sx={{ padding: '0.5rem 1rem' }}>
										Hochgeladen am
									</TableCell>
								)}
								<TableCell align='right' sx={{ padding: '0.5rem 1rem' }}>
									Aktion
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{isArray(fieldValue) ?
								fieldValue.map((eachFileAttributes: any, i: number) => {
									return (
										<TableRow key={i}>
											<TableCell
												onClick={() => handleShowImagePreview(get(eachFileAttributes, urlPath, ''), get(eachFileAttributes, mimePath, ''))}
												sx={{
													...(get(eachFileAttributes, mimePath, '').includes('image') ? { cursor: 'pointer' } : {}),
													padding: '0.5rem 1rem',
												}}
											>
												{get(eachFileAttributes, namePath, '')}
											</TableCell>
											<TableCell align='right' sx={{ padding: '0.5rem 1rem' }}>
												{GetFileSize(get(eachFileAttributes, sizePath) / (get(eachFileAttributes, idPath) ? 1 : 1000))}
											</TableCell>
											{!get(props, 'hideUploadDate') && (
												<TableCell align='right' sx={{ padding: '0.5rem 1rem' }}>
													{DisplayDateTime(get(eachFileAttributes, createdAtPath, new Date()))}
												</TableCell>
											)}
											<TableCell align='right' sx={{ padding: '0.5rem 1rem' }}>
												{!isDisabled && (
													<IconButton aria-label='' onClick={() => setDeleteOpen(i)}>
														<Delete />
													</IconButton>
												)}
												{get(eachFileAttributes, urlPath, '') && (
													<IconButton onClick={() => handleDownloadFile(get(eachFileAttributes, urlPath), get(eachFileAttributes, namePath))}>
														<Download />
													</IconButton>
												)}
											</TableCell>
										</TableRow>
									);
								})
							:	null}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

export default MultiUploadField;
