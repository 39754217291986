import React, { FC } from 'react';
import FoerderAntragRightSidebar from './right-side-bar/FoerderAntragRightSidebar';

const AntragRightSideBar: FC<{}> = () => {
	const RenderSidebar = () => {
		return <FoerderAntragRightSidebar />;
	};

	return <RenderSidebar />;
};

export default AntragRightSideBar;
