import EmailIcon from '@mui/icons-material/Email';
import { Box, Button, Stack, Typography } from '@mui/material';
import { get } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingBackdrop from '../../../../components/ui-component/loading/LoadingBackdrop';
import { MeinBereichPaper } from '../../../../components/ui-component/mein-bereich/MeinBereichComponents';
import { useFetchnachrichtenStartSeite } from '../../../../hooks/FetchNachrichten';
import { PAGES_URLS } from '../../../../lib/constants/routes';
import { DisplayDateTime } from '../../../../utils/formats';

// import Schemas
import Schema from '../../../../lib/paths/nachricht_FULL_Paths.json';
let P = Schema.attributes;

function Nachrichten() {
	const navigate = useNavigate();
	const { loading, error, data } = useFetchnachrichtenStartSeite();

	if (error) console.error('ERROR Fetching Nachrichten', error);
	if (loading) return <LoadingBackdrop />;
	return (
		<>
			<MeinBereichPaper title='Nachrichten' titleIcon={<EmailIcon />}>
				{data?.map((ele, i) => (
					<Box key={i} sx={{ bgcolor: 'common.white', padding: '1rem' }}>
						<Stack spacing={1} alignItems={'stretch'}>
							<Stack direction={'row'} justifyContent={'flex-end'}>
								<Typography variant={'caption'}>Erstellt am: {DisplayDateTime(get(ele, 'attributes.createdAt'))}</Typography>
							</Stack>
							<Stack direction={'row'} justifyContent={'space-between'}>
								<Typography variant={'subtitle2'} sx={{ fontWeight: 500 }}>
									{get(ele, P.Betreff.path)}
								</Typography>
							</Stack>
							<Typography
								variant='subtitle2'
								color='text.secondary'
								component={'div'}
								sx={{
									height: '2.5rem',
									overflow: 'hidden',
									display: '-webkit-box',
									WebkitBoxOrient: 'vertical',
									WebkitLineClamp: 2,
								}}
							>
								<div
									id='no-margin'
									dangerouslySetInnerHTML={{
										__html: get(ele, P.Inhalt.path),
									}}
								></div>
							</Typography>
							<Stack direction={'row'} justifyContent={'flex-end'}>
								<Button variant={'text'} onClick={() => navigate(`${PAGES_URLS.NachrichtenDetails}`, { state: { id: get(ele, 'id') } })}>
									Ansehen
								</Button>
							</Stack>
						</Stack>
					</Box>
				))}

				<Button
					variant={'text'}
					onClick={() => navigate(PAGES_URLS.Nachrichten)}
					sx={{ marginLeft: 'auto !important', textDecoration: 'underline', color: 'text.primary' }}
				>
					Alle anzeigen
				</Button>
			</MeinBereichPaper>
		</>
	);
}

export default Nachrichten;
