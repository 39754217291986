import { Help } from '@mui/icons-material';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import { get } from 'lodash';
import React, { FC } from 'react';
import { MUIFieldProps } from '../../../../../lib/types/types';
import { isTrue } from '../../../../../utils/helper';
import MUIToolTip from '../../../mui-tooltip/MUIToolTip';

const MUICheckboxField: FC<MUIFieldProps> = (props) => {
	const { label, tooltip, fieldValue, updateFieldValue, isDisabled, error, errorMessage, ...restProps } = props;

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let event = { target: { value: get(e, 'target.checked', false) } };
		updateFieldValue(event as React.ChangeEvent<HTMLInputElement>);
	};
	return (
		<>
			<FormControl fullWidth error={error} {...restProps} sx={{ mt: '1rem' }}>
				<FormGroup>
					<FormControlLabel
						disabled={isDisabled}
						label={
							tooltip ? (
								<>
									<>{label}</>
									<MUIToolTip title={tooltip}>
										<Help fontSize="small" color="primary" sx={{ ml: '5px' }} />{' '}
									</MUIToolTip>
								</>
							) : (
								<>{label}</>
							)
						}
						checked={isTrue(fieldValue) ? true : false}
						control={<Checkbox onChange={handleOnChange} sx={{ paddingTop: '2px' }} />}
						sx={{ alignItems: 'flex-start' }}
					/>
				</FormGroup>
			</FormControl>
			{error && <FormHelperText error>{errorMessage}</FormHelperText>}
		</>
	);
};
export default MUICheckboxField;
