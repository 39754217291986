import React from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Container,
	TextField,
	Typography,
	InputAdornment,
	IconButton,
	Alert,
} from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import useFetchFaq from '../../../hooks/FetchFaq';
import LoadingBackdrop from '../../../components/ui-component/loading/LoadingBackdrop';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { get } from 'lodash';
import Highlighter from 'react-highlight-words';

function Faq() {
	const [filteredItems, setFilteredItems] = useState<any[]>([]);
	const [searchWord, setSearchWord] = useState<string>('');
	const { loading, data, error } = useFetchFaq();

	useEffect(() => {
		if (!data) return;

		if (searchWord === '') {
			setFilteredItems(data);
		} else {
			const result: any[] = [];
			const topics = data || [];

			for (let i = 0; i < topics.length; i++) {
				const topic = topics[i];
				const items = topic || [];
				const question = get(items, 'attributes.Allgemein_Frage', '');
				const matched = question.match(new RegExp(`(${searchWord})`, 'gi'));
				if (matched !== null) {
					result.push(items);
				}
			}
			setFilteredItems(result);
		}
	}, [data, searchWord]);

	if (loading) {
		return <LoadingBackdrop />;
	}

	return (
		<>
			{error && <Alert severity="error"> {error}</Alert>}
			<Container>
				<Typography variant="h1"> Häufig gestellte Fragen</Typography>
				<FaqSearchForm {...{ setSearchWord }} />
				<FaqList faqs={filteredItems} searchWord={searchWord} />
			</Container>
		</>
	);
}

const FaqSearchForm: FunctionComponent<FaqSearchFormProps> = ({ setSearchWord }) => {
	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = e.target.value;
		setSearchWord(value.length >= 3 ? value : '');
	};

	return (
		<>
			<Box sx={{ maxWidth: '40.3125rem', /*645px*/ margin: '2rem auto' }}>
				<TextField
					fullWidth
					label="Stellen Sie Ihre Frage"
					variant="filled"
					id="faq-search"
					className="text-field-without-label"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton>
									<SearchIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
					onChange={handleSearch}
				/>
			</Box>
		</>
	);
};

const FaqList: FunctionComponent<FaqListProps> = ({ faqs, searchWord }) => {
	return (
		<>
			{(faqs || []).map((ele, i) => (
				<Box key={i}>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
							<Highlighter
								searchWords={searchWord.split(/\s/)}
								autoEscape={true}
								textToHighlight={get(ele, 'attributes.Allgemein_Frage')}
							/>
						</AccordionSummary>
						<AccordionDetails>
							<Typography component={'div'}>
								<div
									dangerouslySetInnerHTML={{
										__html: get(ele, 'attributes.Allgemein_Antwort'),
									}}
								></div>
							</Typography>
						</AccordionDetails>
					</Accordion>
				</Box>
			))}
		</>
	);
};

interface FaqListProps {
	faqs: any[];
	searchWord: string;
}
interface FaqSearchFormProps {
	setSearchWord: (value: string) => void;
}
export default Faq;
