// const qs = require('qs');

import qs from 'qs';

function generatePopulateAPIConfig(populations: any[]): string {
	const queryStringArray: string[] = [];

	for (let i = 0; i < populations.length; i++) {
		const item = populations[i];

		if (typeof item === 'object') {
			Object.keys(item).forEach((keyName) => {
				const value = item[keyName];
				if (keyName.includes('.')) {
					const keyName_Arr = keyName.split('.');
					let poplevelResult = '';
					for (let j = 0; j < keyName_Arr.length; j++) {
						const eachPopLevel = keyName_Arr[j];
						if (j > 0) poplevelResult += `[populate][${eachPopLevel}]`;
						else poplevelResult += `populate[${eachPopLevel}]`;
					}
					if (Array.isArray(value)) {
						value.forEach((ele) => queryStringArray.push(`${poplevelResult}[fields][]=${ele}`));
					} else {
						queryStringArray.push(`${poplevelResult}[fields][]=${value}`);
					}
				} else {
					if (Array.isArray(value)) {
						value.forEach((ele) => queryStringArray.push(`[populate][${keyName}][fields][]=${ele}`));
					} else {
						queryStringArray.push(`populate[${keyName}][fields][]=${value}`);
					}
				}
			});
		} else if (typeof item === 'string') {
			if (item.includes('.')) {
				const eachPopLevel_Arr = item.split('.');
				let poplevelResult = '';
				for (let j = 0; j < eachPopLevel_Arr.length; j++) {
					const eachPopLevel = eachPopLevel_Arr[j];
					if (j > 0) poplevelResult += `[populate][${eachPopLevel}]`;
					else poplevelResult += `populate[${eachPopLevel}]`;
				}
				queryStringArray.push(`${poplevelResult}[fields][]=*`);
			} else {
				queryStringArray.push(`populate[${item}][fields][]=*`);
			}
		}
	}
	return queryStringArray.join('&');
}
function getQueryString(poplevel: any[], filter: any[] | Record<string, any>, sort: any[] = [], pagination: object = {}, fields: any[] = []) {
	let filterOptions = qs.stringify(
		{
			filters: filter,
			sort,
			pagination,
			fields,
		},
		{
			encodeValuesOnly: true,
		}
	);

	let query = '';
	if (filterOptions.length > 0 || poplevel.length > 0) {
		query = '?';
	}
	if (filterOptions.length > 0) {
		query += filterOptions;
	}
	if (filterOptions.length > 0 && poplevel) {
		query += '&';
	}
	if (poplevel) {
		query += generatePopulateAPIConfig(poplevel);
	}
	return query;
}
export { generatePopulateAPIConfig, getQueryString };
