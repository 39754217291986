import { getUserJwt } from '../../context/AuthHelper';
import { METHOD_GET } from '../constants/apis';
import { STRAPI_URL } from '../constants/url';
import { getQueryString } from './Population';

export default async function GetRecord(
	api: string,
	id: number,
	poplevel: any[] = [],
	filter: any[] = [],
	sort: any[] = [],
	pagination: object = {},
	fields: any = []
) {
	const queryString = getQueryString(poplevel, filter, sort, pagination, fields);
	const fullURL = `${STRAPI_URL}/api/${api}/${id}${queryString}`;

	let response = await fetch(fullURL, {
		method: METHOD_GET,
		headers: {
			Authorization: `Bearer ${getUserJwt()}`,
		},
	});

	if (!response.ok) {
		throw new Error(await response.text());
	}
	const responseData = response.json();
	return responseData;
}
