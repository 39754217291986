import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Paper, Stack, Typography } from '@mui/material';
import React, { FunctionComponent, ReactNode } from 'react';
import theme from '../../../assets/theme/theme';

const MeinBereichPaper: FunctionComponent<MeinBereichPaperProps> = ({ titleIcon, title, children }) => {
	return (
		<>
			<Paper sx={{ padding: '1rem 1.5rem', marginTop: '1rem', minHeight: '20rem' }}>
				<Stack direction={'row'} spacing={1} alignItems={'center'} color={theme.palette.secondary.main}>
					{titleIcon}
					<Typography variant='h3' component='h2'>
						{title}
					</Typography>
				</Stack>
				<Stack spacing={2} sx={{ marginTop: '2rem' }} alignItems={'stretch'}>
					{children}
				</Stack>
			</Paper>
		</>
	);
};

const MeinBereichButton: FunctionComponent<MeinBereichButtonProps> = ({ buttonName, onClick, disabled = false }) => {
	return (
		<>
			<Button variant='meinBereich' endIcon={<KeyboardArrowRightIcon />} onClick={onClick} disabled={disabled}>
				{buttonName}
			</Button>
		</>
	);
};

interface MeinBereichPaperProps {
	titleIcon?: ReactNode;
	title: string;
	children: ReactNode;
}
interface MeinBereichButtonProps {
	buttonName: string;
	onClick?: () => void;
	disabled?: boolean;
}
export { MeinBereichButton, MeinBereichPaper };
