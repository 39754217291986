import { AttachFile, Download } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Backdrop,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormHelperText,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { get } from 'lodash';
import React, { FC, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { getUserJwt } from '../../../../../context/AuthHelper';
import {
	FILE_UPLOAD_ALLOWED_FORMATS,
	FILE_UPLOAD_ALLOWED_FORMATS_ONLY_IMAGES,
	FILE_UPLOAD_ALLOWED_SIZE,
	INVALID_FILE_UPLOAD_TEXT_MESSAGE,
	INVALID_ONLY_IMAGES_UPLOAD_TEXT_MESSAGE,
} from '../../../../../lib/constants/constant';
import { STRAPI_URL } from '../../../../../lib/constants/url';
import { MUIFieldProps } from '../../../../../lib/types/types';
import { DisplayFileSize, GetFileSize } from '../../../../../utils/formats';

import { DeleteFile, PostFile } from '../../../../../lib/api/files';
import LoadingBackdrop from '../../../loading/LoadingBackdrop';

const SingleUpload: FC<MUIFieldProps> = (props) => {
	const { label, fieldValue, updateFieldValue, isDisabled, errorMessage, allowOnlyImage } = props;
	const maxAllowedFileSize = get(props, 'maxFileSize', FILE_UPLOAD_ALLOWED_SIZE);

	const [imageSrc, setImageSrc] = useState<any>(null);
	const [showImage, setShowImage] = useState(false);

	const [deleteOpen, setDeleteOpen] = useState(-1);

	const [tooBigFiles, setTooBigFiles] = useState<any>('');
	const [invalidFileFormat, setInvalidFileFormat] = useState<any>('');
	const [webserviceActive, setWebserviceActive] = useState<boolean>(false);

	const ALLOWED_FORMATS = allowOnlyImage ? FILE_UPLOAD_ALLOWED_FORMATS_ONLY_IMAGES : FILE_UPLOAD_ALLOWED_FORMATS;

	const handleShowImagePreview = async (url: string, fileType: string) => {
		if (fileType.includes('image')) fetchFile(url);
		else; // Do Nothing
	};
	const handleDownloadFile = async (url: string, fileName: string) => {
		await fetchFile(url, true, fileName);
	};
	const fetchFile = async (url: string, download: boolean = false, fileName: string = '') => {
		try {
			setWebserviceActive(true);
			let res = await fetch(`${STRAPI_URL}${url}`, {
				headers: {
					Authorization: `Bearer ${getUserJwt()}`,
				},
			});

			const blob = await res.blob();
			const objectUrl = URL.createObjectURL(blob);
			if (download) {
				const link = document.createElement('a');
				link.href = objectUrl;
				link.download = fileName;
				document.body.appendChild(link);
				link.click();
				link.remove();
				URL.revokeObjectURL(objectUrl);
			} else {
				setImageSrc(objectUrl);
			}
		} catch (err: any) {
			console.error(' ERROR: Downloading File ', err);
		} finally {
			if (!download) setShowImage(true);
			setWebserviceActive(false);
		}
	};

	const validateFile = (file: any) => {
		if (!ALLOWED_FORMATS.includes(file.type)) {
			setInvalidFileFormat('Bitte laden Sie eine gültige Datei im Format .docx, .pdf, .xlsx, .png oder .jpeg hoch.');
			return false;
		}

		if (file.size > maxAllowedFileSize) {
			setTooBigFiles(file);
			return false;
		}
		return true;
	};

	const handleUploadSingleFile = async (file: any) => {
		if (validateFile(file)) {
			try {
				setWebserviceActive(true);
				let res = await PostFile(file);
				let e = { target: { value: { id: get(res, '0.id'), attributes: res[0] } } };
				updateFieldValue(e as unknown as React.ChangeEvent<HTMLInputElement>);
			} catch (err: any) {
				console.error('ERROR: Uploading FILE ', err);
			} finally {
				setWebserviceActive(false);
			}
		}
	};

	const handleDeleteFile = async () => {
		let fileId = get(fieldValue, `id`, '');
		if (fileId) {
			setWebserviceActive(true);
			DeleteFile(fileId).finally(() => setWebserviceActive(false));
		}
		let e = { target: { value: null } };
		updateFieldValue(e as unknown as React.ChangeEvent<HTMLInputElement>);
	};

	return (
		<>
			<LoadingBackdrop open={webserviceActive} />
			<Backdrop open={showImage} onClick={() => setShowImage(false)} sx={{ zIndex: 999 }}>
				<img src={`${imageSrc}`} alt='Vorschau Bild' width={600} />
			</Backdrop>
			<Dialog open={deleteOpen >= 0} onClose={() => setDeleteOpen(-1)} aria-labelledby={'delete-file-dialog'}>
				<DialogTitle id={'delete-file-dialog'}>Datei löschen</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Möchten Sie die Datei <b>{get(fieldValue, `${deleteOpen}.attributes.name`)}</b> löschen?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant='outlined' onClick={() => setDeleteOpen(-1)} color='primary'>
						Abbrechen
					</Button>
					<Button
						variant='contained'
						onClick={() => {
							handleDeleteFile();
							setDeleteOpen(-1);
						}}
						color='error'
					>
						Löschen
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={get(tooBigFiles, 'name', '').length > 0} onClose={() => setTooBigFiles('')} aria-labelledby={'too-big-file-dialog'}>
				<DialogTitle id={'too-big-file-dialog'}>Datei zu groß</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Die hochgeladene Datei <strong>{'"' + get(tooBigFiles, 'name', '') + '"'}</strong> überschreitet die zulässige Größenbeschränkung (
						{DisplayFileSize(maxAllowedFileSize)}) und daher verworfen. <br />
						Bitte laden Sie eine kleinere Datei hoch oder reduzieren Sie die Dateigröße und versuchen Sie es erneut.
					</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button variant='outlined' onClick={() => setTooBigFiles('')}>
						Schließen
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={invalidFileFormat !== '' ? true : false} onClose={() => setInvalidFileFormat('')} aria-labelledby={'too-big-file-dialog'}>
				<DialogTitle id={'too-big-file-dialog'}>Ungültiges Dateiformat!</DialogTitle>
				<DialogContent sx={{ textAlign: 'left' }}>
					<DialogContentText>{allowOnlyImage ? INVALID_ONLY_IMAGES_UPLOAD_TEXT_MESSAGE : INVALID_FILE_UPLOAD_TEXT_MESSAGE}</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<Button variant='outlined' onClick={() => setInvalidFileFormat('')}>
						Schließen
					</Button>
				</DialogActions>
			</Dialog>

			{label && <Typography sx={{ mt: '0' }}>{label}</Typography>}
			{get(fieldValue, 'attributes.name') ?
				<>
					<Box
						sx={{
							border: `${errorMessage ? '2px dashed #f00' : '2px dashed #00818B'}`,
							borderRadius: '9px',
							padding: '0.2rem 1rem',
							cursor: 'pointer',
						}}
					>
						<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
							<Stack direction={'row'} spacing={1}>
								<Typography
									variant='body2'
									component={'div'}
									onClick={() => handleShowImagePreview(get(fieldValue, 'attributes.url'), get(fieldValue, 'attributes.mime'))}
								>
									<Tooltip title={get(fieldValue, 'attributes.name', '')} placement='top' arrow>
										<>
											{get(fieldValue, 'attributes.name', '').length > 40 ?
												get(fieldValue, 'attributes.name', '').slice(0, 34) + '...' + get(fieldValue, 'attributes.name', '').slice(-4)
											:	get(fieldValue, 'attributes.name', '')}
										</>
									</Tooltip>
								</Typography>
								<Typography variant='body2' component={'div'}>
									{'-'}
								</Typography>
								<Typography variant='body2' component={'div'}>
									{GetFileSize(get(fieldValue, 'attributes.size'))}
								</Typography>
							</Stack>
							<Stack direction={'row'}>
								{get(fieldValue, 'attributes.url') && (
									<IconButton onClick={() => handleDownloadFile(get(fieldValue, 'attributes.url'), get(fieldValue, 'attributes.name'))}>
										<Download />
									</IconButton>
								)}

								<IconButton disabled={isDisabled} onClick={() => setDeleteOpen(1)}>
									<DeleteIcon />
								</IconButton>
							</Stack>
						</Stack>
					</Box>
				</>
			:	<>
					{!isDisabled ?
						<>
							<FileUploader multiple={false} handleChange={handleUploadSingleFile} disabled={isDisabled}>
								<Box
									sx={{
										border: `${errorMessage ? '2px dashed #f00' : '2px dashed #00818B'}`,
										borderRadius: '9px',
										padding: '1.1rem',
										cursor: 'pointer',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<AttachFile sx={{ width: '3rem', height: '1rem' }} />
									<Typography variant='body2' component={'div'}>
										Datei auswählen oder hierher ziehen
									</Typography>
								</Box>
							</FileUploader>
							{errorMessage ?
								<FormHelperText error sx={{ marginLeft: '1rem' }}>
									{errorMessage}
								</FormHelperText>
							:	null}
						</>
					:	<Box
							sx={{
								border: `${errorMessage ? '2px dashed #f00' : '2px dashed #00818B'}`,
								borderRadius: '9px',
								padding: '1.1rem',
								cursor: 'pointer',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Typography>Es wurde kein Dokument hochgeladen.</Typography>
						</Box>
					}
				</>
			}
		</>
	);
};
export default SingleUpload;
