export const typography = {
	defaultProps: {
		color: 'primary',
		fontSize: '1rem', // 16px
		fontWeight: 'normal',
	},
	fontFamily: 'Manrope, sans-serif',
	h1: {
		fontSize: '2.75rem', // 44px
	},
	h2: {
		fontSize: '2.25rem', //36px
		lineHeight: '1.75rem', // 28px
	},
	h3: {
		fontSize: '1.5rem', //24px
		fontWeight: 600,
		lineHeight: '2.7rem', // 43.2px
	},
	h4: {
		fontSize: '1.375rem', //22px
		fontWeight: 500,
		lineHeight: '1.69rem', // 27.17px
	},
	h5: {
		fontSize: '1.25rem', //20px
		fontWeight: 600,
		lineHeight: '1.68rem', // 26.68px
	},
	h6: {
		fontSize: '1.0625rem', // 17px
		fontWeight: 500,
		lineHeight: '1.7rem', // 27.2px
	},
	body1: {
		fontSize: '1rem', // 16px
		fontWeight: 400,
		lineHeight: '25.5px', // 25.5px
	},
	body2: {
		fontSize: '0.875rem', // 17px
		fontWeight: 400,
		lineHeight: '1.25rem', // 25.5px
	},
	subtitle1: {
		fontSize: '0.938rem', // 15px
		fontWeight: 400,
		lineHeight: '1.75rem', // 28px
	},
	subtitle2: {
		fontSize: '0.938rem', // 15px
		fontWeight: 400,
		lineHeight: '1.25rem', // 25.5px
	},
};
